
export const tokenGenerationUrl = 'https://medfountwebapi.azurewebsites.net/token'
export const tokendata = 'token_data'
export const user_data = 'user_data'
export const user_info = 'user_info'
export const user_id = 'user_id'
export const tokenExpiresOn = 'token_expires_on'
export const product_data = 'product_data'
export const user_subscription_details = ''
export const ThirdPartyLMSEvaluations = null
export const AlertTimeOut = 5000

export const RetentasType = {
    Surveys: 101,
    PRFs: 102,
}

export const TriggeredFrom = {
    Analytics: 1,
    Menu: 2
}

export const Rights = {
    Admin: 41,
    Manager: 23,
    Preceptor: 22,
}

export const UserLookup = {
    Policy: 1,
    MyTeam: 2
}

export const CheckListGroupBy = {
    Assignment: '1',
    Competency: '2',
    User: '3',
    All: '4',    
}

// export const MenuItemHome = 1
// export const MenuItemProfile = 2
// export const MenuItemQuestionnaire = 3
// export const MenuItemPerformance = 4
// export const MenuItemMyTeam = 5
// export const MenuItemPillars = 6
// export const MenuItemAssignments = 7
// export const MenuItemReports = 8
// export const MenuItemLogin = 9
// export const MenuItemLogout = 10
// export const MenuItemDivisions = 11
// export const MenuItemRatings = 12
// export const MenuItemJobPositions = 13
// export const MenuItemGoals = 14
// export const MenuItemCompetencies = 15
// export const MenuItemUserLookup = 16
// export const MenuItemCompetencyChecklist = 17
// export const MenuItemJobClassTree = 18
// export const MenuItemNotificationMessage = 19
// export const MenuItemProcessStep = 20
// export const MenuItemReminder = 21

export const MenuType =
{
    Home: 85, 
    Profile: 36,
    Questionnaire: 86,
    Performance: 72,
    MyTeam: 10,
    Pillars: 50,
    Assignments: 29,
    Reports: 52,
    Login: 87,
    Logout: 88,
    Divisions: 16,
    Ratings: 89,
    JobPositions: 45,
    Goals: 69,
    Competencies: 63,
    UserLookup: 90,
    CompetencyChecklist: 91,
    JobClassTree: 43,
    NotificationMessage: 81,
    ProcessStep: 92,
    Reminder: 60,
    Policy: 12,
    Group: 11, 
    Menu: 13,
}

export const EvaluationType = {
    User: 1,
    Manager: 2,
    Peer: 3,
    Subordinate: 4,
    HR: 5,    
}

// export const UserEvaluationType = 1
// export const ManagerEvaluationType = 2
// export const PeerEvaluationType = 3
// export const SubordinateEvaluationType = 4
// export const HREvaluationType = 5

export const AttachmentType = {
    User: 1,
    PRF: 5,
}

// export const AttachmentTypeUser = 1
// export const AttachmentTypePRF = 5

export const EntityRowType = {
    User: 1,
    PRF: 8,
}

// export const EntityRowTypeUser = 1
// export const EntityRowTypePRF = 8

export const AcceptableAttachments = 'image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation'

export const AuditEntityType =
{
    Users: 0, Group: 1, Division: 2, Policy: 4, Menu: 5, JobClass: 6, JobCode: 7, JobPosition: 8, 
    Assignment: 12, Company: 18, Evaluation: 19, Report: 20, Help: 21, Reminder: 22, Skill: 23, ResourceType: 24,
    Sponsor: 27, Vendor: 28, UserFieldType: 30, ReportScheduler: 31, 
    EvaluationGroup: 33, RatingType: 34, EmploymentType: 35, NotificationMessage: 36
}

export const PolicyType =
{
    ManageUserAccounts: 2,
    ManageUserGroups: 3,
    ManagePolicies: 4,
    ManageDivisions: 5,
    ManageFacilities: 6,
    ManageMenu: 7,
    ManageAccountMerge: 8,
    ManageInstructor: 9,
    ManageCatalog: 10,
    ManageCourses: 11,
    ManageAnnotations: 12,
    ManageTestLibrary: 13,
    ManageAssignments: 14,
    ManageCertificates: 15,
    ManageStudentCourses: 16,
    ManageStudentTranscript: 17,
    ManageStudentProfile: 18,
    ManageRequestFromCatalog: 19,
    ManageClassCalender: 20,
    ManageStudentEnrollments: 21,
    InstructorRights: 22,
    ManagerRights: 23,
    StudentRights: 24,
    ClientSuperAdministrator: 25,
    ManageChat: 26,
    ManageForums: 27,
    ManageEmail: 28,
    ViewHelp: 29,
    ManageEvaluations: 30,
    ManageScormUploader: 31,
    MyReports: 32,
    ManageJobClass: 33,
    ManageJobCode: 34,
    ManageJobPosition: 35,
    ManageSkill: 36,
    SearchCourses: 37,
    ManageReminders: 38,
    ManageEvaluationQuestionGroup: 39,
    ManageVendor: 40,
    AdministratorRights: 41,
    ManageResourceType: 42,
    ManageGoalCategory: 43,
    ManageGoals: 44,
    ManageRatingType: 45,
    ReportAccessRights: 46,
    ManagePaperCertificate: 47,
    ManageMyPerformance: 48,
    ManageAppraisal: 49,
    ManageContentContributor: 50,
    ManageCoursesInstances: 51,
    ManagerUsersPassword: 52,
    ManageAccreditations: 53,
    ManageEvents: 54,
    ManageSponsors: 55,
    ManageDelegates: 56,
    ManageUserCustomFieldType: 57,
    ManageMandatoryEnrollmentCancellation: 58,
    ManageNotificationMessages: 59,
    ManageScheduleReportsforGroupsUsers: 60,
    ManageGlobalLookAndFeel: 61,
    ManageReportSetup: 62,
    ManageContentDistribution: 63,
    ManageContentApprovalForIKS: 64,
    ManageIndividualAssignmentsONLYAddUsers: 65,
    ReportExecutionRights: 66,
    ViewUserDocumentsHRAdministrator: 67,
    ManageCustomReports: 68,
    ManageMandatoryEnrollmentCancellationExceptCompletes: 69,
    ShowUserLoginLinkInProfile: 70,
    UploadEntityViaExcel: 71,
    CanManageResetEnrollment: 72,
    AllowSCORMReset: 73,
    ViewUserListHelpDesk: 74,
    CanAssignGlobalInstructorsInLOI: 75,
    CanAssignGlobalDivisionsInLOI: 76,
    ShowHistoricalLearningObjectForSelection: 77,
    HideInactiveDivisionFromOrganizationTree: 78,
    OnlyManageMyEntities: 79,
    AllowMoveAndSwapFromRoster: 80,
    BackupManagerRights: 81,
    DiscardAppendSupervisorLogic: 82,
    CanUploadUserDocuments: 83,
    CanRunAdhocAssignments: 84,
    ManageUserCustomFieldsValues: 85,
    CanInactivateLearningObject: 86,
    CanCancelWaiveCertification: 87,
    OverrideCutOffCancellation: 88,
    NonInstructorJustPreceptorRights: 89,
    AddingExternalTrainingManualEntries: 90,
    AllowDueDateModificationOfAssignments: 91,
    CanOverrideCompletedUserEvaluationTMS: 92,
    TMSAdministratorRightsOnly: 93,
    LMSAdministratorRightsOnly: 94,
    AssignUserCustomFieldsValues: 95,
    UpdateAssignmentDueDate: 96,
}

export const PermissionType =
{
    OnlyView: 1,
    OnlyModifyNoCreate: 2,
    OnlyCreateNoModify: 3,
    FullControl: 4,
}

export const SystemUsers = 
{
    UserImportUser: 9999999,
    PasswordResetUser: 99999999,
}

export const AlertVariant =
{
    Primary: 'primary',
    Secondary: 'secondary',
    Success: 'success',
    Danger: 'danger',
    Warning: 'warning',
    Info: 'info',
    Light: 'light',
    Dark: 'dark',
}
