import React, { useRef, useContext, useState, useEffect } from "react"
import { Form, Button, Card, Alert, CardHeader, CardFooter, CardTitle, Image } from "react-bootstrap"
import { Link, useParams, useSearchParams } from "react-router-dom"
import { styles } from '../layout/styles'
import ReCAPTCHA from 'react-google-recaptcha';
import moment from 'moment'
import Spinner from '../layout/Spinner';
import { validateCompany, resetPassword } from '../../store/actions/authActions'
import { connect } from 'react-redux'
import { SystemUsers } from "../../config/Constant";

function ResetPassword(props) {
  const { cid, rpid } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
//   const cid = searchParams.get('cid');
//   const rpid = searchParams.get('rpid');
  const completeQueryStr = '';//searchParams.toString();

  const { auth, companyData, profile, entityError, entitySuccess } = props;

  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [showErrorMsg, setShowErrorMsg] = useState(true);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [disableReset, setDisableReset] = useState(true);

  useEffect(() => {
    if ((cid) && (cid !== -1)) {
        getCompanyDetails();
    }
  }, [cid]);

  useEffect(() => {
      if (loading === true) {
        setDisableReset(true);
      } else if (recaptchaValue === null) {
        setDisableReset(true);
      } else {
        setDisableReset(false);
      }
  }, [loading])  

  useEffect(() => {
      if (recaptchaValue === null) {
        setDisableReset(true);
      } else if (loading === true) {
        setDisableReset(true);
      } else {
        setDisableReset(false);
      }
  }, [recaptchaValue])  

  async function getCompanyDetails() {
    setLoading(true);
    await props.validateCompany(cid, completeQueryStr);
    setLoading(false);
  }

  async function handleSubmit(e) {
    e.preventDefault()
    const regPassword = new RegExp("^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$");
    const isPasswordString = regPassword.test(passwordRef.current.value);

    if ((companyData?.CheckForPasswordComplexity) && (!isPasswordString)) {
        return setError('Your password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character to ensure its strength.')
    }

    if (passwordRef.current.value !== passwordConfirmRef.current.value){
        return setError('Password do not match!')
    }

    try {
      setMessage("")
      setError("")
      setLoading(true)
      const creds = {
          Email:'', 
          UserName:'',
          NewPassword:passwordRef.current.value,
          SecureToken:rpid,
          CompanyID:cid,
          UpdatedByUserID: SystemUsers.PasswordResetUser,
      };
      console.log(creds);
      await props.resetPassword(creds);
      console.log(entitySuccess);
      if (entityError) {
          console.log(entityError);
          setError(entityError.message)
      }
      else if (entitySuccess) {
        if (entitySuccess?.returnParams?.UserDataSyncOut === 'True') {
          setMessage("The reset password process is successful. Please login using your new credentials.")
        } else {
          setError(entitySuccess?.returnParams?.DataSyncMessageOut)
        }
      }
    } catch {
      setError("Password reset failed. Please contact your administrator for assistance.")
    }

    setLoading(false)
  }

  function displayLoginError() {
      if (showErrorMsg) {
          if (error) {
              return (
                  <Alert variant="danger" style={{textAlign:'center'}}>{error}</Alert>
              );    
          } else if ((entityError) && ((typeof entityError === 'string') )) {
              return (
                  <Alert variant="danger" style={{textAlign:'center'}}>{entityError}</Alert>
              );    
          } else if (message) {
            return (
              <Alert variant="success" style={{textAlign:'center'}}>{message}</Alert>
            );    
          } else {
            return (
                <></>
            );    
          }
      } else {
          return (
              <></>
          );    
      }
  }

  function displayLogin() {
      const slink = "/login/" + companyData?.CompanyID;
      return (
          <div style={styles.Label}>
              Back to<Link style={styles.Link} to={slink}>Login</Link>
          </div>
      );    
  }
  

  return (
    <>
          <div style={{position:'absolute', width:'520px', height:'220px', top:'22%', left:'56%'}}>
          <Form onSubmit={handleSubmit}>
            <Card style={{width:'100%', backgroundColor:'transparent'}}>
              <CardHeader> 
                <CardTitle>
                    <span>
                        <h1 style={styles.Header}><Image style={{width:'240px', height:'100px'}} src={companyData?.CompanyLogo}/></h1>
                    </span>
                </CardTitle>
                <Card.Subtitle>
                    <span>
                        <h4 style={styles.Header}>Reset Password</h4>
                    </span>
                    {displayLoginError()}
                </Card.Subtitle>
              </CardHeader>
              <Card.Body>
              {(loading) ? (            
                        <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                            <Spinner/>
                        </div>
                ) 
                : 
                (                            
                  <>
                    <Form.Group id='password'>
                        <Form.Label style={styles.LabelNoPadding}>New Password</Form.Label>
                        <Form.Control type='password' ref={passwordRef} required></Form.Control>
                    </Form.Group>
                    <Form.Group id='password-confirm'>
                        <Form.Label style={styles.LabelNoPadding}>Confirm New Password</Form.Label>
                        <Form.Control type='password' ref={passwordConfirmRef} required></Form.Control>
                    </Form.Group>
                    <Form.Group id='recaptcha'>
                        <div className="w-100 text-center mt-3">
                            <ReCAPTCHA style={{backgroundColor:'transparent'}} sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY} onChange={(value) => setRecaptchaValue(value)}/>
                        </div>
                    </Form.Group>
                  </>
                )}
                </Card.Body>
                <CardFooter>
                <div className="w-100 text-center mt-3">
                    <Button disabled={disableReset} style={(disableReset) ? styles.CDButtonDisabled : styles.CDButton} type="submit">
                        Reset
                    </Button>
                </div>
                {displayLogin()}
            </CardFooter>
          </Card>
        </Form>
      </div>
    </>
  )
}

const mapsStateToProps = (state) => {
  return {
      companyData: state?.auth?.companyData,
      entityError: state?.auth?.entityError,
      entitySuccess: state?.auth?.entitySuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (oParam) => dispatch(resetPassword(oParam)),
    validateCompany: (id, completeQueryStr) => dispatch(validateCompany(id, completeQueryStr)),
}
}

export default connect(mapsStateToProps, mapDispatchToProps)(ResetPassword)


