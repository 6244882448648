import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image, CardBody, CardHeader, ListGroup, Badge  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import Spinner from '../layout/Spinner';
import { getAssignments, getEvaluations, getGoals, getSkills, getUserAttributesLookups, insertAssignment, updateAssignment, deleteAssignment } from '../../store/actions/entityActions';
import { Tree } from 'react-arborist';
import JobPositions from '../auth/JobPositions'
import GoalList from './GoalList';
import CompetencyList from './CompetencyList';
import { UserLookup, Rights } from '../../config/Constant';
import { getUserLookup } from '../../store/actions/authActions';
import UserLookupList from './UserLookupList';
import { useLocation } from 'react-router-dom';
import { getPermissionFlags } from '../layout/Utils';

const AssignmentList = (props) => {
    const { auth, companyData, profile, assignments, evaluations, userattributelookups, orgDivisions, lookupselections, loadingMenu } = props;    
    const location  = useLocation();
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location.state.permissiontypeid));
    const [loading, setLoading] = useState(false);
    const [sliderValue, setSliderValue] = useState([]);
    const [tabValue, setTabValue] = useState(initialTabValue());
    const [nodeName, setnodeName] = useState('');
    const [divisionNodeName, setDivisionNodeName] = useState('');
    const [selectedNodes, setSelectedNodes] = useState('');
    const [addNewAssignment, setAddNewAssignment] = useState(false);
    const [editAssignment, setEditAssignment] = useState(false);
    const [assignmentID, setAssignmentID] = useState(-1);
    const [assignmentName, setAssignmentName] = useState('');
    const [assignmentActive, setAssignmentActive] = useState('1');
    const [assignmentType, setAssignmentType] = useState('1');
    const [assignmentDescription, setAssignmentDescription] = useState('');
    const [assignmentEffectiveDate, setAssignmentEffectiveDate] = useState();
    const [assignmentEndDate, setAssignmentEndDate] = useState('');
    const [assignmentDateHiredStartDate, setAssignmentDateHiredStartDate] = useState();
    const [assignmentDateHiredEndDate, setAssignmentDateHiredEndDate] = useState();
    const [assignmentNewHire, setAssignmentNewHire] = useState('1');
    const [assignmentPillar, setAssignmentPillar] = useState(0);
    const [assignmentFrequency, setAssignmentFrequency] = useState('0');
    const [assignmentDueOn, setAssignmentDueOn] = useState('0');
    const [assignmentRecurringNumber, setAssignmentRecurringNumber] = useState(0);
    const [assignmentRecurringType, setAssignmentRecurringType] = useState(0);
    const [assignmentDueDate, setAssignmentDueDate] = useState();
    const [assignmentDaysAnniversary, setAssignmentDaysAnniversary] = useState(0);
    const [assignmentAnniversaryType, setAssignmentAnniversaryType] = useState(0);
    const [assignmentDueDays, setAssignmentDueDays] = useState(0);
    const [assignmentShowDaysBeforeDueDate, setAssignmentShowDaysBeforeDueDate] = useState(0);    
    const [assignmentEmploymentTypes, setAssignmentEmploymentTypes] = useState('');
    const [assignmentLeadershipAssessmentLevels, setAssignmentLeadershipAssessmentLevels] = useState('');
    const [assignmentSpecialityTypes, setAssignmentSpecialityTypes] = useState('');
    const [assignmentCredentialTypes, setAssignmentCredentialTypes] = useState('');
    const [assignmentSubStatuses, setAssignmentSubStatuses] = useState('');
    const [assignmentSupervisorLevels, setAssignmentSupervisorLevels] = useState('');
    const [assignmentJobPositions, setAssignmentJobPositions] = useState('');
    const [assignmentJobPositionNames, setAssignmentJobPositionNames] = useState('');    
    const [assignmentDivisionDetails, setAssignmentDivisionDetails] = useState('');
    const [assignmentDivisions, setAssignmentDivisions] = useState('');
    const [showJPList, setShowJPList] = useState(false);
    const [assignmentSkillArray, setAssignmentSkillArray] = useState([]);
    const [assignmentSkillDetails, setAssignmentSkillDetails] = useState('');
    const [assignmentSkills, setAssignmentSkills] = useState('');
    const [assignmentSkillNames, setAssignmentSkillNames] = useState('');    
    const [showSkillList, setShowSkillList] = useState(false);
    const [assignmentGoalArray, setAssignmentGoalArray] = useState([]);
    const [assignmentGoalDetails, setAssignmentGoalDetails] = useState('');
    const [assignmentGoals, setAssignmentGoals] = useState('');
    const [assignmentGoalNames, setAssignmentGoalNames] = useState('');    
    const [showGoalList, setShowGoalList] = useState(false);
    const [assignmentSkillPreceptorArray, setAssignmentSkillPreceptorArray] = useState([]);
    const [assignmentSkillPreceptorDetails, setAssignmentSkillPreceptorDetails] = useState('');
    const [assignmentSkillPreceptors, setAssignmentSkillPreceptors] = useState('');
    const [assignmentSkillPreceptorNames, setAssignmentSkillPreceptorNames] = useState('');    
    const [showSkillPreceptorList, setShowSkillPreceptorList] = useState(false);

    const assignment = assignments?.assignments?.Assignments;

    const credentialData = userattributelookups?.Credentials;
    const employmentTypeData = userattributelookups?.EmploymentTypes;
    const lslData = userattributelookups?.LeadershipAssessmentLevels;
    const supervisorLevelTypeData = userattributelookups?.SupervisorLevelTypes;
    const credentialTypeData = userattributelookups?.CredentialTypes;
    const specialityTypeData = userattributelookups?.SpecialityTypes;
    const subStatusTypeData = userattributelookups?.UserSubStatusTypes;
    const evaluation = evaluations?.evaluations?.Evaluations;

    const radios = [
        { name: 'Attributes', value: 1 },
        { name: 'Timings', value: 2 },
        { name: 'Groups', value: 3 },
        { name: 'Organization', value: 4 },
    ];
   
    function initialTabValue() {
        return 1;
    };

    const handleCloseJPList = () => {
        setShowJPList(false);
        updateJobPositionSelection();
    }

    async function handleShowJPList() {
        setShowJPList(true); 
        setLoading(true);
        setLoading(false);
    };

    function updateJobPositionSelection() {
        const sSelections = lookupselections?.JobPosition;
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections.split('&');
            oSelections.forEach(element => {
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element.split('=')[0];
                sSelectedNames += element.split('=')[1].split('|')[0];
            });
            setAssignmentJobPositions(sSelectedIds);
            setAssignmentJobPositionNames(sSelectedNames);
        }
    }

    const handleCloseSkillList = () => {
        setShowSkillList(false);
        updateSkillSelection();
    }

    async function handleShowSkillList() {
        setLoading(true);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getSkills(oParams);

        setShowSkillList(true); 
        setLoading(false);
    };

    function updateSkillSelection() {
        var sSelections = lookupselections?.Skill;
        if (assignmentSkillDetails !== '') {
            sSelections = assignmentSkillDetails + '&' + sSelections;
        }
        console.log(sSelections);
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections.split('&');
            oSelections.forEach(element => {
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element.split('=')[0];
                sSelectedNames += element.split('=')[1].split('|')[0];
            });
            setAssignmentSkillDetails(sSelections);
            setAssignmentSkillArray(oSelections);
            setAssignmentSkills(sSelectedIds);
            setAssignmentSkillNames(sSelectedNames);
        }
    }

    function handleDeleteAssignedSkillClick(goal) {
        var tempSkills = assignmentSkillArray.slice(0);
        var iRIndex = tempSkills.indexOf(goal);
        if (iRIndex !== -1) {
            tempSkills.splice(iRIndex, 1);
            setAssignmentSkillArray(tempSkills);
            let sSelectedDetails = ''
            let sSelectedIds = ''
            let sSelectedNames = ''
            tempSkills?.forEach(element => {
                if (sSelectedDetails.trim() !== '') {
                    sSelectedDetails += '&';
                }
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element.split('=')[0];
                sSelectedNames += element.split('=')[1].split('|')[0];
                sSelectedNames += element;
            });

            setAssignmentSkills(sSelectedIds);
            setAssignmentSkillNames(sSelectedNames);
            setAssignmentSkillDetails(sSelectedDetails);
        }        
    }

    const handleCloseSkillPreceptorList = () => {
        setShowSkillPreceptorList(false);
        updateSkillPreceptorSelection();
    }

    async function handleShowSkillPreceptorList() {
        setLoading(true);
        const oParams = {
            UserID:auth?.UserID,
            PolicyIDs:Rights.Preceptor,
            RecordSize:'10',
            PageNumber:1,
        }; 
        console.log(oParams);
        await props.getUserLookup(oParams);

        setShowSkillPreceptorList(true); 
        setLoading(false);
    };

    function updateSkillPreceptorSelection() {
        var sSelections = lookupselections?.User;
        if (assignmentSkillPreceptorDetails !== '') {
            sSelections = assignmentSkillPreceptorDetails + '&' + sSelections;
        }
        if ((sSelections !== null) && (sSelections !== undefined) && (sSelections != '')) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections.split('&');
            oSelections.forEach(element => {
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element.split('=')[0];
                sSelectedNames += element.split('=')[1].split('|')[0];
            });
            setAssignmentSkillPreceptorDetails(sSelections);
            setAssignmentSkillPreceptorArray(oSelections);
            setAssignmentSkillPreceptors(sSelectedIds);
            setAssignmentSkillPreceptorNames(sSelectedNames);
        }
    }

    function handleDeleteAssignedSkillPreceptorClick(goal) {
        var tempSkills = assignmentSkillPreceptorArray.slice(0);
        var iRIndex = tempSkills.indexOf(goal);
        if (iRIndex !== -1) {
            tempSkills.splice(iRIndex, 1);
            setAssignmentSkillPreceptorArray(tempSkills);
            let sSelectedDetails = ''
            let sSelectedIds = ''
            let sSelectedNames = ''
            tempSkills?.forEach(element => {
                if (sSelectedDetails.trim() !== '') {
                    sSelectedDetails += '&';
                }
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element.split('=')[0];
                sSelectedNames += element.split('=')[1].split('|')[0];
                sSelectedNames += element;
            });

            setAssignmentSkillPreceptors(sSelectedIds);
            setAssignmentSkillPreceptorNames(sSelectedNames);
            setAssignmentSkillPreceptorDetails(sSelectedDetails);
        }        
    }

    const handleCloseGoalList = () => {
        setShowGoalList(false);
        updateGoalSelection();
    }

    async function handleShowGoalList() {
        setLoading(true);
        const evalParams  = {
            UserID:auth.UserID,
            SearchFilter:'',
        };        
        await props.getGoals(evalParams);

        setShowGoalList(true); 
        setLoading(false);
    };

    function updateGoalSelection() {
        var sSelections = lookupselections?.Goal;
        if (assignmentGoalDetails !== '') {
            sSelections = assignmentGoalDetails + '&' + sSelections;
        }
        if (sSelections !== null) {
            let sSelectedIds = ''
            let sSelectedNames = ''
            const oSelections = sSelections?.split('&');
            oSelections?.forEach(element => {
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element.split('=')[0];
                sSelectedNames += element.split('=')[1].split('|')[0];
            });
            setAssignmentGoalDetails(sSelections);
            setAssignmentGoalArray(oSelections);
            setAssignmentGoals(sSelectedIds);
            setAssignmentGoalNames(sSelectedNames);
        }
    }

    function handleDeleteAssignedGoalClick(goal) {
        var tempGoals = assignmentGoalArray.slice(0);
        var iRIndex = tempGoals.indexOf(goal);
        if (iRIndex !== -1) {
            tempGoals.splice(iRIndex, 1);
            setAssignmentGoalArray(tempGoals);
            let sSelectedIds = ''
            let sSelectedNames = ''
            let sSelectedDetails = ''
            tempGoals?.forEach(element => {
                if (sSelectedDetails.trim() !== '') {
                    sSelectedDetails += '&';
                }
                sSelectedDetails += element;
                if (sSelectedIds.trim() !== '') {
                    sSelectedIds += ',';
                    sSelectedNames += ',';
                }
                sSelectedIds += element.split('=')[0];
                sSelectedNames += element.split('=')[1].split('|')[0];
            });
            setAssignmentGoals(sSelectedIds);
            setAssignmentGoalNames(sSelectedNames);
            setAssignmentGoalDetails(sSelectedNames);
        }        
    }

    function handleTabClick(tab) {
        setTabValue(tab);
        displayTabContent();
    };

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    function setSearchDivisionNodeName(e) {
        setDivisionNodeName(e.target.value);
    }

    async function treeSelectedNodes(nodes) {
        setLoading(true);
        // const divisionId = nodes[0]?.data?.id;
        let divisionIds = '';
        let divisionDetails = '';
        // console.log(nodes);
        nodes.forEach(element => {
            if (divisionIds.trim() !== '') {
                divisionIds += ',';
            }
            if (divisionDetails.trim() !== '') {
                divisionDetails += '&';
            }
            divisionIds += element?.data?.id;
            divisionDetails += element?.data?.id + '=' + element?.data?.name
        });
        if (divisionIds) {
            setSelectedNodes(nodes);
            setAssignmentDivisions(divisionIds);
            setAssignmentDivisionDetails(divisionDetails);
        }
        setLoading(false);
    }

    async function searchAssignments() {
        setLoading(true);
        let companyPillars = '';
        const companyParameters = auth.UserCompanyParameters?.split('&');
        companyParameters.map((parameter) => {
            const parameterDetails = parameter?.split('=');
            if (parameterDetails[0] === 'REACT_APP_EVALUATIONS') {
                companyPillars = parameterDetails[1];
            }
        });        
        const assParams1  = {
            UserID:auth.UserID,
            EvaluationIDs:companyPillars,
            SearchFilter:nodeName,
        };        
        await props.getAssignments(assParams1);        
        setLoading(false);
    }
    
    const addInitialSliderArray = (assignment) => {
        return assignment?.map((assRec) => ({
                            AssignmentID:assRec.AssignmentID, 
                            takeAction:false,
                            showDetails:false,
                            editAssignmentClicked:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(assignment);
        setSliderValue(initialScoreValues);
    }, [assignment])

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    function handleGroupsClick(e, type) {
        let selectedValues = '';
        [...e.target.selectedOptions].map((sSelOption) => {
            if (selectedValues.trim() !== '') {
                selectedValues += ',';
            }
            selectedValues += sSelOption.value;
        });

        if (type === 1) {
            setAssignmentEmploymentTypes(selectedValues);
        } else if (type === 2) {
            setAssignmentLeadershipAssessmentLevels(selectedValues);
        } else if (type === 3) {
            setAssignmentSupervisorLevels(selectedValues);
        } else if (type === 4) {
            setAssignmentCredentialTypes(selectedValues);
        } else if (type === 5) {
            setAssignmentSpecialityTypes(selectedValues);
        } else if (type === 6) {
            setAssignmentSubStatuses(selectedValues);
        } else if (type === 7) {
            setAssignmentJobPositions(selectedValues);
        } else if (type === 6) {
            setAssignmentDivisions(selectedValues);
        } 
    }

    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }
        if (!evaluations) {
            let companyPillars = '';
            const companyParameters = auth.UserCompanyParameters?.split('&');
            companyParameters.map((parameter) => {
                const parameterDetails = parameter?.split('=');
                if (parameterDetails[0] === 'REACT_APP_EVALUATIONS') {
                    companyPillars = parameterDetails[1];
                }
            });
            const evalParams  = {
                UserID:auth.UserID,
                EvaluationIDs:companyPillars,
                SearchFilter:'',
            };        
            // console.log(evalParams);
            await props.getEvaluations(evalParams);                
        }
        if (evaluation?.length > 0) {
            setAssignmentPillar(evaluation[0].EvaluationID);
        }

        if (tab === 1) {
            (addNewAssignment) ? setAddNewAssignment(false) : setAddNewAssignment(true);
            setAssignmentID(-1);
        } 

        setLoading(false);
    }

    // async function handleCancelChildernClick(tab, currentAss) {
    //     const updatedSliderValues = sliderValue.slice(0);
    //     updatedSliderValues.map((row, index) => {
    //         if (row.AssignmentID === currentAss?.AssignmentID) {
    //             row.editAssignmentClicked = false;
    //         }
    //     });
    //     setSliderValue(updatedSliderValues);    
    // }
    
    async function handleSaveChildernClick(tab, currentEval) {
        var bIsEvaluation = false;
        var bIsGoal = false;
        var bIsCompetency = false;
        if (assignmentType === '1') {
            bIsEvaluation = true;
        } else if (assignmentType === '2') {
            bIsCompetency = true;
        } else if (assignmentType === '3') {
            bIsGoal = true;
        } 
        setLoading(true);
        if (tab === 1) {
            const oParams  = {
                        AssignmentID: assignmentID,
                        AssignmentName: assignmentName,
                        Active: (assignmentActive === '1' ? true : false),
                        AssignmentDescription: assignmentDescription,
                        EffectiveDate: (assignmentEffectiveDate) ? assignmentEffectiveDate : '',
                        StopDate: assignmentEndDate, 
                        DateHiredStart: (assignmentDateHiredStartDate) ? assignmentDateHiredStartDate : '',
                        DateHiredEnd: (assignmentDateHiredEndDate) ? assignmentDateHiredEndDate : '',
                        NewHire: (assignmentNewHire === '1' ? true : false),
                        EvaluationIDs: assignmentPillar,
                        IsRecurring: (assignmentFrequency === '1' ? true : false),
                        DueType: assignmentDueOn,
                        ShowDaysBeforeDueDate:assignmentShowDaysBeforeDueDate,
                        RecurringNumber: assignmentRecurringNumber,
                        RecurringType: assignmentRecurringType,
                        DueDate: (assignmentDueDate) ? assignmentDueDate : '', 
                        AnniversaryDays: assignmentDaysAnniversary,
                        AnniversaryType: assignmentAnniversaryType,
                        DueDays: assignmentDueDays, 
                        EmploymentTypes: assignmentEmploymentTypes,
                        LeadershipAssessmentLevels: assignmentLeadershipAssessmentLevels,
                        SpecialityTypes: assignmentSpecialityTypes, 
                        CredentialTypes: assignmentCredentialTypes, 
                        SubStatusTypes: assignmentSubStatuses, 
                        SupervisorLevels: assignmentSupervisorLevels,
                        JobPositionIDs: assignmentJobPositions,
                        DivisionIDs: assignmentDivisions,
                        IsEvaluation: bIsEvaluation,
                        IsGoal: bIsGoal,
                        IsSkill: bIsCompetency,
                        EvaluationQuestionIDs: assignmentGoals,
                        SkillIDs:assignmentSkills,
                        PreceptorUserIDs:assignmentSkillPreceptors,
                        IsDeleted:false,
                    };        
            console.log(oParams);
            if (assignmentID === -1) {
                await props.insertAssignment(oParams);    
                setAddNewAssignment(false);
            } else {
                // console.log('Update Assignment - ' + assignmentID);
                await props.updateAssignment(oParams);    
                setEditAssignment(false);
            }
        } 

        let companyPillars = '';
        const companyParameters = auth.UserCompanyParameters?.split('&');
        companyParameters.map((parameter) => {
            const parameterDetails = parameter?.split('=');
            if (parameterDetails[0] === 'REACT_APP_EVALUATIONS') {
                companyPillars = parameterDetails[1];
            }
        });        
        const oParams1  = {
            UserID:auth.UserID,
            EvaluationIDs:companyPillars,
            SearchFilter:nodeName,
        };        
        await props.getAssignments(oParams1);        

        setAddNewAssignment(false);
        setEditAssignment(false);

        setLoading(false);
    }

    async function handleDeleteChildernClick(id) {
        setLoading(true);
        let companyPillars = '';
        const companyParameters = auth.UserCompanyParameters?.split('&');
        companyParameters.map((parameter) => {
            const parameterDetails = parameter?.split('=');
            if (parameterDetails[0] === 'REACT_APP_EVALUATIONS') {
                companyPillars = parameterDetails[1];
            }
        });        

        const oParams  = {
            AssignmentID: id,
        };        
        // console.log(oParams);
        await props.deleteAssignment(oParams);    
        
        const oParams1  = {
            UserID:auth.UserID,
            EvaluationIDs:companyPillars,
            SearchFilter:nodeName,
        };        
        
        await props.getAssignments(oParams1);            

        setLoading(false);
    }

    async function handleUpdateChildernClick(id) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (!evaluations) {
            let companyPillars = '';
            const companyParameters = auth.UserCompanyParameters?.split('&');
            companyParameters.map((parameter) => {
                const parameterDetails = parameter?.split('=');
                if (parameterDetails[0] === 'REACT_APP_EVALUATIONS') {
                    companyPillars = parameterDetails[1];
                }
            });
            const evalParams  = {
                UserID:auth.UserID,
                EvaluationIDs:companyPillars,
                SearchFilter:'',
            };        
            // console.log(evalParams);
            await props.getEvaluations(evalParams);                
        }

        const assEditedRec = assignment?.filter((assRec) => (assRec.AssignmentID === id));
        if ((assEditedRec) && (assEditedRec.length > 0)) {
            setAssignmentID(id);
            setAssignmentName(assEditedRec[0]?.AssignmentName);
            setAssignmentActive((assEditedRec[0]?.Active) ? '1' : '0'); 
            setAssignmentDescription(assEditedRec[0]?.AssignmentDescription);
            setAssignmentEffectiveDate(assEditedRec[0]?.EffectiveDate);
            setAssignmentEndDate(assEditedRec[0]?.StopDate);
            setAssignmentDateHiredStartDate(assEditedRec[0]?.DateHiredStart);
            setAssignmentDateHiredEndDate(assEditedRec[0]?.DateHiredEnd);
            setAssignmentNewHire((assEditedRec[0]?.NewHire) ? '1' : '0');
            setAssignmentPillar(assEditedRec[0]?.EvaluationID);
            setAssignmentFrequency((assEditedRec[0]?.IsRecurring) ? '1' : '0');
            setAssignmentDueOn(assEditedRec[0]?.DueType.toString());
            setAssignmentRecurringNumber(assEditedRec[0]?.RecurringNumber);
            setAssignmentRecurringType(assEditedRec[0]?.RecurringType.toString());
            setAssignmentDueDate(assEditedRec[0]?.DueDate);
            setAssignmentDaysAnniversary(assEditedRec[0]?.AnniversaryDays);
            setAssignmentAnniversaryType(assEditedRec[0]?.AnniversaryType);
            setAssignmentDueDays(assEditedRec[0]?.DueDays);
            setAssignmentShowDaysBeforeDueDate(assEditedRec[0]?.ShowDaysBeforeDueDate);    
            setAssignmentEmploymentTypes(assEditedRec[0]?.EmploymentTypes);
            setAssignmentLeadershipAssessmentLevels(assEditedRec[0]?.LeadershipAssessmentLevels);
            setAssignmentSpecialityTypes(assEditedRec[0]?.SpecialityTypes);
            setAssignmentCredentialTypes(assEditedRec[0]?.CredentialTypes);
            setAssignmentSubStatuses(assEditedRec[0]?.SubStatusTypes);
            setAssignmentSupervisorLevels(assEditedRec[0]?.SupervisorLevels);
            setAssignmentJobPositions(assEditedRec[0]?.JobPositionIDs);
            setAssignmentJobPositionNames(assEditedRec[0]?.JobPositionNames);
            setAssignmentDivisions(assEditedRec[0]?.DivisionIDs);
            setAssignmentDivisionDetails(assEditedRec[0]?.DivisionDetails);
            setAssignmentType((assEditedRec[0]?.IsEvaluation) ? '1' : (assEditedRec[0]?.IsGoal) ? '3' : '2');

            setAssignmentGoalDetails(assEditedRec[0]?.GoalDetails);
            const sSelections = assEditedRec[0]?.GoalDetails;
            if ((sSelections !== null) && (sSelections.trim() !== '')) {
                let sSelectedIds = ''
                let sSelectedNames = ''
                const oSelections = sSelections?.split('&');
                oSelections?.forEach(element => {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element.split('=')[0];
                    sSelectedNames += element.split('=')[1].split('|')[0];
                });
                setAssignmentGoalArray(oSelections);
                setAssignmentGoals(sSelectedIds);
                setAssignmentGoalNames(sSelectedNames);
            }

            setAssignmentSkillDetails(assEditedRec[0]?.SkillDetails);
            const sSSelections = assEditedRec[0]?.SkillDetails;
            if ((sSSelections !== null) && (sSSelections.trim() !== '')) {
                let sSelectedIds = ''
                let sSelectedNames = ''
                const oSelections = sSSelections?.split('&');
                oSelections?.forEach(element => {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element.split('=')[0];
                    sSelectedNames += element.split('=')[1].split('|')[0];
                });
                setAssignmentSkillArray(oSelections);
                setAssignmentSkills(sSelectedIds);
                setAssignmentSkillNames(sSelectedNames);
            }
            setAssignmentSkillPreceptorDetails(assEditedRec[0]?.PreceptorUserDetails);
            const sSPSelections = assEditedRec[0]?.PreceptorUserDetails;
            if ((sSPSelections !== null) && (sSPSelections.trim() !== '')) {
                let sSelectedIds = ''
                let sSelectedNames = ''
                const oSelections = sSPSelections?.split('&');
                oSelections?.forEach(element => {
                    if (sSelectedIds.trim() !== '') {
                        sSelectedIds += ',';
                        sSelectedNames += ',';
                    }
                    sSelectedIds += element.split('=')[0];
                    sSelectedNames += element.split('=')[1].split('|')[0];
                });
                setAssignmentSkillPreceptorArray(oSelections);
                setAssignmentSkillPreceptors(sSelectedIds);
                setAssignmentSkillPreceptorNames(sSelectedNames);
            }
            
            setEditAssignment(true);
        }
        setLoading(false);
    }

    async function handleCancelChildernClick(id) {
        if (assignmentID === -1) {
            setAddNewAssignment(false);
        } else {
            setEditAssignment(false);
        }
        ClearStateFields();
    }
    
    async function ClearStateFields() {
        if (assignmentID > 0) {
            setAssignmentID(-1);
        } 
        setAssignmentName('');
        setAssignmentActive('1'); 
        setAssignmentDescription('');
        // setAssignmentEffectiveDate(assEditedRec[0]?.EffectiveDate);
        // setAssignmentEndDate(assEditedRec[0]?.StopDate);
        // setAssignmentDateHiredStartDate(assEditedRec[0]?.DateHiredStart);
        // setAssignmentDateHiredEndDate(assEditedRec[0]?.DateHiredEnd);
        setAssignmentNewHire('1');
        setAssignmentPillar(-1);
        setAssignmentFrequency('1');
        setAssignmentEmploymentTypes('');
        setAssignmentLeadershipAssessmentLevels('');
        setAssignmentSpecialityTypes('');
        setAssignmentCredentialTypes('');
        setAssignmentSubStatuses('');
        setAssignmentSupervisorLevels('');
        setAssignmentJobPositions('');
        setAssignmentJobPositionNames('');
        setAssignmentDivisions('');
        setAssignmentDivisionDetails('');
        setAssignmentType('1');
        setAssignmentGoalDetails('');
        setAssignmentGoalArray([]);
        setAssignmentGoals('');
        setAssignmentGoalNames('');

        setAssignmentSkillDetails('');
        setAssignmentSkillArray([]);
        setAssignmentSkills('');
        setAssignmentSkillNames('');
        setAssignmentSkillPreceptorDetails('');
        setAssignmentSkillPreceptorArray([]);
        setAssignmentSkillPreceptors('');
        setAssignmentSkillPreceptorNames('');
}


    function getRecurringType(rType) {
        if (rType === 0) {
            return 'Yearly';
        } else if (rType === 1) {
            return 'Quarterly';
        } else if (rType === 2) {
            return 'Monthly';
        } else if (rType === 3) {
            return 'Daily';
        }
    }

    function displaySaveButtons() {
        return (
            <div>
                <Button onClick={() => handleSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Assignment'>Save</Button>&nbsp;&nbsp;
                <Button onClick={() => handleCancelChildernClick(1)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel Assignment'>Cancel</Button>
            </div>
        );
    }

    function displayTabButtons() {
        return (
            radios.map((radio, idx) => (
                <li style={(tabValue === radio.value) ? styles.TabLinkActive : styles.TabLink}>
                    <span style={styles.SpanContainer} onClick={(e) => {handleTabClick(radio.value)}}>{radio.name}</span>
                </li>
            ))
        );
    }

    function displayTabContent() {
        if (tabValue === 1 ) {
            return (
                <div>
                    {displayAttributes()}
                </div>
            );
        }
        else if (tabValue === 2 ) {
            return (
                <div>
                    {displayTimings()}
                </div>
            );
        } else if (tabValue === 3 ) {
            return (
                <div>
                    {displayGroups()}
                </div>
            );
        } else if (tabValue === 4 ) {
            return (
                <div>
                    {displayOrganization()}
                </div>
            );
        } 
    }

    function displayNewAssignment() {
        if ((addNewAssignment) || (editAssignment)) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <CardHeader style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Table style={{textAlign:'left', width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                            <tbody>
                            <tr>
                                <td style={{textAlign:'left', width:'80%', backgroundColor:companyData?.PagesBGColor}}>
                                    {displayTabButtons()}
                                </td>
                                <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                                    {displaySaveButtons()}
                                </td>
                            </tr>
                            </tbody>
                        </Table>    
                    </CardHeader>                                        
                    <CardBody style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        {displayTabContent()}
                    </CardBody>
                </Card>
            )
        }
    }

    function displayAssignedSkills() {
        if (assignmentSkillArray != null) {
            return (
                <ListGroup as="ol" numbered className="mt-2 mb-2 ms-2 me-auto">
                    {assignmentSkillArray?.map((skill, supIndex) => (
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center">
                            <div className="ms-2 me-auto">
                                <div>{skill.split('=')[1].split('|')[2]} - {skill.split('=')[1].split('|')[4]} - {skill.split('=')[1].split('|')[0]}</div>                                
                            </div>
                            <a onClick={() => handleDeleteAssignedSkillClick(skill)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Competency'>delete</span>
                            </a>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            );            
        }
    }

    function displayAssignedSkillPreceptors() {
        if (assignmentSkillPreceptorArray != null) {
            return (
                <ListGroup as="ol" numbered className="mt-2 mb-2 ms-2 me-auto">
                    {assignmentSkillPreceptorArray?.map((skill, supIndex) => (
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center">
                            <div className="ms-2 me-auto">
                                <div>{skill.split('=')[1].split('|')[0]} - {skill.split('=')[1].split('|')[1]} - {skill.split('=')[1].split('|')[2]}</div>                                
                            </div>
                            <a onClick={() => handleDeleteAssignedSkillPreceptorClick(skill)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Preceptor'>delete</span>
                            </a>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            );            
        }
    }

    function displayAssignedGoals() {
        if (assignmentGoalArray != null) {
            return (
                <ListGroup as="ol" numbered className="mt-2 mb-2 ms-2 me-auto">
                    {assignmentGoalArray?.map((goal, supIndex) => (
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center">
                            <div className="ms-2 me-auto">
                                <div>{goal.split('=')[1].split('|')[2]} - {goal.split('=')[1].split('|')[4]} - {goal.split('=')[1].split('|')[0]}</div>                                
                            </div>
                            <a onClick={() => handleDeleteAssignedGoalClick(goal)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Goal'>delete</span>
                            </a>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            );            
        }
    }

    function displayAttributes() {
            return (
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left', width:'65%'}}>
                                <div>
                                    <label htmlFor="assignmentName" style={styles.LabelDataEntryNoPadding}>Name</label>
                                    <input id="assignmentName" type="text" style={styles.TextDataEntryNoPadding} defaultValue={assignmentName} onChange={(e) => (setAssignmentName(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="effectivestart" style={styles.LabelDataEntryNoPadding}>Effective Date</label>
                                    <input id="effectivestart" type="date" style={styles.TextDataEntryNoPadding} value={assignmentEffectiveDate?.substr(0,10)} onChange={(e) => (setAssignmentEffectiveDate(e.target.value))}/>
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'15%'}}>
                                <div>
                                    <label htmlFor="effectivestart" style={styles.LabelDataEntryNoPadding}>Stop Date</label>
                                    <input id="effectiveend" type="date" style={styles.TextDataEntryNoPadding} value={(assignmentEndDate) ? assignmentEndDate?.substr(0,10) : ''} onChange={(e) => (setAssignmentEndDate(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', width:'15%'}}>
                                <div>
                                    <label htmlFor="datehiredstart" style={styles.LabelDataEntryNoPadding}>Date Hired Start</label>
                                    <input id="datehiredstart" type="date" style={styles.TextDataEntryNoPadding} value={(assignmentDateHiredStartDate) ? assignmentDateHiredStartDate?.substr(0,10) : ''} onChange={(e) => (setAssignmentDateHiredStartDate(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', width:'15%'}}>
                                <div>
                                    <label htmlFor="datehiredstart" style={styles.LabelDataEntryNoPadding}>Date Hired End</label>
                                    <input id="datehiredend" type="date" style={styles.TextDataEntryNoPadding} value={(assignmentDateHiredEndDate) ? assignmentDateHiredEndDate?.substr(0,10) : ''} onChange={(e) => (setAssignmentDateHiredEndDate(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' style={{textAlign:'left', width:'70%'}}>
                                <div>
                                    <label htmlFor="description" style={styles.LabelDataEntryNoPadding}>Description</label>
                                    <input id="description" type="text" style={styles.TextDataEntryNoPadding} defaultValue={assignmentDescription} onChange={(e) => (setAssignmentDescription(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', width:'15%'}}>
                                <div>
                                    <label htmlFor="asstype" style={styles.LabelDataEntryNoPadding}>Type</label>
                                    <select id="asstype" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={assignmentType} onChange={(e) => (setAssignmentType(e.target.value))}>
                                            <option value='1'>Pillar</option>        
                                            <option value='2'>Competency</option>        
                                            <option value='3'>Organization Goal</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'15%'}}>
                                <div>
                                    <label htmlFor="assactive" style={styles.LabelDataEntryNoPadding}>Active</label>
                                    <select id="assactive" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={assignmentActive} onChange={(e) => (setAssignmentActive(e.target.value))}>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'15%'}}>
                                <div>
                                    <label htmlFor="newhire" style={styles.LabelDataEntryNoPadding}>For New Hire</label>
                                    <select id="newhire" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={assignmentNewHire} onChange={(e) => (setAssignmentNewHire(e.target.value))}>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                        </tr>              
                        <tr>
                            {(assignmentType === '1') ?
                                <td colSpan='5' style={{textAlign:'left', width:'85%'}}>
                                    <div>
                                        <label htmlFor="pillar" style={styles.LabelDataEntryNoPadding}>Pillar</label>
                                        <select id="pillar" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={assignmentPillar} onChange={(e) => (setAssignmentPillar(e.target.value))}>
                                            {evaluation && evaluation.map((rating, supIndex) => (
                                                <option value={rating.EvaluationID}>{rating.EvaluationName}</option>        
                                            ))}
                                        </select>                                     
                                    </div>                    
                                </td>
                            : (assignmentType === '2') ?
                                <>
                                <td colSpan='2' style={{textAlign:'left', width:'85%'}}>
                                    <div>
                                        <a onClick={() => setShowSkillList(true)}>
                                            <label style={styles.LabelDataEntryNoPadding}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Competency/Skill List'>mystery</span>                                    
                                                Competencies & Skills
                                            </label>
                                        </a>
                                        {/* <input id="skillid" type="text" disabled style={styles.TextDataEntryNoPadding} value={assignmentSkillNames}/>                                     */}
                                        {displayAssignedSkills()}
                                    </div>
                                </td>
                                <td colSpan='3' style={{textAlign:'left', width:'85%'}}>
                                    <div>
                                        <a onClick={() => setShowSkillPreceptorList(true)}>
                                            <label style={styles.LabelDataEntryNoPadding}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Preceptor List'>mystery</span>                                    
                                                Preceptors
                                            </label>
                                        </a>
                                        {/* <input id="skillid" type="text" disabled style={styles.TextDataEntryNoPadding} value={assignmentSkillNames}/>                                     */}
                                        {displayAssignedSkillPreceptors()}
                                    </div>
                                </td>
                                </>
                            :                           
                                <td colSpan='5' style={{textAlign:'left', width:'85%'}}>
                                    <div>
                                        <a onClick={() => setShowGoalList(true)}>
                                            <label style={styles.LabelDataEntryNoPadding}>
                                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Organization Goals List'>mystery</span>                                    
                                                Organization Goals
                                            </label>
                                        </a>
                                        {/* <input id="goalid" type="text" disabled style={styles.TextDataEntryNoPadding} value={assignmentGoalNames}/> */}
                                        {displayAssignedGoals()}                                    
                                    </div>
                                </td>
                            }
                        </tr>              
                    </tbody>
                </table>                                   
            );
    }

    function displayTimings() {
        return (
            <table class="table table-sm caption-top text-left">  
                <thead>
                </thead>                        
                <tbody> 
                    <tr>
                        <td style={{textAlign:'left', width:'34%'}}>
                            <div>
                                <label htmlFor="assactive" style={styles.LabelDataEntryNoPadding}>Frequency</label>
                                <select id="assactive" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={assignmentFrequency} onChange={(e) => (setAssignmentFrequency(e.target.value))}>
                                        <option value='0'>One-Time</option>        
                                        <option value='1'>Recurring</option>        
                                </select>                                     
                            </div>
                        </td>
                        {(assignmentFrequency === '1') ?
                        <>
                            <td style={{textAlign:'left', width:'33%'}}>
                                <div>
                                    <label htmlFor="recurringnumber" style={styles.LabelDataEntryNoPadding}>Recur Every</label>
                                    <input id="recurringnumber" type="number" min='1' step='1' style={styles.TextDataEntryNoPadding} defaultValue={assignmentRecurringNumber} onChange={(e) => (setAssignmentRecurringNumber(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', width:'33%'}}>
                                <div>
                                    <label htmlFor="assactive" style={styles.LabelDataEntryNoPadding}>Recur by</label>
                                    <select id="assactive" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={assignmentRecurringType} onChange={(e) => (setAssignmentRecurringType(e.target.value))}>
                                            <option value='0'>Year</option>        
                                            <option value='1'>Month</option>        
                                            <option value='2'>Day</option>        
                                    </select>                                     
                                </div>
                            </td>
                        </>
                        :
                        <></>
                        }
                    </tr>              
                    <tr>
                        <td style={{textAlign:'left', width:'34%'}}>
                            <div>
                                <label htmlFor="assactive" style={styles.LabelDataEntryNoPadding}>Due On</label>
                                <select id="assactive" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={assignmentDueOn} onChange={(e) => (setAssignmentDueOn(e.target.value))}>
                                        <option value='0'>Date</option>        
                                        <option value='2'>Anniversary</option>        
                                        <option value='3'>Birth Date</option>        
                                        <option value='1'>Flexible By Anniversary</option>        
                                        <option value='4'>Days</option>        
                                </select>                                     
                            </div>
                        </td>
                        {(assignmentDueOn === '0') ?
                        <>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div>
                                <label htmlFor="duedate" style={styles.LabelDataEntryNoPadding}>Due Date</label>
                                <input id="duedate" type="date" style={styles.TextDataEntryNoPadding} value={assignmentDueDate?.substr(0,10)} onChange={(e) => (setAssignmentDueDate(e.target.value))}/>
                            </div>
                        </td>
                        </>
                        :
                        <></>
                        }
                        {(assignmentDueOn === '1') ?
                        <>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div>
                                <label htmlFor="flexdays" style={styles.LabelDataEntryNoPadding}>Days</label>
                                <input id="flexdays" type="number" min='1' step='1' style={styles.TextDataEntryNoPadding} defaultValue={assignmentDaysAnniversary} onChange={(e) => (setAssignmentDaysAnniversary(e.target.value))}/>
                            </div>                    
                        </td>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div>
                                <label htmlFor="asswhen" style={styles.LabelDataEntryNoPadding}>When</label>
                                <select id="asswhen" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={assignmentAnniversaryType} onChange={(e) => (setAssignmentAnniversaryType(e.target.value))}>
                                        <option value='0'>Before</option>        
                                        <option value='1'>After</option>        
                                </select>                                     
                            </div>
                        </td>
                        </>
                        :
                        <></>
                        }
                        {(assignmentDueOn === '4') ?
                        <>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div>
                                <label htmlFor="duedays" style={styles.LabelDataEntryNoPadding}>Days</label>
                                <input id="duedays" type="number" min='1' step='1' style={styles.TextDataEntryNoPadding} defaultValue={assignmentDueDays} onChange={(e) => (setAssignmentDueDays(e.target.value))}/>
                            </div>                    
                        </td>
                        </>
                        :
                        <></>
                        }
                    </tr>
                    <tr>
                        <td style={{textAlign:'left', width:'33%'}}>
                            <div>
                                <label htmlFor="showdaysbefore" style={styles.LabelDataEntryNoPadding}>Show Days Before Due</label>
                                <input id="showdaysbefore" type="number" min='1' step='1' style={styles.TextDataEntryNoPadding} defaultValue={assignmentShowDaysBeforeDueDate} onChange={(e) => (setAssignmentShowDaysBeforeDueDate(e.target.value))}/>
                            </div>                    
                        </td>
                    </tr>
                </tbody>
            </table>                                   
        );
}

function displayGroups() {
    return (
        <table class="table table-sm caption-top text-left">  
            <thead>
            </thead>                        
            <tbody> 
                <tr>
                    <td style={{textAlign:'left', width:'34%', height:'200px'}}>
                        <div>
                            <label htmlFor="emptypes" style={styles.LabelDataEntryNoPadding}>Employment Types</label>
                            <select id="reqRatings" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100} defaultValue={assignmentEmploymentTypes?.split(',')} onChange={(e) => (handleGroupsClick(e, 1))}>
                                {employmentTypeData?.map((rating, supIndex) => (
                                    <option id={rating?.EmploymentTypeID} value={rating?.EmploymentTypeID}>{rating.EmploymentTypeDescription}</option>        
                                ))}
                            </select>                                     
                        </div>
                    </td>
                    <td style={{textAlign:'left', width:'33%', height:'200px'}}>
                        <div>
                            <label htmlFor="reqRatings" style={styles.LabelDataEntryNoPadding}>Leadership Assessment Levels</label>
                            <select id="reqRatings" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100} defaultValue={assignmentLeadershipAssessmentLevels?.split(',')} onChange={(e) => (handleGroupsClick(e, 2))}>
                                {lslData?.map((rating, supIndex) => (
                                    <option value={rating.LeadershipAssessmentLevelID}>{rating.LeadershipAssessmentLevelDescription}</option>        
                                ))}
                            </select>                                     
                        </div>
                    </td>
                    <td style={{textAlign:'left', width:'33%', height:'200px'}}>
                        <div>
                            <label htmlFor="reqRatings" style={styles.LabelDataEntryNoPadding}>Supervisor Levels</label>
                            <select id="reqRatings" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100} defaultValue={assignmentSupervisorLevels?.split(',')} onChange={(e) => (handleGroupsClick(e, 3))}>
                                {supervisorLevelTypeData?.map((rating, supIndex) => (
                                    <option value={rating.SupervisorLevelTypeID}>{rating.SupervisorLevelTypeName}</option>        
                                ))}
                            </select>                                     
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style={{textAlign:'left', width:'34%', height:'200px'}}>
                        <div>
                            <label htmlFor="credypes" style={styles.LabelDataEntryNoPadding}>Credential Types</label>
                            <select id="credtypes" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100} defaultValue={assignmentCredentialTypes?.split(',')} onChange={(e) => (handleGroupsClick(e, 4))}>
                                {credentialTypeData?.map((rating, supIndex) => (
                                    <option value={rating.CredentialID}>{rating.CredentialName}</option>        
                                ))}
                            </select>                                     
                        </div>
                    </td>
                    <td style={{textAlign:'left', width:'33%', height:'200px'}}>
                        <div>
                            <label htmlFor="spectypes" style={styles.LabelDataEntryNoPadding}>Speciality Types</label>
                            <select id="spectypes" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100} defaultValue={assignmentSpecialityTypes?.split(',')} onChange={(e) => (handleGroupsClick(e, 5))}>
                                {specialityTypeData?.map((rating, supIndex) => (
                                    <option value={rating.SpecialityID}>{rating.SpecialityName}</option>        
                                ))}
                            </select>                                     
                        </div>
                    </td>
                    <td style={{textAlign:'left', width:'33%', height:'200px'}}>
                        <div>
                            <label htmlFor="substatus" style={styles.LabelDataEntryNoPadding}>Sub Statuses</label>
                            <select id="substatus" class="browser-default" multiple style={styles.TextDataEntryNoPaddingHeight100} defaultValue={assignmentSubStatuses?.split(',')} onChange={(e) => (handleGroupsClick(e, 6))}>
                                {subStatusTypeData?.map((rating, supIndex) => (
                                    <option value={rating.UserSubStatusTypeID}>{rating.UserSubStatusTypeName}</option>        
                                ))}
                            </select>                                     
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan='3' style={{textAlign:'left'}}>
                        <div>
                            <a onClick={() => setShowJPList(true)}>
                                <label style={styles.LabelDataEntryNoPadding}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Show Job Position List'>mystery</span>                                    
                                    Job Position
                                </label>
                            </a>
                            <input id="jpid" type="text" disabled style={styles.TextDataEntryNoPadding} value={assignmentJobPositionNames}/>                                    
                        </div>
                    </td>
                </tr>                
            </tbody>
        </table>                                   
    );
}

function displayOrganization() {
    return (
        <table class="table table-sm caption-top text-left">  
            <thead>
            </thead>                        
            <tbody> 
                <tr>
                <td style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                    <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                                        <Card.Title>
                                            <div>
                                                <Table style={{backgroundColor:companyData?.PagesBGColor}}>
                                                    <tbody>
                                                    <tr>
                                                        <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h4 style={styles.SubHeader}>Organization Tree</h4></td>
                                                        <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                                                            <div className='input-field'>
                                                                <input id="searchDivisionNode" type="text" style={styles.TextDataEntryNoPadding} onChange={setSearchDivisionNodeName} placeholder='Search Division'/>
                                                            </div>                                            
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </Table>                            
                                            </div>
                                        </Card.Title>
                                    </Card.Header>                   
                                    <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                        <Table style={{backgroundColor:companyData?.PagesBGColor, padding:'20px 20px 20px 20px'}}>
                                            <tbody>
                                                <tr>
                                                    <td style={{textAlign:'left', width:'55%', backgroundColor:companyData?.PagesBGColor, 
                                                                padding:'0px 0px 0px 40px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                        <Tree 
                                                            width='100%'
                                                            data={orgDivisions} 
                                                            openByDefault={false}
                                                            indent={40}
                                                            padding={10}
                                                            searchTerm={divisionNodeName}
                                                            searchMatch={(node, term) => node.data.name.toLowerCase().includes(term.toLowerCase())}   
                                                            onSelect={(nodes) => {treeSelectedNodes(nodes)}}         
                                                            disableEdit={false}                                    
                                                            disableDrag={false}                                    
                                                            disableDrop={false}                                    
                                                            disableMultiSelection={false}              
                                                            selection={assignmentDivisions}                      
                                                        >
                                                            {/* {Node} */}
                                                        </Tree>
                                                    </td>
                                                    <td style={{textAlign:'left', width:'45%', backgroundColor:companyData?.PagesBGColor, 
                                                                padding:'0px 50px 0px 0px', fontFamily: companyData?.CDfontFamily, fontWeight: 'normal'}}>
                                                            {displaySelectedDivisions()}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>                            
                                    </Card.Body>
                                    <Card.Footer className="text-left">
                                    </Card.Footer>
                                </Card>
                            </td>
                </tr>
            </tbody>
        </table>                                   
    );
}

function displaySelectedDivisions() {
    const assignmentDivisionArray = assignmentDivisionDetails?.split('&'); 
    if (assignmentDivisionArray) {
    return (
            <Table responsive="sm" variant="light" style={{width:'100%', height:'100%', backgroundColor:companyData?.PagesBGColor}}>
            <thead>
                <tr>
                    <th style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h5 style={styles.SubHeaderCenter}>Selected Division(s)</h5></th>
                </tr>
            </thead>
            <tbody>
                {assignmentDivisionArray.map((node, index) => {
                    return (
                    <tr>
                        {/* <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeaderCenter}>{index + 1}.&nbsp;&nbsp;{node?.data.name}</h6></td> */}
                        <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeaderCenter}>{index + 1}.&nbsp;&nbsp;{node?.split('=')[1]}</h6></td>
                    </tr>
                    )
                })}
            </tbody>
        </Table>                                                    
        );
    }
}

function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Assignments</h3></td>
                <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                    <div className='input-field'>
                        <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Assignment'/>
                        <a onClick={() => searchAssignments()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a>
                        {(permissionFlags.CanCreate) ? 
                            (addNewAssignment) ? 
                                <a onClick={() => handleAddChildernClick(1, null)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Add Assignment'>shadow_minus</span>
                                </a>
                                :
                                <a onClick={() => handleAddChildernClick(1, null)}>
                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Add Assignment'>note_add</span>
                                </a>
                        : 
                            <></>
                        }
                    </div>                                            
                </td>
            </tr>
            </tbody>
        </Table>                            
        );    
    }

    function displayAssignedEmployeesDetails(assRec) {
        let sMsg = '';
        if (assRec?.AssignedUsers > 0) {
            sMsg = assRec?.AssignedUsers.toLocaleString() + ' assigned';
        }
        if (assRec?.CompletedUsers > 0) {
            if (sMsg !== '') {
                sMsg += ', ';
            }
            sMsg = assRec?.CompletedUsers.toLocaleString() + ' completed';
        }
        return sMsg;
    }

    function displayAssignedTypeName(assRec) {
        let sMsg = '';
        if (assRec?.IsEvaluation > 0) {
            sMsg = 'Pillar - ' + assRec?.EvaluationName
        } else if (assRec?.IsGoal > 0) {
            sMsg = 'Organization Goal - ' + assRec?.EvaluationName
        } else {
            sMsg = assRec?.EvaluationName
        } 

        return sMsg;
    }
    
    return (
        <>
        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showJPList} onShow={handleShowJPList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseJPList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <JobPositions fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseJPList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showSkillList} onShow={handleShowSkillList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseSkillList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CompetencyList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseSkillList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showSkillPreceptorList} onShow={handleShowSkillPreceptorList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseSkillPreceptorList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserLookupList fromLookupParam={true} lookupParamType={UserLookup.Policy} lookupParamValue={Rights.Preceptor}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseSkillPreceptorList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal style={{background:'transparent', maxHeight:'100%'}} show={showGoalList} onShow={handleShowGoalList} backdrop="static" size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseGoalList}>
            <Modal.Header closeButton>
            <Modal.Title>Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <GoalList fromLookupParam={true}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleCloseGoalList}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          
                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {displayNewAssignment()}                                               
                            {((!assignment) || (assignment.length === 0)) ? (                                        
                                <div style={styles.tableText}>
                                    No Assignments.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableTextMain}>Name</th>
                                                <th style={styles.tableText}>Type</th>
                                                <th style={styles.tableText}>Effective</th>
                                                <th style={styles.tableText}>Recurring</th>
                                                <th style={styles.tableText}>Employees</th>
                                                <th style={styles.tableText}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {assignment && assignment.map((assRec, index) => {
                                                return (
                                                    <>
                                                    <tr>
                                                        <td style={styles.tableText}>{assRec?.AssignmentID}</td>
                                                        <td style={styles.tableTextSubHeader}>
                                                            {assRec?.AssignmentName}
                                                        </td>
                                                        <td style={styles.tableTextLeft}>{displayAssignedTypeName(assRec)}</td>
                                                        <td style={styles.tableText}>{moment(assRec?.EffectiveDate?.substr(0,10)).format("MM/DD/YYYY")}</td>
                                                        <td style={styles.tableText}>{(assRec?.IsRecurring) ? ('Yes - ' + getRecurringType(assRec?.RecurringType))  : 'No'}</td>
                                                        <td style={styles.tableText}>{displayAssignedEmployeesDetails(assRec)}</td>
                                                        <td style={styles.tableText}>
                                                            <div>
                                                                {(permissionFlags.CanModify) ? 
                                                                    <>
                                                                    <a onClick={() => handleUpdateChildernClick(assRec?.AssignmentID)}>
                                                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Assignment'>edit</span>
                                                                    </a>
                                                                    {((permissionFlags.CanDelete) && (assRec?.AssignedUsers === 0) && (assRec?.CompletedUsers === 0)) ? 
                                                                        <a onClick={() => handleDeleteChildernClick(assRec?.AssignmentID)}>
                                                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Assignment'>delete</span>
                                                                        </a>
                                                                        :
                                                                        <></>
                                                                    }
                                                                    </>
                                                                : 
                                                                    <></>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-left">
                        </Card.Footer>
                </Card>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      evaluations: state.entity.evaluations, 
      assignments: state.entity.assignments, 
      userattributelookups: state.entity.userattributelookups,
      orgDivisions: state.auth.orgDivisions,
      lookupselections: state.entity.lookupselections,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAssignments: (assParams) => dispatch(getAssignments(assParams)),
        getEvaluations: (evalParams) => dispatch(getEvaluations(evalParams)),
        getGoals: (evalParams) => dispatch(getGoals(evalParams)),
        getSkills: (oParams) => dispatch(getSkills(oParams)),
        getUserLookup: (oParams) => dispatch(getUserLookup(oParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        insertAssignment: (oParams) => dispatch(insertAssignment(oParams)),
        deleteAssignment: (oParams) => dispatch(deleteAssignment(oParams)),
        updateAssignment: (oParams) => dispatch(updateAssignment(oParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentList)