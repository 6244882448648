import React, { useState, useEffect } from 'react';
import { Card, Form } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import { getAttachments, insertAttachmentForm, deleteAttachment } from '../../store/actions/entityActions'
import { AcceptableAttachments, AttachmentTypePRF, EntityRowTypePRF } from '../../config/Constant';

const Attachment = (props) => {
    const navigate = useNavigate();
    const { auth, companyData, attachments, isAttachmentVisible, isAttachmentDisable, iAttachmentTypeID, iEntityRowTypeID, iEntityID } = props;
    console.log(iAttachmentTypeID);
    console.log(iEntityID);
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [attachmentName, setAttachmentName] = useState('');
    const [attachmentFile, setAttachmentFile] = useState(null);

    useEffect(() => {
        setIsVisible(isAttachmentVisible);
    }, [isAttachmentVisible])  

    useEffect(() => {
        setIsDisable(isAttachmentDisable);
    }, [isAttachmentDisable])  

    function handleFileOnChange (e) {
        setAttachmentFile(e.target.files[0]);
    }

    async function handleAddAttachmentClick(){
        setLoading(true);
        const formData = new FormData();
        formData.append("attachment", attachmentFile);
        formData.append("AttachmentName", attachmentName);
        formData.append("AttachmentTypeID", iAttachmentTypeID);
        formData.append("EntityRowTypeID", iEntityRowTypeID);
        formData.append("EntityID", iEntityID);
        formData.append("FileSize", attachmentFile[0]?.size);
        formData.append("AttachmentFileExt", attachmentFile?.name.slice((attachmentFile?.name.lastIndexOf(".") - 1 >>> 0) + 2));
        formData.append("UserID", auth?.UserID);
        formData.append("CompanyID", companyData?.CompanyID);
        console.log(attachmentFile?.name.slice((attachmentFile?.name.lastIndexOf(".") - 1 >>> 0) + 2));
        await props.insertAttachmentForm(formData);
        const oAParams  = {
            UserID:auth.UserID,
            EntityID: iEntityID,
            AttachmentTypeID: iAttachmentTypeID,
            EntityRowTypeID: iEntityRowTypeID,
            SearchFilter:'',
        };                
        await props.getAttachments(oAParams);    
        setLoading(false);
    }

    async function handleDeleteAttachment(iAttachmentID, sAttachmentFileName) {
        setLoading(true);
        const oADParams  = {
            AttachmentID: iAttachmentID,
            AttachmentFileName: sAttachmentFileName,
            CompanyID: companyData?.CompanyID,
        };                
        await props.deleteAttachment(oADParams);    
        const oAParams  = {
            UserID:auth.UserID,
            EntityID: iEntityID,
            AttachmentTypeID: iAttachmentTypeID,
            EntityRowTypeID: iEntityRowTypeID,
            SearchFilter:'',
        };                
        await props.getAttachments(oAParams);    
        setLoading(false);
    }    

    async function handleOpenAttachment(sURL) {
        setLoading(true);
        window.open(sURL, "_blank", "noreferrer");
        setLoading(false);
    }    

    function displayAttachmentSection() {
        if (isVisible) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                        <Card.Title style={styles.tableTextLeftBold}>
                            Attachment Section                                                    
                        </Card.Title>
                        <fieldset disabled={isDisable}>
                            <table class="table table-sm caption-top text-left">  
                                <tbody>
                                    <tr>
                                        <td style={{textAlign:'left', width:'50%', backgroundColor:companyData?.PagesBGColor}}>
                                            <div>
                                                <label htmlFor="attachmentname" style={styles.LabelDataEntryNoPadding}>Name</label>
                                                <input id="attachmentname" type="text" style={styles.TextDataEntryNoPaddingWBG} defaultValue={attachmentName} onChange={(e) => (setAttachmentName(e.target.value))}/>
                                            </div>                    
                                        </td>
                                        <td style={{textAlign:'left', width:'45%', backgroundColor:companyData?.PagesBGColor}}>
                                            <div>
                                                <Form.Group controlId="formFile" className="mb-3">
                                                    <Form.Label style={styles.LabelDataEntryNoPadding}>File Upload</Form.Label>
                                                    <Form.Control style={styles.TextDataEntryNoPaddingWBG} type="file" onChange={handleFileOnChange}
                                                        accept={AcceptableAttachments}
                                                    />
                                                </Form.Group>
                                            </div>                    
                                        </td>
                                        <td style={{textAlign:'center', width:'5%', backgroundColor:companyData?.PagesBGColor}}>
                                            <div>
                                                <label style={styles.LabelDataEntryNoPadding}>Add</label>
                                            </div>
                                            <div>
                                                <a onClick={() => handleAddAttachmentClick()}>
                                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Attachment'>add_circle</span> 
                                                </a> 
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>
                    </Card.Header>                   
                    <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <fieldset disabled={isDisable}>
                            <table class="table table-sm caption-top text-left">  
                                <thead>
                                    <tr>
                                        <th style={styles.tableText}>#</th>
                                        <th style={styles.tableTextMain}>Name</th>
                                        <th style={styles.tableText}>Type</th>
                                        <th style={styles.tableText}>View</th>
                                        <th style={styles.tableText}>Delete</th>
                                    </tr>
                                </thead>                            
                                <tbody>
                                    {(attachments && attachments.map((attachment, index) => {
                                        return (<tr>
                                            <td style={styles.tableText}>{index+1}</td>
                                            <td style={styles.tableText}>{attachment?.AttachmentName}</td>
                                            <td style={styles.tableText}>{(attachment?.AttachmentTypeID === 1) ? 'User Document' : 'Performance Document'}</td>
                                            <td style={styles.tableText}>
                                                <a onClick={() => handleOpenAttachment(attachment?.StorageURL)}>
                                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Open Attachment'>open_in_browser</span>
                                                </a>
                                            </td>
                                            <td style={styles.tableText}>
                                                <a onClick={() => handleDeleteAttachment(attachment?.AttachmentID, attachment?.AttachmentFileName)}>
                                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Attachment'>delete</span>
                                                </a>
                                            </td>
                                        </tr>)
                                    }))
                                    }
                                </tbody>
                            </table>
                        </fieldset>
                    </Card.Body>
                </Card>        
            );    
        } else {
            return (<></>);
        }
    }

    return (
        <>
        {(loading) ? (            
            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                <Spinner/>
            </div>
        ) 
        : 
        (                                          
            displayAttachmentSection()
        )}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      attachments:state.entity.attachments?.attachments,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAttachments: (oAParams) => dispatch(getAttachments(oAParams)),
        insertAttachmentForm: (formData) => dispatch(insertAttachmentForm(formData)),
        deleteAttachment: (oAParams) => dispatch(deleteAttachment(oAParams)),        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Attachment)