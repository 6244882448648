import React, { useRef, useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useIdleTimer } from "react-idle-timer";
import { assignActiveMenu, loadingMenuSelection, signOut } from '../../store/actions/authActions';
import { clearEntityState } from '../../store/actions/entityActions';
import { MenuType } from '../../config/Constant';
import { useNavigate } from 'react-router-dom';

const UserIdle = (props) => {
    const { auth, companyData, currentActiveMenuItem, } = props;    
    const [loading, setLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [modalIsOpen, setIsModalOpen] = useState(false);
    const [inactivityTimeout, setInactivityTimeout] = useState(30 * (60 * 1000));
    const idleTimerRef = useRef(null);
    const sessionTimeoutRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if ((auth?.UserID > 0) && (companyData?.ForceSessionLockout) && (companyData.SessionLockoutDuration) && (companyData.SessionLockoutDuration > 0)) {
            setInactivityTimeout(companyData.SessionLockoutDuration * (60 * 1000));
            // console.log("User Session Lockout - ", (companyData?.SessionLockoutDuration * (60 * 1000)));
        }
    }, [auth])  

    const onIdle = () => {
        if ((auth?.UserID > 0) && (companyData?.ForceSessionLockout) && (companyData.SessionLockoutDuration) && (companyData.SessionLockoutDuration > 0)) {
            // console.log("User is Idle");
            setIsModalOpen(true);
            sessionTimeoutRef.current = setTimeout(handleClose, 5000);
        }
    };

    async function handleClose() {
        if ((auth?.UserID > 0) && (companyData?.ForceSessionLockout) && (companyData.SessionLockoutDuration) && (companyData.SessionLockoutDuration > 0)) {
            setIsLoggedIn(false);
            setIsModalOpen(false);
            clearTimeout(sessionTimeoutRef.current);
            // console.log("User is logged out");
            setLoading(true);
            props.loadingMenuSelection(true);
            props.assignActiveMenu(MenuType.Logout);
            await props.clearEntityState();
            await props.signOut();
            if ((companyData?.CompanyADIntegrated) && (companyData?.IDPService != '') && (companyData?.CompanyLogoutPage != '')) {
                props.loadingMenuSelection(false);
                // organization home/login/logout page
                window.location.href = companyData?.CompanyLogoutPage;        
            } else {
                navigate('/Login/' + companyData?.CompanyID);
                props.loadingMenuSelection(false);
            }
            setLoading(false);
        }
    };

    const idleTimer = useIdleTimer({
        crossTab: true,
        ref: idleTimerRef,
        timeout: inactivityTimeout,
        onIdle: onIdle,
    });

    // const stayActive = () => {
    //     setIsModalOpen(false);
    //     setIsLoggedIn(true);
    //     clearTimeout(sessionTimeoutRef.current);
    //     console.log("User is Active");
    // };

    function displayInactivityMessage() {
        return (
            <span>You have been logged out due to inactivity. Please log in again to continue.</span>
        );
    }

    return (
        <>
        {(auth?.UserID > 0) ? (            
            <div>
                <Modal style={{background:'transparent', maxHeight:'100%'}} show={modalIsOpen} backdrop="static" size="sm" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Inactivity Timeout</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{displayInactivityMessage()}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>
                <div idleTimer={idleTimer}></div>
                {/* <IIdleTimer ref={idleTimerRef} timeout={inactivityTimeout} onIdle={onIdle} /> */}
            </div>
        ) 
        : 
        (                                          
            <div>
            </div>
        )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
      auth: state?.auth?.user,
      companyData: state?.auth?.companyData,
      profile: state?.auth?.profile,
      currentActiveMenuItem: state?.auth?.activeMenuItem,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        clearEntityState: () => dispatch(clearEntityState()),
        assignActiveMenu: (menuId) => dispatch(assignActiveMenu(menuId)),
        loadingMenuSelection: (isLoading) => dispatch(loadingMenuSelection(isLoading)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserIdle)