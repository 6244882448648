import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { AlertTimeOut } from '../../config/Constant';

const AlertMessage = (props) => {
    const { auth, companyData, showAlertMessage, alertVariant, alertMessage, handleParentAlertClose} = props;
    const [ showMessage, setShowMessage] = useState(false);
    const [alertKey, setAlertKey] = useState(0); // Unique key for each alert

    useEffect(() => {
        let timer;

        if (showAlertMessage) {
            setShowMessage(true);
            setAlertKey((prevKey) => prevKey + 1); // Increment key to force re-render
            timer = setTimeout(() => {
                setShowMessage(false);
                handleParentAlertClose();
              }, AlertTimeOut);      
        }

        return () => clearTimeout(timer);
    }, [showAlertMessage]);

    return (
        <>
            {showMessage && (
                <Alert key={alertKey} variant={alertVariant} style={{ textAlign: 'center' }}>
                    {alertMessage}
                </Alert>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessage)