import React, { useRef, useState, useEffect, componentDidMount } from 'react';
import { Form, Button, Card, CardBody, CardHeader, CardTitle, Alert, Image } from 'react-bootstrap';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Container } from "react-bootstrap";
import { connect } from 'react-redux';
import { signIn, assignActiveMenu, validateCompany } from '../../store/actions/authActions';
import { getUserEvaluationSessions } from '../../store/actions/entityActions';
import { styles } from '../layout/styles';
import Colors, { CompanyData, companyLogo } from '../../config/Colors';
import Spinner from '../layout/Spinner';
import { RetentasType, MenuType } from '../../config/Constant';

function Login(props) {
    const { cid } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const uesid = searchParams.get('uesid');
    const completeQueryStr = searchParams.toString();
    const { auth, companyData, profile, authError } = props;

    const usernameRef = useRef()
    const passwordRef = useRef()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(true)
    const [showErrorMsg, setShowErrorMsg] = useState(true)
    const navigate = useNavigate()
    // const { auth } = useSelector(state => state);
    // const user = auth?.user;
    // console.log(auth);
    // console.log(profile);
    //const profile = auth?.profile;
    // const authError = auth?.authError;
    //const { user, authError } = useSelector(state => state?.auth);

    // const loginError = authError;
    //console.log(loginError);
    
    useEffect(() => {
        if ((auth?.Validate) && (auth?.UserID > 0)) {
            props.getUserEvaluationSessions(auth?.UserID)
            if ((uesid) && (uesid > 0)) {
                props.assignActiveMenu(MenuType.Questionnaire);
                navigate('/questionnaires', {state: {processleveltypeid:RetentasType.Surveys, uesid:uesid}});
            } else {
                if ((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) {
                    props.assignActiveMenu(MenuType.Home);
                    navigate('/');
                } else {
                    props.assignActiveMenu(MenuType.Questionnaire);
                    navigate('/questionnaires', {state: {processleveltypeid:RetentasType.Surveys}});
                }    
            }
        }
    }, [auth]);

    useEffect(() => {
        setError(authError?.message);
    }, [authError]);

    useEffect(() => {
        if ((cid) && (cid !== -1)) {
            getCompanyDetails();
        }
    }, [cid]);

    useEffect(() => {
        setLoading(true);
        if ((companyData?.CompanyADIntegrated) && (companyData?.IDPService != '') && (companyData?.IDPServicePassPhrase != '')) {
            initiateSSO();
        }
        setLoading(false);
    }, [companyData]);

    async function getCompanyDetails() {
        setLoading(true);
        await props.validateCompany(cid, completeQueryStr);
        setLoading(false);
    }

    function initiateSSO() {
        setLoading(true);

        // Redirect to WorkOS
        window.location.href = companyData?.IDPService;        

        setLoading(false);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setShowErrorMsg(false);

        try {
            setError('');
            setLoading(true);
            const creds = {
                username:usernameRef.current.value, 
                password:passwordRef.current.value,
                companyID:cid,
            }
            const loginResp = await props.signIn(creds);
            setLoading(false);
    
        } catch (err){
            console.log('Failed to login. Error - ' + err);
            setError('Failed to login!');
            //setError('Failed to login. Error - ' + err)
        }

        setShowErrorMsg(true);
        setLoading(false);
    }

    function displayForgotPassword() {
        const slink = "/forgotpassword/" + companyData?.CompanyID;
        if (companyData?.CompanyADIntegrated) {
            return (
                <></>
            );    
        } else {
            return (
                <div className="w-100 text-center mt-3">
                    <Link style={styles.Link} to={slink}>Forgot Password?</Link>
                </div>
            );    
        }
    }

    function displaySignUp() {
        const slink = "/signup/" + companyData?.CompanyID;
        if (companyData?.AllowGuest) {
            return (
                <div style={styles.Label}>
                    Need an account? <Link style={styles.Link} to={slink}>Sign Up</Link>
                </div>
            );    
        } else {
            return (
                <></>
            );    
        }
    }

    function displayLoginError() {
        if (showErrorMsg) {
            if (error) {
                return (
                    <Alert variant="danger" style={{textAlign:'center'}}>{error}</Alert>
                );    
            } else if ((authError) && ((typeof authError === 'string') )) {
                return (
                    <Alert variant="danger" style={{textAlign:'center'}}>{authError}</Alert>
                );    
            } else 
            return (
                <></>
            );    
        } else {
            return (
                <></>
            );    
        }
    }

    function clearMsg() {
        setShowErrorMsg(false);
    }

  return (
    <>
            <div style={{position:'absolute', width:'520px', height:'220px', top:'22%', left:'56%'}}>
                <Card style={{width:'100%', backgroundColor:'transparent'}}>
                    <CardHeader> 
                        <CardTitle>
                            <span>
                                <h1 style={styles.Header}><Image style={{width:'240px', height:'100px'}} src={companyData?.CompanyLogo}/></h1>
                            </span>
                            {displayLoginError()}
                        </CardTitle>
                    </CardHeader>
                    {(loading) ? (            
                            <Card.Body>
                                <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                    <Spinner/>
                                </div>
                            </Card.Body>
                        ) 
                        : 
                        (!companyData) ? (
                            <Card.Body>
                                <div style={styles.Label}>
                                    Please contact your administrator for login information.
                                </div>
                            </Card.Body>
                            ) 
                        :
                        (                                          
                            <CardBody >
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group id='email'>
                                        <Form.Label style={styles.LabelNoPadding}>User name</Form.Label>
                                        <Form.Control type='text' style={styles.TextNoPadding} ref={usernameRef} required></Form.Control>
                                    </Form.Group>
                                    <Form.Group id='password'>
                                        <Form.Label style={styles.LabelNoPadding}>Password</Form.Label>
                                        <Form.Control type='password' style={styles.TextNoPadding} ref={passwordRef} required></Form.Control>
                                    </Form.Group>
                                    <div className="w-100 text-center mt-3">
                                        <Button disabled={loading} style={styles.CDButton} type="submit">
                                            {(loading) ? 'Validating...' : 'Login'}
                                        </Button>
                                    </div>
                                    {displayForgotPassword()}
                                    {displaySignUp()}
                                </Form>
                            </CardBody>
                        )}
                </Card>
            </div>
    </>
  )
}

const mapsStateToProps = (state) => {
    return {
        authError: state?.auth?.authError,
        companyData: state?.auth?.companyData,
        auth: state?.auth?.user,
        profile: state?.auth?.profile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds)),
        validateCompany: (id, completeQueryStr) => dispatch(validateCompany(id, completeQueryStr)),
        assignActiveMenu: (menuId) => dispatch(assignActiveMenu(menuId)),
        getUserEvaluationSessions: (id) => dispatch(getUserEvaluationSessions(id)),
    }
} 

export default connect(mapsStateToProps, mapDispatchToProps)(Login)

