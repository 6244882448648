import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { getGroups, getUserAttributesLookups, insertGroup, deleteGroup, updateGroup, assignLookupSelection } from '../../store/actions/entityActions';
import Spinner from '../layout/Spinner';
import { useLocation } from 'react-router-dom';
import { getPermissionFlags } from '../layout/Utils';
import { PermissionType } from '../../config/Constant';
import { AuditEntityType } from '../../config/Constant';
import AuditTrail from '../layout/AuditTrail';

const GroupList = (props) => {
    const { auth, companyData, profile, groups, userattributelookups, loadingMenu, fromLookupParam } = props;    
    const [loading, setLoading] = useState(false);
    const [nodeName, setnodeName] = useState('');
    const [sliderValue, setSliderValue] = useState([]);
    const [addNewGroup, setAddNewGroup] = useState(false);
    const [editGroup, setEditGroup] = useState(false);
    const [groupID, setGroupID] = useState(-1);
    const [groupName, setGroupName] = useState('');
    const [groupDescription, setGroupDescription] = useState('');
    const [EditedGroupID, setEditedGroupID] = useState(-1);

    const group = groups?.groups?.Groups;

    const [auditEntityID, setAuditEntityID] = useState(-1);
    const [showAudit, setShowAudit] = useState(false);
    const handleCloseAudit = () => {setShowAudit(false); }
    function handleShowAudit() {
        setShowAudit(true); 
    };
    async function handleAuditClick(iID) {
        setLoading(true);
        setAuditEntityID(iID);
        handleShowAudit();
        setLoading(false);
    }    

    const location  = useLocation();
    let fromLookup = location?.state?.fromLookup;
    if ((fromLookup === null) || (fromLookup === undefined)) {
        fromLookup = fromLookupParam;
    }
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location.state.permissiontypeid));

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    async function previousPage() {
        setLoading(true);
        const previousPageNumber = groups?.groups?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            GroupName:nodeName,
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        await props.getGroups(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = groups?.groups?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            GroupName:nodeName,
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };            
        await props.getGroups(oParams);
        setLoading(false);
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }
    
    async function searchGroups() {
        setLoading(true);
        const oParams = {
            GroupName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getGroups(oParams);        
        setLoading(false);
    }
    
    const addInitialSliderArray = (entity) => {
        return group?.map((oRec) => ({
            GroupID:oRec.GroupID, 
            EditedGroupID:-1,
            takeAction:false,
            showDetails:false,
            disableNewGroup:false,
            newGroupClicked:false,
            editGroupClicked:false,
        }))
    }
              
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(group);
        setSliderValue(initialScoreValues);
    }, [group])
    
    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            (addNewGroup) ? setAddNewGroup(false) : setAddNewGroup(true);
            setGroupID(-1);
            setEditedGroupID(-1);
            setGroupDescription('');
            setGroupName('');
        } 

        setLoading(false);
    }

    async function handleCancelChildernClick(tab, currentEntity) {
        if (tab === 1) {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.GroupID === currentEntity?.GroupID) {
                    if (tab === 2) {
                        row.disableNewGroup = false;                    
                        row.newGroupClicked = false;
                        row.editGroupClicked = false;
                    } 
                }
            });
            setSliderValue(updatedSliderValues);    
            setGroupID(-1);
            setEditedGroupID(-1);
            setGroupName('');
            setGroupDescription('');
            setAddNewGroup(false);
            setEditGroup(false);
        } 
    }
    
    async function handleSaveChildernClick(tab, currentEntity) {
        setLoading(true);
        if (tab === 1) {
            const oIParams  = {
                GroupID : -1,
                GroupName : groupName,
                GroupDescription : groupDescription,
            };        
            console.log(oIParams);
            await props.insertGroup(oIParams);    
        } 

        const oParams = {
            GroupName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getGroups(oParams);        
        setGroupID(-1);
        setGroupName('');
        setGroupDescription('');
        setAddNewGroup(false);
        setEditGroup(false);
        setLoading(false);
    }

    async function handleEditedSaveChildernClick(tab, currentEntity) {
        setLoading(true);
        if (tab === 1) {
            if (EditedGroupID > 0) {
                const oUParams  = {
                    GroupID : EditedGroupID,
                    GroupName : groupName,
                    GroupDescription : groupDescription,
                    UpdatedByUserID : auth?.UserID,
                };        
                await props.updateGroup(oUParams);    
            } else {
                const oUParams  = {
                    GroupID : -1,
                    GroupName : groupName,
                    GroupDescription : groupDescription,
                    CreatedByUserID : auth?.UserID,
                };        
                await props.insertGroup(oUParams);    
            }

            setGroupID(-1);
            setEditedGroupID(-1);
            setGroupName('');
            setGroupDescription('');
            setAddNewGroup(false);
            setEditGroup(false);    
        } 
   
        const oParams = {
            GroupName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getGroups(oParams);        
        setLoading(false);
    }

    async function handleUpdateChildernClick(tab, id, id1) {
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            const oEdited = group?.filter((oRec) => (oRec.GroupID === id));
            if ((oEdited) && (oEdited.length > 0)) {
                setGroupID(id);
                setEditedGroupID(id);
                setGroupName((oEdited[0]?.GroupName) ? oEdited[0]?.GroupName : '');
                setGroupDescription((oEdited[0]?.GroupDescription) ? oEdited[0]?.GroupDescription : '');
            }

            setEditGroup(true);
        }  
    }

    async function handleDeleteChildernClick(tab, id, id1) {
        setLoading(true);

        if (tab === 1) {
            const oParams  = {
                GroupID: id,
            };        
            console.log(oParams);
            await props.deleteGroup(oParams);    
        } 
        
        const oParams = {
            GroupName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getGroups(oParams);            

        setLoading(false);
    }

    async function handleAddChildernValues(tab, rowIndex, value) {
        setLoading(true);

        setLoading(false);
    }

    function checkMainGroup(tab, id, id1) {
        if (!fromLookup) {            
            if (permissionFlags.CanModify) {
                return (
                        <div>
                            <a onClick={() => handleAuditClick(id)}>
                                <span style={styles.ActionIconGreyBold} class="material-symbols-outlined" title='Show Audit'>history_2</span>
                            </a>
                            <a onClick={() => handleUpdateChildernClick(tab, id, id1)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Group'>edit</span>
                            </a>
                            <a onClick={() => handleDeleteChildernClick(tab, id, id1)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Group'>delete</span>
                            </a>
                        </div>
                    );      
            } else {
                return (<></>)
            }
        } else {
            return (<></>)
        }
    }

    function displayNewGroup() {
        if ((!fromLookup) && ((addNewGroup) || (editGroup))) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                    <Card.Title>
                        {(addNewGroup) ? 'New Group' : 'Edit Group'}                        
                    </Card.Title>
                </Card.Header>                   
                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="groupname" style={styles.LabelDataEntryNoPadding}>Name</label>
                                    <input id="groupname" type="text" style={styles.TextDataEntryNoPaddingWBG} defaultValue={groupName} onChange={(e) => (setGroupName(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="description" style={styles.LabelDataEntryNoPadding}>Description</label>
                                    <input id="description" type="text" style={styles.TextDataEntryNoPaddingWBG} defaultValue={groupDescription} onChange={(e) => (setGroupDescription(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'right', width:'5%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <Button onClick={() => handleEditedSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Group'>Save</Button>
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'5%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <Button onClick={() => handleCancelChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey}>Cancel</Button>
                                </div>
                            </td>
                        </tr>  
                    </tbody>
                </table>                
                </Card.Body>
                </Card>                   
            );
        }
    }

    function displayAddMainGroup() {
        if (!fromLookup) {
            if (permissionFlags.CanCreate) {
                if (addNewGroup) {
                    return (
                        <a onClick={() => handleAddChildernClick(1, null)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Group'>shadow_minus</span>
                        </a>
                    );   
                } else {
                    return (
                        <a onClick={() => handleAddChildernClick(1, null)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Group'>note_add</span>
                        </a>
                    );    
                }
                return (<></>);
            } else {
                return (<></>);
            }
        } else {
            return (<></>);
        }
    }

    function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Groups</h3></td>
                <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                    <div className='input-field'>
                        <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Group'/>
                        <a onClick={() => searchGroups()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a>
                        {displayAddMainGroup()}
                    </div>                                            
                </td>
            </tr>
            </tbody>
        </Table>                            
        );
    }

    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;        
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (row.GroupID === rowIndex)) {
                bReturn = row.showDetails;
            }
        })
        return bReturn;
    }
 
    function handleSwitchDetailChange(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.GroupID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSliderValue(updatedSliderValues);
    }

    function displayAddButtons(tabValue, currentEntity) {
        const oRequestList = sliderValue.filter((oRow) => oRow.GroupID === currentEntity.GroupID);

        if (permissionFlags.CanCreate) {       
            return (<></>);
        } else {
            return (<></>);
        }
    }

    return (
        <>
        <AuditTrail showFromAudit={showAudit} handleParentAuditClose={handleCloseAudit} AuditEntityTypeID={AuditEntityType.Group} EntityID={auditEntityID}/>

        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          

                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {displayNewGroup()}                                               
                            {((!group) || (group.length === 0)) ? (             
                                <div style={styles.tableText}>
                                    No Groups.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableTextMain}>Name</th>
                                                <th style={styles.tableText}>Description</th>
                                                <th style={styles.tableText}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {group && group.map((oRec, index) => {
                                                return (
                                                    <tr>
                                                        <td style={styles.tableText}>{oRec?.GroupID}</td>
                                                        <td style={styles.tableTextSubHeader}>{oRec?.GroupName}</td>
                                                        <td style={styles.tableText}>{oRec?.GroupDescription}</td>
                                                        <td style={styles.tableText10}>{checkMainGroup(1, oRec?.GroupID, -1)}</td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{groups?.groups?.RecordFound?.toLocaleString()}   Groups&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(groups?.groups?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(groups?.groups?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {groups?.groups?.PagingMetaData?.PageNumber}  of  {groups?.groups?.PagingMetaData?.TotalPage?.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                </Card>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state?.auth?.profile,
      groups: state.entity?.groups, 
      userattributelookups: state.entity.userattributelookups,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getGroups: (oParams) => dispatch(getGroups(oParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        insertGroup: (oParams) => dispatch(insertGroup(oParams)),
        deleteGroup: (oParams) => dispatch(deleteGroup(oParams)),
        updateGroup: (oParams) => dispatch(updateGroup(oParams)),
        assignLookupSelection: (id, value) => dispatch(assignLookupSelection(id, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupList)