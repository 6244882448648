// import { store } from '../../store/store'
// import { connect } from 'react-redux';
// import { CompanyData, darkgrey, grey, accentColor, loginBGImage, cdfontFamily, cdtextcolor, navbarcolor, navbartextcolor, pagesbgcolor, 
//     black, white, accentColorSecondary, cdLabelColor, lightgrey} from '../../config/Colors';

// console.log(loginBGImage);
// const currState = store?.getState();
// const companyData = currState?.auth?.companyData;
// console.log(currState);

const LoginOutDiv = { 
    display: "flex",
    width: "100%",
    height: "100%",
    // minHeight: "100vh",
    // minWidth: "100vh",
    // backgroundImage: `url(${process.env.PUBLIC_URL + loginBGImage})`,
    backgroundImage: "var(--login-bg-image)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
};
const DivMainAllPages = { 
    // display: "flex",
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    minWidth: "100%",
    // backgroundImage: `url(${process.env.PUBLIC_URL + var(--login-bg-page)})`,
    backgroundImage: "var(--login-bg-image)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    // maxWidth:'100vh',
    // maxHeight:'100vh',    
};
const DivMainAllPagesPreLogin = { 
    display: "flex",
    width: "100vw",
    height: "100vh",
    minHeight: "100vh",
    // minWidth: "100%",
    // backgroundImage: `url(${process.env.PUBLIC_URL + loginBGImage})`,
    backgroundImage: "var(--login-bg-image)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
};
const Container = { 
    display: "flex",
    //justifyContent: "start",
    flexDirection: "row",
    alignContent: 'flex-start',
    //alignItems: "flex-end",
    // fontFamily: cdfontFamily,
    // borderWidth: '3',
    // borderColor: accentColor,
};
const NavDiv = { 
    // display: "flex",
    width: "100%",
    height: "100%",
    // minHeight: "10%",
    // minWidth: "100%",
    color:"var(--navbar-text-color)",//navbartextcolor,
    background:'rgba(0,0,0,0,0.55)',    
    //backgroundColor:navbarcolor,
    // backgroundImage: `url(${process.env.PUBLIC_URL + loginBGImage})`,
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "top"
};
const DataEntryContainer = { 
    display: "flex",
    padding: "20px 20px", 
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-end",
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
};
const NavInsideDiv = { 
    width:"30%",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
};
const NavContainer = { 
    display: "flex",
    width: "100%",
    height:'100%',
    // minWidth: "100vh",
    justifyContent: "space-between",
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    // padding: "2px 2px", 
    textAlign: "center", 
    background:'rgba(0,0,0,0,0)',    
};
const NavCenter = { 
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
};
const NavRight = { 
    display: "flex",
    justifyContent: "right",
    alignItems: "right",
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
};
const Header = { 
    padding: "10px 20px", 
    textAlign: "center", 
    color: "var(--cd-text-color)",//cdtextcolor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
};
const SubHeader = { 
    //padding: "10px 20px", 
    textAlign: "left", 
    color: "var(--darkgrey)",//darkgrey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
};
const SubHeaderCenter = { 
    //padding: "10px 20px", 
    textAlign: "center", 
    color: "var(--darkgrey)",//darkgrey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
};
const SubHeaderTransparent = { 
    //padding: "10px 20px", 
    textAlign: "left", 
    color: "var(--darkgrey)",//darkgrey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
};
const SubHeaderPrimary = { 
    //padding: "10px 20px", 
    textAlign: "left", 
    color: "var(--accent-color)",//accentColor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
};
const SubHeaderSecondary = { 
    //padding: "10px 20px", 
    textAlign: "left", 
    color: "var(--accent-color-secondary)",//accentColorSecondary,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
};
const ProductNameHeader = { 
    padding: "10px 20px", 
    textAlign: "center", 
    color:"var(--navbar-text-color)",//navbartextcolor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
};
const NavHeader = { 
    padding: "10px 20px", 
    textAlign: "center", 
    color:"var(--navbar-text-color)",//navbartextcolor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "300",
};
const LabelContainer = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};
const Label = { 
    padding: "10px 20px", 
    textAlign: "center", 
    color: "var(--cd-text-color)",//cdtextcolor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
};
const LabelNoPadding = { 
    //padding: "10px 20px", 
    textAlign: "center", 
    color:"var(--cd-label-color)",//cdLabelColor, 
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
    fontSize:'1rem',
};
const TextNoPadding = { 
    //padding: "10px 20px", 
    color: "var(--cd-text-color)",//cdtextcolor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
    fontSize:'1.45rem',
};
const LabelDataEntryNoPadding = { 
    //padding: "10px 20px", 
    textAlign: "center", 
    color: "var(--darkgrey)",//darkgrey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
    fontSize:'0.85rem',
};
const LabelDataEntryNormalNoPadding = { 
    //padding: "10px 20px", 
    textAlign: "center", 
    color: "var(--darkgrey)",//darkgrey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "normal",
    fontSize:'0.85rem',
};
const TextDataEntryNoPadding = { 
    //padding: "10px 20px", 
    color: "var(--darkgrey)",//darkgrey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
    fontSize:'1.20rem',
};
const TextDataEntryNoPaddingWBG = { 
    //padding: "10px 20px", 
    color: "var(--darkgrey)",//darkgrey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
    fontSize:'1.20rem',
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
};
const TextDataEntryNoPaddingHeight100 = { 
    //padding: "10px 20px", 
    color: "var(--darkgrey)",//darkgrey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
    fontSize:'1.20rem',
    height:'100%'
};
const TextDataEntryNoPaddingSmall = { 
    //padding: "10px 20px", 
    color: "var(--darkgrey)",//darkgrey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
    fontSize:'1.20rem',
    width:'20%',
};
const TextDataEntryNoPadding100 = { 
    //padding: "10px 20px", 
    color: "var(--darkgrey)",//darkgrey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
    fontSize:'1.20rem',
    width:'100%',
};
const TextDataEntryNoPadding90 = { 
    //padding: "10px 20px", 
    color: "var(--darkgrey)",//darkgrey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
    fontSize:'1.20rem',
    width:'90%',
};
const TextDataEntryNoPadding80 = { 
    //padding: "10px 20px", 
    color: "var(--darkgrey)",//darkgrey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
    fontSize:'1.20rem',
    width:'80%',
};
const CheckBoxNoPadding = { 
    //padding: "10px 20px", 
    textAlign: "right", 
    color: "var(--accent-color-secondary)",//darkgrey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
    fontSize:'1.56rem',
    position:'relative',
    opacity:'1', 
    pointerEvents:'auto',
};
const Link = { 
    padding: "5px 10px", 
    textAlign: "center", 
    color: "var(--cd-text-color)",//cdtextcolor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
};
const MenuLink = { 
    padding: "5px 10px", 
    textAlign: "center", 
    color:"var(--navbar-text-color)",//navbartextcolor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
};
const LinkBold = { 
    //padding: "5px 10px", 
    textAlign: "center", 
    color: "var(--cd-text-color)",//cdtextcolor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
};
const LinkBoldGrey = { 
    padding: "10px 10px 10px 10px", 
    textAlign: "center", 
    color:"var(--accent-color)",//accentColor, 
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
};
const SubLink = { 
    //padding: "5px 10px", 
    textAlign: "center", 
    color:"var(--grey)",//grey, 
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "300",
};
const TabLinkActive = { 
    padding: "5px 15px 5px 15px", 
    textAlign: "center", 
    color:"var(--accent-color)",//accentColor, 
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "bold",
    display:'table-cell',
    fontSize:'1.00rem',
};
const TabLink = { 
    padding: "5px 15px 5px 15px", 
    textAlign: "center", 
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
    display:'table-cell',
    color: "var(--darkgrey)",//darkgrey,
    fontSize:'1.00rem',
};
const TabLinkRight = { 
    padding: "5px 15px 5px 15px", 
    textAlign: "right", 
    color: "var(--darkgrey)",//darkgrey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
    display:'table-cell',
    fontSize:'1.00rem',
};
const SpanContainer = {
    cursor: 'pointer',
};
const DDButton = {
    color:"var(--accent-color)!important",
}
const CDButton = {
    alignItems: "center", 
    width:'30%',
    backgroundColor:"var(--accent-color)",//accentColor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",    
}
const CDButtonDisabled = {
    alignItems: "center", 
    width:'30%',
    backgroundColor:"var(--grey)",//accentColor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",    
    cursor: 'not-allowed',
}
const CDButton20 = {
    alignItems: "center", 
    width:'20%',
    backgroundColor:"var(--accent-color)",//accentColor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",    
}
const CDButton50 = {
    alignItems: "center", 
    width:'50%',
    backgroundColor:"var(--accent-color)",//accentColor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",    
}
const CDButtonDisabled50 = {
    alignItems: "center", 
    width:'50%',
    backgroundColor:"var(--grey)",//grey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",    
    cursor: 'not-allowed',
}
const CDFullButton = {
    alignItems: "center", 
    width:'100%',
    backgroundColor:"var(--accent-color)",//accentColor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",    
}
const CDButtonStd = {
    alignItems: "center", 
    backgroundColor:"var(--accent-color)",//accentColor,
    borderColor:"var(--accent-color)",//accentColor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",    
}
const CDButtonSelected = {
    alignItems: "center", 
    textAlign:"center",
    backgroundColor:"var(--accent-color)",//accentColor,
    borderColor:"var(--accent-color)",//accentColor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",    
    height:28,
}
const CDButtonUnselected = {
    alignItems: "center", 
    backgroundColor:"var(--grey)",//grey,
    borderColor:"var(--grey)",//grey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",    
    height:28,
}
const CDPillFullButton = {
    alignItems: "center", 
    width:'85%',
    height:"50%",
    minHeight:"5px",
    backgroundColor:"var(--accent-color)",//accentColor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "300",
    borderRadius:'30px',    
}
const CDPillButton = {
    alignItems: "center", 
    backgroundColor:"var(--accent-color)",//accentColor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
    borderRadius:'30px',    
}
const CDPillButtonUnSelected = {
    alignItems: "center", 
    backgroundColor:"var(--grey)",//grey,
    borderColor: "var(--grey)",//grey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "400",
    borderRadius:'30px',    
}
const CDPillButtonSMGrey = {
    alignItems: "center", 
    backgroundColor:"var(--grey)",//grey,
    borderColor: "var(--grey)",//grey,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "light",
    borderRadius:'16px',    
    fontSize:'14px', 
}
const CDSwitch = {
    backgroundColor:"var(--accent-color)",//accentColor,
    borderColor:"var(--accent-color)",//accentColor,
    opacity:'1',
    position:'relative', 
    pointerEvents:'auto', 
}
const CDSwitchUnSelected = {
    backgroundColor:"var(--grey)",//grey,
    borderColor:"var(--grey)",//grey,
    opacity:'1',
    position:'relative', 
    pointerEvents:'auto', 
}
const ProfileIconButton = {
    padding: "5px 10px", 
    alignItems: "center", 
    backgroundColor:"var(--accent-color)",//accentColor,
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "600", 
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    textAlign: "center",           
}
const FormContainer = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};
const ErrorMessage = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};
const DashboardContainer = { 
    // padding: "5px 5px", 
    //backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
    background:"rgba(0,0,0,0,0.35)",
    width: "100%",
    height: "100%",
    // minHeight: "90vh",
    // minWidth: "80vh",
    margin:"auto",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    maxWidth:'90vw',
    // maxHeight:'100vh',
    // overflow:'scroll',
};
const MainBGDiv = {
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
    textAlign:"center",  
    width: "100%",
    height: "100vh",
    minHeight: "100vh",
    minWidth: "100vh",      
}
const MainInnerDiv = {
    // backgroundColor:pagesbgcolor,
    background:'rgba(0,0,0,0.55)',
    textAlign:"center",  
    width: "100%",
    minHeight:'90vh',
    // height: "89vh",
    padding: "20px 0px 0px 0px", 
    // minHeight: "100vh",
    // minWidth: "100vh",      
}
const MainInnerDivAutoGrow = {
    //backgroundColor:pagesbgcolor,
    background:'rgba(0,0,0,0.65)',
    textAlign:"center",  
    // width: "100%",
    // height: "100vh",
    // minHeight: "100vh",
    // minWidth: "100vh",      
}
const MainBGDivNoAlign = {
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
    width: "100%",
    height: "100vh",
    minHeight: "100vh",
    minWidth: "100vh",      
}
const Image25 = {
    width: "25px",
    height: "25px",
}
const Image60Border = {
    width: "60px",
    height: "60px",
    //overflow: "hidden",
    borderWidth: '3',
    borderColor: "var(--accent-color)",//accentColor,
    borderRadius: '8',
}
const Image100 = {
    width: "100px",
    height: "100px",
}
const ActionIcon = {
    color:"var(--accent-color)",//accentColor, 
    cursor:'pointer', 
    fontSize:'32px', 
    fontWeight:'lighter', 
    fontStyle:'normal',
}
const ActionIconGrey = {
    color:"var(--grey)",//grey, 
    cursor:'pointer', 
    fontSize:'28px', 
    fontWeight:'lighter', 
    fontStyle:'normal',
}
const ActionIconGreyBold = {
    color:"var(--grey)",//grey, 
    cursor:'pointer', 
    fontSize:'28px', 
    fontWeight:'400', 
    fontStyle:'normal',
}
const ActionInsideIconGrey = {
    color:"var(--grey)",//grey, 
    cursor:'pointer', 
    fontSize:'24px', 
    fontWeight:'lighter', 
    fontStyle:'normal',
    // position:'absolute',
    textAlign:'center',
}
// const ImageSmileyNotGood = {
//     src: `url(${process.env.PUBLIC_URL + smileyNotGood})`,
// }
// const sliderRange = {
//     backgroundColor: transparent,
//     webkitAppearance: none,
//     mozAppearance: none,
//     Appearance: none,
// }
const td33 = {
    textAlign:'left',
    width: "33%",
}
const td34 = {
    textAlign:'left',
    width: "34%",
}
const tableBG = {
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const tableText = {
    color:"var(--grey)",//grey,
    textAlign:'center',
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
    // whiteSpace: 'normal',
}
const tableTextAction = {
    color:"var(--grey)",//grey,
    textAlign:'center',
    width:'5%',
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const tableTextAccent = {
    color:"var(--accent-color)",//accentColor,
    textAlign:'center',
    fontWeight:'bold',
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const tableTextMain = {
    color:"var(--grey)",//grey,
    textAlign:'center',
    // width:'15%'
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const tableTextLeftBold = {
    color:"var(--grey)",//grey,
    textAlign:'left',
    fontSize:'16px', 
    fontWeight:'bold',
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const tableText15 = {
    color:"var(--grey)",//grey,
    textAlign:'center',
    // width:'15%'
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const tableText10 = {
    color:"var(--grey)",//grey,
    textAlign:'center',
    width:'10%',
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const tableText8 = {
    color:"var(--grey)",//grey,
    textAlign:'center',
    width:'8%',
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const tableText5 = {
    color:"var(--grey)",//grey,
    textAlign:'center',
    width:'5%',
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const tableTextLeft = {
    color:"var(--grey)",//grey,
    textAlign:'left',
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const tableTextRight = {
    color:"var(--grey)",//grey,
    textAlign:'right',
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const tableTextSubHeader = {
    color:"var(--grey)",//grey,
    textAlign:'left',
    padding: "0px 0px 0px 20px", 
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const tableTextMainColumn = {
    color:"var(--grey)",//grey,
    textAlign:'left',
    padding: "0px 0px 0px 20px", 
    fontSize:'16px', 
    // fontWeight:'bold',
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const tableTextMainColumnTransparent = {
    color:"var(--grey)",//grey,
    textAlign:'left',
    padding: "0px 0px 0px 20px", 
    fontSize:'16px', 
    // fontWeight:'bold',
    background:'transparent',    
}
const tableInnerText = {
    color:"var(--grey)",//grey,
    textAlign:'center',
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontWeight: "200",
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const tableCellInnerGraph = {
    textAlign:'center',
    width:'33%',
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const tableCellInnerGraph25 = {
    textAlign:'center',
    width:'25%'
}
const tableCellInnerGraph33 = {
    textAlign:'center',
    width:'33%',
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const tableCellInnerGraph50 = {
    textAlign:'right',
    width:'50%',
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const progressBarInnerText = {
    // color:"var(--grey)",//grey,
    backgroundColor:"var(--white)",//pagesbgcolor,
    textAlign:'center',
    fontFamily: "var(--cd-font-family)",//cdfontFamily,
    fontSize:'12px', 
    fontWeight: "600",
    height:"22px",
    borderRadius:"11px",
}
const stepper = {
    activeBgColor:"var(--accent-color)", 
    completedBgColor:"var(--accent-color-secondary)", 
    inactiveBgColor:"var(--grey)"
}
const NavDropdownSelected = {
    color:"var(--accent-color)",//accentColor,
}
const NavDropdownUnSelected = {
    color:"var(--navbar-text-color)",//accentColor,
}
const NavDropdownItemSelected = {
    backgroundColor:"var(--pages-bg-color)",//pagesbgcolor,
}
const NavDropdownItemUnSelected = {
    color:"var(--darkgrey)!important",//grey,
}
const smileyNotGood = {
    borderColor: '#e60a3d',
    // fill: '#e60a3d',
    backgroundColor:'#e60a3d',
}
const smileyAverage = {
    borderColor: '#f25129',
    // fill: '#f25129',
    backgroundColor:'#f25129',
}
const smileyNormal = {
    borderColor: '#ff9814',
    // fill: '#ff9814',
    backgroundColor:'#ff9814',
}
const smileyGood = {
    borderColor: '#8ca554',
    // fill: '#8ca554',
    backgroundColor:'#8ca554',
}
const smileyVeryGood = {
    borderColor: '#19b394',
    // fill: '#19b394',
    backgroundColor:'#19b394',
}

export const styles = {
    LoginOutDiv: LoginOutDiv,
    NavDiv: NavDiv,
    NavInsideDiv:NavInsideDiv,
    Container: Container,
    NavContainer: NavContainer,
    NavCenter: NavCenter,
    NavRight: NavRight,
    Header: Header,
    SubHeader: SubHeader,
    SubHeaderCenter:SubHeaderCenter,
    NavHeader:NavHeader,
    LabelContainer: LabelContainer,
    Label: Label,
    LabelNoPadding:LabelNoPadding,
    ErrorMessage: ErrorMessage,
    FormContainer: FormContainer,
    Link: Link,
    MenuLink: MenuLink,
    LinkBold: LinkBold,
    LinkBoldGrey: LinkBoldGrey,
    SubLink: SubLink,
    CDButton: CDButton,
    CDButton20: CDButton20,
    CDButton50: CDButton50,
    CDButtonDisabled50: CDButtonDisabled50,
    CDButtonDisabled:CDButtonDisabled,
    CDFullButton: CDFullButton,
    CDPillFullButton:CDPillFullButton,
    ProfileIconButton: ProfileIconButton,
    ProductNameHeader: ProductNameHeader,
    DashboardContainer: DashboardContainer,
    MainBGDiv: MainBGDiv,
    MainBGDivNoAlign:MainBGDivNoAlign,
    DataEntryContainer:DataEntryContainer,
    Image25:Image25,
    Image60Border:Image60Border,
    Image100:Image100,
    td33:td33,
    td34:td34,
    tableText:tableText,
    tableTextLeft:tableTextLeft,
    tableTextRight:tableTextRight,
    tableTextSubHeader:tableTextSubHeader,
    tableTextAction:tableTextAction,
    CDButtonStd:CDButtonStd,
    CDButtonSelected:CDButtonSelected,
    CDButtonUnselected:CDButtonUnselected,
    smileyNotGood:smileyNotGood,
    smileyAverage:smileyAverage,
    smileyNormal:smileyNormal,
    smileyGood:smileyGood,
    smileyVeryGood:smileyVeryGood,
    CDPillButton:CDPillButton,
    CDPillButtonUnSelected:CDPillButtonUnSelected,
    CDSwitch:CDSwitch,
    CDSwitchUnSelected:CDSwitchUnSelected,
    tableTextMain:tableTextMain,
    tableTextLeftBold:tableTextLeftBold,
    tableText15:tableText15,
    tableText10:tableText10,
    tableText8:tableText8,
    tableText5:tableText5,
    tableInnerText:tableInnerText,
    tableCellInnerGraph:tableCellInnerGraph,
    SubHeaderSecondary:SubHeaderSecondary,
    SubHeaderPrimary:SubHeaderPrimary,
    tableCellInnerGraph25:tableCellInnerGraph25,
    tableCellInnerGraph33:tableCellInnerGraph33,
    tableCellInnerGraph50:tableCellInnerGraph50, 
    TextNoPadding:TextNoPadding,
    DivMainAllPages:DivMainAllPages,
    DivMainAllPagesPreLogin:DivMainAllPagesPreLogin,
    MainInnerDiv:MainInnerDiv,
    MainInnerDivAutoGrow:MainInnerDivAutoGrow,
    LabelDataEntryNoPadding:LabelDataEntryNoPadding,
    LabelDataEntryNormalNoPadding:LabelDataEntryNormalNoPadding,
    TextDataEntryNoPadding:TextDataEntryNoPadding,
    TextDataEntryNoPaddingSmall:TextDataEntryNoPaddingSmall,
    TextDataEntryNoPaddingHeight100:TextDataEntryNoPaddingHeight100,
    TextDataEntryNoPaddingWBG:TextDataEntryNoPaddingWBG,
    TabLinkActive:TabLinkActive,
    TabLink:TabLink,
    SpanContainer:SpanContainer,
    TabLinkRight:TabLinkRight,
    ActionIcon:ActionIcon,
    ActionIconGrey: ActionIconGrey, 
    ActionIconGreyBold: ActionIconGreyBold, 
    ActionInsideIconGrey: ActionInsideIconGrey,
    TextDataEntryNoPadding100:TextDataEntryNoPadding100,
    TextDataEntryNoPadding90:TextDataEntryNoPadding90,
    TextDataEntryNoPadding80:TextDataEntryNoPadding80,
    CheckBoxNoPadding:CheckBoxNoPadding,
    CDPillButtonSMGrey:CDPillButtonSMGrey,
    tableTextMainColumn:tableTextMainColumn,
    SubHeaderTransparent:SubHeaderTransparent,
    tableTextMainColumnTransparent: tableTextMainColumnTransparent,
    tableTextAccent:tableTextAccent,
    tableBG:tableBG,
    progressBarInnerText:progressBarInnerText,
    stepper:stepper,
    DDButton:DDButton,
    NavDropdownUnSelected:NavDropdownUnSelected,
    NavDropdownSelected:NavDropdownSelected,
    NavDropdownItemUnSelected:NavDropdownItemUnSelected,
    NavDropdownItemSelected:NavDropdownItemSelected,
    //sliderRange:sliderRange,
    // ImageSmileyNotGood:ImageSmileyNotGood,
}
