import axios from "axios";
import { store, persistor } from '../store/store' 
import * as actionTypes from '../store/actions/actionTypes';
import Moment from 'moment';

const RetentasAPIMain = axios.create();

let rtapimaintokendata = null;
let rtapimaintokenexpirydate = null

function objToQueryString(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
        keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
    }
    return keyValuePairs.join('&');
}

async function generateRTToken(cid) {
    const queryParameter = objToQueryString({
        client_id: process.env.REACT_APP_RETENTASAPI_KEY,
        client_secret: process.env.REACT_APP_RETENTASAPI_SECRET,
        grant_type: process.env.REACT_APP_RETENTASAPI_GRANTTYPE
    });
    const url = process.env.REACT_APP_RETENTASAPI_TOKENURL;
    try {
        const res = await axios.post(url, queryParameter);
        rtapimaintokendata = res;
        const currentUTCDate = Moment(Moment.utc().valueOf());
        var tokenExpiryDate = currentUTCDate.add(res.data.expires_in - 5, "second").utc().format(); 
        rtapimaintokenexpirydate = tokenExpiryDate;
    } catch (error) {
        console.log('Error getting RTToken - ' + error);
    }
};


RetentasAPIMain.interceptors.request.use(async req => {
    const currState = store.getState();
    // console.log(currState);
    const currtokenState = currState?.auth?.rtapimaintoken?.rtapimaintokendata;

    let authTokens = null;
    let accessToken = null;
    let refreshToken = null;
    let isTokenExpired = false;

    if (currtokenState?.data.access_token) {
        // do nothing
        // console.log('Old token');
    } else {
        // console.log('Fresh token');
        // console.log(req?.data?.CompanyID);
        await generateRTToken(req?.data?.CompanyID);
        store.dispatch( {type: actionTypes.MAINAPITOKEN_SUCCESS, rtapimaintokendata } );
    }

    const newState = store.getState();
    const newtokenState = newState?.auth?.rtapimaintoken?.rtapimaintokendata;

    authTokens = newtokenState?.data;
    accessToken = authTokens.access_token;
    refreshToken = authTokens.refresh_token;

    if(authTokens){
        req.headers.ContentType = 'application/json';   
        req.headers.Authorization = `Bearer ${authTokens.access_token}`
    }

    if (authTokens[".expires"]) {
        var currentDate = Moment().toDate(); //To get the Current Date
        var elapseSeconds = Moment(currentDate).diff(authTokens[".expires"], 'seconds');
        // console.log('ElapseSeconds - ', elapseSeconds);
        if (elapseSeconds > 0) {
            isTokenExpired = true;
        }    
    }
    // console.log('Is Token Expired - ', isTokenExpired);
    // console.log('Old Token - ', accessToken);
    
    if(!isTokenExpired) return req

    const queryParameter = objToQueryString({
        client_id: process.env.REACT_APP_RETENTASAPI_KEY,
        client_secret: process.env.REACT_APP_RETENTASAPI_SECRET,
        grant_type: process.env.REACT_APP_RETENTASAPI_GRANTTYPE
    });
    const baseURL = process.env.REACT_APP_RETENTASAPI_TOKENURL;

    const response = await axios.post(baseURL, queryParameter);

    store.dispatch( {type: actionTypes.MAINAPITOKEN_SUCCESS, response } );

    // console.log('New Token - ', response.data.access_token);

    req.headers.Authorization = `Bearer ${response.data.access_token}`
    return req
})

export default RetentasAPIMain;
