import React, { useEffect, useState } from 'react'
import { Card, Table, ButtonGroup, ToggleButton } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { styles } from '../layout/styles'
import moment from 'moment';
import { getEvaluation, getEvaluationQuestions, getEvaluationRatings, getUserEvaluationSessionResponses, getUserEvaluation, getGoals, 
    getUserAttributesLookups, getAttachments } from '../../store/actions/entityActions';
import { connect } from 'react-redux'
import { AttachmentType, EntityRowType, RetentasType } from '../../config/Constant';
import { Stepper, Step } from 'react-form-stepper';
import Spinner from '../layout/Spinner';

const UserEvaluationSummary = (props) => {
    const { auth, userevaluationsessions, processleveltypeid, companyData, UserEvaluationSession360, UserEvaluationSession360StepSummary, uesid, userattributelookups } = props;
    const typeCompanySummary = (companyData.CompanyID === '32') ? 2 : 12;
    const typeSummary = (processleveltypeid === null) ? typeCompanySummary : processleveltypeid;
    const navigate = useNavigate();
    const [radioValue, setRadioValue] = useState('1');
    const radios = [
        { name: 'Assigned', value: '1' },
        { name: 'Completed', value: '2' },
    ];
    const [loading, setloading] = useState(false);
    const [currentUESID, setcurrentUESID] = useState(-1);
    let currentListCount = 0;
    let currentRecIndex = 0;

    const processStepData = userattributelookups?.ProcessSteps;

    useEffect(() => {
        if ((uesid) && (uesid > 0)) {
            if (userevaluationsessions != null) {
                var launchUserEvalRow = userevaluationsessions?.filter((usereval) => (parseInt(usereval.UserEvaluationSessionID) === parseInt(uesid)));
                if ((launchUserEvalRow) && (launchUserEvalRow.length > 0)) {
                    handleLaunchEvaluation(launchUserEvalRow[0].EvaluationID, uesid);
                }
            }
        }
    }, [])  

    function getcurrentRecordIndex(){
        currentRecIndex++;        
        return currentRecIndex;
    }

    async function handleLaunchEvaluation(evalid, userevalsessionid) {
        //e.preventDefault();
        try {
            setcurrentUESID(userevalsessionid);
            setloading(true);
            await props.getEvaluation(evalid);
            await props.getEvaluationQuestions(evalid);
            await props.getEvaluationRatings(evalid);
            await props.getUserEvaluationSessionResponses(userevalsessionid);
            if (processleveltypeid === RetentasType.PRFs) {
                await props.getUserEvaluation(userevalsessionid);
                const evalParams  = {
                    UserID:auth.UserID,
                    SearchFilter:'',
                };                
                await props.getGoals(evalParams);    
            }
            setloading(false);
            setcurrentUESID(-1);
            navigate('/launchevaluation/' + userevalsessionid, {state: {processleveltypeid:processleveltypeid}});    
        } catch (err){
            console.log('Error is getting evaluation details - ' + evalid);
            setloading(false);
        }
    }

    async function handleProgressEvaluation(evalid, userevalsessionid) {
        try {
            setloading(true);
            await props.getEvaluation(evalid);
            await props.getEvaluationQuestions(evalid);
            await props.getEvaluationRatings(evalid);
            await props.getUserEvaluationSessionResponses(userevalsessionid);
            if (!userattributelookups) {
                await props.getUserAttributesLookups(auth?.UserID);
            }            
            if (processleveltypeid === RetentasType.PRFs) {
                await props.getUserEvaluation(userevalsessionid);
                const evalParams  = {
                    UserID:auth.UserID,
                    SearchFilter:'',
                };                
                await props.getGoals(evalParams);    
                const oAParams  = {
                    UserID:auth.UserID,
                    EntityID: userevalsessionid,
                    AttachmentTypeID: AttachmentType.PRF,
                    EntityRowTypeID: EntityRowType.PRF,
                    SearchFilter:'',
                };                
                await props.getAttachments(oAParams);    
            }
            setloading(false);
            navigate('/userevalsession/' + userevalsessionid, {state: {processleveltypeid:processleveltypeid}});    
            setloading(false);
        } catch (err){
            console.log('Error is getting progress evaluation details - ' + userevalsessionid);
            setloading(false);
        }
    }

    function extractPLSValues(queryString) {
        let plsValues = [];
        // Split the query string into individual key-value pairs
        const params = new URLSearchParams(queryString);
        
        // Get the value of the 'PLS' key
        const oPlsValues = params.get('PLS');
        
        // Check if 'PLS' exists and split it into an array
        if (oPlsValues) {
            plsValues = oPlsValues.split(','); // Splitting by ',' to get individual entries
        } else {
            // Return an empty array if 'PLS' key is not present
            plsValues = [];
        }        

        return plsValues;
    }

    function displayProcessSteps(userevaluation) {       
        return (
            <tr id={`tr${'detail' + userevaluation.UserEvaluationSessionID}`}>
                <td colSpan="9" style={{backgroundColor:companyData?.PagesBGColor}}> 
                    <table class="table table-responsive table-sm">  
                        <tbody> 
                            <tr> 
                                <td style={styles.tableText}>
                                    <Stepper 
                                        activeStep={2}
                                        styleConfig={{size:'1.6em', labelFontSize:'0.78rem', circleFontSize:'0.78rem', activeBgColor:companyData?.AccentColor, completedBgColor:companyData?.AccentColorSecondary, inactiveBgColor:companyData?.Grey}}>
                                            <Step label="Step 1" />
                                            <Step label="Personal Detail" />
                                            <Step label="Employment Detail" />
                                            <Step label="Confirmation" />                                        
                                    </Stepper>
                                </td> 
                                <td style={styles.tableText}></td> 
                            </tr>
                        </tbody>
                    </table>    
                </td>
            </tr>
        )        
    }

    function displayQuestionaires() {
        if (userevaluationsessions?.length === 0) {
            return (
                <div style={styles.tableText}>
                    No previous questionaires.
                </div>
            )
        } else {
            return (
                <div>
                    <Table responsive="sm" striped bordered hover variant="light">
                        <thead>
                        <tr>
                            <th style={styles.tableText}>#</th>
                            <th style={styles.tableText}>Name</th>
                            <th style={styles.tableText}>Assigned</th>
                            <th style={styles.tableText}>Due</th>
                            <th style={styles.tableText}>Completed</th>
                            <th style={styles.tableText}>Launch</th>
                        </tr>
                        </thead>
                        <tbody>
                            {userevaluationsessions && userevaluationsessions.map((userevaluation, index) => {
                                if (userevaluation.ProcessLevelTypeID === typeSummary) {
                                    const isCompleted = userevaluation.CompletedDate ? 1 : 2
                                    const displayEvalName = (typeSummary === RetentasType.Surveys) ? userevaluation?.EvaluationName : userevaluation?.AssignmentName;
                                    if ((radioValue !== isCompleted) && (userevaluation?.UserEvaluationTypeID !== 5) && (userevaluation?.UserEvaluationTypeID !== 6)) {
                                        currentListCount++;
                                        return (
                                            <tr>
                                                <td style={styles.tableText}>{getcurrentRecordIndex()}</td>
                                                <td style={styles.tableTextSubHeader}>{displayEvalName}</td>
                                                <td style={styles.tableText}>{userevaluation?.AssignedDate && moment(userevaluation?.AssignedDate).format("MM/DD/YYYY")}</td>
                                                <td style={styles.tableText}>{userevaluation?.DueDate && moment(userevaluation?.DueDate).format("MM/DD/YYYY")}</td>
                                                <td style={styles.tableText}>{userevaluation?.CompletedDate && moment(userevaluation?.CompletedDate).format("MM/DD/YYYY")}</td>
                                                <td style={styles.tableText}>      
                                                    {(userevaluation?.CompletedDate) ? 
                                                        <a>
                                                            <span class="material-symbols-outlined" style={{color:companyData?.Grey, fontSize:'25px', cursor: 'pointer'}}>monitoring</span>
                                                        </a>
                                                        :
                                                        ((loading && userevaluation?.UserEvaluationSessionID === currentUESID) ?    
                                                            <div>                                                    
                                                                <span class="material-symbols-outlined" style={{color:companyData?.AccentColor, fontSize:'25px', cursor: 'pointer'}}>rocket_launch</span>
                                                                <span style={styles.LinkBoldGrey}>Launching...</span>
                                                            </div>
                                                        :
                                                        <a onClick={() => handleLaunchEvaluation(userevaluation?.EvaluationID, userevaluation?.UserEvaluationSessionID)}> 
                                                            <span class="material-symbols-outlined" style={{color:companyData?.Grey, fontSize:'25px', cursor: 'pointer'}} title='Launch Questionnaire'>rocket</span>
                                                        </a>)
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    } else 
                                    {
                                        return (<></>)
                                    }
                                }
                            }
                        ) 
                        }    

                        </tbody>
                    </Table>        
                </div>
            )
        }
    } 

    function displayPerformances() {
        if (UserEvaluationSession360?.length === 0) {
            return (
                <div style={styles.tableText}>
                    No previous performance reviews.
                </div>
            )
        } else {
            return (
                <div>
                    <Table responsive="sm" striped bordered hover variant="light">
                        <thead>
                        <tr>
                            <th style={styles.tableText}>#</th>
                            <th style={styles.tableText}>Name</th>
                            <th style={styles.tableText}>Employee Name</th>
                            <th style={styles.tableText}>Evaluation Type</th>
                            <th style={styles.tableText}>Assigned</th>
                            <th style={styles.tableText}>Due</th>
                            <th style={styles.tableText}>Completed</th>
                            <th style={styles.tableText}>Launch</th>
                            <th style={styles.tableText}>Steps</th>
                        </tr>
                        </thead>
                        <tbody>
                        {UserEvaluationSession360 && UserEvaluationSession360.map((userevaluation, oIndex) => {
                            if (userevaluation.ProcessLevelTypeID === typeSummary) {
                                const isCompleted = ((userevaluation?.IsPublished) && (userevaluation?.IsPublished == true)) ? 1 : 2
                                const displayEvalName = userevaluation?.AssignmentName;
                                if ((parseInt(radioValue) !== isCompleted) && (userevaluation?.UserEvaluationTypeID !== 5) && (userevaluation?.UserEvaluationTypeID !== 6)) {
                                    currentListCount++;
                                    return (
                                        <>
                                        <tr>
                                            <td style={styles.tableText}>{getcurrentRecordIndex()}</td>
                                            <td style={styles.tableTextSubHeader}>{displayEvalName}</td>
                                            <td style={styles.tableTextSubHeader}>{userevaluation?.UserFullName}</td>
                                            <td style={styles.tableText}>{userevaluation?.UserEvaluationTypeName}</td>
                                            <td style={styles.tableText}>{userevaluation?.AssignedDate && moment(userevaluation?.AssignedDate).format("MM/DD/YYYY")}</td>
                                            <td style={styles.tableText}>{userevaluation?.DueDate && moment(userevaluation?.DueDate).format("MM/DD/YYYY")}</td>
                                            <td style={styles.tableText}>{(isCompleted === 1) && (userevaluation?.CompletedDate) && moment(userevaluation?.CompletedDate).format("MM/DD/YYYY")}</td>
                                            <td style={styles.tableText}>      
                                                {(isCompleted === 1) ? 
                                                    <a>
                                                        <span class="material-symbols-outlined" style={{color:companyData?.Grey, fontSize:'25px', cursor: 'pointer'}}>monitoring</span>
                                                    </a>
                                                    :
                                                    ((loading && userevaluation?.UserEvaluationSessionID === currentUESID) ?    
                                                        <div>                                                    
                                                            <span class="material-symbols-outlined" style={{color:companyData?.AccentColor, fontSize:'25px', cursor: 'pointer'}}>rocket_launch</span>
                                                            <span style={styles.LinkBoldGrey}>Launching...</span>
                                                        </div>
                                                    :
                                                    <a onClick={() => handleLaunchEvaluation(userevaluation?.EvaluationID, userevaluation?.UserEvaluationSessionID)}> 
                                                        <span class="material-symbols-outlined" style={{color:companyData?.Grey, fontSize:'25px', cursor: 'pointer'}} title='Launch Evaluation'>rocket</span>
                                                    </a>)
                                                }
                                            </td>
                                            <td style={styles.tableText}>
                                                <a onClick={() => handleProgressEvaluation(userevaluation?.EvaluationID, userevaluation?.UserEvaluationSessionID)}> 
                                                    <span class="material-symbols-outlined" style={{color:companyData?.Grey, fontSize:'25px', cursor: 'pointer'}} title='Progress'>stairs</span>
                                                </a>
                                            </td>
                                        </tr>
                                        {/* {displayProcessSteps(userevaluation)} */}
                                        </>    
                                    );
                                } else 
                                {
                                    return (<></>)
                                }
                                }
                            }
                        ) 
                        }    

                        </tbody>
                    </Table>        
                </div>
            )    
        }
    } 

    function displayStepAnalysis() {
        let evalID = '';
        const companyParameters = auth.UserCompanyParameters?.split('&');
        companyParameters.map((parameter) => {
            const parameterDetails = parameter?.split('=');
            if (parameterDetails[0] === 'PERFORMANCEEVALUATIONS') {
                evalID = parameterDetails[1];
            }
        });

        if (evalID.trim() !== ''){
            var oEvalSessionRows = UserEvaluationSession360?.filter((oSession) => (parseInt(oSession.EvaluationID) === parseInt(evalID)));
            if ((oEvalSessionRows) && (oEvalSessionRows.length > 0)) {
                const plsValues = extractPLSValues(oEvalSessionRows[0].ProcessLevels);
                return (
                    <Stepper 
                        activeStep={plsValues.length}
                        styleConfig={{size:'1.6em', labelFontSize:'0.98rem', circleFontSize:'0.78rem', activeBgColor:companyData?.AccentColor, completedBgColor:companyData?.AccentColorSecondary, inactiveBgColor:companyData?.Grey}}>
                            {plsValues.map((plsValue, iIndex) => {
                                let oStepItem = null; // Default to null
                                let sStepName = '';
                                const iStepID = parseInt(plsValue.split(':')[1]);
                                const oStepRow = processStepData?.filter((step) => (parseInt(step.ProcessStepID) === parseInt(iStepID)));
                                if ((oStepRow) && (oStepRow.length > 0)) {
                                    var oEvalRows = UserEvaluationSession360StepSummary?.filter((oStep) => (parseInt(oStep.StepID) === parseInt(iStepID) && (parseInt(oStep.EvaluationID) === parseInt(evalID))));
                                    if ((oEvalRows) && (oEvalRows.length > 0)) {
                                        sStepName = oStepRow[0].ProcessStepName + ' (' + oEvalRows[0].StepCount.toLocaleString() + ' - Employees)';
                                    } else {
                                        sStepName = oStepRow[0].ProcessStepName;
                                    }
                                }
                                oStepItem = (
                                    <Step style={{ whiteSpace: 'pre-wrap' }} label={sStepName} />
                                );
                                return oStepItem;
                            })}                                    
                    </Stepper>
                )            
            }
        }
    }

    function displayMain() {
        if (loading) {
            return (
                <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                    <Spinner/>
                </div>
            )
        } else {
            if (processleveltypeid === RetentasType.Surveys) { 
                return displayQuestionaires();
            } else {
                return displayPerformances();
            }
        }
    }

    return ( 
        <div>
            <Card className="text-center">
                <Card.Header style={{backgroundColor:companyData?.PagesBGColor}}> 
                    <Table responsive="lg" >
                            <thead>
                            <tr style={{backgroundColor:companyData?.PagesBGColor}}>
                                <th style={{width:'40%', backgroundColor:companyData?.PagesBGColor, alignItems:'left'}}>
                                    {processleveltypeid === RetentasType.Surveys ?
                                            <h3 style={styles.SubHeader}>Questionnaires</h3>
                                        :
                                            <h3 style={styles.SubHeader}>Performance Reviews</h3>
                                    }                                
                                </th>
                                <th style={{alignItems:'center', backgroundColor:companyData?.PagesBGColor}}>
                                    <Card.Title>
                                    </Card.Title>
                                </th>
                                <th style={{width:'20%', alignItems:'right', backgroundColor:companyData?.PagesBGColor, color:companyData?.AccentColor}}>
                                        <ButtonGroup className="mb-2" size='sm'>
                                            {radios.map((radio, idx) => (
                                                <ToggleButton
                                                    key={typeSummary + idx}
                                                    id={`radio-${typeSummary + idx}`}
                                                    type="radio"
                                                    style={radioValue === radio.value ? styles.CDButtonSelected : styles.CDButtonUnselected}
                                                    name="radio"
                                                    value={radio.value}
                                                    checked={radioValue === radio.value}
                                                    size='sm'
                                                    onChange={(e) => {currentRecIndex = 0; setRadioValue(e.currentTarget.value)}}
                                                >
                                                    {radio.name}
                                                </ToggleButton>
                                            ))}
                                        </ButtonGroup>                        
                                </th>
                            </tr>
                            {processleveltypeid === RetentasType.Surveys ?
                                    <></>
                                :
                                    <>
                                        <tr style={{backgroundColor:companyData?.PagesBGColor}}>
                                            <th colSpan='3' style={styles.tableText}>
                                                {displayStepAnalysis()}
                                            </th>
                                        </tr>
                                    </>
                            }                                
                            </thead>
                            <tbody>
                            </tbody>
                    </Table>
                </Card.Header>
                <Card.Body style={{backgroundColor:companyData?.PagesBGColor}}>
                    {displayMain()}
                </Card.Body>
                <Card.Footer className="text-right" style={{alignItems:'end', backgroundColor:companyData?.PagesBGColor}}>
                    <Table variant="light">
                        <thead>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                    {processleveltypeid === RetentasType.Surveys ?
                                            <h5 style={styles.tableTextLeft}>{currentListCount.toLocaleString()}   Questionnaires</h5>
                                        :
                                            <h5 style={styles.tableTextLeft}>{currentListCount.toLocaleString()}   Performance Reviews</h5>
                                    }                                
                                </td>
                                <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                    {/* {(loading) ? <></> : 
                                        <div style={{display:'flex', justifyContent:'center'}}>
                                        <h5 style={styles.tableText}>
                                        {(reminders?.reminders?.PagingMetaData?.PreviousPage) ? (
                                                <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                    <span title='Previous Page'>Previous</span>
                                                </a>
                                        ) 
                                        : 
                                        <></> }
                                        {(reminders?.reminders?.PagingMetaData?.NextPage) ? (
                                                <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                    <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                </a>
                                        ) 
                                        : 
                                        <></> }
                                        </h5>
                                        </div>
                                    } */}
                                </td>
                                <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                    {/* <h5 style={styles.tableTextRight}> Page   {reminders?.reminders?.PagingMetaData?.PageNumber}  of  {reminders?.reminders?.PagingMetaData?.TotalPage.toLocaleString()}</h5> */}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Footer>
            </Card>
        </div>
    )        

}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state?.auth?.user,
        companyData: state?.auth?.companyData,
        UserEvaluationSession360: state?.auth?.userDetail?.UserEvaluationSession360,
        UserEvaluationSession360StepSummary: state?.auth?.userDetail?.UserEvaluationSession360StepSummary,
        userattributelookups: state.entity.userattributelookups,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEvaluation: (id) => dispatch(getEvaluation(id)),
        getEvaluationQuestions: (id) => dispatch(getEvaluationQuestions(id)),
        getEvaluationRatings: (id) => dispatch(getEvaluationRatings(id)),
        getUserEvaluationSessionResponses: (id) => dispatch(getUserEvaluationSessionResponses(id)),        
        getUserEvaluation: (id) => dispatch(getUserEvaluation(id)),        
        getGoals: (evalParams) => dispatch(getGoals(evalParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        getAttachments: (oAParams) => dispatch(getAttachments(oAParams)),
    }
} 

export default connect(mapStateToProps, mapDispatchToProps)(UserEvaluationSummary)
