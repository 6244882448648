import * as actionTypes from '../actions/actionTypes'; 
import { MenuType } from '../../config/Constant';

const initState = {
    user: null,
    loading: false,
    authError: null,
    rtapitoken:null,
    rtapimaintoken:null,
    profile: null,
    userDetail: null, 
    mygroups:null,
    myTeam: null, 
    myTeamSelected: null,
    myTeamSuggestionData: null,
    myTeamStats: null,
    activeMenuItem:null,
    orgDivisions:null,
    reports:null,
    userReportDetails:null,
    userReportPillar:null,
    userReportPerformance:null,
    selectedProfile:null,
    orgDivisionManagers:null,
    myTeamStatsSelected: null,
    restructureDetail:null,
    loadingMenu:false,
    companyID:-1,
    companyData:null,
    orgDivisionSelectedLALs:null,
    userLookup:null, 
    orgDivisionSelectedCompetencyAnalysis:null,
    orgJobClasses:null,
    orgDivisionSelectedPillarAnalysis:null,
    orgJobPositionSelectedPillarAnalysis:null,
    ssoStateDate:null,
    myMenus:null,
    entityError: null,
    entitySuccess: null,
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.MAINAPITOKEN_SUCCESS:
            return {
                ...state, 
                rtapitoken:null,
                rtapimaintoken:action,
            }
        case actionTypes.APITOKEN_SUCCESS:
            return {
                ...state, 
                rtapitoken:action,
            }
        case actionTypes.VALIDATECOMPANY_ERROR:
            return {
                ...state, 
                companyData: null,
                authError: action.payload.error,
                companyID: action.payload.companyID
            }
        case actionTypes.VALIDATECOMPANY_SUCCESS:
            return {
                ...state, 
                authError: null,
                companyData: action.payload.companyData,
            }
        case actionTypes.VALIDATESSOSTATE_ERROR:
            return {
                ...state, 
                ssoStateData: null,
                authError: action.payload.error,
            }
        case actionTypes.VALIDATESSOSTATE_SUCCESS:
            return {
                ...state, 
                authError: null,
                ssoStateData: action.payload.ssoStateData,
            }
        case actionTypes.LOGIN_ERROR:
            return {
                ...state, 
                loading: false,
                user: null,
                authError: action.payload.error,
                companyID: action.payload.companyID
            }
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state, 
                loading: false,
                user: action.payload.userData,
                profile: action.payload.profileData,
                mygroups: action.payload.userGroupsData,
                myTeam: action.payload.myTeamData,
                myTeamSuggestionData: action.payload.myTeamSuggestionData,
                myTeamStat: action.payload.myTeamStats,
                activeMenuItem: MenuType.Home,
                userDetail: action.payload.userDetail,
                companyID: action.payload.companyID,
                myMenus: action.payload.myMenus,
                authError: null,
                entityError: null,
                entitySuccess: null,
            }
        case actionTypes.SIGNOUT_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.SIGNOUT_SUCCESS:
            return {
                ...state, 
                user: null,
                profile: null,
                mygroups:null,
                authError: null,
                myTeam: null,
                myTeamSuggestionData: null,
                myTeamStat: null,
                activeMenuItem: null,
                loading: false,
                userDetail: null, 
                myTeamSelected: null,
                myTeamStats: null,
                orgDivisions:null,
                reports:null,
                userReportDetails:null,
                userReportPillar:null,
                userReportPerformance:null,
                selectedProfile:null,
                orgDivisionManagers:null,
                myTeamStatsSelected: null,
                restructureDetail:null,
                loadingMenu:false,    
                rtapitoken:null,
                companyData:null,
                orgDivisionSelectedLALs:null,
                orgDivisionSelectedCompetencyAnalysis:null,
                userLookup:null,
                orgJobClasses:null,
                orgDivisionSelectedPillarAnalysis:null,
                orgJobPositionSelectedPillarAnalysis:null,
                myMenus:null,
                entityError: null,
                entitySuccess: null,
            }
        case actionTypes.SIGNUP_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.SIGNUP_SUCCESS:
            return {
                ...state, 
                authError: null
            }
        case actionTypes.GETPROFILE_ERROR:
            return {
                ...state, 
                authError: action
            }
        case actionTypes.GETPROFILE_SUCCESS:
            return {
                ...state, 
                profile: action
            }
        case actionTypes.UPDATEPROFILE_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.UPDATEPROFILE_SUCCESS:
            return {
                ...state, 
                authError: null
            }
        case actionTypes.GETUSERPROFILE_SUCCESS:
            return {
                ...state, 
                profile: action.payload.profileData,
            }
        case actionTypes.GETUSERPROFILE_ERROR:
            return {
                ...state, 
                authError: action
            }
            case actionTypes.GETMYTEAMRETENTIONSUGGESTIONS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETMYTEAMRETENTIONSUGGESTIONS_SUCCESS:
            return {
                ...state, 
                myTeamSuggestionData: action.payload.myTeamSuggestionData,
                authError: null
            }
        case actionTypes.MYTEAMSELECTED_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.MYTEAMSELECTED_SUCCESS:
            return {
                ...state, 
                myTeamSelected: action.payload.myTeamSelected,
                authError: null
            }
        case actionTypes.ASSIGNACTIVEMENU_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.ASSIGNACTIVEMENU_SUCCESS:
            return {
                ...state, 
                activeMenuItem: action.payload.menuId,
                authError: null
            }
        case actionTypes.LOADINGMENUSELECTION_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.LOADINGMENUSELECTION_SUCCESS:
            return {
                ...state, 
                loadingMenu: action.payload.loadingMenu,
                authError: null
            }
        case actionTypes.GETORGDIVISIONS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETORGDIVISIONS_SUCCESS:
            return {
                ...state, 
                orgDivisions: action.payload.orgDivisions,
                authError: null
            }
        case actionTypes.GETORGJOBCLASSES_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETORGJOBCLASSES_SUCCESS:
            return {
                ...state, 
                orgJobClasses: action.payload.orgJobClasses,
                authError: null
            }
        case actionTypes.GETRTREPORTS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETRTREPORTS_SUCCESS:
            return {
                ...state, 
                reports: action.payload.reports,
                authError: null
            }
        case actionTypes.GETRTUSERREPORTDETAILS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETRTUSERREPORTDETAILS_SUCCESS:
            return {
                ...state, 
                userReportDetails: action.payload.userReportDetails,
                authError: null
            }
        case actionTypes.GETRTUSERREPORTPILLARS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETRTUSERREPORTPILLARS_SUCCESS:
            return {
                ...state, 
                userReportPillar: action.payload.userReportPillar,
                authError: null
            }
        case actionTypes.GETRTUSERREPORTPERFORMANCE_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETRTUSERREPORTPERFORMANCE_SUCCESS:
            return {
                ...state, 
                userReportPerformance: action.payload.userReportPerformance,
                authError: null
            }
        case actionTypes.GETUSERGROUP_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETUSERGROUP_SUCCESS:
            return {
                ...state, 
                userDetail: {
                    ...state.userDetail,  
                    UserGroups: action.payload.UserGroups
                },
                authError: null
            }
        case actionTypes.GETUSERGROUPSELECTED_SUCCESS:
            return {
                ...state, 
                selectedProfile: {
                    ...state.selectedProfile,  
                    UserGroups: action.payload.UserGroups
                },
                authError: null
            }
        case actionTypes.GETUSERLICENSE_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETUSERLICENSE_SUCCESS:
            return {
                ...state, 
                userDetail: {
                  ...state.userDetail,  
                  UserLicense: action.payload.UserLicense
                },
                authError: null
            }
        case actionTypes.GETUSERLICENSESELECTED_SUCCESS:
            return {
                ...state, 
                selectedProfile: {
                    ...state.selectedProfile,  
                    UserLicense: action.payload.UserLicense
                },
                authError: null
            }
        case actionTypes.GETUSERCREDENTIAL_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETUSERCREDENTIAL_SUCCESS:
            return {
                ...state, 
                userDetail: {
                    ...state.userDetail,  
                    UserCredential: action.payload.UserCredential
                },
                authError: null
            }
        case actionTypes.GETUSEREVALUATIONS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETUSEREVALUATIONS_SUCCESS:
            return {
                ...state, 
                userDetail: {
                    ...state.userDetail,  
                    UserEvaluationSession360: action.payload.UserEvaluationSession360,
                    UserEvaluationSession360StepSummary: action.payload.UserEvaluationSession360StepSummary
                },
                authError: null
            }
        case actionTypes.GETUSERCREDENTIALSELECTED_SUCCESS:
            return {
                ...state, 
                selectedProfile: {
                    ...state.selectedProfile,  
                    UserCredential: action.payload.UserCredential
                },
                authError: null
            }
        case actionTypes.GETUSERGOAL_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETUSERGOAL_SUCCESS:
            return {
                ...state, 
                userDetail: {
                    ...state.userDetail,  
                    UserGoal: action.payload.UserGoal
                },
                authError: null
            }
        case actionTypes.GETUSERGOALSELECTED_SUCCESS:
            return {
                ...state, 
                selectedProfile: {
                    ...state.selectedProfile,  
                    UserGoal: action.payload.UserGoal
                },
                authError: null
            }
        case actionTypes.GETUSERSKILL_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETUSERSKILL_SUCCESS:
            return {
                ...state, 
                userDetail: {
                    ...state.userDetail,  
                    UserSkill: action.payload.UserSkill
                },
                authError: null
            }
        case actionTypes.GETUSERSKILLSELECTED_SUCCESS:
            return {
                ...state, 
                selectedProfile: {
                    ...state.selectedProfile,  
                    UserSkill: action.payload.UserSkill
                },
                authError: null
            }
        case actionTypes.GETUSERJOBPOSITIONREQUIREMENTS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETUSERJOBPOSITIONREQUIREMENTS_SUCCESS:
            return {
                ...state, 
                userDetail: {
                    ...state.userDetail,  
                    UserJobPositionRequirements: action.payload.UserJobPositionRequirements
                },
                authError: null
            }
        case actionTypes.GETUSERJOBPOSITIONREQUIREMENTSSELECTED_SUCCESS:
            return {
                ...state, 
                selectedProfile: {
                    ...state.selectedProfile,  
                    UserJobPositionRequirements: action.payload.UserJobPositionRequirements
                },
                authError: null
            }
        case actionTypes.GETUSERFUTUREJOBPOSITIONS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETUSERFUTUREJOBPOSITIONS_SUCCESS:
            return {
                ...state, 
                userDetail: {
                    ...state.userDetail,  
                    UserFutureJobPosition: action.payload.UserFutureJobPosition
                },
                authError: null
            }
        case actionTypes.GETUSERFUTUREJOBPOSITIONSSELECTED_SUCCESS:
            return {
                ...state, 
                selectedProfile: {
                    ...state.selectedProfile,  
                    UserFutureJobPosition: action.payload.UserFutureJobPosition
                },
                authError: null
            }
        case actionTypes.GETMYTEAM_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETMYTEAM_SUCCESS:
            return {
                ...state, 
                myTeam: action.payload.myTeam,
                authError: null
            }
        case actionTypes.GETUSERLOOKUP_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETUSERLOOKUP_SUCCESS:
            return {
                ...state, 
                userLookup: action.payload.userLookup,
                authError: null
            }
        case actionTypes.GETSELECTEDUSERPROFILE_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETSELECTEDUSERPROFILE_SUCCESS:
            return {
                ...state, 
                selectedProfile: action.payload.selectedProfile,
                authError: null
            }
        case actionTypes.GETDIVISIONMANAGERS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETDIVISIONMANAGERS_SUCCESS:
            return {
                ...state, 
                orgDivisionManagers: action.payload.orgDivisionManagers,
                authError: null
            }
        case actionTypes.GETDIVISIONLEADERSHIPASSESSMENTLEVELS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETDIVISIONLEADERSHIPASSESSMENTLEVELS_SUCCESS:
            return {
                ...state, 
                orgDivisionSelectedLALs: action.payload.orgDivisionSelectedLALs,
                authError: null
            }
        case actionTypes.GETDIVISIONCOMPETENCYANALYSIS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETDIVISIONCOMPETENCYANALYSIS_SUCCESS:
            return {
                ...state, 
                orgDivisionSelectedCompetencyAnalysis: action.payload.orgDivisionSelectedCompetencyAnalysis,
                authError: null
            }
        case actionTypes.GETDIVISIONPILLARANALYSIS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETDIVISIONPILLARANALYSIS_SUCCESS:
            return {
                ...state, 
                orgDivisionSelectedPillarAnalysis: action.payload.orgDivisionSelectedPillarAnalysis,
                authError: null
            }
        case actionTypes.GETJOBPOSITIONPILLARANALYSIS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETJOBPOSITIONPILLARANALYSIS_SUCCESS:
            return {
                ...state, 
                orgJobPositionSelectedPillarAnalysis: action.payload.orgJobPositionSelectedPillarAnalysis,
                authError: null
            }
        case actionTypes.GETMYTEAMSTATSELECTED_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.GETMYTEAMSTATSELECTED_SUCCESS:
            return {
                ...state, 
                myTeamStatSelected: action.payload.myTeamStatSelected,
                authError: null
            }
        case actionTypes.ASSIGNRESTRUCTUREDETAILS_ERROR:
            return {
                ...state, 
                authError: action 
            }
        case actionTypes.ASSIGNRESTRUCTUREDETAILS_SUCCESS:
            return {
                ...state, 
                restructureDetail: action.payload.rParams,
                authError: null
            }
        case actionTypes.FORGOTPASSWORD_ERROR:
            return {
                ...state, 
                entityError: action.payload,
                entitySuccess: null
            }
        case actionTypes.FORGOTPASSWORD_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload,
                entityError: null
            }
        case actionTypes.RESETPASSWORD_ERROR:
            return {
                ...state, 
                entityError: action.payload,
                entitySuccess: null
            }
        case actionTypes.RESETPASSWORD_SUCCESS:
            return {
                ...state, 
                entitySuccess: action.payload,
                entityError: null
            }
                                                                    
        default:
        {
                return state;
        }    
    }
}

export default authReducer