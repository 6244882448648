import React, { useEffect, useCallback, useState } from 'react'
import { Button, Card, ListGroup, Badge, Table, Image, ButtonGroup, ToggleButton, Form, Modal } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { styles } from '../layout/styles'
import moment from 'moment';
import { getRetentionPrediction, updateUserSuggestion } from '../../store/actions/entityActions';
import { getMyTeamRetentionSuggestions, getMyTeamSelected } from '../../store/actions/authActions';
import { connect, useSelector } from 'react-redux';
import { ResponsiveContainer, PieChart, Pie, Sector, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Rectangle, 
    ScatterChart, Scatter, LabelList, ReferenceArea, LineChart, Line } from 'recharts';
import ScatterGetClickedPoint from './ScatterGetClickedPoint';
import ScatterCustomDot from './ScatterCustomDot';
import UserList from './UserList'
import {TriggeredFrom} from '../../config/Constant';
import Spinner from '../layout/Spinner';
import CustomTooltip from '../layout/CustomTooltip';
import LineChartCustomDot from './LineChartCustomDot';
import CustomLineChartLegend from '../layout/CustomLineChartLegend';
import CylinderBar from './CylinderBar';

const AnalyticsDetails = (props) => {
    const { companyData, myTeam, myTeamStat, myTeamFromState, retentionSessionResults, auth, myTeamSuggestions } = props;
    //const userlist = myTeam.Users;
    const typeSummary = 'analytics';
    const navigate = useNavigate();
    const [radioValue, setRadioValue] = useState('1');
    const radios = [
        { name: 'Attributes', value: '1' },
        { name: 'Pillars', value: '2' },
        { name: 'Finance', value: '3' },
    ];
    const [loading, setloading] = useState(false);
    const [currentUserID, setcurrentUserID] = useState(-1);
    let currentRecIndex = 0;
    const [activeIndex, setActiveIndex] = useState(0);
    const [anyGraphClicked, setanyGraphClicked] = useState(false);
    const [entityRowTypeIDClicked, setEntityRowTypeIDClicked] = useState(-1);
    const [entityIDClicked, setEntityIDClicked] = useState(-1);
    const [selectedPillarID, setSelectedPillarID] = useState("0");
    const onPieEnter = useCallback(
      (_, index) => {
        setActiveIndex(index);
      },
      [setActiveIndex]
    );
    
    const scatterGraphData = myTeamStat?.UserAmount.slice(0,6);
    const MIN_ZOOM = 0.1; // adjust based on your data
    const DEFAULT_ZOOM = { x1: null, y1: null, x2: null, y2: null };
      
  // data currently on the plot
  const [filteredData, setFilteredData] = useState(scatterGraphData);
  // selected data point
  //const [selectedPoint, setSelectedPoint] = useState(scatterGraphData[0]);
  const [selectedPoint, setSelectedPoint] = useState(null);
  // zoom coordinates
  const [zoomArea, setZoomArea] = useState(DEFAULT_ZOOM);
  // flag if currently zooming (press and drag)
  const [isZooming, setIsZooming] = useState(false);
  // flag if zoomed in
  const isZoomed = filteredData?.length !== scatterGraphData?.length;

  // flag to show the zooming area (ReferenceArea)
  const showZoomBox =
    isZooming &&
    !(Math.abs(zoomArea.x1 - zoomArea.x2) < MIN_ZOOM) &&
    !(Math.abs(zoomArea.y1 - zoomArea.y2) < MIN_ZOOM);
   
  // reset the states on zoom out
  function handleScatterZoomOut() {
    setFilteredData(scatterGraphData);
    setZoomArea(DEFAULT_ZOOM);
  }

  /**
   * Two possible events:
   * 1. Clicking on a dot(data point) to select
   * 2. Clicking on the plot to start zooming
   */
  function handleScatterMouseDown(e) {
    setIsZooming(true);
    const { chartX, chartY, xValue, yValue } = e || {};
    const clickedPoint = ScatterGetClickedPoint(chartX, chartY, filteredData);

    if ((clickedPoint !== undefined) && (clickedPoint !== null)) {
        console.log(clickedPoint);
        handleUserClick(clickedPoint.UserID);
    }

    if (clickedPoint) {
      setSelectedPoint(clickedPoint);
    } else {
      // console.log("zoom start");
      setZoomArea({ x1: xValue, y1: yValue, x2: xValue, y2: yValue });
    }
  }

  // Update zoom end coordinates
  function handleScatterMouseMove(e) {
    if (isZooming) {
      // console.log("zoom selecting");
      setZoomArea((prev) => ({ ...prev, x2: e?.xValue, y2: e?.yValue }));
    }
  }

  // When zooming stops, update with filtered data points
  // Ignore if not enough zoom
  function handleScatterMouseUp(e) {
    if (isZooming) {
      setIsZooming(false);
      let { x1, y1, x2, y2 } = zoomArea;

      // ensure x1 <= x2 and y1 <= y2
      if (x1 > x2) [x1, x2] = [x2, x1];
      if (y1 > y2) [y1, y2] = [y2, y1];

      if (x2 - x1 < MIN_ZOOM || y2 - y1 < MIN_ZOOM) {
        // console.log("zoom cancel");
      } else {
        // console.log("zoom stop");
        const dataPointsInRange = filteredData.filter(
          (d) => d?.ScoredProbabilities >= x1 && d?.ScoredProbabilities <= x2 && d.AtRiskAmount >= y1 && d.AtRiskAmount <= y2
        );
        setFilteredData(dataPointsInRange);
        setZoomArea(DEFAULT_ZOOM);
      }
    }
  }

  const renderRetentionPie = (props) => {
      const RADIAN = Math.PI / 180;
      const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value
      } = props;
      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      const sx = cx + (outerRadius + 10) * cos;
      const sy = cy + (outerRadius + 10) * sin;
      const mx = cx + (outerRadius + 30) * cos;
      const my = cy + (outerRadius + 30) * sin;
      const ex = mx + (cos >= 0 ? 1 : -1) * 22;
      const ey = my;
      const textAnchor = cos >= 0 ? "start" : "end";
      const fill2 = companyData?.AccentColorSecondary;
  
      return (
        <g>
          <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
            {payload.name}
          </text>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill2}
            // fill={companyData?.accentColorSecondary}
          //   fill={fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill="none"
          />
          <circle cx={ex} cy={ey} r={2} 
              fill={fill} 
              stroke="none" />
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill="#333"
          >{`${value.toLocaleString()}`}({payload.RetentionTypeName})</text>
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill="#999"
          >
            {`(${(percent * 100).toFixed(2)}%)`}
          </text>
        </g>
      );
    };
      
    async function handleUserClick(id) {
        setloading(true);
        setanyGraphClicked(true);
        setEntityRowTypeIDClicked(1);        
        setEntityIDClicked(id);        
        const teamParams = {
            UserID:auth.UserID,
            SearchUserID: id,
            RecordSize:'20',
            PageNumber:'1',
        };        

        await props.getMyTeamSelected(teamParams);
        setloading(false);
    };

    async function handleDivisionClick(data, index) {
        setloading(true);
        setanyGraphClicked(true);
        setEntityRowTypeIDClicked(4);        
        setEntityIDClicked(data?.activePayload[0].payload.DivisionID);        
        const teamParams = {
            UserID:auth.UserID,
            DivisionID: data?.activePayload[0].payload.DivisionID,
            ScoredProbabilities:'AtRisk',
            RecordSize:'10',
            PageNumber:'1',
        };        
        console.log(teamParams);
        await props.getMyTeamSelected(teamParams);
        setloading(false);
    };

    async function handleFacilityClick(data, index) {
        setloading(true);
        setanyGraphClicked(true);
        setEntityRowTypeIDClicked(3);        
        setEntityIDClicked(data?.activePayload[0].payload.FacilityID);        
        const teamParams = {
            UserID:auth.UserID,
            FacilityID: data?.activePayload[0].payload.FacilityID,
            ScoredProbabilities:'AtRisk',
            RecordSize:'10',
            PageNumber:'1',
        };        

        console.log(teamParams);
        await props.getMyTeamSelected(teamParams);
        setloading(false);
    };

    async function handleJobCodeClick(data, index) {
        setloading(true);
        setanyGraphClicked(true);
        setEntityRowTypeIDClicked(6);        
        setEntityIDClicked(data?.activePayload[0].payload.JobCodeID);        
        const teamParams = {
            UserID:auth.UserID,
            JobCodeID: data?.activePayload[0].payload.JobCodeID,
            ScoredProbabilities:'AtRisk',
            RecordSize:'10',
            PageNumber:'1',
        };        

        await props.getMyTeamSelected(teamParams);
        setloading(false);
    };

    async function handleJobPositionClick(data, index) {
        setloading(true);
        setanyGraphClicked(true);
        setEntityRowTypeIDClicked(7);        
        setEntityIDClicked(data?.activePayload[0].payload.JobPositionID);        
        const teamParams = {
            UserID:auth.UserID,
            JobPositionID: data?.activePayload[0].payload.JobPositionID,
            ScoredProbabilities:'AtRisk',
            RecordSize:'10',
            PageNumber:'1',
        };        

        await props.getMyTeamSelected(teamParams);
        setloading(false);
    };

    async function handleEmploymentTypeClick(data, index) {
        setloading(true);
        setanyGraphClicked(true);
        setEntityRowTypeIDClicked(8);        
        setEntityIDClicked(data?.activePayload[0].payload.EmploymentTypeID);        
        const teamParams = {
            UserID:auth.UserID,
            EmploymentTypeID: data?.activePayload[0].payload.EmploymentTypeID,
            ScoredProbabilities:'AtRisk',
            RecordSize:'10',
            PageNumber:'1',
        };        
        console.log(teamParams);
        await props.getMyTeamSelected(teamParams);
        setloading(false);
    };

    function getcurrentRecordIndex(){
        currentRecIndex++;        
        return currentRecIndex;
    }

    function getGraphs() {
        if (radioValue === '1') {
            return getUserAttributesGraphs();
        } else if (radioValue === '2') {
            return getPillarGraphs();
        } else if (radioValue === '3') {
            return getBusinessGraphs();
        }
    }

    function getUserAttributesGraphs() {
        return (
            <tbody>
                <tr>
                <td style={styles.tableCellInnerGraph}>
                    <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                        <thead>
                            <tr>
                                <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Overall</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                    <ResponsiveContainer width={'100%'} height={220}>
                                        <PieChart>
                                            <Pie
                                                activeIndex={activeIndex}
                                                activeShape={renderRetentionPie}
                                                data={myTeamStat?.Overall}
                                                cx={210}
                                                cy={106}
                                                innerRadius={42}
                                                outerRadius={62}
                                                fill={companyData?.AccentColorLighter}
                                                nameKey="RetentionTypeName"
                                                dataKey="RetentionCount"
                                                onMouseEnter={onPieEnter}
                                            />
                                        </PieChart>
                                    </ResponsiveContainer>
                                </td>
                            </tr>
                        </tbody>
                    </Table>                                                    
                </td>                            
                <td style={styles.tableCellInnerGraph}>
                    <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                        <thead>
                            <tr>
                                <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Facility</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                    <ResponsiveContainer width={'100%'} height={220}>
                                        <BarChart onClick={handleFacilityClick}
                                            data={myTeamStat?.Facility.slice(0, 10)}
                                            >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="FacilityID"/>
                                            <YAxis tickFormatter={tick => { return tick.toLocaleString()}} domain={['auto', 'dataMax + 5']}/>
                                            <Tooltip content={<CustomTooltip displayName='FacilityName'/>} formatter={(value) => value && value.toLocaleString()}/>
                                            <Bar dataKey="SafeCount" stackId="a" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"FacilitySafeCount"} lowerbar={true}/>}/>
                                            <Bar dataKey="AtRiskCount" stackId="a" fill={companyData?.AccentColorLighter} shape={<CylinderBar id={"FacilityAtRiskCount"} lowerbar={false}/>}/>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </td>
                            </tr>
                        </tbody>
                    </Table>                                                    
                </td>                            
                <td style={styles.tableCellInnerGraph}>
                    <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                        <thead>
                            <tr>
                                <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Divisions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                    <ResponsiveContainer width={'100%'} height={220}>
                                        <BarChart onClick={handleDivisionClick}
                                            data={myTeamStat?.Division.slice(0, 10)}
                                            >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="DivisionID"/>
                                            <YAxis tickFormatter={tick => { return tick.toLocaleString()}} domain={['auto', 'dataMax + 5']}/>
                                            <Tooltip content={<CustomTooltip displayName='DivisionName'/>} formatter={(value) => value && value.toLocaleString()}/>
                                            <Bar dataKey="SafeCount" stackId="a" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"DivisionSafeCount"} lowerbar={true}/>}/>
                                            <Bar dataKey="AtRiskCount" stackId="a" fill={companyData?.AccentColorLighter} shape={<CylinderBar id={"DivisionAtRiskCount"} lowerbar={false}/>}/>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </td>
                            </tr>
                        </tbody>
                    </Table>                                                    
                </td>                            
            </tr>    
            <tr>
                <td style={styles.tableCellInnerGraph}>
                    <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                        <thead>
                            <tr>
                                <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Employment Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                    <ResponsiveContainer width={'100%'} height={220}>
                                        <BarChart onClick={handleEmploymentTypeClick}
                                            data={myTeamStat?.EmploymentType}
                                            >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="EmploymentTypeID"/>
                                            <YAxis tickFormatter={tick => { return tick.toLocaleString()}} domain={['auto', 'dataMax + 5']}/>
                                            <Tooltip content={<CustomTooltip displayName='EmploymentTypeName'/>} formatter={(value) => value && value.toLocaleString()}/>
                                            <Bar dataKey="SafeCount" stackId="a" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"EmploymentSafeCount"} lowerbar={true} fatbar={true}/>}/>
                                            <Bar dataKey="AtRiskCount" stackId="a" fill={companyData?.AccentColorLighter} shape={<CylinderBar id={"EmploymentAtRiskCount"} lowerbar={true} fatbar={true}/>}/>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </td>
                            </tr>
                        </tbody>
                    </Table>                                                    
                </td>                            
                <td style={styles.tableCellInnerGraph}>
                    <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                        <thead>
                            <tr>
                                <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Job Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                    <ResponsiveContainer width={'100%'} height={220}>
                                        <BarChart onClick={handleJobCodeClick}
                                            data={myTeamStat?.JobCode.slice(0, 10)}
                                            >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="JobCodeID"/>
                                            <YAxis tickFormatter={tick => { return tick.toLocaleString()}} domain={['auto', 'dataMax + 5']}/>
                                            <Tooltip content={<CustomTooltip displayName='JobCodeName'/>} formatter={(value) => value && value.toLocaleString()}/>
                                            <Bar dataKey="SafeCount" stackId="a" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"JobCodeSafeCount"} lowerbar={true}/>}/>
                                            <Bar dataKey="AtRiskCount" stackId="a" fill={companyData?.AccentColorLighter} shape={<CylinderBar id={"JobCodeAtRiskCount"} lowerbar={false}/>}/>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </td>
                            </tr>
                        </tbody>
                    </Table>                                                    
                </td>                            
                <td style={styles.tableCellInnerGraph}>
                    <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                        <thead>
                            <tr>
                                <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Job Position</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                    <ResponsiveContainer width={'100%'} height={220}>
                                        <BarChart onClick={handleJobPositionClick}
                                            data={myTeamStat?.JobPosition.slice(0, 10)}
                                            >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="JobPositionID"/>
                                            <YAxis tickFormatter={tick => { return tick.toLocaleString()}} domain={['auto', 'dataMax + 5']}/>
                                            <Tooltip content={<CustomTooltip displayName='JobPositionName'/>} formatter={(value) => value && value.toLocaleString()}/>
                                            <Bar dataKey="SafeCount" stackId="a" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"JobPositionSafeCount"} lowerbar={true}/>}/>
                                            <Bar dataKey="AtRiskCount" stackId="a" fill={companyData?.AccentColorLighter} shape={<CylinderBar id={"JobPositionAtRiskCount"} lowerbar={false}/>}/>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </td>
                            </tr>
                        </tbody>
                    </Table>                                                    
                </td>                            
            </tr>    
            </tbody>
        );
    }
      
    function getPillarBenchmarkGraph() {
        let pillarData = null;
        let bReverse = 0;
        if (selectedPillarID === "0") {
            pillarData = myTeamStat?.OverallBenchmarks.slice(myTeamStat?.OverallBenchmarks.length-9, myTeamStat?.OverallBenchmarks.length);
        } else if (selectedPillarID === "1") {
            pillarData = myTeamStat?.WorkSafetyBenchmarks.slice(myTeamStat?.WorkSafetyBenchmarks.length-9, myTeamStat?.WorkSafetyBenchmarks.length);
        } else if (selectedPillarID === "2") {
            pillarData = myTeamStat?.JobStressBenchmarks.slice(myTeamStat?.JobStressBenchmarks.length-9, myTeamStat?.JobStressBenchmarks.length);
        } else if (selectedPillarID === "3") {
            pillarData = myTeamStat?.JobSatisfactionBenchmarks.slice(myTeamStat?.JobSatisfactionBenchmarks.length-9, myTeamStat?.JobSatisfactionBenchmarks.length);
        } else if (selectedPillarID === "4") {
            pillarData = myTeamStat?.SocialSupportBenchmarks.slice(myTeamStat?.SocialSupportBenchmarks.length-9, myTeamStat?.SocialSupportBenchmarks.length);
        } else if (selectedPillarID === "5") {
            pillarData = myTeamStat?.ControlBenchmarks.slice(myTeamStat?.ControlBenchmarks.length-9, myTeamStat?.ControlBenchmarks.length);
        } else if (selectedPillarID === "6") {
            pillarData = myTeamStat?.PersonalEfficacyBenchmarks.slice(myTeamStat?.PersonalEfficacyBenchmarks.length-9, myTeamStat?.PersonalEfficacyBenchmarks.length);
        } else if (selectedPillarID === "7") {
            pillarData = myTeamStat?.TurnoverBenchmarks.slice(myTeamStat?.TurnoverBenchmarks.length-9, myTeamStat?.TurnoverBenchmarks.length);
        } else if (selectedPillarID === "8") {
            pillarData = myTeamStat?.CommitmentBenchmarks.slice(myTeamStat?.CommitmentBenchmarks.length-9, myTeamStat?.CommitmentBenchmarks.length);
        } else if (selectedPillarID === "9") {
            pillarData = myTeamStat?.CyberSecurityBenchmarks;//.slice(myTeamStat?.CyberSecurityBenchmarks.length-9, myTeamStat?.CyberSecurityBenchmarks.length);
            bReverse = 1;
        }  

        return (
            <LineChart
                data={pillarData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="BenchmarkYear" />
                <YAxis domain={['auto', 'dataMax + 5']}/>
                <Tooltip />
                <Legend content={<CustomLineChartLegend accentColorSecondary={companyData?.AccentColorSecondary} 
                    accentColor={companyData?.AccentColor}
                />} verticalAlign='bottom' />
                <Line
                    type="monotone"
                    dataKey="Score"
                    stroke={companyData?.AccentColorSecondary}
                />
                <Line type="monotone" dataKey="ScoreSelf" stroke={companyData?.AccentColor} 
                    dot={<LineChartCustomDot companyData={companyData} reverse={bReverse}/>}
                />
            </LineChart>
        );            
    }


    function getPillarGraphs() {
        return (
            <tbody>
                <tr>
                    <td style={styles.tableCellInnerGraph33}>
                        <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                            <thead>
                                <tr>
                                    <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Job Stress</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <ResponsiveContainer width={'100%'} height={220}>
                                            <BarChart
                                                data={myTeamStat?.JobStress}
                                                >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="Score" />
                                                <YAxis tickFormatter={tick => { return tick.toLocaleString()}} domain={['auto', 'dataMax + 5']}/>
                                                <Tooltip content={<CustomTooltip displayName='RetentionTypeName'/>} formatter={(value) => value && value.toLocaleString()}/>
                                                <Bar dataKey="AtRiskCount" fill={companyData?.AccentColorLighter} shape={<CylinderBar id={"JSAtRiskCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColor} stroke="blue" />} />
                                                <Bar dataKey="SafeCount" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"JSSafeCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColorSecondary} stroke="purple" />} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>                                                    
                    </td>                            
                    <td style={styles.tableCellInnerGraph33}>
                        <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                            <thead>
                                <tr>
                                    <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Job Satisfaction</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <ResponsiveContainer width={'100%'} height={220}>
                                            <BarChart
                                                data={myTeamStat?.JobSatisfaction}
                                                >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="Score" />
                                                <YAxis tickFormatter={tick => { return tick.toLocaleString()}} domain={['auto', 'dataMax + 5']}/>
                                                <Tooltip content={<CustomTooltip displayName='RetentionTypeName'/>} formatter={(value) => value && value.toLocaleString()}/>
                                                <Bar dataKey="AtRiskCount" fill={companyData?.AccentColorLighter} shape={<CylinderBar id={"JSAAtRiskCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColor} stroke="blue" />} />
                                                <Bar dataKey="SafeCount" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"JSASafeCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColorSecondary} stroke="purple" />} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>                                                    
                    </td>                            
                    <td style={styles.tableCellInnerGraph33}>
                        <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                            <thead>
                                <tr>
                                    <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Social Support</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <ResponsiveContainer width={'100%'} height={220}>
                                            <BarChart
                                                data={myTeamStat?.SocialSupport}
                                                >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="Score" />
                                                <YAxis tickFormatter={tick => { return tick.toLocaleString()}} domain={['auto', 'dataMax + 5']}/>
                                                <Tooltip content={<CustomTooltip displayName='RetentionTypeName'/>} formatter={(value) => value && value.toLocaleString()}/>
                                                <Bar dataKey="AtRiskCount" fill={companyData?.AccentColorLighter} shape={<CylinderBar id={"SSAtRiskCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColor} stroke="blue" />} />
                                                <Bar dataKey="SafeCount" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"SSSafeCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColorSecondary} stroke="purple" />} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>                                                    
                    </td>                            
                </tr>    
                <tr>
                    <td style={styles.tableCellInnerGraph33}>
                        <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                            <thead>
                                <tr>
                                    <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Work Safety</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <ResponsiveContainer width={'100%'} height={220}>
                                            <BarChart
                                                data={myTeamStat?.WorkSafety}
                                                >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="Score" />
                                                <YAxis tickFormatter={tick => { return tick.toLocaleString()}} domain={['auto', 'dataMax + 5']}/>
                                                <Tooltip content={<CustomTooltip displayName='RetentionTypeName'/>} formatter={(value) => value && value.toLocaleString()}/>
                                                <Bar dataKey="AtRiskCount" fill={companyData?.AccentColorLighter} shape={<CylinderBar id={"WSAtRiskCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColor} stroke="blue" />} />
                                                <Bar dataKey="SafeCount" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"WSSafeCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColorSecondary} stroke="purple" />} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>                                                    
                    </td>                            
                    <td style={styles.tableCellInnerGraph33}>
                        <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                            <thead>
                                <tr>
                                    <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Cyber Security</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <ResponsiveContainer width={'100%'} height={220}>
                                            <BarChart
                                                data={myTeamStat?.CyberSecurity}
                                                >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="Score" />
                                                <YAxis tickFormatter={tick => { return tick.toLocaleString()}} domain={['auto', 'dataMax + 5']}/>
                                                <Tooltip content={<CustomTooltip displayName='RetentionTypeName'/>} formatter={(value) => value && value.toLocaleString()}/>
                                                <Bar dataKey="AtRiskCount" fill={companyData?.AccentColorLighter} shape={<CylinderBar id={"CSAtRiskCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColor} stroke="blue" />} />
                                                <Bar dataKey="SafeCount" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"CSSafeCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColorSecondary} stroke="purple" />} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>                                                    
                    </td>                            
                    <td style={styles.tableCellInnerGraph33}>
                        <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                            <thead>
                                <tr>
                                    <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Control</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <ResponsiveContainer width={'100%'} height={220}>
                                            <BarChart
                                                data={myTeamStat?.Control}
                                                >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="Score" />
                                                <YAxis tickFormatter={tick => { return tick.toLocaleString()}} domain={['auto', 'dataMax + 5']}/>
                                                <Tooltip content={<CustomTooltip displayName='RetentionTypeName'/>} formatter={(value) => value && value.toLocaleString()}/>
                                                <Bar dataKey="AtRiskCount" fill={companyData?.AccentColorLighter} shape={<CylinderBar id={"CSAtRiskCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColor} stroke="blue" />} />
                                                <Bar dataKey="SafeCount" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"CSSafeCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColorSecondary} stroke="purple" />} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>                                                    
                    </td>                            
                </tr>    
                <tr>
                    <td style={styles.tableCellInnerGraph33}>
                        <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                            <thead>
                                <tr>
                                    <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Personal Efficacy</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <ResponsiveContainer width={'100%'} height={220}>
                                            <BarChart
                                                data={myTeamStat?.PersonalEfficacy}
                                                >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="Score" />
                                                <YAxis tickFormatter={tick => { return tick.toLocaleString()}} domain={['auto', 'dataMax + 5']}/>
                                                <Tooltip content={<CustomTooltip displayName='RetentionTypeName'/>} formatter={(value) => value && value.toLocaleString()}/>
                                                <Bar dataKey="AtRiskCount" fill={companyData?.AccentColorLighter} shape={<CylinderBar id={"PEAtRiskCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColor} stroke="blue" />} />
                                                <Bar dataKey="SafeCount" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"PESafeCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColorSecondary} stroke="purple" />} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>                                                    
                    </td>                            
                    <td style={styles.tableCellInnerGraph33}>
                        <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                            <thead>
                                <tr>
                                    <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Turnover</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <ResponsiveContainer width={'100%'} height={220}>
                                            <BarChart
                                                data={myTeamStat?.Turnover}
                                                >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="Score" />
                                                <YAxis tickFormatter={tick => { return tick.toLocaleString()}} domain={['auto', 'dataMax + 5']}/>
                                                <Tooltip content={<CustomTooltip displayName='RetentionTypeName'/>} formatter={(value) => value && value.toLocaleString()}/>
                                                <Bar dataKey="AtRiskCount" fill={companyData?.AccentColorLighter} shape={<CylinderBar id={"TOAtRiskCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColor} stroke="blue" />} />
                                                <Bar dataKey="SafeCount" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"TOSafeCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColorSecondary} stroke="purple" />} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>                                                    
                    </td>                            
                    <td style={styles.tableCellInnerGraph33}>
                        <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                            <thead>
                                <tr>
                                    <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Commitment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <ResponsiveContainer width={'100%'} height={220}>
                                            <BarChart
                                                data={myTeamStat?.Commitment}
                                                >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="Score" />
                                                <YAxis tickFormatter={tick => { return tick.toLocaleString()}} domain={['auto', 'dataMax + 5']}/>
                                                <Tooltip content={<CustomTooltip displayName='RetentionTypeName'/>} formatter={(value) => value && value.toLocaleString()}/>
                                                <Bar dataKey="AtRiskCount" fill={companyData?.AccentColorLighter} shape={<CylinderBar id={"OCAtRiskCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColor} stroke="blue" />} />
                                                <Bar dataKey="SafeCount" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"OCSafeCount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColorSecondary} stroke="purple" />} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>                                                    
                    </td>                            
                </tr>
                <tr>
                    <td colSpan='4' style={styles.tableCellInnerGraph50}>
                        <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                            <thead>
                                <tr>
                                    <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>
                                    <Table variant="light" style={{width:'100%', height:'100%', alignItems:'center'}}>
                                        <thead>
                                            <tr>
                                                <th style={{width:'50%', color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>
                                                    Benchmark
                                                </th>
                                                <th style={{width:'50%', color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>
                                                    <select id="selbenchmark" class="browser-default" style={{width:'200px', color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}} defaultValue={selectedPillarID} onChange={(e) => (setSelectedPillarID(e.target.value))}>
                                                            <option value="0">Overall</option>        
                                                            <option value="2">Job Stress</option>        
                                                            <option value="3">Job Satisfaction</option>        
                                                            <option value="4">Social Support</option>        
                                                            <option value="1">Work Safety</option>        
                                                            <option value="9">Cyber Security</option>        
                                                            <option value="5">Control Scale</option>        
                                                            <option value="6">Personal Efficacy</option>        
                                                            <option value="7">Turnover</option>        
                                                            <option value="8">Commitment</option>        
                                                    </select>                                     
                                                </th>
                                            </tr>
                                        </thead>
                                        </Table>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan='2' style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <ResponsiveContainer width={'100%'} height={240}>
                                            {getPillarBenchmarkGraph()}
                                        </ResponsiveContainer>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>                                                    
                    </td>                            
                </tr>
            </tbody>
        );
    }

    function getBusinessGraphs() {
        return (
            <tbody>
                <tr>
                <td style={styles.tableCellInnerGraph}>
                    <Table responsive="sm" hover variant="light" style={{width:'100%', height:'100%'}}>
                        <thead>
                            <tr>
                                <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>At Risk</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                    {(myTeamStat?.OverallAmounts && myTeamStat?.OverallAmounts?.length > 0) ?
                                        <div>
                                            <span><h5 style={styles.SubHeader}>Employees</h5></span>
                                            <Table responsive="sm" hover variant="light" style={{width:'100%'}}>
                                                <thead>
                                                    <tr>
                                                        <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>Total</h6></th>
                                                        <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>At Risk</h6></th>
                                                        <th style={{backgroundColor:companyData?.PagesBGColor}}><h6 style={styles.SubHeader}>Percentage</h6></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                            <h1 style={styles.SubHeaderSecondary}>{myTeamStat?.OverallAmounts[0].SafeCount.toLocaleString()}</h1>                                                            
                                                        </td>
                                                        <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                            <h1 style={styles.SubHeaderPrimary}>{myTeamStat?.OverallAmounts[0].AtRiskCount.toLocaleString()}</h1>                                                            
                                                        </td>
                                                        <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                                            <h1 style={styles.SubHeaderPrimary}>{((myTeamStat?.OverallAmounts[0].AtRiskCount * 100)/myTeamStat?.OverallAmounts[0].SafeCount).toFixed(2)}%</h1>                                                            
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        :
                                        <div>
                                            <span><h3>Overall numbers coming soon</h3></span>
                                        </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                    {(myTeamStat?.OverallAmounts && myTeamStat?.OverallAmounts?.length > 0) ?
                                        <div>
                                            <span><h5 style={styles.SubHeader}>Amount</h5><h1 style={styles.SubHeaderPrimary}>{myTeamStat.OverallAmounts[0].AtRiskAmount.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                        })}</h1></span>
                                        </div>
                                        :
                                        <div>
                                            <span><h3>Overall numbers coming soon</h3></span>
                                        </div>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </Table>                                                    
                </td>                            
                <td style={styles.tableCellInnerGraph}>
                    <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                        <thead>
                            <tr>
                                <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Facility</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                    <ResponsiveContainer width={'100%'} height={220}>
                                        <BarChart onClick={handleFacilityClick}
                                            layout='horizontal'
                                            data={myTeamStat?.FacilityAmount.slice(0, 10)}
                                            >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="FacilityID"/>
                                            <YAxis tickFormatter={tick => { return tick.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                            })}} domain={['auto', 'dataMax + 5']}/>
                                            <Tooltip content={<CustomTooltip displayName='FacilityName' displayValue='Currency'/>} formatter={(value) => value && value.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                            })}/>
                                            <Bar dataKey="AtRiskAmount" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"FacilityAtRiskAmount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColorLighter} stroke="blue" />} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </td>
                            </tr>
                        </tbody>
                    </Table>                                                    
                </td>                            
                <td style={styles.tableCellInnerGraph}>
                    <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                        <thead>
                            <tr>
                                <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Divisions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                    <ResponsiveContainer width={'100%'} height={220}>
                                        <BarChart onClick={handleDivisionClick}
                                            layout='horizontal'
                                            data={myTeamStat?.DivisionAmount.slice(0, 10)}
                                            >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="DivisionID"/>
                                            <YAxis tickFormatter={tick => { return tick.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                            })}} domain={['auto', 'dataMax + 5']}/>
                                            <Tooltip content={<CustomTooltip displayName='DivisionName' displayValue='Currency'/>} formatter={(value) => value && value.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                            })}/>
                                            <Bar dataKey="AtRiskAmount" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"DivisionAtRiskAmount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColorLighter} stroke="blue" />} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </td>
                            </tr>
                        </tbody>
                    </Table>                                                    
                </td>                            
            </tr>    
            <tr>
                <td style={styles.tableCellInnerGraph}>
                    <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                        <thead>
                            <tr>
                                <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Employees</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                    <div style={{ position: 'relative'}}>
                                    {isZoomed && <button onClick={handleScatterZoomOut}>Zoom Out</button>}
                                    <ResponsiveContainer width={'100%'} height={220}>
                                        <ScatterChart 
                                            onMouseDown={handleScatterMouseDown}
                                            onMouseMove={handleScatterMouseMove}
                                            onMouseUp={handleScatterMouseUp}                                        
                                            >
                                            <CartesianGrid />
                                            <XAxis type="number" dataKey="ScoredProbabilities" name="Probability" 
                                                    domain={["dataMin - 0.25", "dataMax + 0.25"]} />
                                            <YAxis type="number" dataKey="AtRiskAmount" name="Cost" allowDecimals='true'
                                                            domain={["dataMin - 25", "dataMax + 25"]}
                                                            tickFormatter={tick => { return tick.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                            })}}/>
                                            <Tooltip content={<CustomTooltip displayName='UserFullName' displayValue='Currency' NoDisplay='ScoredProbabilities'/>} 
                                                    cursor={{ strokeDasharray: '2 2' }} />
                                            {showZoomBox && (
                                                <ReferenceArea
                                                    x1={zoomArea?.x1}
                                                    x2={zoomArea?.x2}
                                                    y1={zoomArea?.y1}
                                                    y2={zoomArea?.y2}
                                                />
                                                )}
                                            <Scatter name="A school"                                             
                                                    data={filteredData} 
                                                    fill={companyData?.AccentColorLighter}
                                                    shape={<ScatterCustomDot selectedPoint={selectedPoint} companyData={companyData}/>}
                                                    >
                                                {/* <LabelList dataKey="UserID" /> */}
                                            </Scatter>
                                        </ScatterChart>                    
                                    </ResponsiveContainer>
                                    </div>            
                                </td>
                            </tr>
                        </tbody>
                    </Table>                                                    
                </td>                            
                <td style={styles.tableCellInnerGraph}>
                    <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                        <thead>
                            <tr>
                                <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Job Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                    <ResponsiveContainer width={'100%'} height={220}>
                                        <BarChart onClick={handleJobCodeClick}
                                            layout='horizontal'
                                            data={myTeamStat?.JobCodeAmount.slice(0, 10)}
                                            >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="JobCodeID"/>
                                            <YAxis tickFormatter={tick => { return tick.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                            })}} domain={['auto', 'dataMax + 5']}/>
                                            <Tooltip content={<CustomTooltip displayName='JobCodeName' displayValue='Currency'/>} formatter={(value) => value && value.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                            })}/>
                                            <Bar dataKey="AtRiskAmount" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"JCAtRiskAmount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColorLighter} stroke="blue" />} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </td>
                            </tr>
                        </tbody>
                    </Table>                                                    
                </td>                            
                <td style={styles.tableCellInnerGraph}>
                    <Table responsive="sm" bordered hover variant="light" style={{width:'100%', height:'100%'}}>
                        <thead>
                            <tr>
                                <th style={{color:companyData?.Darkgrey, backgroundColor:companyData?.PagesBGColor}}>Job Position</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor}}>
                                    <ResponsiveContainer width={'100%'} height={220}>
                                        <BarChart onClick={handleJobPositionClick}
                                            layout='horizontal'
                                            data={myTeamStat?.JobPositionAmount.slice(0, 10)}
                                            >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="JobPositionID"/>
                                            <YAxis tickFormatter={tick => { return tick.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                            })}} domain={['auto', 'dataMax + 5']}/>
                                            <Tooltip content={<CustomTooltip displayName='JobPositionName' displayValue='Currency'/>} formatter={(value) => value && value.toLocaleString('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0
                                                            })}/>
                                            <Bar dataKey="AtRiskAmount" fill={companyData?.AccentColorSecondary} shape={<CylinderBar id={"JPAtRiskAmount"} lowerbar={true}/>} activeBar={<Rectangle fill={companyData?.AccentColorLighter} stroke="blue" />} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </td>
                            </tr>
                        </tbody>
                    </Table>                                                    
                </td>                            
            </tr>    
            </tbody>
        );
    }

    return ( 
        <>
            {(loading) ? (            
                <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                    <Spinner/>
                </div>) : (
            <Card style={{width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                <Card.Header> 
                    <Table style={{backgroundColor:companyData?.PagesBGColor}}>
                            <thead>
                            <tr>
                                <th style={{width:'50%', alignItems:'left', backgroundColor:companyData?.PagesBGColor}}> <h3 style={styles.SubHeader}>Dashboard</h3>
                                </th>
                                <th style={{width:'35%', alignItems:'center', backgroundColor:companyData?.PagesBGColor}}>
                                </th>
                                <th style={{width:'15%', alignItems:'right', backgroundColor:companyData?.PagesBGColor}}>
                                    <Card.Title>
                                        <ButtonGroup className="mb-2" size='sm'>
                                            {radios.map((radio, idx) => (
                                                <ToggleButton
                                                    key={typeSummary + idx}
                                                    id={`radio-${typeSummary + idx}`}
                                                    type="radio"
                                                    style={radioValue === radio.value ? styles.CDButtonSelected : styles.CDButtonUnselected}
                                                    name="radio"
                                                    value={radio.value}
                                                    checked={radioValue === radio.value}
                                                    size='sm'
                                                    onChange={(e) => {currentRecIndex = 0; setRadioValue(e.currentTarget.value)}}
                                                >
                                                    {radio.name}
                                                </ToggleButton>
                                            ))}
                                        </ButtonGroup>                   
                                    </Card.Title>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                    </Table>
                </Card.Header>
                <Card.Body>
                    <div>
                        <Table responsive="sm" bordered hover variant="light" style={{width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                            <thead>
                            </thead>
                            {getGraphs()}
                        </Table>
                    </div>                                                    
                </Card.Body>
                <Card.Footer className="text-right" style={{alignItems:'end'}}>
                    {(anyGraphClicked) ? <UserList myTeam={myTeamFromState} triggeredFrom={TriggeredFrom.Analytics} clickedData={{EntityRowTypeID:entityRowTypeIDClicked, EntityID:entityIDClicked}}/> : <></>}                    
                </Card.Footer>
            </Card>
            )}
        </>
    )        

}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth.user,
        companyData: state?.auth?.companyData,
        myTeamFromState: state.auth.myTeam,
        myTeamSuggestions: state.auth.myTeamSuggestionData,
        myTeamStat: state.auth.myTeamStat,
        retentionSessionResults: state.entity.retentionSessionResults,
   }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRetentionPrediction: (retentionSessions) => dispatch(getRetentionPrediction(retentionSessions)),
        getMyTeamRetentionSuggestions: (id) => dispatch(getMyTeamRetentionSuggestions(id)),
        updateUserSuggestion: (suggestions) => dispatch(updateUserSuggestion(suggestions)),
        getMyTeamSelected: (teamParams) => dispatch(getMyTeamSelected(teamParams)),
    }
} 

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsDetails)
