import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image, DropdownButton, Dropdown, ButtonGroup, DropdownMenu, ToggleButton } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { getSkillChecklist, getSkillChecklistGroupBy, getUserAttributesLookups, updateUserSkill, assignLookupSelection } from '../../store/actions/entityActions';
import Spinner from '../layout/Spinner';
import { useLocation } from 'react-router-dom';
import { accentColor, companySignLogo } from '../../config/Colors';
import "../App.css";
import moment, { now } from 'moment';
import { CheckListGroupBy } from '../../config/Constant';

const CompetencyChecklist = (props) => {
    const { auth, companyData, profile, skillChecklist, skillChecklistGroupBy, userattributelookups, loadingMenu, listby } = props;    
    const [loading, setLoading] = useState(false);
    const [nodeName, setnodeName] = useState('');
    const [nodeType, setnodeType] = useState('1');
    const [nodeTypeDescription, setnodeTypeDescription] = useState('Search Employee');
    const [listTypeDescription, setlistTypeDescription] = useState('User Competencies');
    const [sliderValue, setSliderValue] = useState([]);
    const [sliderSubValue, setSliderSubValue] = useState([]);
    const [addNewCompetency, setAddNewCompetency] = useState(false);
    const [editCompetency, setEditCompetency] = useState(false);
    const [userSkillId, setUserSkillId] = useState(-1);
    const [skillId, setSkillId] = useState(-1);
    const [skillLevelId, setSkillLevelId] = useState(-1);
    const [skillComments, setSkillComments] = useState('');
    const [skillIsComplete, setSkillIsComplete] = useState(0);
    const [skillPreceptorComments, setSkillPreceptorComments] = useState('');
    const [skillAssignedDate, setSkillAssignedDate] = useState(moment());
    const [skillCompletedDate, setSkillCompletedDate] = useState(moment());
    const [skillURL, setSkillURL] = useState('');
    const [skillMetricTypeId, setSkillMetricTypeId] = useState(1);
    const [skillScore, setSkillScore] = useState(-1);
    const [skillTestComplete, setSkillTestComplete] = useState(0);
    const [skillTestDateCompleted, setSkillTestDateCompleted] = useState(moment());
    const [skillTestScore, setSkillTestScore] = useState(-1);

    const [editUserCompetencyClicked, setEditUserCompetencyClicked] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedSubValue, setSelectedSubValue] = useState('');

    const skillList = skillChecklist?.skillChecklist?.UserSkills;
    const skillListGroupBy = skillChecklistGroupBy?.skillChecklistGroupBy?.UserSkills;
    const skillTypeData = userattributelookups?.SkillTypes;
    const skillSpecialityData = userattributelookups?.SkillSpecialities;
    const vendorData = userattributelookups?.Vendors;
    const metricTypeData =  userattributelookups?.MetricTypes;
    const skillLevelData = userattributelookups?.SkillLevels;
    const skillData = userattributelookups?.Skills;

    const location  = useLocation();
    let listByValue = location?.state?.listby;
    if ((listByValue === null) || (listByValue === undefined)) {
        listByValue = listby;
    }

    const [radioValue, setRadioValue] = useState(initialRadioValue());
    const radios = [
        { name: 'Assigned', value: '1' },
        { name: 'Completed', value: '2' },
    ];
    const [radioTypeValue, setRadioTypeValue] = useState(initialRadioTypeValue());
    const radioTypes = [
        { name: 'Assignment', value: CheckListGroupBy.Assignment },
        { name: 'Competencies', value: CheckListGroupBy.Competency },
        { name: 'Users', value: CheckListGroupBy.User },
        { name: 'All', value: CheckListGroupBy.All },
    ];

    function initialRadioValue() {
        return '1';
    };

    function initialRadioTypeValue() {
        return listByValue;
    };

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    function changeSearchValue(e) {
        if (e.target.innerHTML === 'Employee') {
            setnodeTypeDescription('Search Employee Name');
            setnodeType('1');
        } else if (e.target.innerHTML === 'Assignment') {
            setnodeTypeDescription('Search Assignment Name');
            setnodeType('2');
        } else if (e.target.innerHTML === 'Speciality') {
            setnodeTypeDescription('Search Speciality');
            setnodeType('3');
        } else if (e.target.innerHTML === 'Competency') {
            setnodeTypeDescription('Search Competency');
            setnodeType('4');
        }
    }

    function getSearchParams(pageNumber) {
        let oParams = {
            CheckListGroupBy:radioTypeValue,
            UserID:auth.UserID,
            UserFullName:nodeName,
            IsComplete:(radioValue === '1') ? 'false' : 'true',
            RecordSize:'10',
            PageNumber:pageNumber,
        }; 
        if (nodeType === '2') {
            oParams = {
                CheckListGroupBy:radioTypeValue,
                UserID:auth.UserID,
                AssignmentName:nodeName,
                IsComplete:(radioValue === '1') ? 'false' : 'true',
                RecordSize:'10',
                PageNumber:pageNumber,
            };     
        } else if (nodeType === '3') {
            oParams = {
                CheckListGroupBy:radioTypeValue,
                UserID:auth.UserID,
                SkillSpecialityName:nodeName,
                IsComplete:(radioValue === '1') ? 'false' : 'true',
                RecordSize:'10',
                PageNumber:pageNumber,
            };     
        } else if (nodeType === '4') {
            oParams = {
                CheckListGroupBy:radioTypeValue,
                UserID:auth.UserID,
                SkillName:nodeName,
                IsComplete:(radioValue === '1') ? 'false' : 'true',
                RecordSize:'10',
                PageNumber:pageNumber,
            };     
        }            
        return oParams;
    }

    function getSearchParamsEx(bRadioValue, bRadioTypeValue, pageNumber)  {
        let oParams = {
            CheckListGroupBy:bRadioTypeValue,
            UserID:auth.UserID,
            UserFullName:nodeName,
            IsComplete:(bRadioValue === '1') ? 'false' : 'true',
            RecordSize:'10',
            PageNumber:pageNumber,
        }; 
        if (nodeType === '2') {
            oParams = {
                CheckListGroupBy:bRadioTypeValue,
                UserID:auth.UserID,
                AssignmentName:nodeName,
                IsComplete:(bRadioValue === '1') ? 'false' : 'true',
                RecordSize:'10',
                PageNumber:pageNumber,
            };     
        } else if (nodeType === '3') {
            oParams = {
                CheckListGroupBy:bRadioTypeValue,
                UserID:auth.UserID,
                SkillSpecialityName:nodeName,
                IsComplete:(bRadioValue === '1') ? 'false' : 'true',
                RecordSize:'10',
                PageNumber:pageNumber,
            };     
        } else if (nodeType === '4') {
            oParams = {
                CheckListGroupBy:bRadioTypeValue,
                UserID:auth.UserID,
                SkillName:nodeName,
                IsComplete:(bRadioValue === '1') ? 'false' : 'true',
                RecordSize:'10',
                PageNumber:pageNumber,
            };     
        }            
        return oParams;
    }

    function getSearchGroupByParamsEx(typeValues) {
        let sAssignmentIDs = '';
        let sSkillIDs = '';
        let sUserIDs = '';

        if (radioTypeValue === '1') {
            sAssignmentIDs = typeValues;
        } else if (radioTypeValue === '2') {
            sSkillIDs = typeValues;
        } if (radioTypeValue === '3') {
            sUserIDs = typeValues;
        }

        let sUserFullNameNodeName = '';
        let sAssignmentNodeName = '';
        let sSpecialityNodeName = '';
        let sSkillNodeName = '';
        if (nodeType === '1') {
            sUserFullNameNodeName = nodeName;
        } else if (nodeType === '2') {
            sAssignmentNodeName = nodeName;
        } else if (nodeType === '3') {
            sSpecialityNodeName = nodeName;
        } else if (nodeType === '4') {
            sSkillNodeName = nodeName;
        }            

        let oParams = {
            CheckListGroupBy:radioTypeValue,
            UserID:auth.UserID,
            UserFullName:sUserFullNameNodeName,
            AssignmentName:sAssignmentNodeName,
            SkillSpecialityName:sSpecialityNodeName,
            SkillName:sSkillNodeName,
            AssignmentIDs:sAssignmentIDs,
            SkillIDs:sSkillIDs,
            UserIDs:sUserIDs,
            IsComplete:(radioValue === '1') ? 'false' : 'true',
            RecordSize:'10',
            PageNumber:'1',
        }; 
        return oParams;
    }

    async function searchPillars() {
        setLoading(true);
        const oParams = getSearchParams('1');
        await props.getSkillChecklist(oParams);
        setLoading(false);
    }
    
    async function searchUserSkillGroupBy(typeValues) {
        setLoading(true);
        const oParams = getSearchGroupByParamsEx(typeValues);
        if ((oParams) && ((oParams.AssignmentIDs !== '') || (oParams.SkillIDs !== '') || (oParams.UserIDs !== ''))) {
            await props.getSkillChecklistGroupBy(oParams);    
        }
        setLoading(false);
    }
    
    async function previousPage() {
        setLoading(true);
        const previousPageNumber = skillChecklist?.skillChecklist?.PagingMetaData?.PageNumber - 1;
        // const oParams = {
        //     UserID:auth.UserID,
        //     SkillName:nodeName,
        //     IsComplete:(radioValue === '1') ? 'false' : 'true',
        //     RecordSize:'10',
        //     PageNumber:previousPageNumber,
        // };            
        const oParams = getSearchParamsEx(radioValue, radioTypeValue, previousPageNumber);
        await props.getSkillChecklist(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = skillChecklist?.skillChecklist?.PagingMetaData?.PageNumber + 1;
        // const oParams = {
        //     UserID:auth.UserID,
        //     SkillName:nodeName,
        //     IsComplete:(radioValue === '1') ? 'false' : 'true',
        //     RecordSize:'10',
        //     PageNumber:nextPageNumber,
        // };            
        const oParams = getSearchParamsEx(radioValue, radioTypeValue, nextPageNumber);
        await props.getSkillChecklist(oParams);
        setLoading(false);
    }
    
    const addInitialSliderArray = (competency) => {
        return skillList?.map((evalRec) => ({
            UserSkillID:evalRec.UserSkillID,
            AssignmentID:evalRec.AssignmentID,
            UserSkillSessionID:evalRec.UserSkillSessionID,
            SkillID:evalRec.SkillID, 
            SkillName:evalRec.SkillName,
            SkillTypeID:evalRec.SkillTypeID,
            SkillSpecialityID:evalRec.SkillSpecialityID,
            SkillTypeName:evalRec.SkillTypeName,
            SkillSpecialityName:evalRec.SkillSpecialityName,
            UserID:evalRec.UserID,
            takeAction:false,
            showDetails:false,
            showSubDetails:false,
            disableNewSkill:false,
            newSkillClicked:false,
            editSkillClicked:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(skillList);
        setSliderValue(initialScoreValues);
    }, [skillChecklist])
    
    const addInitialSliderSubArray = (competency) => {
        return skillListGroupBy?.map((evalRec) => ({
            UserSkillID:evalRec.UserSkillID,
            AssignmentID:evalRec.AssignmentID,
            UserSkillSessionID:evalRec.UserSkillSessionID,
            SkillID:evalRec.SkillID, 
            SkillName:evalRec.SkillName,
            SkillTypeID:evalRec.SkillTypeID,
            SkillSpecialityID:evalRec.SkillSpecialityID,
            SkillTypeName:evalRec.SkillTypeName,
            SkillSpecialityName:evalRec.SkillSpecialityName,
            UserID:evalRec.UserID,
            takeAction:false,
            showDetails:false,
            disableNewSkill:false,
            newSkillClicked:false,
            editSkillClicked:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderSubArray(skillListGroupBy);
        setSliderSubValue(initialScoreValues);
    }, [skillListGroupBy])
    
    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            (addNewCompetency) ? setAddNewCompetency(false) : setAddNewCompetency(true);
            setUserSkillId(-1);
            setSkillMetricTypeId(-1);
            setSkillLevelId(-1);
            setSkillIsComplete(0);
            setSkillScore(0);
            setSkillCompletedDate(moment());
            setSkillTestComplete(0);
            setSkillTestScore(0);
            setSkillTestDateCompleted(moment());
            setSkillPreceptorComments('');
            setEditUserCompetencyClicked(false);
            setEditCompetency(false);
            setAddNewCompetency(false);
        } 

        setLoading(false);
    }

    async function handleCancelChildernClick(tab, currentEval) {
        if (tab === 1) {
            if ((parseInt(radioTypeValue) == CheckListGroupBy.Assignment) || (parseInt(radioTypeValue) == CheckListGroupBy.Competency) || (parseInt(radioTypeValue) == CheckListGroupBy.User)) {
                const updatedSliderValues = sliderSubValue.slice(0);
                updatedSliderValues.map((row, index) => {
                    row.showSubDetails = false;
                    row.showDetails = false;
                    row.disableNewSkill = false;                    
                    row.newSkillClicked = false;
                    row.editSkillClicked = false;
                });    
                setSliderSubValue(updatedSliderValues);    
            } else {
                const updatedSliderValues = sliderValue.slice(0);
                updatedSliderValues.map((row, index) => {
                    row.showSubDetails = false;
                    row.showDetails = false;
                    row.disableNewSkill = false;                    
                    row.newSkillClicked = false;
                    row.editSkillClicked = false;
                });    
                setSliderValue(updatedSliderValues);    
            }

            getSelectedValue();
            setUserSkillId(-1);
            setSkillMetricTypeId(-1);
            setSkillLevelId(-1);
            setSkillIsComplete(0);
            setSkillScore(0);
            setSkillCompletedDate(moment());
            setSkillTestComplete(0);
            setSkillTestScore(0);
            setSkillTestDateCompleted(moment());
            setSkillPreceptorComments('');
            setEditUserCompetencyClicked(false);
            setEditCompetency(false);
            setAddNewCompetency(false);
        } 
    }
    
    async function handleSaveChildernClick(tab, currentEval) {
        setLoading(true);
        var sSelectedValue = selectedValue;

        if (tab === 1) {
            let skillParams = {
                "UserSkillID": userSkillId,
                "UserSkillIDs": sSelectedValue,
                "SkillID":skillId,
                "SkillLevelID":skillLevelId,
                "Comments": skillComments, 
                "IsComplete": (skillIsComplete === '1') ? true : false,
                "PreceptorComments":skillPreceptorComments,
                // "AssignedDate": skillAssignedDate,
                "CompletedDate": skillCompletedDate,
                "SkillURL":skillURL,
                "MetricTypeID":skillMetricTypeId,
                "Score":skillScore,
                "TestComplete":(skillTestComplete === '1') ? true : false,
                "TestDateCompleted":skillTestDateCompleted,
                "TestScore":skillTestScore,    
                "UpdatedByUserID":auth?.UserID
            }    
            await props.updateUserSkill(skillParams);
        } 

        if (parseInt(radioTypeValue) == CheckListGroupBy.All) {
            const oParams = getSearchParamsEx(radioValue, radioTypeValue, '1');
            await props.getSkillChecklist(oParams);
        } else if ((parseInt(radioTypeValue) == CheckListGroupBy.Assignment) || (parseInt(radioTypeValue) == CheckListGroupBy.Competency) || (parseInt(radioTypeValue) == CheckListGroupBy.User)) {
            const oParams = getSearchGroupByParamsEx(radioValue, radioTypeValue, '1');            
            await props.getSkillChecklistGroupBy(oParams);
        } 
        setUserSkillId(-1);
        setSkillMetricTypeId(-1);
        setSkillLevelId(-1);
        setSkillIsComplete(0);
        setSkillScore(0);
        setSkillCompletedDate(moment());
        setSkillTestComplete(0);
        setSkillTestScore(0);
        setSkillTestDateCompleted(moment());
        setSkillPreceptorComments('');
        setEditUserCompetencyClicked(false);
        setEditCompetency(false);
        setAddNewCompetency(false);
        setEditUserCompetencyClicked(false);
        setEditCompetency(false);
        setLoading(false);
        setSelectedSubValue('');
        setSelectedValue('');
    }

    function checkMainPillar(tab, assid, ussid1, usid) {
        let mainid = usid;
        var idValue = 'All';
        if (tab === CheckListGroupBy.All) {
            mainid = usid;
            idValue = 'All';
        } else if (tab === CheckListGroupBy.Assignment) {
            mainid = assid;
            idValue = 'Ass';
        } else if (tab === CheckListGroupBy.Competency) {
            mainid = assid;
            idValue = 'Sk';
        } else if (tab === CheckListGroupBy.User) {
            mainid = assid;
            idValue = 'Us';
        } 

        return (
            <div class="form-check form-switch">
                <label class="form-check-label" for={`switch${'detail' + idValue + mainid}`}>Select</label>
                <input class="form-check-input" type="checkbox" role="switch" 
                    checked={getDetailSwitchValue(mainid)} id={`switch${'usdetail' + idValue + mainid}`} 
                    style={(getDetailSwitchValue(mainid)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                    onChange={(e) => {handleSwitchDetailChange(mainid)}}>
                </input>
            </div>
        );
    }

    function checkDetails(tab, assid, ussid1, usid, uid) {
        let mainid = usid;
        if (tab === CheckListGroupBy.All) {
            mainid = usid;
        } else if (tab === CheckListGroupBy.Assignment) {
            mainid = assid;
        } else if (tab === CheckListGroupBy.Competency) {
            mainid = ussid1;
        } else if (tab === CheckListGroupBy.User) {
            mainid = uid;
        } 

        return (
            <div class="form-check form-switch">
                <label class="form-check-label" for={`switch${'subdetail' + mainid}`}>Details</label>
                <input class="form-check-input" type="checkbox" role="switch" 
                    checked={getSubDetailSwitchValue(mainid)} id={`switch${'ussubdetail' + mainid}`} 
                    style={(getSubDetailSwitchValue(mainid)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                    onChange={(e) => {handleSwitchSubDetailChange(mainid)}}>
                </input>
            </div>
        );
    }

    function displayNewUserSkill() {
        if ((addNewCompetency) || (editUserCompetencyClicked)) {
            return (
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td colSpan='2' style={{textAlign:'left', width:'15%'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Metric Type</label>
                                    <select id="metrictype" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={skillMetricTypeId} onChange={(e) => (setSkillMetricTypeId(e.target.value))}>
                                        {metricTypeData?.map((metric, supIndex) => (
                                            <option value={metric.MetricTypeID}>{metric.MetricTypeName}</option>        
                                        ))}
                                    </select> 
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'15%'}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Level</label>
                                    <select id="skillLevel" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={skillLevelId} onChange={(e) => (setSkillLevelId(e.target.value))}>
                                        {skillLevelData?.map((skillLevel, supIndex) => (
                                            <option value={skillLevel.SkillLevelID}>{skillLevel.SkillLevelName}</option>        
                                        ))}
                                    </select> 
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'10%'}}>
                                <div>
                                    <label htmlFor="skillcomplete" style={styles.LabelDataEntryNoPadding}>Complete</label>
                                    <select id="skillcomplete" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={skillIsComplete} onChange={(e) => (setSkillIsComplete(e.target.value))}>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'10%'}}>
                                <div>
                                    <label htmlFor="skillscore" style={styles.LabelDataEntryNoPadding}>Score</label>
                                    <input id="skillscore" type="number" min='1' step='1' style={styles.TextDataEntryNoPadding} defaultValue={skillScore} onChange={(e) => (setSkillScore(e.target.value))}/>
                                </div>                    
                            </td>
                            {/* <td style={{textAlign:'left', width:'15%'}}>
                                <div>
                                    <label htmlFor="assigneddate" style={styles.LabelDataEntryNoPadding}>Assigned Date</label>
                                    <input id="assigneddate" type="date" disabled='true' style={styles.TextDataEntryNoPadding} value={(skillAssignedDate) ? skillAssignedDate : ''} onChange={(e) => (setSkillAssignedDate(e.target.value))}/>
                                </div>                    
                            </td> */}
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="completeddate" style={styles.LabelDataEntryNoPadding}>Completed Date</label>
                                    <input id="completeddate" type="date" style={styles.TextDataEntryNoPadding} value={(skillCompletedDate) ? skillCompletedDate : ''} onChange={(e) => (setSkillCompletedDate(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', width:'10%'}}>
                                <div>
                                    <label htmlFor="skilltestcomplete" style={styles.LabelDataEntryNoPadding}>Test Complete</label>
                                    <select id="skilltestcomplete" class="browser-default" style={styles.TextDataEntryNoPadding} defaultValue={skillTestComplete} onChange={(e) => (setSkillTestComplete(e.target.value))}>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'10%'}}>
                                <div>
                                    <label htmlFor="testscore" style={styles.LabelDataEntryNoPadding}>Test Score</label>
                                    <input id="testscore" type="number" min='1' step='1' style={styles.TextDataEntryNoPadding} defaultValue={skillTestScore} onChange={(e) => (setSkillTestScore(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="testcompleteddate" style={styles.LabelDataEntryNoPadding}>Test Completed Date</label>
                                    <input id="testcompleteddate" type="date" style={styles.TextDataEntryNoPadding} value={(skillTestDateCompleted) ? skillTestDateCompleted : ''} onChange={(e) => (setSkillTestDateCompleted(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            {/* <td colSpan='3' style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="skillComments" style={styles.LabelDataEntryNoPadding}>User Comments</label>
                                    <input id="skillComments" type="text" disabled='true' style={styles.TextDataEntryNoPadding} defaultValue={skillComments} onChange={(e) => (setSkillComments(e.target.value))}/>
                                </div>
                            </td> */}
                            <td colSpan='7' style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="skillPreceptorComments" style={styles.LabelDataEntryNoPadding}>Preceptor Comments</label>
                                    <input id="skillPreceptorComments" type="text" style={styles.TextDataEntryNoPadding} defaultValue={skillPreceptorComments} onChange={(e) => (setSkillPreceptorComments(e.target.value))}/>
                                </div>
                            </td>
                            <td style={{textAlign:'right'}}>
                                <div>
                                    <Button onClick={() => handleSaveChildernClick(1)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save User Competency/Skill'>Save</Button>
                                </div>
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <Button onClick={() => handleCancelChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Cancel User Competency/Skill'>Cancel</Button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>                                   
            );
        }
    }

    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;        
        if (radioTypeValue === CheckListGroupBy.All) {
            sliderValue && sliderValue.map((row, index) => {
                if ((row) && (row.UserSkillID === rowIndex)) {
                    bReturn = row.showDetails;
                }   
            });
        } else if (radioTypeValue === CheckListGroupBy.Assignment) {
            sliderSubValue && sliderSubValue.map((row, index) => {
                if ((row) && (row.UserSkillID === rowIndex)) {
                    bReturn = row.showDetails;
                }   
            });
        } else if (radioTypeValue === CheckListGroupBy.Competency) {
            sliderSubValue && sliderSubValue.map((row, index) => {
                if ((row) && (row.UserSkillID === rowIndex)) {
                    bReturn = row.showDetails;
                }   
            });
        } else if (radioTypeValue === CheckListGroupBy.User) {
            sliderSubValue && sliderSubValue.map((row, index) => {
                if ((row) && (row.UserSkillID === rowIndex)) {
                    bReturn = row.showDetails;
                }   
            });
        }
        return bReturn;
    }
 
    async function handleSwitchDetailChange(rowIndex) {
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }
        var updatedSliderValues = sliderValue.slice(0);
        if ((parseInt(radioTypeValue) == CheckListGroupBy.Assignment) || (parseInt(radioTypeValue) == CheckListGroupBy.Competency) || (parseInt(radioTypeValue) == CheckListGroupBy.User)) {
            updatedSliderValues = sliderSubValue.slice(0);
        }

        updatedSliderValues.map((row, index) => {
            if (row.UserSkillID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                    setEditUserCompetencyClicked(true);
                }
            }
        });
        if ((parseInt(radioTypeValue) == CheckListGroupBy.Assignment) || (parseInt(radioTypeValue) == CheckListGroupBy.Competency) || (parseInt(radioTypeValue) == CheckListGroupBy.User)) {
            setSliderSubValue(updatedSliderValues);
        } else {
            setSliderValue(updatedSliderValues);
        }
        getSelectedValue();
    }

    function getSubDetailSwitchValue(rowIndex) {
        let bReturn = false;        
        sliderValue && sliderValue.map((row, index) => {
            if (parseInt(radioTypeValue) == CheckListGroupBy.Assignment) {
                if ((row) && (row.AssignmentID === rowIndex)) {
                    bReturn = row.showSubDetails;
                }    
            } else if (parseInt(radioTypeValue) == CheckListGroupBy.Competency) {
                if ((row) && (row.SkillID === rowIndex)) {
                    bReturn = row.showSubDetails;
                }    
            } else if (parseInt(radioTypeValue) == CheckListGroupBy.User) {
                if ((row) && (row.UserID === rowIndex)) {
                    bReturn = row.showSubDetails;
                }    
            } 
        })
        return bReturn;
    }
 
    async function handleSwitchSubDetailChange(rowIndex) {
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (radioTypeValue === CheckListGroupBy.Assignment) {
                    if (row.AssignmentID === rowIndex) {
                        if (row.showSubDetails) {
                            row.showSubDetails = false;
                        } else {
                            row.showSubDetails = true;
                        }
                    }
            } else if (radioTypeValue === CheckListGroupBy.Competency) {
                if (row.SkillID === rowIndex) {
                    if (row.showSubDetails) {
                        row.showSubDetails = false;
                    } else {
                        row.showSubDetails = true;
                    }
                }
            } else if (radioTypeValue === CheckListGroupBy.User) {
                if (row.UserID === rowIndex) {
                    if (row.showSubDetails) {
                        row.showSubDetails = false;
                    } else {
                        row.showSubDetails = true;
                    }
                }
            }
        });
        setSliderValue(updatedSliderValues);
        const sSelectedIDs = getSelectedSubValue();
        searchUserSkillGroupBy(sSelectedIDs);
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }

    async function getSelectedValue() {
        let currentSelectedValue = '';
        if ((parseInt(radioTypeValue) == CheckListGroupBy.Assignment) || (parseInt(radioTypeValue) == CheckListGroupBy.Competency) || (parseInt(radioTypeValue) == CheckListGroupBy.User)) {
            sliderSubValue.map((row, index) => {
                if (row.showDetails) {
                    if (currentSelectedValue !== '') {
                        currentSelectedValue += ',';
                    }
                    currentSelectedValue += row.UserSkillID;
                } 
            });
        } else {
            sliderValue.map((row, index) => {
                if (row.showDetails) {
                    if (currentSelectedValue !== '') {
                        currentSelectedValue += ',';
                    }
                    currentSelectedValue += row.UserSkillID;
                } 
            });
        }
        setSelectedValue(currentSelectedValue);
        return currentSelectedValue;
    }

    function getSelectedSubValue() {
        let currentSelectedSubValue = '';
        sliderValue.map((row, index) => {
            if (row.showSubDetails) {
                if (currentSelectedSubValue !== '') {
                    currentSelectedSubValue += ',';
                }
                if (radioTypeValue === '1') {
                    currentSelectedSubValue += row.AssignmentID;
                } else if (radioTypeValue === '2') {
                    currentSelectedSubValue += row.SkillID;
                } if (radioTypeValue === '3') {
                    currentSelectedSubValue += row.UserID;
                }
            } 
        });
        setSelectedSubValue(currentSelectedSubValue);
        return currentSelectedSubValue;
    }

    // function getcurrentRecordIndex(){
    //     currentRecIndex++;        
    //     return currentRecIndex;
    // }

    function displayBulkAction() {
        if (addNewCompetency) {
            return (
                <a onClick={() => handleAddChildernClick(1, null)}>
                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Competency'>shadow_minus</span>
                </a>
            );   
        } else {
            return (
                <a onClick={() => handleAddChildernClick(1, null)}>
                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Competency'>note_add</span>
                </a>
            );    
        }
    }

    async function searchUserSkills(bRadioValue, bRadioTypeValue) {
        setLoading(true);
        const oParams = getSearchParamsEx(bRadioValue, bRadioTypeValue, '1');
        await props.getSkillChecklist(oParams);
        setLoading(false);
    }
    
    async function handleRadioChange(e) {
        // currentRecIndex = 0; 
        setRadioValue(e.currentTarget.value);
        searchUserSkills(e.currentTarget.value, radioTypeValue);
    }

    async function handleRadioTypeChange(e) {
        // currentRecIndex = 0; 
        setRadioTypeValue(e.currentTarget.value);
        if (e.currentTarget.value === '1') {
            setlistTypeDescription('Assignments');
        } else if (e.currentTarget.value === '2') {
            setlistTypeDescription('Competencies');
        } else if (e.currentTarget.value === '3') {
            setlistTypeDescription('Users');
        } else {
            setlistTypeDescription('User Competencies');
        } 
        searchUserSkills(radioValue, e.currentTarget.value);
    }

    function displayListRadioButtons() {
        return (
            radios.map((radio, idx) => (
                <ToggleButton
                    key={'checklisttype' + idx}
                    id={`radio-${'checklisttype' + idx}`}
                    type="radio"
                    style={radioValue === radio.value ? styles.CDButtonSelected : styles.CDButtonUnselected}
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    size='sm'
                    onChange={(e) => handleRadioChange(e)}
                >
                    {radio.name}
                </ToggleButton>
            ))
        );
    }

    function displayGroupRadioButtons() {
        return (
            radioTypes.map((radio, idx) => (
                <ToggleButton
                    key={'checklistgroup' + idx}
                    id={`radio-${'checklistgroup' + idx}`}
                    type="radio"
                    style={radioTypeValue === radio.value ? styles.CDButtonSelected : styles.CDButtonUnselected}
                    name="radioType"
                    value={radio.value}
                    checked={radioTypeValue === radio.value}
                    size='sm'
                    onChange={(e) => handleRadioTypeChange(e)}
                >
                    {radio.name}
                </ToggleButton>
            ))
        );
    }

    function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Competencies Checklist</h3></td>
                <td style={{textAlign:'right', width:'40%', backgroundColor:companyData?.PagesBGColor}}>
                    <Table>
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:companyData?.PagesBGColor, alignItems:'right'}}>
                                    <ButtonGroup className="mb-2" size='sm'>
                                        {displayListRadioButtons()}
                                    </ButtonGroup>                                                                   
                                </td>
                                <td style={{backgroundColor:companyData?.PagesBGColor, alignItems:'left'}}>
                                    <ButtonGroup className="mb-2" size='sm'>
                                        {displayGroupRadioButtons()}
                                    </ButtonGroup>                                                                   
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table>
                        <tbody>
                            <tr>
                                <td style={{textAlign:'right', width:'5%', backgroundColor:companyData?.PagesBGColor}}>
                                    <Dropdown>
                                        <Dropdown.Toggle className='dropdown-search-transparent' id="dropdown-custom-components">
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>expand_circle_down</span>
                                        </Dropdown.Toggle>                                
                                        <DropdownMenu>
                                            {(nodeType === '1') ?
                                                <Dropdown.Item eventKey="1" className='dropdown-search-item-selected' onClick={changeSearchValue}>Employee</Dropdown.Item>
                                                :
                                                <Dropdown.Item eventKey="1" className='dropdown-search-item-unselected' onClick={changeSearchValue}>Employee</Dropdown.Item>
                                            }                                        
                                            {(nodeType === '2') ?
                                                <Dropdown.Item eventKey="2" className='dropdown-search-item-selected' onClick={changeSearchValue}>Assignment</Dropdown.Item>
                                                :
                                                <Dropdown.Item eventKey="2" className='dropdown-search-item-unselected' onClick={changeSearchValue}>Assignment</Dropdown.Item>
                                            }                                        
                                            {(nodeType === '3') ?
                                                <Dropdown.Item eventKey="3" className='dropdown-search-item-selected' onClick={changeSearchValue}>Speciality</Dropdown.Item>
                                                :
                                                <Dropdown.Item eventKey="3" className='dropdown-search-item-unselected' onClick={changeSearchValue}>Speciality</Dropdown.Item>
                                            }             
                                            {(nodeType === '4') ?
                                                <Dropdown.Item eventKey="4" className='dropdown-search-item-selected' onClick={changeSearchValue}>Competency</Dropdown.Item>
                                                :
                                                <Dropdown.Item eventKey="4" className='dropdown-search-item-unselected' onClick={changeSearchValue}>Competency</Dropdown.Item>
                                            }             
                                        </DropdownMenu>                           
                                    </Dropdown>
                                </td>
                                <td style={{textAlign:'left', width:'85%', backgroundColor:companyData?.PagesBGColor}}>
                                    <input id="searchSkillNode" type="text" style={styles.TextDataEntryNoPadding100} onChange={setSearchNodeName} placeholder={nodeTypeDescription}/>
                                </td>
                                <td style={{textAlign:'left', width:'5%', backgroundColor:companyData?.PagesBGColor}}>
                                    <a onClick={() => searchPillars()}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </td>
            </tr>
            </tbody>
        </Table>                            
        );
    }

    function displayMainList() {
        if (radioTypeValue == 4) {
            return displayChecklistAll();
        } else if (radioTypeValue == 1) {
            return displayChecklistAssignment();
        } else if (radioTypeValue == 2) {
            return displayChecklistCompetency();
        } else if (radioTypeValue == 3) {
            return displayChecklistUser();
        } else {
            return displayChecklistAll();
        }
    }

    function displayChecklistAll() {
        return (
            <Table responsive="sm" striped bordered hover variant="light">
                <thead>
                    <tr>
                        <th style={styles.tableText}>#</th>
                        <th style={styles.tableText}>Assignment</th>
                        <th style={styles.tableTextMain}>Speciality</th>
                        <th style={styles.tableTextMain}>Competency</th>
                        <th style={styles.tableText}>Employee</th>
                        <th style={styles.tableText}>Completed</th>
                        <th style={styles.tableText}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {skillList && skillList.map((evalRec, index) => {
                        return (
                            <>
                            <tr>
                                <td style={styles.tableText}>{evalRec?.UserSkillID}</td>
                                <td style={styles.tableTextSubHeader}>{evalRec?.AssignmentName}</td>
                                <td style={styles.tableTextSubHeader}>{evalRec?.SkillSpecialityName}</td>
                                <td style={styles.tableTextSubHeader}>{evalRec?.SkillName}</td>
                                <td style={styles.tableText}>{evalRec?.FullName}</td>
                                <td style={styles.tableText}>
                                    {evalRec?.IsComplete ? ((evalRec?.CompletedDate) ? 'Yes - ' + moment(evalRec?.CompletedDate).format("MM/DD/YYYY") : 'Yes') : 'No'}
                                </td>
                                <td style={styles.tableText}>{checkMainPillar(CheckListGroupBy.All, evalRec?.AssignmentID, evalRec?.SkillID, evalRec?.UserSkillID)}</td>
                            </tr>
                            </>
                        )
                    })
                    }
                </tbody>
            </Table>                                                            
        );
    }

    function displayChecklistAssignment() {
        return (
            <>
            <Table responsive="sm" striped bordered hover variant="light">
                <thead>
                    <tr>
                        <th style={styles.tableText}>#</th>
                        <th style={styles.tableText}>Assignment</th>
                        <th style={styles.tableTextMain}># Competencies</th>
                        <th style={styles.tableText}># Assigned</th>
                        <th style={styles.tableText}># Completed</th>
                        {/* <th style={styles.tableText}>Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {skillList && skillList?.map((evalRec, index) => {
                        return (
                            <>
                            <tr>
                                <td style={styles.tableText}>{evalRec?.AssignmentID}</td>
                                <td style={styles.tableTextSubHeader}>
                                    {evalRec?.AssignmentName}
                                    {checkDetails(CheckListGroupBy.Assignment, evalRec?.AssignmentID, evalRec?.SkillID, evalRec?.UserSkillID, evalRec?.UserID)}
                                </td>
                                <td style={styles.tableText}>{evalRec?.SkillCount}</td>
                                <td style={styles.tableText}>{evalRec?.AssignedUserCount}</td>
                                <td style={styles.tableText}>{evalRec?.CompletedUserCount}</td>
                                {/* <td style={styles.tableText}>{checkMainPillar(CheckListGroupBy.Assignment, evalRec?.AssignmentID, evalRec?.SkillID, evalRec?.UserSkillID)}</td> */}
                            </tr>
                            {displayUserSkills(evalRec)}
                            </>
                        )
                    })
                    }
                </tbody>
            </Table>        
            </>
        );
    }

    function displayChecklistCompetency() {
        return (
            <>
            <Table responsive="sm" striped bordered hover variant="light">
                <thead>
                    <tr>
                        <th style={styles.tableText}>#</th>
                        <th style={styles.tableText}>Competency</th>
                        <th style={styles.tableText}># Assigned</th>
                        <th style={styles.tableText}># Completed</th>
                        {/* <th style={styles.tableText}>Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {skillList && skillList?.map((evalRec, index) => {
                        return (
                            <>
                            <tr>
                                <td style={styles.tableText}>{evalRec?.SkillID}</td>
                                <td style={styles.tableTextSubHeader}>
                                    {evalRec?.SkillName}
                                    {checkDetails(CheckListGroupBy.Competency, evalRec?.AssignmentID, evalRec?.SkillID, evalRec?.UserSkillID, evalRec?.UserID)}
                                </td>
                                <td style={styles.tableText}>{evalRec?.AssignedUserCount}</td>
                                <td style={styles.tableText}>{evalRec?.CompletedUserCount}</td>
                                {/* <td style={styles.tableText}>{checkMainPillar(CheckListGroupBy.Competency, evalRec?.AssignmentID, evalRec?.SkillID, evalRec?.UserSkillID)}</td> */}
                            </tr>
                            {displayUserSkills(evalRec)}
                            </>
                        )
                    })
                    }
                </tbody>
            </Table>        
            </>
        );
    }

    function displayChecklistUser() {
        return (
            <>
            <Table responsive="sm" striped bordered hover variant="light">
                <thead>
                    <tr>
                        <th style={styles.tableText}>#</th>
                        <th style={styles.tableText}>User</th>
                        <th style={styles.tableTextMain}># Competencies</th>
                        <th style={styles.tableText}># Assigned</th>
                        <th style={styles.tableText}># Completed</th>
                        {/* <th style={styles.tableText}>Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {skillList && skillList?.map((evalRec, index) => {
                        return (
                            <>
                            <tr>
                                <td style={styles.tableText}>{evalRec?.UserID}</td>
                                <td style={styles.tableTextSubHeader}>
                                    {evalRec?.FullName}
                                    {checkDetails(CheckListGroupBy.User, evalRec?.AssignmentID, evalRec?.SkillID, evalRec?.UserSkillID, evalRec?.UserID)}
                                </td>
                                <td style={styles.tableText}>{evalRec?.SkillCount}</td>
                                <td style={styles.tableText}>{evalRec?.AssignedUserCount}</td>
                                <td style={styles.tableText}>{evalRec?.CompletedUserCount}</td>
                                {/* <td style={styles.tableText}>{checkMainPillar(CheckListGroupBy.Competency, evalRec?.AssignmentID, evalRec?.SkillID, evalRec?.UserSkillID)}</td> */}
                            </tr>
                            {displayUserSkills(evalRec)}
                            </>
                        )
                    })
                    }
                </tbody>
            </Table>        
            </>
        );
    }

    function displayUserSkills(currRec){
        var bShowSkillDetails = false;
        if ((parseInt(radioTypeValue) == CheckListGroupBy.Assignment) && (parseInt(selectedSubValue) === currRec?.AssignmentID)) {
            bShowSkillDetails = true;
        } else if ((parseInt(radioTypeValue) == CheckListGroupBy.Competency) && (parseInt(selectedSubValue) === currRec?.SkillID)) {
            bShowSkillDetails = true;
        } else if ((parseInt(radioTypeValue) == CheckListGroupBy.User) && (parseInt(selectedSubValue) === currRec?.UserID)) {
            bShowSkillDetails = true;
        }

        if (bShowSkillDetails) {
            return (
                <tr>
                    <td colSpan='6' style={{backgroundColor:companyData?.PagesBGColor}}>
                        <table class="table table-sm caption-top">  
                        <thead>
                            <tr>
                                <th style={styles.tableTextLeft}>Users</th>
                            </tr>
                        </thead>                        
                        <tbody> 
                            <tr>
                                <td colSpan="6"> 
                                {(skillListGroupBy?.length === 0) ? <></> : 
                                    <table class="table table-sm caption-top">  
                                        <thead>
                                            <tr>
                                            <th style={styles.tableText}>#</th>
                                            {(parseInt(radioTypeValue) != CheckListGroupBy.Assignment) ? <th style={styles.tableText}>Assignment</th> : <></>}                                
                                            <th style={styles.tableTextMain}>Speciality</th>
                                            {(parseInt(radioTypeValue) != CheckListGroupBy.Competency) ? <th style={styles.tableText}>Competency</th> : <></>}                                
                                            {(parseInt(radioTypeValue) != CheckListGroupBy.User) ? <th style={styles.tableText}>Employee</th> : <></>}                                
                                            <th style={styles.tableText}>Completed</th>
                                            <th style={styles.tableText}>Action</th>
                                        </tr>
                                        </thead>                        
                                        <tbody> 
                                        {skillListGroupBy && skillListGroupBy?.map((evalRec, index) => {
                                            return (
                                                <>
                                                <tr>
                                                    <td style={styles.tableText}>{evalRec?.UserSkillID}</td>
                                                    {(parseInt(radioTypeValue) != CheckListGroupBy.Assignment) ? <td style={styles.tableTextSubHeader}>{evalRec?.AssignmentName}</td> : <></>}                                                                    
                                                    <td style={styles.tableTextSubHeader}>{evalRec?.SkillSpecialityName}</td>
                                                    {(parseInt(radioTypeValue) != CheckListGroupBy.Competency) ? <td style={styles.tableTextSubHeader}>{evalRec?.SkillName}</td> : <></>}                                                                        
                                                    {(parseInt(radioTypeValue) != CheckListGroupBy.User) ? <td style={styles.tableTextSubHeader}>{evalRec?.FullName}</td> : <></>}                                                                        
                                                    <td style={styles.tableText}>
                                                        {evalRec?.IsComplete ? ((evalRec?.CompletedDate) ? 'Yes - ' + moment(evalRec?.CompletedDate).format("MM/DD/YYYY") : 'Yes') : 'No'}
                                                    </td>
                                                    <td style={styles.tableText}>{checkMainPillar(CheckListGroupBy.All, evalRec?.AssignmentID, evalRec?.SkillID, evalRec?.UserSkillID)}</td>
                                                </tr>
                                                </>
                                            )
                                        })
                                        }
                                        </tbody>
                                    </table>    
                                }
                                </td> 
                            </tr>             
                        </tbody>
                        </table>    
                    </td>
                </tr>
            );
        }
    }

    return (
        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          

                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {displayNewUserSkill()}                                               
                            {((!skillList) || (skillList.length === 0)) ? (             
                                <div style={styles.tableText}>
                                    No User Competencies.
                                </div>)
                                :
                                (
                                    (displayMainList())
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{skillChecklist?.skillChecklist?.RecordFound?.toLocaleString()}   {listTypeDescription}&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(skillChecklist?.skillChecklist?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(skillChecklist?.skillChecklist?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {skillChecklist?.skillChecklist?.PagingMetaData?.PageNumber}  of  {skillChecklist?.skillChecklist?.PagingMetaData?.TotalPage.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                    </Card>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      skillChecklist: state?.entity?.skillChecklist,
      skillChecklistGroupBy: state?.entity?.skillChecklistGroupBy,
      userattributelookups: state.entity.userattributelookups,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSkillChecklist: (oParams) => dispatch(getSkillChecklist(oParams)),
        getSkillChecklistGroupBy: (oParams) => dispatch(getSkillChecklistGroupBy(oParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        updateUserSkill: (skillParams) => dispatch(updateUserSkill(skillParams)),
        assignLookupSelection: (id, value) => dispatch(assignLookupSelection(id, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompetencyChecklist)