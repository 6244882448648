import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { getMenus, getUserAttributesLookups, insertMenu, deleteMenu, updateMenu, assignLookupSelection } from '../../store/actions/entityActions';
import Spinner from '../layout/Spinner';
import { useLocation } from 'react-router-dom';
import { getPermissionFlags } from '../layout/Utils';
import { PermissionType } from '../../config/Constant';
import { AuditEntityType } from '../../config/Constant';
import AuditTrail from '../layout/AuditTrail';

const MenuList = (props) => {
    const { auth, companyData, profile, menus, userattributelookups, loadingMenu, fromLookupParam } = props;    
    const [loading, setLoading] = useState(false);
    const [nodeName, setnodeName] = useState('');
    const [sliderValue, setSliderValue] = useState([]);
    const [addNewMenu, setAddNewMenu] = useState(false);
    const [editMenu, setEditMenu] = useState(false);
    const [menuID, setMenuID] = useState(-1);
    const [menuName, setMenuName] = useState('');
    const [functionCall, setFunctionCall] = useState('');
    const [menuParentID, setMenuParentID] = useState(-1);
    const [displayOrder, setDisplayOrder] = useState(-1);
    const [resourceKey, setResourceKey] = useState('');
    const [isMenuRoot, setIsMenuRoot] = useState(false);
    const [EditedMenuID, setEditedMenuID] = useState(-1);

    const menu = menus?.menus?.Menus;

    const [auditEntityID, setAuditEntityID] = useState(-1);
    const [showAudit, setShowAudit] = useState(false);
    const handleCloseAudit = () => {setShowAudit(false); }
    function handleShowAudit() {
        setShowAudit(true); 
    };
    async function handleAuditClick(iID) {
        setLoading(true);
        setAuditEntityID(iID);
        handleShowAudit();
        setLoading(false);
    }    

    const location  = useLocation();
    let fromLookup = location?.state?.fromLookup;
    if ((fromLookup === null) || (fromLookup === undefined)) {
        fromLookup = fromLookupParam;
    }
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location.state.permissiontypeid));

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    async function previousPage() {
        setLoading(true);
        const previousPageNumber = menus?.menus?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            MenuName:nodeName,
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        await props.getMenus(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = menus?.menus?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            MenuName:nodeName,
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };            
        await props.getMenus(oParams);
        setLoading(false);
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }
    
    async function searchMenus() {
        setLoading(true);
        const oParams = {
            MenuName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getMenus(oParams);        
        setLoading(false);
    }
    
    const addInitialSliderArray = (entity) => {
        return menu?.map((oRec) => ({
            MenuID:oRec.MenuID, 
            EditedMenuID:-1,
            takeAction:false,
            showDetails:false,
            disableNewMenu:false,
            newMenuClicked:false,
            editMenuClicked:false,
        }))
    }
              
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(menu);
        setSliderValue(initialScoreValues);
    }, [menu])
    
    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            (addNewMenu) ? setAddNewMenu(false) : setAddNewMenu(true);
            setMenuID(-1);
            setEditedMenuID(-1);
            setFunctionCall('');
            setMenuParentID(-1);
            setDisplayOrder(-1);
            setResourceKey('');
            setIsMenuRoot(false);        
            setMenuName('');
        } 

        setLoading(false);
    }

    async function handleCancelChildernClick(tab, currentEntity) {
        if (tab === 1) {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.MenuID === currentEntity?.MenuID) {
                    if (tab === 2) {
                        row.disableNewMenu = false;                    
                        row.newMenuClicked = false;
                        row.editMenuClicked = false;
                    } 
                }
            });
            setSliderValue(updatedSliderValues);    
            setMenuID(-1);
            setEditedMenuID(-1);
            setMenuName('');
            setFunctionCall('');
            setMenuParentID(-1);
            setDisplayOrder(-1);
            setResourceKey('');
            setIsMenuRoot(false);        
            setAddNewMenu(false);
            setEditMenu(false);
        } 
    }
    
    async function handleSaveChildernClick(tab, currentEntity) {
        setLoading(true);
        if (tab === 1) {
            const oIParams  = {
                MenuID : -1,
                MenuName : menuName,
                FunctionCall : functionCall,
                MenuParentID : menuParentID,
                DisplayOrder : displayOrder,
                ResourceKey : resourceKey, 
                IsMenuRoot : isMenuRoot,
            };        
            console.log(oIParams);
            await props.insertMenu(oIParams);    
        } 

        const oParams = {
            MenuName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getMenus(oParams);        
        setMenuID(-1);
        setMenuName('');
        setFunctionCall('');
        setMenuParentID(-1);
        setDisplayOrder(-1);
        setResourceKey('');
        setIsMenuRoot(false);        
        setAddNewMenu(false);
        setEditMenu(false);
        setLoading(false);
    }

    async function handleEditedSaveChildernClick(tab, currentEntity) {
        setLoading(true);
        if (tab === 1) {
            if (EditedMenuID > 0) {
                const oUParams  = {
                    MenuID : EditedMenuID,
                    MenuName : menuName,
                    FunctionCall : functionCall,
                    MenuParentID : menuParentID,
                    DisplayOrder : displayOrder,
                    ResourceKey : resourceKey, 
                    IsMenuRoot : isMenuRoot,
                    UpdatedByUserID : auth?.UserID,
                };        
                await props.updateMenu(oUParams);    
            } else {
                const oUParams  = {
                    MenuID : -1,
                    MenuName : menuName,
                    FunctionCall : functionCall,
                    MenuParentID : menuParentID,
                    DisplayOrder : displayOrder,
                    ResourceKey : resourceKey, 
                    IsMenuRoot : isMenuRoot,
                    CreatedByUserID : auth?.UserID,
                };        
                await props.insertMenu(oUParams);    
            }

            setMenuID(-1);
            setEditedMenuID(-1);
            setMenuName('');
            setFunctionCall('');
            setMenuParentID(-1);
            setDisplayOrder(-1);
            setResourceKey('');
            setIsMenuRoot(false);        
            setAddNewMenu(false);
            setEditMenu(false);    
        } 
   
        const oParams = {
            MenuName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getMenus(oParams);        
        setLoading(false);
    }

    async function handleUpdateChildernClick(tab, id, id1) {
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            const oEdited = menu?.filter((oRec) => (oRec.MenuID === id));
            if ((oEdited) && (oEdited.length > 0)) {
                setMenuID(id);
                setEditedMenuID(id);
                setMenuName((oEdited[0]?.MenuName) ? oEdited[0]?.MenuName : '');
                setFunctionCall((oEdited[0]?.FunctionCall) ? oEdited[0]?.FunctionCall : '');
                setMenuParentID(oEdited[0]?.MenuParentID);
                setDisplayOrder(oEdited[0]?.DisplayOrder);
                setResourceKey((oEdited[0]?.ResourceKey) ? oEdited[0]?.ResourceKey : '');
                setIsMenuRoot(oEdited[0]?.IsMenuRoot);        
            }

            setEditMenu(true);
        }  
    }

    async function handleDeleteChildernClick(tab, id, id1) {
        setLoading(true);

        if (tab === 1) {
            const oParams  = {
                MenuID: id,
            };        
            console.log(oParams);
            await props.deleteMenu(oParams);    
        } 
        
        const oParams = {
            MenuName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getMenus(oParams);            

        setLoading(false);
    }

    async function handleAddChildernValues(tab, rowIndex, value) {
        setLoading(true);

        setLoading(false);
    }

    function checkMainMenu(tab, id, id1) {
        if (!fromLookup) {            
            if (permissionFlags.CanModify) {
                return (
                        <div>
                            <a onClick={() => handleAuditClick(id)}>
                                <span style={styles.ActionIconGreyBold} class="material-symbols-outlined" title='Show Audit'>history_2</span>
                            </a>
                            <a onClick={() => handleUpdateChildernClick(tab, id, id1)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Menu'>edit</span>
                            </a>
                            <a onClick={() => handleDeleteChildernClick(tab, id, id1)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Menu'>delete</span>
                            </a>
                        </div>
                    );      
            } else {
                return (<></>)
            }
        } else {
            return (<></>)
        }
    }

    function displayNewMenu() {
        if ((!fromLookup) && ((addNewMenu) || (editMenu))) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                    <Card.Title>
                        {(addNewMenu) ? 'New Menu' : 'Edit Menu'}                        
                    </Card.Title>
                </Card.Header>                   
                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="menuname" style={styles.LabelDataEntryNoPadding}>Name</label>
                                    <input id="menuname" type="text" style={styles.TextDataEntryNoPaddingWBG} defaultValue={menuName} onChange={(e) => (setMenuName(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', width:'15%',backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="isroot" style={styles.LabelDataEntryNoPadding}>Is Root</label>
                                    <select id="isroot" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={(isMenuRoot) ? '1' : '0'} onChange={(e) => (setIsMenuRoot(e.target.value))}>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                    </select>                                     
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', width:'15%',backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="parentMenu" style={styles.LabelDataEntryNoPadding}>Parent Menu</label>
                                    <select id="parentMenu" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={menuParentID} onChange={(e) => (setMenuParentID(e.target.value))}>
                                        {menu?.map((pMenu, supIndex) => (
                                            <option value={pMenu.MenuID}>{pMenu.MenuName}</option>        
                                        ))}
                                    </select>                                     
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="description" style={styles.LabelDataEntryNoPadding}>Function Call</label>
                                    <input id="description" type="text" style={styles.TextDataEntryNoPaddingWBG} defaultValue={functionCall} onChange={(e) => (setFunctionCall(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="displayorder" style={styles.LabelDataEntryNoPadding}>Display Order</label>
                                    <input id="displayorder" type="number" min='1' max='100' step='1' style={styles.TextDataEntryNoPaddingWBG} defaultValue={displayOrder} onChange={(e) => (setDisplayOrder(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="resourcekey" style={styles.LabelDataEntryNoPadding}>Resource Key</label>
                                    <input id="resourcekey" type="text" style={styles.TextDataEntryNoPaddingWBG} defaultValue={resourceKey} onChange={(e) => (setResourceKey(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='3' style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                <div class="row d-flex justify-content-center align-items-center">
                                    <div class="col-auto">
                                        <Button onClick={() => handleEditedSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Menu'>Save</Button>
                                    </div>
                                    <div class="col-auto">
                                        <Button onClick={() => handleCancelChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey}>Cancel</Button>
                                    </div>
                                </div>
                            </td>
                        </tr>  
                    </tbody>
                </table>                
                </Card.Body>
                </Card>                   
            );
        }
    }

    function displayAddMainMenu() {
        if (!fromLookup) {
            if (permissionFlags.CanCreate) {
                if (addNewMenu) {
                    return (
                        <a onClick={() => handleAddChildernClick(1, null)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Menu'>shadow_minus</span>
                        </a>
                    );   
                } else {
                    return (
                        <a onClick={() => handleAddChildernClick(1, null)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Menu'>note_add</span>
                        </a>
                    );    
                }
                return (<></>);
            } else {
                return (<></>);
            }
        } else {
            return (<></>);
        }
    }

    function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Menus</h3></td>
                <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                    <div className='input-field'>
                        <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Menu'/>
                        <a onClick={() => searchMenus()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a>
                        {displayAddMainMenu()}
                    </div>                                            
                </td>
            </tr>
            </tbody>
        </Table>                            
        );
    }

    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;        
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (row.MenuID === rowIndex)) {
                bReturn = row.showDetails;
            }
        })
        return bReturn;
    }
 
    function handleSwitchDetailChange(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.MenuID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSliderValue(updatedSliderValues);
    }

    function displayAddButtons(tabValue, currentEntity) {
        const oRequestList = sliderValue.filter((oRow) => oRow.MenuID === currentEntity.MenuID);

        if (permissionFlags.CanCreate) {       
            return (<></>);
        } else {
            return (<></>);
        }
    }

    return (
        <>
        <AuditTrail showFromAudit={showAudit} handleParentAuditClose={handleCloseAudit} AuditEntityTypeID={AuditEntityType.Menu} EntityID={auditEntityID}/>

        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          

                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {displayNewMenu()}                                               
                            {((!menu) || (menu.length === 0)) ? (             
                                <div style={styles.tableText}>
                                    No Menus.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableTextMain}>Name</th>
                                                <th style={styles.tableText}>Is Root</th>
                                                <th style={styles.tableText}>Display Order</th>
                                                <th style={styles.tableText}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {menu && menu.map((oRec, index) => {
                                                return (
                                                    <tr>
                                                        <td style={styles.tableText}>{oRec?.MenuID}</td>
                                                        <td style={styles.tableTextSubHeader}>{oRec?.MenuName}</td>
                                                        <td style={styles.tableText}>{(oRec?.IsMenuRoot) ? 'Yes' : 'No'}</td>
                                                        <td style={styles.tableTextSubHeader}>{oRec?.DisplayOrder}</td>
                                                        <td style={styles.tableText10}>{checkMainMenu(1, oRec?.MenuID, -1)}</td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{menus?.menus?.RecordFound?.toLocaleString()}   Menus&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(menus?.menus?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(menus?.menus?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {menus?.menus?.PagingMetaData?.PageNumber}  of  {menus?.menus?.PagingMetaData?.TotalPage?.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                </Card>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state?.auth?.profile,
      menus: state.entity?.menus, 
      userattributelookups: state.entity.userattributelookups,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getMenus: (oParams) => dispatch(getMenus(oParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        insertMenu: (oParams) => dispatch(insertMenu(oParams)),
        deleteMenu: (oParams) => dispatch(deleteMenu(oParams)),
        updateMenu: (oParams) => dispatch(updateMenu(oParams)),
        assignLookupSelection: (id, value) => dispatch(assignLookupSelection(id, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuList)