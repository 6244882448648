import React, { useRef, useContext, useState, useEffect } from "react"
import { Form, Button, Card, Alert, CardHeader, CardFooter, CardTitle, Image } from "react-bootstrap"
import { Link } from "react-router-dom"
import { styles } from '../layout/styles'
import ReCAPTCHA from 'react-google-recaptcha';
import moment from 'moment'
import Spinner from '../layout/Spinner';
import { forgotPassword } from '../../store/actions/authActions'
import { connect } from 'react-redux'

function ForgotPassword(props) {
  const { auth, companyData, profile, entityError, entitySuccess } = props;

  const emailRef = useRef()
  // const { resetPassword,  } = useContext(AuthContext)
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [showErrorMsg, setShowErrorMsg] = useState(true);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [disableReset, setDisableReset] = useState(true);

  useEffect(() => {
      if (loading === true) {
        setDisableReset(true);
      } else if (recaptchaValue === null) {
        setDisableReset(true);
      } else {
        setDisableReset(false);
      }
  }, [loading])  

  useEffect(() => {
      if (recaptchaValue === null) {
        setDisableReset(true);
      } else if (loading === true) {
        setDisableReset(true);
      } else {
        setDisableReset(false);
      }
  }, [recaptchaValue])  


  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setMessage("")
      setError("")
      setLoading(true)
      const creds = {
          Email:emailRef.current.value, 
          UserName:emailRef.current.value,
          CompanyID:companyData?.CompanyID,
      };
      console.log(creds);
      await props.forgotPassword(creds);
      console.log(entitySuccess);
      if (entityError) {
          console.log(entityError);
          setError(entityError.message)
      }
      else if (entitySuccess) {
        if (entitySuccess?.returnParams?.UserDataSyncOut === 'True') {
          setMessage("The password reset process has been initiated. Please check your inbox for instructions to reset your password.")
        } else {
          setError("Password reset failed. Please contact your administrator for assistance.")
        }
      }
    } catch {
      setError("Password reset failed. Please contact your administrator for assistance.")
    }

    setLoading(false)
  }

  function displayLoginError() {
      if (showErrorMsg) {
          if (error) {
              return (
                  <Alert variant="danger" style={{textAlign:'center'}}>{error}</Alert>
              );    
          } else if ((entityError) && ((typeof entityError === 'string') )) {
              return (
                  <Alert variant="danger" style={{textAlign:'center'}}>{entityError}</Alert>
              );    
          } else if (message) {
            return (
              <Alert variant="success" style={{textAlign:'center'}}>{message}</Alert>
            );    
          } else {
            return (
                <></>
            );    
          }
      } else {
          return (
              <></>
          );    
      }
  }

  function displayLogin() {
      const slink = "/login/" + companyData?.CompanyID;
      return (
          <div style={styles.Label}>
              Back to<Link style={styles.Link} to={slink}>Login</Link>
          </div>
      );    
  }
  

  return (
    <>
          <div style={{position:'absolute', width:'520px', height:'220px', top:'22%', left:'56%'}}>
          <Form onSubmit={handleSubmit}>
            <Card style={{width:'100%', backgroundColor:'transparent'}}>
              <CardHeader> 
                <CardTitle>
                    <span>
                        <h1 style={styles.Header}><Image style={{width:'240px', height:'100px'}} src={companyData?.CompanyLogo}/></h1>
                    </span>
                </CardTitle>
                <Card.Subtitle>
                    <span>
                        <h4 style={styles.Header}>Forgot Password</h4>
                    </span>
                    {displayLoginError()}
                </Card.Subtitle>
              </CardHeader>
              {/* {error && <Alert variant="danger">{error}</Alert>}
              {message && <Alert variant="success">{message}</Alert>} */}
              <Card.Body>
              {(loading) ? (            
                        <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                            <Spinner/>
                        </div>
                ) 
                : 
                (                            
                  <>
                    <Form.Group id="email">
                      <Form.Label style={styles.LabelNoPadding}>User Name</Form.Label>
                      <Form.Control type="email" ref={emailRef} required />
                    </Form.Group>
                    <Form.Group id='recaptcha'>
                        <div className="w-100 text-center mt-3">
                            <ReCAPTCHA style={{backgroundColor:'transparent'}} sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY} onChange={(value) => setRecaptchaValue(value)}/>
                        </div>
                    </Form.Group>
                  </>
                )}
                </Card.Body>
                <CardFooter>
                <div className="w-100 text-center mt-3">
                    <Button disabled={disableReset} style={(disableReset) ? styles.CDButtonDisabled : styles.CDButton} type="submit">
                        Reset
                    </Button>
                </div>
                {displayLogin()}
            </CardFooter>
          </Card>
        </Form>
      </div>
    </>
  )
}

const mapsStateToProps = (state) => {
  return {
      companyData: state?.auth?.companyData,
      entityError: state?.auth?.entityError,
      entitySuccess: state?.auth?.entitySuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (oParam) => dispatch(forgotPassword(oParam)),
  }
}

export default connect(mapsStateToProps, mapDispatchToProps)(ForgotPassword)


