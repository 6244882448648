import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { getPolicies, getUserAttributesLookups, insertPolicy, deletePolicy, updatePolicy, 
        insertGroupPolicy, deleteGroupPolicy, assignLookupSelection } from '../../store/actions/entityActions';
import Spinner from '../layout/Spinner';
import { useLocation } from 'react-router-dom';
import { getPermissionFlags } from '../layout/Utils';
import { PermissionType } from '../../config/Constant';
import { AuditEntityType } from '../../config/Constant';
import AuditTrail from '../layout/AuditTrail';

const PolicyList = (props) => {
    const { auth, companyData, profile, policies, userattributelookups, loadingMenu, fromLookupParam } = props;    
    const [loading, setLoading] = useState(false);
    const [nodeName, setnodeName] = useState('');
    const [sliderValue, setSliderValue] = useState([]);
    const [addNewPolicy, setAddNewPolicy] = useState(false);
    const [addNewGroupPolicy, setAddNewGroupPolicy] = useState(false);
    const [editPolicy, setEditPolicy] = useState(false);
    const [policyID, setPolicyID] = useState(-1);
    const [policyDescription, setPolicyDescription] = useState('');
    const [policyTypeID, setPolicyTypeID] = useState(-1);
    const [menuID, setMenuID] = useState(-1);
    const [EditedPolicyID, setEditedPolicyID] = useState(-1);
    const [EditedGroupPolicyID, setEditedGroupPolicyID] = useState(-1);

    const policy = policies?.policies?.Policies;
    const grouppolicies = policies?.policies?.GroupPolicy;
    const groupData = userattributelookups?.Groups;

    const [auditEntityID, setAuditEntityID] = useState(-1);
    const [showAudit, setShowAudit] = useState(false);
    const handleCloseAudit = () => {setShowAudit(false); }
    function handleShowAudit() {
        setShowAudit(true); 
    };
    async function handleAuditClick(iID) {
        setLoading(true);
        setAuditEntityID(iID);
        handleShowAudit();
        setLoading(false);
    }    

    const location  = useLocation();
    let fromLookup = location?.state?.fromLookup;
    if ((fromLookup === null) || (fromLookup === undefined)) {
        fromLookup = fromLookupParam;
    }
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location.state.permissiontypeid));

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    async function previousPage() {
        setLoading(true);
        const previousPageNumber = policies?.policies?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            PolicyDescription:nodeName,
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        await props.getPolicies(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = policies?.policies?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            PolicyDescription:nodeName,
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };            
        await props.getPolicies(oParams);
        setLoading(false);
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }
    
    async function searchPolicies() {
        setLoading(true);
        const oParams = {
            PolicyDescription:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getPolicies(oParams);        
        setLoading(false);
    }
    
    const addInitialSliderArray = (entity) => {
        return policy?.map((oRec) => ({
            PolicyID:oRec.PolicyID, 
            EditedPolicyID:-1,
            takeAction:false,
            showDetails:false,
            disableNewPolicy:false,
            newPolicyClicked:false,
            editPolicyClicked:false,
            disableNewGroupPolicy:false,
            newGroupPolicyClicked:false,
            editGroupPolicyClicked:false,
            EditedGroupPolicyID:-1,
            IsUser:false,
            PermissionTypeID:-1,
        }))
    }
              
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(policy);
        setSliderValue(initialScoreValues);
    }, [policy])
    
    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            (addNewPolicy) ? setAddNewPolicy(false) : setAddNewPolicy(true);
            setPolicyID(-1);
            setPolicyDescription('');
        } else {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.PolicyID === rowIndex) {
                    if (tab === 2) {
                        setPolicyID(rowIndex);
                        (row.newGroupPolicyClicked) ? row.newGroupPolicyClicked = false : row.newGroupPolicyClicked = true;                    
                    } 
                }
            });
            setAddNewGroupPolicy(true);
            setSliderValue(updatedSliderValues);
        }

        setLoading(false);
    }

    async function handleCancelChildernClick(tab, currentEntity) {
        if (tab === 2) {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.PolicyID === currentEntity.PolicyID) {
                    if (tab === 2) {
                        row.editGroupPolicyClicked = false;                    
                        row.disableNewGroupPolicy = false;
                        row.newGroupPolicyClicked = false;
                    } 
                }
            });
            setSliderValue(updatedSliderValues);    
        } else if (tab === 1) {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.PolicyID === currentEntity?.PolicyID) {
                    if (tab === 2) {
                        row.disableNewPolicy = false;                    
                        row.newPolicyClicked = false;
                        row.editPolicyClicked = false;
                    } 
                }
            });
            setSliderValue(updatedSliderValues);    
            setPolicyID(-1);
            setEditedPolicyID(-1);
            setEditedGroupPolicyID(-1);
            setPolicyDescription('');
            setAddNewPolicy(false);
            setEditPolicy(false);
            setAddNewGroupPolicy(false);
        } 
    }
    
    async function handleSaveChildernClick(tab, currentEntity) {
        setLoading(true);
        if (tab === 1) {
            const oIParams  = {
                PolicyID : -1,
                PolicyDescription : policyDescription,
                PolicyTypeID : policyTypeID,
                MenuID : menuID,
            };        
            console.log(oIParams);
            await props.insertPolicy(oIParams);    
        } if (tab === 2) {
            const oRequestList = sliderValue.filter((oRow) => oRow.PolicyID === currentEntity.PolicyID);
            const oGroupList = groupData.filter((oRow) => (oRow.GroupID === oRequestList[0].GroupID));
            const oIParams  = {
                PolicyID : oRequestList[0].PolicyID,
                GroupID : EditedGroupPolicyID,
                GroupName : oGroupList[0]?.GroupName,
                IsUser : oRequestList[0].IsUser,
                PermissionTypeID : oRequestList[0].PermissionTypeID,
                PermissionTypeName : PermissionType[oRequestList[0].PermissionTypeID],
                CreatedByUserID : auth?.UserID,
            };        
            console.log(oIParams);
            await props.insertGroupPolicy(oIParams);    
        } 

        const oParams = {
            PolicyDescription:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getPolicies(oParams);        
        setPolicyID(-1);
        setEditedGroupPolicyID(-1);
        setPolicyDescription('');
        setAddNewPolicy(false);
        setEditPolicy(false);
        setLoading(false);
    }

    async function handleEditedSaveChildernClick(tab, currentEntity) {
        setLoading(true);
        if (tab === 1) {
            if (EditedPolicyID > 0) {
                const oUParams  = {
                    PolicyID : EditedPolicyID,
                    PolicyDescription : policyDescription,
                    PolicyTypeID : policyTypeID,
                    MenuID : menuID,
                    UpdatedByUserID : auth?.UserID,
                };        
                await props.updatePolicy(oUParams);    
            } else {
                const oUParams  = {
                    PolicyID : EditedPolicyID,
                    PolicyDescription : policyDescription,
                    PolicyTypeID : policyTypeID,
                    MenuID : menuID,
                    CreatedByUserID : auth?.UserID,
                };        
                await props.insertPolicy(oUParams);    
            }
            setPolicyID(-1);
            setEditedPolicyID(-1);
            setPolicyDescription('');
            setAddNewPolicy(false);
            setEditPolicy(false);    
        } else if (tab === 2) {
            const oRequestList = sliderValue.filter((oRow) => (oRow.PolicyID === currentEntity.PolicyID));
            const oGroupList = groupData.filter((oRow) => (oRow.GroupID === parseInt(oRequestList[0].GroupID)));
            const entry = Object.entries(PermissionType).find(([key, val]) => val === parseInt(oRequestList[0].PermissionTypeID));
            const sPermissionTypeName = entry ? entry[0] : null; // Return the key (name) or null if not found

            if (EditedGroupPolicyID > 0) {
                const oUParams  = {
                    PolicyID : oRequestList[0].PolicyID,
                    GroupID : oRequestList[0].GroupID,
                    GroupName : oGroupList[0]?.GroupName,
                    IsUser : oRequestList[0].IsUser,
                    PermissionTypeID : oRequestList[0].PermissionTypeID,
                    PermissionTypeName : sPermissionTypeName,
                    UpdatedByUserID : auth?.UserID,
                };        
                    await props.updateGroupPolicy(oUParams);    
            } else {
                const oUParams  = {
                    PolicyID : oRequestList[0].PolicyID,
                    GroupID : oRequestList[0].GroupID,
                    GroupName : oGroupList[0]?.GroupName,
                    IsUser : oRequestList[0].IsUser,
                    PermissionTypeID : oRequestList[0].PermissionTypeID,
                    PermissionTypeName : sPermissionTypeName,
                    CreatedByUserID : auth?.UserID,
                };        
                await props.insertGroupPolicy(oUParams);    
            }
            setPolicyID(-1);
            setEditedPolicyID(-1);
            setEditedGroupPolicyID(-1);
        } 
   
        const oParams = {
            PolicyDescription:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getPolicies(oParams);        
        setLoading(false);
    }

    async function handleUpdateChildernClick(tab, id, id1) {
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            const oEdited = policy?.filter((oRec) => (oRec.PolicyID === id));
            if ((oEdited) && (oEdited.length > 0)) {
                setPolicyID(id);
                setEditedPolicyID(id);
                setPolicyDescription((oEdited[0]?.PolicyDescription) ? oEdited[0]?.PolicyDescription : '');
            }

            setEditPolicy(true);
        } else if (tab === 2) {
            setEditedGroupPolicyID(id1);
            const evalEditedGroupPolicies = grouppolicies?.filter((oRec) => ((oRec.PolicyID === id) && (oRec.GroupID === id1)));

            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.PolicyID === id) {
                    if (tab === 2) {
                        row.editGroupPolicyClicked = true;                    
                        row.disableNewGroupPolicy = true;
                        row.newGroupPolicyClicked = false;
                        row.EditedGroupPolicyID = id1;
                        row.GroupPolicyID = id1;
                        row.IsUser = evalEditedGroupPolicies[0]?.IsUser;
                        row.PermissionTypeID = evalEditedGroupPolicies[0]?.PermissionTypeID;
                    } 
                }
            });
            setSliderValue(updatedSliderValues);    
        }  
    }

    async function handleDeleteChildernClick(tab, id, id1) {
        setLoading(true);

        if (tab === 1) {
            const oParams  = {
                PolicyID: id,
            };        
            console.log(oParams);
            // await props.deletePolicy(oParams);    
        } else if (tab === 2) {
            const oParams  = {
                PolicyID: id,
                GroupID: id1,
                UpdatedByUserID : auth?.UserID,
            };        
            console.log(oParams);
            await props.deleteGroupPolicy(oParams);    
        } 
        
        const oParams = {
            PolicyDescription:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getPolicies(oParams);            

        setLoading(false);
    }

    async function handleAddChildernValues(tab, rowIndex, value) {
        setLoading(true);

        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.PolicyID === rowIndex) {
                if (tab === 1) {
                    row.GroupID = value;                    
                } else if (tab === 2) {
                    row.PermissionTypeID = value;
                } else if (tab === 3) {
                    row.IsUser = (value === '1') ? true : false;
                } 
            }        
        });
        setSliderValue(updatedSliderValues);

        setLoading(false);
    }

    function checkMainPolicy(tab, id, id1) {
        if (!fromLookup) {            
            if (permissionFlags.CanModify) {
                if (tab === 2) {
                    return (
                        <div>
                            {/* <a onClick={() => handleUpdateChildernClick(tab, id, id1)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Group Policy'>edit</span>
                            </a> */}
                            <a onClick={() => handleDeleteChildernClick(tab, id, id1)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Group Policy'>delete</span>
                            </a>
                        </div>
                    );                
                } else {            
                    return (
                        <div>
                            <a onClick={() => handleAuditClick(id)}>
                                <span style={styles.ActionIconGreyBold} class="material-symbols-outlined" title='Show Audit'>history_2</span>
                            </a>
                            {/* <a onClick={() => handleUpdateChildernClick(tab, id, id1)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Policy'>edit</span>
                            </a>
                            <a onClick={() => handleDeleteChildernClick(tab, id, id1)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Policy'>delete</span>
                            </a> */}
                        </div>
                    );      
                }      
            } else {
                return (<></>)
            }
        } else {
            return (<></>)
        }
    }

    function displayNewPolicy() {
        if ((!fromLookup) && ((addNewPolicy) || (editPolicy))) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                    <Card.Title>
                        {(addNewPolicy) ? 'New Policy' : 'Edit Policy'}                        
                    </Card.Title>
                </Card.Header>                   
                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="description" style={styles.LabelDataEntryNoPadding}>Description</label>
                                    <input id="description" type="text" style={styles.TextDataEntryNoPaddingWBG} defaultValue={policyDescription} onChange={(e) => (setPolicyDescription(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'right', width:'5%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <Button onClick={() => handleEditedSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Policy'>Save</Button>
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'5%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <Button onClick={() => handleCancelChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey}>Cancel</Button>
                                </div>
                            </td>
                        </tr>  
                    </tbody>
                </table>                
                </Card.Body>
                </Card>                   
            );
        }
    }

    function displayAddMainPolicy() {
        if (!fromLookup) {
            if (permissionFlags.CanCreate) {
                // if (addNewPolicy) {
                //     return (
                //         <a onClick={() => handleAddChildernClick(1, null)}>
                //             <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Policy'>shadow_minus</span>
                //         </a>
                //     );   
                // } else {
                //     return (
                //         <a onClick={() => handleAddChildernClick(1, null)}>
                //             <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Policy'>note_add</span>
                //         </a>
                //     );    
                // }
                return (<></>);
            } else {
                return (<></>);
            }
        } else {
            return (<></>);
        }
    }

    function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Policies</h3></td>
                <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                    <div className='input-field'>
                        <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Policy'/>
                        <a onClick={() => searchPolicies()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a>
                        {displayAddMainPolicy()}
                    </div>                                            
                </td>
            </tr>
            </tbody>
        </Table>                            
        );
    }

    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;        
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (row.PolicyID === rowIndex)) {
                bReturn = row.showDetails;
            }
        })
        return bReturn;
    }
 
    function handleSwitchDetailChange(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.PolicyID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSliderValue(updatedSliderValues);
    }

    function displayAddButtons(tabValue, currentEntity) {
        const oRequestList = sliderValue.filter((oRow) => oRow.PolicyID === currentEntity.PolicyID);

        if (permissionFlags.CanCreate) {       
            if (tabValue === 2 ) {
                if (!oRequestList[0].disableNewGroupPolicy) {
                    if (oRequestList[0].newGroupPolicyClicked) {
                        return (
                            <a onClick={() => handleAddChildernClick(2, currentEntity.PolicyID)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Group Policy'>shadow_minus</span>
                            </a>
                        );
                    } else {
                        return (
                            <a onClick={() => handleAddChildernClick(2, currentEntity.PolicyID)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Group Policy'>note_add</span>
                            </a>
                        );
                    }
                }
            } 
        } else {
            return (<></>);
        }
    }

    function displayNewGroupPolicy(currentEntity) {
        const oRequestList = sliderValue.filter((oRow) => oRow.PolicyID === currentEntity.PolicyID);
        const evalEditedGroupPolicies = grouppolicies?.filter((oRow) => ((oRow.PolicyID === currentEntity.PolicyID) && (oRow.GroupID === oRequestList[0]?.EditedGroupPolicyID)));

        if ((oRequestList[0].newGroupPolicyClicked) || (oRequestList[0].editGroupPolicyClicked)) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                    <Card.Title>
                        {(addNewGroupPolicy) ? 'New Group Policy' : 'Edit Group Policy'}                        
                    </Card.Title>
                </Card.Header>                   
                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <table class="table caption-top text-left">  
                        <thead>
                        </thead>                        
                        <tbody> 
                            <tr>                          
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label style={styles.LabelDataEntryNoPadding}>Group</label>
                                        <select id="emailto" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={evalEditedGroupPolicies[0]?.GroupID} onChange={(e) => (handleAddChildernValues(1, currentEntity.PolicyID, e.target?.selectedOptions[0]?.value))}>
                                            {groupData?.map((group, supIndex) => (
                                                <option value={group.GroupID}>{group.GroupName}</option>        
                                            ))}
                                        </select>                                     
                                    </div>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="status" style={styles.LabelDataEntryNoPadding}>Permission Type</label>
                                        <select id="status" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={evalEditedGroupPolicies[0]?.PermissionTypeID} onChange={(e) => (handleAddChildernValues(2, currentEntity.PolicyID, e.target?.selectedOptions[0]?.value))}>
                                                <option value='1'>Only View</option>        
                                                <option value='2'>Only Modify/No Create</option>        
                                                <option value='3'>Only Create/No Modify</option>        
                                                <option value='4'>Full Control</option>        
                                        </select>                                     
                                    </div>
                                </td>
                                <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                    <div>
                                        <label htmlFor="beforeduedate" style={styles.LabelDataEntryNoPadding}>Is User</label>
                                        <select id="beforeduedate" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={(evalEditedGroupPolicies[0]?.BeforeDueDateEmail) ? '1' : '0'} onChange={(e) => (handleAddChildernValues(3, currentEntity.PolicyID, e.target.value))}>
                                                <option value='1'>Yes</option>        
                                                <option value='0'>No</option>        
                                        </select>                                     
                                    </div>
                                </td>
                            </tr>
                            <tr>                       
                                <td colSpan='3' style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                    <div class="row d-flex justify-content-center align-items-center">
                                        <div class="col-auto">
                                            <Button onClick={() => handleEditedSaveChildernClick(2, currentEntity)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Group Policy'>Save</Button>
                                        </div>
                                        <div class="col-auto">
                                            <Button onClick={() => handleCancelChildernClick(2, currentEntity)} disabled={loading} style={styles.CDPillButtonSMGrey}>Cancel</Button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>                                   
                </Card.Body>
                </Card>
            );
        }
    }

    function getGroupPolicy(policyGroups) {
        if (policyGroups.length === 0) {
            return (
                <></>
            );
        } else {
            return policyGroups.map((group, oIndex) => (
                <tr> 
                    <td style={styles.tableText}>{oIndex + 1}</td> 
                    <td style={styles.tableText}>{group?.GroupID}</td> 
                    <td style={styles.tableTextLeft}>{group?.GroupName}</td> 
                    <td style={styles.tableText}>{(group?.IsUser && (group?.IsUser === true)) ? 'True' : 'False'}</td> 
                    <td style={styles.tableText}>{group?.PermissionTypeName}</td> 
                    <td style={styles.tableText}>{checkMainPolicy(2, group?.PolicyID, group?.GroupID)}</td> 
                </tr> 
            ));        
        }
    }

    function getDetailSwitchTR(currentEntity) {
        const oRequestList = sliderValue?.filter((oRec) => oRec.PolicyID === currentEntity.PolicyID);
        const policyGroups = grouppolicies?.filter((oRec) => (oRec.PolicyID === currentEntity.PolicyID));
        if (oRequestList[0]?.showDetails) {
            return (
                <tr id={`tr${'detail' + currentEntity.PolicyID}`}>
                    <td colSpan="5"> 
                        <table class="table table-sm caption-top">  
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th style={styles.tableTextLeft}>Groups</th>
                                    <th style={styles.tableTextRight}>{displayAddButtons(2, currentEntity)}</th>
                                </tr>
                            </thead>                        
                            <tbody> 
                                <tr>
                                    <td colSpan="6" style={{backgroundColor:companyData?.PagesBGColor}}> 
                                    {displayNewGroupPolicy(currentEntity)}
                                    {(policyGroups.length === 0) ? <></> :
                                        <table class="table table-sm caption-top">  
                                            <thead>
                                                <tr>
                                                    <th style={styles.tableText}>#</th>
                                                    <th style={styles.tableText}>ID</th>
                                                    <th style={styles.tableTextLeft}>Group</th>
                                                    <th style={styles.tableText}>Is User</th>
                                                    <th style={styles.tableTextMain}>Permission Type</th>
                                                    <th style={styles.tableText}>Action</th>
                                                </tr>
                                            </thead>                        
                                            <tbody> 
                                                {getGroupPolicy(policyGroups)}  
                                            </tbody> 
                                        </table> 
                                        }
                                    </td> 
                                </tr>             
                            </tbody>
                        </table>    
                    </td> 
            </tr>             
            );
        }
    }


    return (
        <>
        <AuditTrail showFromAudit={showAudit} handleParentAuditClose={handleCloseAudit} AuditEntityTypeID={AuditEntityType.Policy} EntityID={auditEntityID}/>

        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          

                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {displayNewPolicy()}                                               
                            {((!policy) || (policy.length === 0)) ? (             
                                <div style={styles.tableText}>
                                    No Policies.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableTextMain}>Name</th>
                                                <th style={styles.tableText}>Type</th>
                                                <th style={styles.tableText}>Menu</th>
                                                <th style={styles.tableText}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {policy && policy.map((oRec, index) => {
                                                return (
                                                    <>
                                                    <tr>
                                                        <td style={styles.tableText}>{oRec?.PolicyID}</td>
                                                        <td style={styles.tableTextSubHeader}>{oRec?.PolicyDescription}
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input" type="checkbox" role="switch" 
                                                                    checked={getDetailSwitchValue(oRec?.PolicyID)} id={`switch${'odetail' + oRec?.PolicyID}`} 
                                                                    style={(getDetailSwitchValue(oRec?.PolicyID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                                                    onChange={(e) => {handleSwitchDetailChange(oRec?.PolicyID)}}>
                                                                </input>
                                                                <label class="form-check-label" htmlFor={`switch${'detail' + oRec?.PolicyID}`}>Details</label>
                                                            </div>
                                                        </td>
                                                        <td style={styles.tableTextLeft}>{oRec?.PolicyTypeName}</td>
                                                        <td style={styles.tableTextLeft}>{oRec?.MenuName}</td>
                                                        <td style={styles.tableText10}>{checkMainPolicy(1, oRec?.PolicyID, -1)}</td>
                                                    </tr>
                                                    {getDetailSwitchTR(oRec)}
                                                    </>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{policies?.policies?.RecordFound.toLocaleString()}   Policies&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(policies?.policies?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(policies?.policies?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {policies?.policies?.PagingMetaData?.PageNumber}  of  {policies?.policies?.PagingMetaData?.TotalPage.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                </Card>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state?.auth?.profile,
      policies: state.entity?.policies, 
      userattributelookups: state.entity.userattributelookups,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPolicies: (oParams) => dispatch(getPolicies(oParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        insertPolicy: (oParams) => dispatch(insertPolicy(oParams)),
        deletePolicy: (oParams) => dispatch(deletePolicy(oParams)),
        updatePolicy: (oParams) => dispatch(updatePolicy(oParams)),
        insertGroupPolicy: (oParams) => dispatch(insertGroupPolicy(oParams)),
        deleteGroupPolicy: (oParams) => dispatch(deleteGroupPolicy(oParams)),
        assignLookupSelection: (id, value) => dispatch(assignLookupSelection(id, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PolicyList)