import React, { useState, useEffect } from 'react';
import { Button, Modal, Table, Card } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuditTrail } from '../../store/actions/entityActions';
import Spinner from '../layout/Spinner';

const AuditTrail = (props) => {
    const navigate = useNavigate();
    const { auth, companyData, audittrail, showFromAudit, AuditEntityTypeID, EntityID, handleParentAuditClose} = props;
    const [loading, setLoading] = useState(false);
    const [showAudit, setShowAudit] = useState(false);
    const handleCloseAudit = () => {setShowAudit(false); handleParentAuditClose();}
    function handleShowAudit() {
        setShowAudit(true); 
    };

    useEffect(() => {
        setShowAudit(showFromAudit);
        if (showFromAudit === true) {
            handleAuditClick();
        }
    }, [showFromAudit])  

    async function handleAuditClick() {
        setLoading(true);
        const oParams = {
            "AuditEntityTypeID": AuditEntityTypeID,
            "EntityID": EntityID,
        }
        console.log(oParams);
        await props.getAuditTrail(oParams);
        handleShowAudit();
        setLoading(false);
    }

    function displayAuditTrail() {
        return (
            (((audittrail) && (audittrail?.audittrail?.AuditTrails?.length)) === 0) ? 
                <>No Audit Trail</> 
                : 
                <Table responsive="sm" striped bordered hover variant="light"> 
                    <thead>
                        <tr>
                            <th style={styles.tableText}>#</th>
                            <th style={styles.tableText}>Date</th>
                            <th style={styles.tableText}>Column</th>
                            <th style={styles.tableText}>Old Value</th>
                            <th style={styles.tableText}>New Value</th>
                            <th style={styles.tableText}>Updated By</th>
                        </tr>
                    </thead>                        
                    <tbody> 
                        {audittrail?.audittrail?.AuditTrails?.map((oRow, rowIndex) => (
                            <tr> 
                                <td style={styles.tableText}>{rowIndex + 1}</td> 
                                <td style={styles.tableText}>{oRow?.AuditDate}</td> 
                                <td style={styles.tableText}>{oRow?.ColumnName}</td> 
                                <td style={styles.tableText}>{oRow?.OldValue}</td> 
                                <td style={styles.tableText}>{oRow?.NewValue}</td> 
                                <td style={styles.tableText}>
                                    {(oRow?.GhostedUserFullName && (oRow?.GhostedUserFullName !== '') && (oRow?.GhostedUserFullName !== ',')) ?
                                    oRow?.UpdatedUserFullName + ' (Ghosted By - ' + oRow?.GhostedUserFullName + ')'
                                    :
                                    oRow?.UpdatedUserFullName
                                    }
                                </td> 
                            </tr> 
                        ))}
                    </tbody>
                </Table>                                   
        );
    }    

    return (
        <>
        {(loading) ? (            
            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                <Spinner/>
            </div>
        ) 
        : 
        (                                          
            <Modal style={{background:'transparent', maxHeight:'100%'}} show={showAudit} fullscreen backdrop="static" size="sm" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseAudit}>
                <Modal.Header closeButton>
                <Modal.Title>Audit Trail</Modal.Title>
                </Modal.Header>
                <Modal.Body>{displayAuditTrail()}</Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleCloseAudit}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        )}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      audittrail: state.entity.audittrail,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAuditTrail: (oParams) => dispatch(getAuditTrail(oParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditTrail)