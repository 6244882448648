import React, { useRef, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { styles } from '../layout/styles';
import { Button, Card, CardBody, Modal, Table, Form } from 'react-bootstrap';
import { updateEvaluationResponses, updateUserEvaluationSession, getUserAttributesLookups, getUsersByPolicy, getAttachments, insertAttachmentForm, deleteAttachment } from '../../store/actions/entityActions'
import { getUserEvaluationSessions360 } from '../../store/actions/authActions'
import moment from 'moment';
import { Stepper, Step } from 'react-form-stepper';
import {BackupManagerRights} from '../../config/Policies'
import {RetentasType, EvaluationType, AttachmentType, EntityRowType, AcceptableAttachments} from '../../config/Constant'
import Attachment from '../layout/Attachment';

function UserEvaluationSession (props) {
    const { id } = useParams();
    const { auth, companyData, evaluation, evaluationquestions, evaluationratings, UserEvaluationSession360, userattributelookups, userevaluationsessionstep, 
        userevaluationsessions, launchuserevaluation, launchuserevaluationsession, userDetail, userlookup, attachments } = props;
    const location  = useLocation();
    const processleveltypeid = location.state.processleveltypeid;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showMsg, setshowMsg] = useState(false);
    const [formValidationMessage, setFormValidationMessage] = useState(false);
    const handleCloseMsg = () => {setshowMsg(false);}
    function handleShowMsg() {
        setshowMsg(true); 
    };
   
    const [publishEvaluation, setpublishEvaluation] = useState(false);
    const [launchUserEvalRow, setLaunchUserEvalRow] = useState(null);
    const [currentUESStep, SetCurrentUESStep] = useState(-1);
    const [currentUESUserID, SetCurrentUESUserID] = useState(-1);
    const [currentUESStepRow, SetCurrentUESStepRow] = useState(null);
    const [plsValues, setPlsValues] = useState([]);
    const [isEmployeeSectionDisabled, setIsEmployeeSectionDisabled] = useState(true);
    const [isManagerSectionDisabled, setIsManagerSectionDisabled] = useState(true);
    const [isHRSectionDisabled, setIsHRSectionDisabled] = useState(true);
    const [isEmployeeSectionVisible, setIsEmployeeSectionVisible] = useState(false);
    const [isManagerSectionVisible, setIsManagerSectionVisible] = useState(false);
    const [isHRSectionVisible, setIsHRSectionVisible] = useState(false);
    const [isAttachmentSectionDisabled, setIsAttachmentSectionDisabled] = useState(false);
    const [isAttachmentSectionVisible, setIsAttachmentSectionVisible] = useState(false);
    const [isSignOffStep, setIsSignOffStep] = useState(false);
    const [isPublishStep, setIsPublishStep] = useState(false);
    const [nextStepButtonText, setNextStepButtonText] = useState('');
    const [showAlertModal, setshowAlertModal] = useState(false);
    const [header, setHeader] = useState((processleveltypeid == RetentasType.Surveys) ? 'Employee Engagement Details' : 'Performance Details');
    const [employeeComments, setEmployeeComments] = useState(null);
    const [managerComments, setManagerComments] = useState(null);
    const [hrComments, setHRComments] = useState(null);
    const [employeeDigSign, setEmployeeDigSign] = useState(false);
    const [managerDigSign, setManagerDigSign] = useState(false);
    const [score, setScore] = useState(null);
    const [weight, setWeight] = useState(null);
    const [meritBased, setMeritBased] = useState(false);
    const [compensationRate, setCompensationRate] = useState(null);
    const [completedDate, setCompletedDate] = useState(null);
    const [backupManagerUserID, setBackupManagerUserID] = useState(null);
    const [isGoToNextStep, setIsGoToNextStep] = useState(false);
    const [isPublishClicked, setIsPublishClicked] = useState(false);
    const [isEmployeePublishClicked, setIsEmployeePublishClicked] = useState(null);
    const [isManagerPublishClicked, setIsManagerPublishClicked] = useState(null);
    const [isApproverPublishClicked, setIsApproverPublishClicked] = useState(null);
    const [isHRPublishClicked, setIsHRPublishClicked] = useState(null);
    const [validateEmployeeSignOff, setValidateEmployeeSignOff] = useState(false); 
    const [validateManagerSignOff, setValidateManagerSignOff] = useState(false); 
    const [isCompletedEvaluation, setIsCompletedEvaluation] = useState(false); 
    const [attachmentName, setAttachmentName] = useState('');
    const [attachmentFile, setAttachmentFile] = useState(null);

    const processStepData = userattributelookups?.ProcessSteps;
    
    function handleAlertModalClose() { 
        setshowAlertModal(false);
    }
    
    function handleAlertModalShow() { setshowAlertModal(true);}
    
    useEffect(() => {
        if ((id) && (id > 0)) {
            if (UserEvaluationSession360 != null) {
                var olaunchUserEvalRow = UserEvaluationSession360?.filter((usereval) => (parseInt(usereval.UserEvaluationSessionID) === parseInt(id)));
                if ((olaunchUserEvalRow) && (olaunchUserEvalRow.length > 0)) {
                    setHeader(olaunchUserEvalRow[0].AssignmentName + ' - ' + olaunchUserEvalRow[0].UserFullName);
                    extractPLSValues(olaunchUserEvalRow[0].ProcessLevels);
                    SetCurrentUESUserID(olaunchUserEvalRow[0].UserID);
                    setLaunchUserEvalRow(olaunchUserEvalRow);
                    setEmployeeComments(olaunchUserEvalRow[0]?.AssignedUserComments);
                    setManagerComments(olaunchUserEvalRow[0]?.SupervisorComments);
                    setHRComments(olaunchUserEvalRow[0]?.HRComments);
                    setIsEmployeePublishClicked(olaunchUserEvalRow[0]?.IsAssignedUserPublished);
                    setIsManagerPublishClicked(olaunchUserEvalRow[0]?.IsSupervisorPublished);
                    setIsHRPublishClicked(olaunchUserEvalRow[0]?.IsHRPublished);
                    setIsPublishClicked(olaunchUserEvalRow[0]?.IsPublished);
                    var bCompletedEvaluation = false;
                    if ((olaunchUserEvalRow[0]?.IsPublished) && (olaunchUserEvalRow[0]?.IsPublished === true)) {
                        setIsCompletedEvaluation(true);
                        bCompletedEvaluation = true;
                    }
                    // console.log('Current UES - ', olaunchUserEvalRow);
                    let currentActiveStep = 0;
                    let currentUESStepRow = null;
                    if (userevaluationsessionstep && userevaluationsessionstep.length > 0) {
                        userevaluationsessionstep.map((oRow) => {
                            if ((oRow.IsComplete != null) && (oRow.IsComplete)) {
                                currentActiveStep++;
                            } else if ((oRow.IsDeleted === null) || ((oRow.IsDeleted != null) && (!oRow.IsDeleted))) {
                                currentUESStepRow = oRow;
                                // console.log('Current Step - ', oRow);
                            }
                        });
                        SetCurrentUESStep(currentActiveStep);
                        SetCurrentUESStepRow(currentUESStepRow);
                        // Check for signoff and publish step
                        const oStepRow = processStepData?.filter((step) => (parseInt(step.ProcessStepID) === parseInt(currentUESStepRow?.StepID)));
                        if ((oStepRow) && (oStepRow.length > 0)) {
                            if ((oStepRow[0].IsSignOffStep != null) && (oStepRow[0].IsSignOffStep)) {
                                setIsSignOffStep(true);
                                if (olaunchUserEvalRow[0].UserEvaluationTypeID === 1) {
                                    setValidateEmployeeSignOff(true);
                                } else if (olaunchUserEvalRow[0].UserEvaluationTypeID === 2) {
                                    setValidateManagerSignOff(true);
                                } 
                            }
                            if ((oStepRow[0].IsPublishStep != null) && (oStepRow[0].IsPublishStep)) {
                                setIsPublishStep(true);
                            }
                            // Get next stepID from PLSValues.
                            const params = new URLSearchParams(olaunchUserEvalRow[0].ProcessLevels);
                            // Get the value of the 'PLS' key
                            const oPlsValues = params.get('PLS');
                            const iNextStepID = getNextStepId(oPlsValues.split(','), currentUESStepRow?.StepID);
                            // console.log('Next Step - ', iNextStepID);
                            const oNextStepRow = processStepData?.filter((step) => (parseInt(step.ProcessStepID) === parseInt(iNextStepID)));
                            if ((oNextStepRow) && (oNextStepRow.length > 0)) {
                                setNextStepButtonText('Next Step - ' + oNextStepRow[0].ProcessStepName);
                            } else {
                                setNextStepButtonText('Submit/Complete Evaluation');
                            }
                        }
                    }

                    // console.log('Current UES Step - ', currentUESStepRow);
                    if (currentUESStepRow != null) {
                        // If Employee logs in
                        if ((parseInt(currentUESStepRow.UserEvaluationTypeID) === parseInt(EvaluationType.User)) && (parseInt(currentUESStepRow.UserEvaluationUserID) === parseInt(auth.UserID))) {
                            setIsEmployeeSectionDisabled(false);
                            setIsEmployeeSectionVisible(true);
                            setIsManagerSectionDisabled(true);
                            setIsManagerSectionVisible(false);
                            setIsHRSectionDisabled(true);
                            setIsHRSectionVisible(false);
                            setIsAttachmentSectionDisabled(false);
                            setIsAttachmentSectionVisible(true);
                        }
                        // If Manager logs in
                        if ((parseInt(currentUESStepRow.UserEvaluationTypeID) === parseInt(EvaluationType.Manager)) && (parseInt(currentUESStepRow.UserEvaluationUserID) === parseInt(auth.UserID))) {
                            setIsEmployeeSectionDisabled(true);
                            setIsEmployeeSectionVisible(true);
                            setIsManagerSectionDisabled(false);
                            setIsManagerSectionVisible(true);
                            setIsHRSectionDisabled(true);
                            setIsHRSectionVisible(false);
                            setIsAttachmentSectionDisabled(false);
                            setIsAttachmentSectionVisible(true);
                        }
                        // If HR logs in
                    }

                    // completed evaluation
                    if (bCompletedEvaluation) {
                        setIsEmployeeSectionDisabled(true);
                        setIsEmployeeSectionVisible(true);
                        setIsManagerSectionDisabled(true);
                        setIsManagerSectionVisible(true);
                        setIsAttachmentSectionDisabled(true);
                        setIsAttachmentSectionVisible(true);
                    }
                    
            }
        }
        }
    }, [])  

    async function extractPLSValues(queryString) {
        // Split the query string into individual key-value pairs
        const params = new URLSearchParams(queryString);
        
        // Get the value of the 'PLS' key
        const oPlsValues = params.get('PLS');
        
        // Check if 'PLS' exists and split it into an array
        if (oPlsValues) {
            setPlsValues(oPlsValues.split(',')); // Splitting by ',' to get individual entries
        } else {
            // Return an empty array if 'PLS' key is not present
            setPlsValues([]);
        }        

        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }            
        await props.getUsersByPolicy(BackupManagerRights);
    }

    function getNextStepId(array, currentStepId) {
        // Parse the array into objects for easier manipulation
        const steps = array.map(item => {
            const parts = item.split(":");
            return { stepId: parseInt(parts[1], 10) };
        });
    
        // Find the index of the current stepId
        const currentIndex = steps.findIndex(step => step.stepId === currentStepId);
    
        // If the current index is valid and there's a next step, return it
        if (currentIndex !== -1 && currentIndex < steps.length - 1) {
            return steps[currentIndex + 1].stepId;
        }
    
        // Otherwise, return null
        return null;
    }

    async function handleAlertModalYes() { 
        setshowAlertModal(false);
        const userEvalSessionParams = [{
            UserEvaluationSessionID:id,
            IsQuestionnaire:false,
            IsPRF:true,
            GoToNextStep:isGoToNextStep,
            UserID:launchUserEvalRow[0].UserID,
            CompletedDate:completedDate, 
            IsPublished:isPublishClicked,
            Score:score,
            Weight:weight,
            MeritBased:meritBased,
            CompensationRate:compensationRate,
            IsSetReady:null,
            UserComments:employeeComments,
            ManagerComments:managerComments,
            HRComments:hrComments,
            UserPublished:isEmployeePublishClicked,
            UserDenied:null,
            ManagerPublished:isManagerPublishClicked,
            ManagerDenied:null,
            HRPublished:isHRPublishClicked,
            HRDenied:null,
            ApproverPublished:isApproverPublishClicked,
            ApproverDenied:null,
            BackupManagerUserID:backupManagerUserID,
            ChangeManagerUserID:null,
            DataSyncOut:'',
            DataSyncMessageOut:''
        }];
        console.log('Update UES Params - ', userEvalSessionParams);
        await props.updateUserEvaluationSession(userEvalSessionParams);
        await props.getUserEvaluationSessions360(auth.UserID);
        navigate('/questionnaires', {state: {processleveltypeid:RetentasType.PRFs}});
    }

    function handleAlertModalNo() { 
        setshowAlertModal(false);
        console.log('Do not update user evaluation session.');
        navigate('/questionnaires', {state: {processleveltypeid:RetentasType.PRFs}});
    }

    function handleCancel() {
        if (processleveltypeid == RetentasType.Surveys) {
            navigate('/questionnaires', {state: {processleveltypeid:RetentasType.Surveys}});
        } else {
            navigate('/questionnaires', {state: {processleveltypeid:RetentasType.PRFs}});
        }
    }

    function handleNextStepClick (evalTypeID) {
        setIsGoToNextStep(true);
        if ((validateEmployeeSignOff) && (!HasEmployeeSignedOff())) {
            setFormValidationMessage('Sign off needed to complete this step!');
            setshowMsg(true);
        } else if ((validateManagerSignOff) && (!HasManagerSignedOff())) {
            setFormValidationMessage('Sign off needed to complete this step!');
            setshowMsg(true);            
        } else {
            handleAlertModalShow();
        }
    }

    function handlePublishEvaluationClick (evalTypeID) {
        setCompletedDate(moment().toDate());
        if (evalTypeID === EvaluationType.User) {
            setIsGoToNextStep(true);
            setIsEmployeePublishClicked(true);
        } else if (evalTypeID === EvaluationType.Manager) {
            setIsManagerPublishClicked(true);
        } else if (evalTypeID === EvaluationType.HR) {
            setIsHRPublishClicked(true);
        }
        handleAlertModalShow();
    }

    function handleSubmit(e) {
        e.preventDefault();
    }

    function HasEmployeeSignedOff() {
        var bSignOff = false;
        if (employeeDigSign) {
            bSignOff = true;
            setIsEmployeePublishClicked(true);
            setCompletedDate(moment().toDate());
            if (isPublishStep) {
                setIsPublishClicked(true);
            }
        }
        return bSignOff;
    }

    function HasManagerSignedOff() {
        var bSignOff = false;
        if (managerDigSign) {
            bSignOff = true;
            setIsManagerPublishClicked(true);
            setCompletedDate(moment().toDate());
            if (isPublishStep) {
                setIsPublishClicked(true);
            }
        }
        return bSignOff;
    }

    async function handleAddAttachmentClick(){
        setLoading(true);
        const formData = new FormData();
        formData.append("attachment", attachmentFile);
        formData.append("AttachmentName", attachmentName);
        formData.append("AttachmentTypeID", '5');
        formData.append("EntityRowTypeID", '8');
        formData.append("EntityID", id);
        formData.append("FileSize", attachmentFile[0]?.size);
        formData.append("AttachmentFileExt", attachmentFile?.name.slice((attachmentFile?.name.lastIndexOf(".") - 1 >>> 0) + 2));
        formData.append("UserID", currentUESUserID);
        formData.append("CompanyID", companyData?.CompanyID);
        console.log(attachmentFile?.name.slice((attachmentFile?.name.lastIndexOf(".") - 1 >>> 0) + 2));
        await props.insertAttachmentForm(formData);
        const oAParams  = {
            UserID:auth.UserID,
            EntityID: id,
            AttachmentTypeID: AttachmentType.PRF,
            EntityRowTypeID: EntityRowType.PRF,
            SearchFilter:'',
        };                
        await props.getAttachments(oAParams);    
        setLoading(false);
    }

    async function handleDeleteAttachment(iAttachmentID, sAttachmentFileName) {
        setLoading(true);
        const oADParams  = {
            AttachmentID: iAttachmentID,
            AttachmentFileName: sAttachmentFileName,
            CompanyID: companyData?.CompanyID,
        };                
        await props.deleteAttachment(oADParams);    
        const oAParams  = {
            UserID:auth.UserID,
            EntityID: id,
            AttachmentTypeID: AttachmentType.PRF,
            EntityRowTypeID: EntityRowType.PRF,
            SearchFilter:'',
        };                
        await props.getAttachments(oAParams);    
        setLoading(false);
    }    

    async function handleOpenAttachment(sURL) {
        setLoading(true);
        window.open(sURL, "_blank", "noreferrer");
        setLoading(false);
    }    

    function handleFileOnChange (e) {
        // const target = e.target as HTMLInputElement & {
        //     files: FileList;
        // }
        // console.log('target', e.target.files);
        setAttachmentFile(e.target.files[0]);
    }

    function displayProcessSteps() {
        if (launchUserEvalRow && launchUserEvalRow.length > 0) {
            return (
                <table class="table table-responsive table-sm">  
                    <tbody> 
                        <tr id={`tr${'detail' + launchUserEvalRow[0].UserEvaluationSessionID}`}> 
                            <td style={styles.tableText}>
                                <Stepper 
                                    activeStep={currentUESStep}
                                    styleConfig={{size:'1.6em', labelFontSize:'0.78rem', circleFontSize:'0.78rem', activeBgColor:companyData?.AccentColor, completedBgColor:companyData?.AccentColorSecondary, inactiveBgColor:companyData?.Grey}}>
                                        {plsValues.map((plsValue) => {
                                            let oStepItem = null; // Default to null
                                            let sStepName = '';
                                            const iStepID = parseInt(plsValue.split(':')[1]);
                                            const oStepRow = processStepData?.filter((step) => (parseInt(step.ProcessStepID) === parseInt(iStepID)));
                                            if ((oStepRow) && (oStepRow.length > 0)) {
                                                sStepName = oStepRow[0].ProcessStepName;
                                            }
                                            oStepItem = (
                                                <Step label={sStepName} />
                                            );
                                            return oStepItem;
                                        })}                                    
                                </Stepper>
                            </td> 
                        </tr>
                    </tbody>
                </table>    
            )            
        } else {
            return (<></>)
        }
    }

    function displayEmployeeSection() {
        if (isEmployeeSectionVisible) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                        <Card.Title style={styles.tableTextLeftBold}>
                            Employee Section                        
                        </Card.Title>
                    </Card.Header>                   
                    <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <fieldset disabled={isEmployeeSectionDisabled}>
                            <table class="table table-sm caption-top text-left">  
                                <thead>
                                </thead>                        
                                <tbody>
                                    {(isSignOffStep) ? 
                                        <tr>
                                            <td style={{textAlign:'right', width:'1%', backgroundColor:companyData?.PagesBGColor}}>
                                                <input type='checkbox' id='digsignemployee' style={styles.CheckBoxNoPadding} defaultChecked={isEmployeePublishClicked} onChange={(e) => (setEmployeeDigSign(e.target.value))}/>
                                            </td>
                                            <td style={{textAlign:'left', width:'79%', backgroundColor:companyData?.PagesBGColor}}>
                                                <label style={styles.LabelDataEntryNoPadding}>By checking this box I agree that I am applying my digital signature and I am agreeing that I have BOTH had an in-person Evaluation / Performance review meeting with my Supervisor AND have reviewed my Evaluation form.</label>
                                            </td>
                                        </tr>
                                    :
                                        <></>
                                    } 
                                    <tr>
                                        <td colSpan='3' style={{textAlign:'left', width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                                            <div>
                                                <label htmlFor="empcomments" style={styles.LabelDataEntryNoPadding}>Comments</label>
                                                <textarea id="empcomments" rows={5} style={styles.TextDataEntryNoPaddingHeight100} defaultValue={employeeComments} onChange={(e) => (setEmployeeComments(e.target.value))}/>
                                            </div>                    
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>
                    </Card.Body>
                </Card>        
            );    
        } else {
            return (<></>);
        }
    }

    function displayManagerSection() {
        if (isManagerSectionVisible) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                        <Card.Title style={styles.tableTextLeftBold}>
                            Manager Section                        
                        </Card.Title>
                    </Card.Header>                   
                    <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <fieldset disabled={isManagerSectionDisabled}>
                            <table class="table table-sm caption-top text-left">  
                                <thead>
                                </thead>                        
                                <tbody> 
                                    {(isPublishStep) ? 
                                        <tr>
                                            <td style={{textAlign:'right', width:'1%', backgroundColor:companyData?.PagesBGColor}}>
                                                <input type='checkbox' id='digsignmanager' style={styles.CheckBoxNoPadding} defaultChecked={isManagerPublishClicked} onChange={(e) => (setManagerDigSign(e.target.value))}/>
                                            </td>
                                            <td style={{textAlign:'left', width:'79%', backgroundColor:companyData?.PagesBGColor}}>
                                                <label style={styles.LabelDataEntryNoPadding}>By checking this box I agree that I am applying my digital signature and I am agreeing that I have completed this Evaluation form.</label>
                                            </td>
                                        </tr>
                                        :
                                        <></>
                                    } 
                                    <tr>
                                        <td colSpan='3' style={{textAlign:'left', width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                                            <div>
                                                <label htmlFor="mancomments" style={styles.LabelDataEntryNoPadding}>Comments</label>
                                                <textarea id="mancomments" type="text" rows={5} style={styles.TextDataEntryNoPaddingHeight100} defaultValue={managerComments} onChange={(e) => (setManagerComments(e.target.value))}/>
                                            </div>                    
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>
                    </Card.Body>
                </Card>        
            );    
        } else {
            return (<></>);
        }
    }

    function displayHRSection() {
        if (isHRSectionVisible) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                        <Card.Title style={styles.tableTextLeftBold}>
                            HR Section                        
                        </Card.Title>
                    </Card.Header>                   
                    <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <fieldset disabled={isHRSectionDisabled}>
                            <table class="table table-sm caption-top text-left">  
                                <thead>
                                </thead>                        
                                <tbody> 
                                    <tr>
                                        <td style={{textAlign:'left', width:'75%', backgroundColor:companyData?.PagesBGColor}}>
                                            <label style={styles.LabelDataEntryNoPadding}>Backup Manager</label>
                                            <select id="backupmanager" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} onChange={(e) => (setBackupManagerUserID(e.target.value))}>
                                                    {userlookup?.map((backupman, supIndex) => (
                                                        <option value={backupman.UserID}>{backupman.UserFullName}</option>        
                                                    ))}
                                                </select>                                     
                                        </td>
                                        <td style={{textAlign:'center', width:'25%', backgroundColor:companyData?.PagesBGColor}}>
                                            <Button 
                                                onClick={() => handleNextStepClick(EvaluationType.Manager)} 
                                                disabled={loading} 
                                                style={styles.CDPillButtonSMGrey} 
                                                title="Send To Backup Manager">
                                                Send To Backup Manager
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign:'left', width:'50%', backgroundColor:companyData?.PagesBGColor}}>
                                            <label htmlFor="compensationrate" style={styles.LabelDataEntryNoPadding}>Compensation Rate</label>
                                            <input id="compensationrate" type="number" max='100' min='0' style={styles.TextDataEntryNoPadding} defaultValue={compensationRate} onChange={(e) => (setCompensationRate(e.target.value))}/>
                                        </td>
                                        <td style={{textAlign:'left', width:'50%', backgroundColor:companyData?.PagesBGColor}}>
                                            <input type='checkbox' id='meritbased' style={styles.CheckBoxNoPadding} defaultValue={meritBased}/>
                                            <label style={styles.LabelDataEntryNoPadding}>&nbsp;&nbsp;Merit based</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan='2' style={{textAlign:'left', width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                                            <div>
                                                <label htmlFor="hrcomments" style={styles.LabelDataEntryNoPadding}>Comments</label>
                                                <textarea id="hrcomments" type="text" rows={5} style={styles.TextDataEntryNoPaddingHeight100} defaultValue={hrComments} onChange={(e) => (setHRComments(e.target.value))}/>
                                            </div>                    
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan='2' style={{textAlign:'left', width:'100%', backgroundColor:companyData?.PagesBGColor}}>
                                            <div class="row d-flex justify-content-center align-items-center">
                                                <div class="col-auto">
                                                    <Button 
                                                        onClick={() => handlePublishEvaluationClick(EvaluationType.HR)} 
                                                        disabled={loading} 
                                                        style={styles.CDPillButtonSMGrey}
                                                        title='Submit/Complete Evaluation'>
                                                        Submit/Complete Evaluation
                                                    </Button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>
                    </Card.Body>
                </Card>        
            );    
        } else {
            return (<></>);
        }
    }

    function displayNextStepButtonSection() {
        if (isCompletedEvaluation) {
            return (<></>);
        } else {
            return (
                <div class="row d-flex justify-content-center align-items-center">
                    <div class="col-auto">
                        <Button 
                            onClick={() => handleNextStepClick(EvaluationType.User)} 
                            disabled={loading} 
                            style={styles.CDPillButtonSMGrey} 
                            title={nextStepButtonText}>
                            {nextStepButtonText}
                        </Button>
                    </div>
                </div>
            );            
        }
    }

    function displayAttachmentSection() {
        if (isAttachmentSectionVisible) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                    <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                        <Card.Title style={styles.tableTextLeftBold}>
                            Attachment Section                                                    
                        </Card.Title>
                        <fieldset disabled={isAttachmentSectionDisabled}>
                            <table class="table table-sm caption-top text-left">  
                                <tbody>
                                    <tr>
                                        <td style={{textAlign:'left', width:'50%', backgroundColor:companyData?.PagesBGColor}}>
                                            <div>
                                                <label htmlFor="attachmentname" style={styles.LabelDataEntryNoPadding}>Name</label>
                                                <input id="attachmentname" type="text" style={styles.TextDataEntryNoPaddingWBG} defaultValue={attachmentName} onChange={(e) => (setAttachmentName(e.target.value))}/>
                                            </div>                    
                                        </td>
                                        <td style={{textAlign:'left', width:'45%', backgroundColor:companyData?.PagesBGColor}}>
                                            <div>
                                                <Form.Group controlId="formFile" className="mb-3">
                                                    <Form.Label style={styles.LabelDataEntryNoPadding}>File Upload</Form.Label>
                                                    <Form.Control style={styles.TextDataEntryNoPaddingWBG} type="file" onChange={handleFileOnChange}
                                                        accept={AcceptableAttachments}
                                                    />
                                                </Form.Group>
                                            </div>                    
                                        </td>
                                        <td style={{textAlign:'center', width:'5%', backgroundColor:companyData?.PagesBGColor}}>
                                            <div>
                                                <label style={styles.LabelDataEntryNoPadding}>Add</label>
                                            </div>
                                            <div>
                                                <a onClick={() => handleAddAttachmentClick()}>
                                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Attachment'>add_circle</span> 
                                                </a> 
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>
                    </Card.Header>                   
                    <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <fieldset disabled={isAttachmentSectionDisabled}>
                            <table class="table table-sm caption-top text-left">  
                                <thead>
                                    <tr>
                                        <th style={styles.tableText}>#</th>
                                        <th style={styles.tableTextMain}>Name</th>
                                        <th style={styles.tableText}>Type</th>
                                        <th style={styles.tableText}>View</th>
                                        <th style={styles.tableText}>Delete</th>
                                    </tr>
                                </thead>                            
                                <tbody>
                                    {(attachments && attachments.map((attachment, index) => {
                                        return (<tr>
                                            <td style={styles.tableText}>{index+1}</td>
                                            <td style={styles.tableText}>{attachment?.AttachmentName}</td>
                                            <td style={styles.tableText}>{(attachment?.AttachmentTypeID === 1) ? 'User Document' : 'Performance Document'}</td>
                                            <td style={styles.tableText}>
                                                <a onClick={() => handleOpenAttachment(attachment?.StorageURL)}>
                                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Open Attachment'>open_in_browser</span>
                                                </a>
                                            </td>
                                            <td style={styles.tableText}>
                                                <a onClick={() => handleDeleteAttachment(attachment?.AttachmentID, attachment?.AttachmentFileName)}>
                                                    <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Attachment'>delete</span>
                                                </a>
                                            </td>
                                        </tr>)
                                    }))
                                    }
                                </tbody>
                            </table>
                        </fieldset>
                    </Card.Body>
                </Card>        
            );    
        } else {
            return (<></>);
        }
    }

    return (
        <div style={styles.MainInnerDiv}>
            <Modal style={{background:'transparent'}} aria-labelledby="contained-modal-title-vcenter" centered 
                show={showAlertModal} onHide={handleAlertModalClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton={false}>
            <Modal.Title>Processing your request!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Do you want to submit this change?</p>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" style={{backgroundColor:companyData?.AccentColor}} onClick={handleAlertModalYes}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleAlertModalNo}>
                No
            </Button>
            </Modal.Footer>
            </Modal>        

            <Modal style={{background:'transparent'}} show={showMsg} backdrop="static" size="sm" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleCloseMsg}>
                <Modal.Header closeButton>
                <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>{formValidationMessage}</Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleCloseMsg}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>


            <div style={styles.DashboardContainer}>
                <form onSubmit={handleSubmit}>
                    <Card style={{ alignContent:'center', alignItem:'center', justifyContent: "center", backgroundColor:companyData?.PagesBGColor}}>
                        <Card.Header className='text-center'> 
                            <Card.Title>
                                <div>
                                    <Table responsive="sm" style={{backgroundColor:companyData?.PagesBGColor}}>
                                        <tbody>
                                        <tr>
                                            <td style={{textAlign:'left', width:'85%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>{header}</h3></td>
                                            <td style={{textAlign:'center', width:'15%', backgroundColor:companyData?.PagesBGColor}}><Button disabled={loading} style={styles.CDFullButton} onClick={() => handleCancel()}>Cancel</Button></td>
                                        </tr>
                                        </tbody>
                                    </Table>                            
                                </div>
                            </Card.Title>
                        </Card.Header>                    
                        <Card.Body>
                            <div >
                                <Card style={{ alignContent:'center', alignItem:'center', justifyContent: "center", backgroundColor:companyData?.PagesBGColor}}>
                                    <Card.Header style={{backgroundColor:companyData?.PagesBGColor}}> 
                                        <Card.Title>
                                            {displayProcessSteps()}
                                        </Card.Title>
                                    </Card.Header>                    
                                    <CardBody style={{backgroundColor:companyData?.PagesBGColor}}>
                                        {displayEmployeeSection()}
                                        {displayManagerSection()}
                                        {displayHRSection()}
                                        {/* {displayAttachmentSection()} */}
                                        <Attachment isAttachmentVisible={isAttachmentSectionVisible} isAttachmentDisable={isAttachmentSectionDisabled} iAttachmentTypeID={AttachmentType.PRF} iEntityRowTypeID={EntityRowType.PRF} iEntityID={id}/>
                                    </CardBody>
                                    <Card.Footer className="text-center" style={{backgroundColor:companyData?.PagesBGColor}}>
                                        {displayNextStepButtonSection()}
                                    </Card.Footer>
                                </Card>
                            </div>
                        </Card.Body>
                    </Card>
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
      auth: state.auth.user,
      userDetail: state.auth.userDetail,
      companyData: state?.auth?.companyData,
      evaluation: state.entity.evaluation,
      evaluationquestions: state.entity.evaluationquestions,
      evaluationratings: state.entity.evaluationratings,
      userevaluationsessions: state.entity.userevaluationsessions, 
      launchuserevaluation: state.entity.userevaluation?.UserEvaluation, 
      launchuserevaluationsession: state.entity.userevaluation?.UserEvaluationSession, 
      UserEvaluationSession360: state?.auth?.userDetail?.UserEvaluationSession360,
      userattributelookups: state.entity.userattributelookups,
      userevaluationsessionstep:state.entity.userevaluation?.UserEvaluationSessionStep,
      userlookup:state.entity.userlookup,
      attachments:state.entity.attachments?.attachments,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateEvaluationResponses: (evaluationResponses) => dispatch(updateEvaluationResponses(evaluationResponses)),
        updateUserEvaluationSession: (evaluationSession) => dispatch(updateUserEvaluationSession(evaluationSession)),
        getUserEvaluationSessions360: (id) => dispatch(getUserEvaluationSessions360(id)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        getUsersByPolicy: (id) => dispatch(getUsersByPolicy(id)),        
        getAttachments: (oAParams) => dispatch(getAttachments(oAParams)),
        insertAttachmentForm: (formData) => dispatch(insertAttachmentForm(formData)),
        deleteAttachment: (oAParams) => dispatch(deleteAttachment(oAParams)),        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEvaluationSession)

