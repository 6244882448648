import React, {useState, useEffect} from 'react'
import UserList from './UserList'
import { styles } from '../layout/styles'
import {TriggeredFrom} from '../../config/Constant';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { useLocation } from 'react-router-dom';

const MyTeam = (props) => {
    const { myTeam, loadingMenu } = props;
    const [loading, setLoading] = useState(false);
    const location  = useLocation();

    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    if (!myTeam) {
        return (
            <div>Loading my team details...</div>
        )
    } else {
        if (loading) {
            return (
                <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                    <Spinner/>
                </div>
            )
        } else {
            return (
                <div style={styles.MainInnerDiv}>
                    <div style={styles.DashboardContainer}>
                        <UserList myTeam={myTeam}  triggeredFrom={TriggeredFrom.Menu} permissiontypeid={location.state.permissiontypeid}/>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      myTeam: state.auth.myTeam,
      loadingMenu: state.auth.loadingMenu,
    }
}

export default connect(mapStateToProps, null)(MyTeam)

