import React, { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';
import { connect } from 'react-redux';
import { styles } from '../layout/styles';
import { Image, Dropdown, NavItem, NavDropdown } from 'react-bootstrap';
import { assignActiveMenu, loadingMenuSelection, signOut, getOrgDivisions, getReports, getMyTeamFull, getDivisionManagers, getOrgJobClasses } from '../../store/actions/authActions';
import { getEvaluations, getAssignments, getJobPositions, clearEntityState, getGoals, getCompetencies, 
        getSkills, getSkillChecklist, getNotificationMessages, getProcessSteps, getReminders, getAttachments, getPolicies, getGroups, getMenus, 
        getUserAttributesLookups } from '../../store/actions/entityActions';
import { ThirdPartyLMSEvaluations, RetentasType, MenuType, 
        CheckListGroupBy, AttachmentType, EntityRowType } from '../../config/Constant';
import Spinner from '../layout/Spinner';
import { CompanyData } from '../../config/Colors';
// import { companyLogo, companyNameLogo, companySignLogo } from '../../config/Colors';


const Navbar = (props) => {
    const { auth, profile, currentActiveMenuItem, companyID, companyData, myMenus, userattributelookups } = props;
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    function isMenuVisible(menuID) {
        let iVisible = false;
        // Filter myMenus to find matching records
        const oMenuRec = myMenus?.filter((mRec) => mRec.MenuID === menuID) || [];
        if (oMenuRec.length > 0) {
            iVisible = oMenuRec[0].Show;
        }
        return iVisible;
    }

    function getMenuPermissionType(menuID) {
        let iPermissionTypeID = 1;
        // Filter myMenus to find matching records
        const oMenuRec = myMenus?.filter((mRec) => mRec.MenuID === menuID) || [];
        if (oMenuRec.length > 0) {
            iPermissionTypeID = oMenuRec[0].PermissionTypeID;
        }
        return iPermissionTypeID;
    }

    async function handleLogout(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.Logout);
        await props.clearEntityState();
        await props.signOut();
        if ((companyData?.CompanyADIntegrated) && (companyData?.IDPService != '') && (companyData?.CompanyLogoutPage != '')) {
            props.loadingMenuSelection(false);
            // organization home/login/logout page
            window.location.href = companyData?.CompanyLogoutPage;        
        } else {
            navigate('/Login/' + companyID);
            props.loadingMenuSelection(false);
        }
        setLoading(false);
    }  

    async function handleDashboard(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.Home);
        navigate('/');
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleQuestionnaires(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.Questionnaire);
        navigate('/questionnaires', {state: {processleveltypeid:RetentasType.Surveys}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handlePerformance(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.Performance);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }
        // navigate('/performance', {state: {processleveltypeid:ThirdPartyLMSEvaluations}});
        navigate('/performance', {state: {processleveltypeid:RetentasType.PRFs}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleTeam(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.MyTeam);
        const oParams = {
            UserID:auth?.UserID,
            ScoredProbabilities:'AtRisk',
            RecordSize:'10',
            PageNumber:1,
        }; 
        await props.getMyTeamFull(oParams);
        const iPermissionTypeID = getMenuPermissionType(MenuType.UserAccounts);
        navigate('/myTeam', {state: {permissiontypeid:iPermissionTypeID}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handlePillars(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        let companyPillars = '';
        const companyParameters = auth.UserCompanyParameters?.split('&');
        companyParameters.map((parameter) => {
            const parameterDetails = parameter?.split('=');
            if (parameterDetails[0] === 'REACT_APP_EVALUATIONS') {
                companyPillars = parameterDetails[1];
            }
            if (parameterDetails[0] === 'PERFORMANCEEVALUATIONS') {
                companyPillars += ',' + parameterDetails[1];
            }
        });
        props.assignActiveMenu(MenuType.Pillars);
        const evalParams  = {
            UserID:auth.UserID,
            EvaluationIDs:companyPillars,
            SearchFilter:'',
        };        
        // console.log(evalParams);
        await props.getEvaluations(evalParams);
        const iPermissionTypeID = getMenuPermissionType(MenuType.Pillars);
        navigate('/evaluations', {state: {permissiontypeid:iPermissionTypeID}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleGoals(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.Goals);
        const evalParams  = {
            UserID:auth.UserID,
            SearchFilter:'',
        };        
        await props.getGoals(evalParams);
        const iPermissionTypeID = getMenuPermissionType(MenuType.Goals);
        navigate('/goals', {state: {permissiontypeid:iPermissionTypeID}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleCompetencies(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.Competencies);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getSkills(oParams);
        const iPermissionTypeID = getMenuPermissionType(MenuType.Competencies);
        navigate('/competencies', {state: {fromLookup:false, permissiontypeid:iPermissionTypeID}});
        
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleNotificationMessages(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.NotificationMessage);
        const oParams  = {
            UserID:auth.UserID,
            SearchFilter:'',
        };        
        await props.getNotificationMessages(oParams);
        const iPermissionTypeID = getMenuPermissionType(MenuType.NotificationMessage);
        navigate('/notificationmessagelist', {state: {permissiontypeid:iPermissionTypeID}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleReminders(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.Reminder);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };              
        await props.getReminders(oParams);
        const iPermissionTypeID = getMenuPermissionType(MenuType.Reminder);
        navigate('/reminderlist', {state: {permissiontypeid:iPermissionTypeID}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleProcessSteps(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.ProcessStep);
        const oParams  = {
            UserID:auth.UserID,
            SearchFilter:'',
        };        
        await props.getProcessSteps(oParams);
        const iPermissionTypeID = getMenuPermissionType(MenuType.ProcessStep);
        navigate('/processsteplist', {state: {permissiontypeid:iPermissionTypeID}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleCompetencyChecklist(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.CompetencyChecklist);
        const oParams = {
            CheckListGroupBy:CheckListGroupBy.All,
            UserID:auth.UserID,
            IsComplete:'false',
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getSkillChecklist(oParams);
        navigate('/competencychecklist', {state: {listby:CheckListGroupBy.All}});
        
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleRatings(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.Ratings);
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleAssignments(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        let companyPillars = '';
        const companyParameters = auth.UserCompanyParameters?.split('&');
        companyParameters.map((parameter) => {
            const parameterDetails = parameter?.split('=');
            if (parameterDetails[0] === 'REACT_APP_EVALUATIONS') {
                companyPillars = parameterDetails[1];
            }
            if (parameterDetails[0] === 'PERFORMANCEEVALUATIONS') {
                companyPillars += ',' + parameterDetails[1];
            }
        });
        props.assignActiveMenu(MenuType.Assignments);
        const evalParams  = {
            UserID:auth.UserID,
            EvaluationIDs:companyPillars,
        };        
        await props.getOrgDivisions(auth?.UserID);
        await props.getAssignments(evalParams);
        const goalParams  = {
            UserID:auth.UserID,
            SearchFilter:'',
        };        
        await props.getGoals(goalParams);
        const iPermissionTypeID = getMenuPermissionType(MenuType.Assignments);
        navigate('/assignments', {state: {permissiontypeid:iPermissionTypeID}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleDivisions(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.Divisions);
        await props.getOrgDivisions(auth?.UserID);
        // await props.getDivisionManagers(31449);
        navigate('/divisions');
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleJobPositions(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.JobPositions);
        const jpParams = {
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getJobPositions(jpParams);
        await props.getOrgJobClasses(auth?.UserID);
        navigate('/jobpositions', {state: {fromLookup:false}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleJobClassTree(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.JobClassTree);
        await props.getOrgJobClasses(auth?.UserID);
        navigate('/jobclasstree', {state: {fromLookup:false}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handlePolicies(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.Policy);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };              
        await props.getPolicies(oParams);
        const iPermissionTypeID = getMenuPermissionType(MenuType.Policy);
        navigate('/policylist', {state: {permissiontypeid:iPermissionTypeID}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleGroups(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.Group);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };              
        await props.getGroups(oParams);
        const iPermissionTypeID = getMenuPermissionType(MenuType.Group);
        navigate('/grouplist', {state: {permissiontypeid:iPermissionTypeID}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleMenus(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.Menu);
        const oParams = {
            RecordSize:'10',
            PageNumber:'1',
        };              
        await props.getMenus(oParams);
        const iPermissionTypeID = getMenuPermissionType(MenuType.Menu);
        navigate('/menulist', {state: {permissiontypeid:iPermissionTypeID}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleReports(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        props.assignActiveMenu(MenuType.Reports);
        // console.log(auth?.UserID);
        await props.getReports(auth?.UserID);
        navigate('/reports');
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    async function handleProfile(e) {
        setLoading(true);
        props.loadingMenuSelection(true);
        const oAParams  = {
            UserID:auth.UserID,
            EntityID: auth.UserID,
            AttachmentTypeID: AttachmentType.User,
            EntityRowTypeID: EntityRowType.User,
            SearchFilter:'',
        };                
        await props.getAttachments(oAParams);    
        await props.getUserAttributesLookups(auth?.UserID);
        const iPermissionTypeID = getMenuPermissionType(MenuType.UserAccounts);
        props.assignActiveMenu(MenuType.Profile);
        navigate('/updateProfile', {state: {permissiontypeid:iPermissionTypeID}});
        props.loadingMenuSelection(false);
        setLoading(false);
    }  

    function displayPillarsSubMenu(){
        if (isMenuVisible(MenuType.Pillars)) {
            return (
                <>
                    {(currentActiveMenuItem === MenuType.Pillars) ? 
                        <NavDropdown.Item eventKey="4.1" disabled='true' style={styles.NavDropdownItemSelected}><label>Pillars</label></NavDropdown.Item>
                    :
                        <NavDropdown.Item eventKey="4.1"><a style={styles.NavDropdownItemUnSelected} onClick={handlePillars}>Pillars</a></NavDropdown.Item>
                    }
                </>
            );        
        } else {
            return (
                <></>
            );        
        }
    }

    function displayGoalsSubMenu(){
        if (isMenuVisible(MenuType.Goals)) {
            return (
                <>
                    {(currentActiveMenuItem === MenuType.Goals) ? 
                        <NavDropdown.Item eventKey="4.3" disabled='true' style={styles.NavDropdownItemSelected}><label>Goals</label></NavDropdown.Item>
                    :
                        <NavDropdown.Item eventKey="4.3"><a style={styles.NavDropdownItemUnSelected} onClick={handleGoals}>Goals</a></NavDropdown.Item>
                    }
                </>
            );    
        } else {
            return (
                <></>
            );        
        }
    }

    function displayCompetenciesSubMenu(){
        if (isMenuVisible(MenuType.Competencies)) {
            return (
            <>
                {(currentActiveMenuItem === MenuType.Competencies) ? 
                    <NavDropdown.Item eventKey="4.2" disabled='true' style={styles.NavDropdownItemSelected}><label>Competencies</label></NavDropdown.Item>
                :
                    <NavDropdown.Item eventKey="4.2"><a style={styles.NavDropdownItemUnSelected} onClick={handleCompetencies}>Competencies</a></NavDropdown.Item>
                }
            </>
        );    
        } else {
            return (
                <></>
            );        
        }
    }

    function displayAssignmentsSubMenu(){
        if (isMenuVisible(MenuType.Assignments)) {
            return (
            <>
                {(currentActiveMenuItem === MenuType.Assignments) ? 
                    <NavDropdown.Item eventKey="4.2" disabled='true' style={styles.NavDropdownItemSelected}><label>Assignments</label></NavDropdown.Item>
                :
                    <NavDropdown.Item eventKey="4.2"><a style={styles.NavDropdownItemUnSelected} onClick={handleAssignments}>Assignments</a></NavDropdown.Item>
                }
            </>
        );    
        } else {
            return (
                <></>
            );        
        }
    }

    function displayNotificationMessagesSubMenu(){
        if (isMenuVisible(MenuType.NotificationMessage)) {
            return (
            <>
                {(currentActiveMenuItem === MenuType.NotificationMessage) ? 
                    <NavDropdown.Item eventKey="4.4" disabled='true' style={styles.NavDropdownItemSelected}><label>Notification Messages</label></NavDropdown.Item>
                :
                    <NavDropdown.Item eventKey="4.4"><a style={styles.NavDropdownItemUnSelected} onClick={handleNotificationMessages}>Notification Messages</a></NavDropdown.Item>
                }
            </>
        );    
        } else {
            return (
                <></>
            );        
        }
    }

    function displayProcessStepsSubMenu(){
        if (isMenuVisible(MenuType.ProcessStep)) {
            return (
            <>
                {(currentActiveMenuItem === MenuType.ProcessStep) ? 
                    <NavDropdown.Item eventKey="4.5" disabled='true' style={styles.NavDropdownItemSelected}><label>Process Steps</label></NavDropdown.Item>
                :
                    <NavDropdown.Item eventKey="4.5"><a style={styles.NavDropdownItemUnSelected} onClick={handleProcessSteps}>Process Steps</a></NavDropdown.Item>
                }
            </>
        );    
        } else {
            return (
                <></>
            );        
        }
    }

    function displayRemindersSubMenu(){
        if (isMenuVisible(MenuType.Reminder)) {
            return (
            <>
                {(currentActiveMenuItem === MenuType.Reminder) ? 
                    <NavDropdown.Item eventKey="4.6" disabled='true' style={styles.NavDropdownItemSelected}><label>Reminders</label></NavDropdown.Item>
                :
                    <NavDropdown.Item eventKey="4.6"><a style={styles.NavDropdownItemUnSelected} onClick={handleReminders}>Reminders</a></NavDropdown.Item>
                }
            </>
        );    
        } else {
            return (
                <></>
            );        
        }
    }

    function displayCompetencyChecklistSubMenu(){
        return (
            <>
            {(currentActiveMenuItem === MenuType.CompetencyChecklist) ? 
                <NavDropdown.Item eventKey="4.11" disabled='true' style={styles.NavDropdownItemSelected}><label>Checklist</label></NavDropdown.Item>
            :
                <NavDropdown.Item eventKey="4.11"><a style={styles.NavDropdownItemUnSelected} onClick={handleCompetencyChecklist}>Checklist</a></NavDropdown.Item>
            }
            </>
        );    
    }

    function displayDivisionsSubMenu(){
        return (
            <>
                {(currentActiveMenuItem === MenuType.Divisions) ? 
                    <NavDropdown.Item eventKey="6.1" disabled='true' style={styles.NavDropdownItemSelected}><label>Divisions</label></NavDropdown.Item>
                :
                    <NavDropdown.Item eventKey="6.1"><a style={styles.NavDropdownItemUnSelected} onClick={handleDivisions}>Divisions</a></NavDropdown.Item>
                }
            </>
        );    
    }

    function displayJobPositionsSubMenu(){
        return (
            <>
                {(currentActiveMenuItem === MenuType.JobPositions) ? 
                    <NavDropdown.Item eventKey="6.2" disabled='true' style={styles.NavDropdownItemSelected}><label>Job Positions</label></NavDropdown.Item>
                :
                    <NavDropdown.Item eventKey="6.2"><a style={styles.NavDropdownItemUnSelected} onClick={handleJobPositions}>Job Positions</a></NavDropdown.Item>
                }
            </>
        );    
    }

    function displayJobClassTreeSubMenu(){
        return (
            <>
                {(currentActiveMenuItem === MenuType.JobClassTree) ? 
                    <NavDropdown.Item eventKey="6.2" disabled='true' style={styles.NavDropdownItemSelected}><label>Job Class Tree</label></NavDropdown.Item>
                :
                    <NavDropdown.Item eventKey="6.2"><a style={styles.NavDropdownItemUnSelected} onClick={handleJobClassTree}>Job Class Tree</a></NavDropdown.Item>
                }
            </>
        );    
    }

    function displayPoliciesSubMenu(){
        if (isMenuVisible(MenuType.Policy)) {
            return (
                <>
                    {(currentActiveMenuItem === MenuType.Policy) ? 
                        <NavDropdown.Item eventKey="6.3" disabled='true' style={styles.NavDropdownItemSelected}><label>Policies</label></NavDropdown.Item>
                    :
                        <NavDropdown.Item eventKey="6.3"><a style={styles.NavDropdownItemUnSelected} onClick={handlePolicies}>Policies</a></NavDropdown.Item>
                    }
                </>
            );    
        }else {
            return (
                <></>
            );        
        }
    }

    function displayGroupsSubMenu(){
        if (isMenuVisible(MenuType.Group)) {
            return (
                <>
                    {(currentActiveMenuItem === MenuType.Group) ? 
                        <NavDropdown.Item eventKey="6.4" disabled='true' style={styles.NavDropdownItemSelected}><label>Groups</label></NavDropdown.Item>
                    :
                        <NavDropdown.Item eventKey="6.4"><a style={styles.NavDropdownItemUnSelected} onClick={handleGroups}>Groups</a></NavDropdown.Item>
                    }
                </>
            );    
        }else {
            return (
                <></>
            );        
        }
    }

    function displayMenusSubMenu(){
        if (isMenuVisible(MenuType.Menu)) {
            return (
                <>
                    {(currentActiveMenuItem === MenuType.Menu) ? 
                        <NavDropdown.Item eventKey="6.5" disabled='true' style={styles.NavDropdownItemSelected}><label>Menus</label></NavDropdown.Item>
                    :
                        <NavDropdown.Item eventKey="6.5"><a style={styles.NavDropdownItemUnSelected} onClick={handleMenus}>Menus</a></NavDropdown.Item>
                    }
                </>
            );    
        }else {
            return (
                <></>
            );        
        }
    }

    function displayElementsMenu() {
        var mainSelectedClassName = '';
        if ((currentActiveMenuItem === MenuType.Pillars) || (currentActiveMenuItem === MenuType.Goals) ||
            (currentActiveMenuItem === MenuType.Competencies) || (currentActiveMenuItem === MenuType.CompetencyChecklist) ||
            (currentActiveMenuItem === MenuType.Assignments)) {
            mainSelectedClassName = 'mainnav-primary-item-selected';
        }
        if ((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) {
            return (
                <NavDropdown title='Elements' className={mainSelectedClassName} id="mainnav-dropdown">
                {displayPillarsSubMenu()}
                {displayGoalsSubMenu()}
                {displayCompetenciesSubMenu()}
                {displayAssignmentsSubMenu()}
                {displayNotificationMessagesSubMenu()}
                {displayRemindersSubMenu()}
                {displayProcessStepsSubMenu()}
                <NavDropdown.Divider />
                {displayCompetencyChecklistSubMenu()}
                </NavDropdown>                                    
            );    
        } else {
            return (<></>);
        }
    }

    function displayOrganizationMenu() {
        var mainSelectedClassName = '';
        if ((currentActiveMenuItem === MenuType.Divisions) || (currentActiveMenuItem === MenuType.JobPositions)) {
            mainSelectedClassName = 'mainnav-primary-item-selected';
        }
        if ((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) {
            return (
                <NavDropdown title='Organization' className={mainSelectedClassName} id="mainnav-dropdown">
                    {displayDivisionsSubMenu()}
                    {displayJobPositionsSubMenu()}
                    {displayJobClassTreeSubMenu()}
                    <NavDropdown.Divider />
                    {displayPoliciesSubMenu()}
                    {displayGroupsSubMenu()}
                    {displayMenusSubMenu()}
                {/* {(currentActiveMenuItem === MenuType.Divisions) ? 
                        <NavDropdown.Item eventKey="6.1" disabled='true' style={styles.NavDropdownItemSelected}><label>Divisions</label></NavDropdown.Item>
                    :
                        <NavDropdown.Item eventKey="6.1"><a style={styles.NavDropdownItemUnSelected} onClick={handleDivisions}>Divisions</a></NavDropdown.Item>
                    }
                    {(currentActiveMenuItem === MenuType.JobPositions) ? 
                        <NavDropdown.Item eventKey="6.2" disabled='true' style={styles.NavDropdownItemSelected}><label>Job Positions</label></NavDropdown.Item>
                    :
                        <NavDropdown.Item eventKey="6.2"><a style={styles.NavDropdownItemUnSelected} onClick={handleJobPositions}>Job Positions</a></NavDropdown.Item>
                    }
                    {(currentActiveMenuItem === MenuType.JobClassTree) ? 
                        <NavDropdown.Item eventKey="6.2" disabled='true' style={styles.NavDropdownItemSelected}><label>Job Class Tree</label></NavDropdown.Item>
                    :
                        <NavDropdown.Item eventKey="6.2"><a style={styles.NavDropdownItemUnSelected} onClick={handleJobClassTree}>Job Class Tree</a></NavDropdown.Item>
                    } */}
                </NavDropdown>                                    
            );    
        } else {
            return (<></>);
        }
    }

    if (!auth?.UserID) {
        return (
            <>
            </>
        )
    } else {
        const links = auth.UserID ? <SignedInLinks profile={profile}/> : <SignedOutLinks/>;
        return (
            <>
                <div style={styles.NavContainer}>
                    <div style={styles.NavInsideDiv}>
                    </div>
                    <div style={styles.NavInsideDiv}>
                        <span>
                            <h1 style={styles.Header}><Image style={{width:'226px', height:'38px'}} src={companyData?.CompanyNameLogo}/></h1>
                        </span>
                    </div>
                    <div style={styles.NavInsideDiv}>
                        {/* { links } */}
                    </div>
                </div>

                <nav> 
                    <div class="nav-content">
                        <span>{profile?.FirstName} {profile?.LastName}</span>                       
                        <div class="nav-links">
                            {(currentActiveMenuItem === MenuType.Profile) ? 
                                <label>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                                    </svg> 
                                </label>                           
                                : 
                                <a onClick={handleProfile}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                                    </svg>                            
                                </a>
                            }

                            {((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) ?
                                (currentActiveMenuItem === MenuType.Home) ? <label>Home</label> : <a onClick={handleDashboard}>Home</a>
                                :
                                <></>
                            }
                            {(currentActiveMenuItem === MenuType.Questionnaire) ? <label>Questionnaires</label> : <a onClick={handleQuestionnaires}>Questionnaires</a>}
                            {(currentActiveMenuItem === MenuType.Performance) ? <label>Performance</label> : <a onClick={handlePerformance}>Performance</a>}
                            {((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) ?
                                (currentActiveMenuItem === MenuType.MyTeam) ? <label>Team</label> : <a onClick={handleTeam}>Team</a>
                                :
                                <></>
                            }
                            {displayElementsMenu()}
                            {displayOrganizationMenu()}
                            {((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) ?
                                (currentActiveMenuItem === MenuType.Reports) ? <label>Reports</label> : <a onClick={handleReports}>Reports</a>
                                :
                                <></>
                            }
                            {(currentActiveMenuItem === MenuType.Logout) ? 
                                <label>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
                                        <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                                    </svg> 
                                </label>                           
                                : 
                                <a onClick={handleLogout}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
                                            <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                                    </svg>                            
                                </a>
                            }
                        </div>
                    </div>
                </nav>                
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state?.auth?.user,
        profile: state?.auth?.profile,
        currentActiveMenuItem: state?.auth?.activeMenuItem,
        companyID: state?.auth?.companyID,
        companyData: state?.auth?.companyData,
        myMenus: state?.auth?.myMenus,
        userattributelookups: state.entity.userattributelookups,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        clearEntityState: () => dispatch(clearEntityState()),
        assignActiveMenu: (menuId) => dispatch(assignActiveMenu(menuId)),
        loadingMenuSelection: (isLoading) => dispatch(loadingMenuSelection(isLoading)),
        getOrgDivisions: (id) => dispatch(getOrgDivisions(id)),
        getEvaluations: (evalParams) => dispatch(getEvaluations(evalParams)),
        getAssignments: (oParams) => dispatch(getAssignments(oParams)),
        getReports: (id) => dispatch(getReports(id)),
        getJobPositions: (jpParams) => dispatch(getJobPositions(jpParams)),
        getMyTeamFull: (oParams) => dispatch(getMyTeamFull(oParams)),
        getDivisionManagers: (id) => dispatch(getDivisionManagers(id)),
        getGoals: (oParams) => dispatch(getGoals(oParams)),
        getCompetencies: (oParams) => dispatch(getCompetencies(oParams)),
        getSkills: (oParams) => dispatch(getSkills(oParams)),
        getSkillChecklist: (oParams) => dispatch(getSkillChecklist(oParams)),
        getOrgJobClasses: (id) => dispatch(getOrgJobClasses(id)),
        getNotificationMessages: (oParams) => dispatch(getNotificationMessages(oParams)),
        getProcessSteps: (oParams) => dispatch(getProcessSteps(oParams)),
        getReminders: (oParams) => dispatch(getReminders(oParams)),
        getAttachments: (oAParams) => dispatch(getAttachments(oAParams)),
        getPolicies: (oParams) => dispatch(getPolicies(oParams)),
        getGroups: (oParams) => dispatch(getGroups(oParams)),
        getMenus: (oParams) => dispatch(getMenus(oParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
