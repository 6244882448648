import React, { useRef, useState, useEffect, componentDidMount } from 'react';
import { Form, Button, Card, CardBody, CardHeader, CardTitle, Alert, Image } from 'react-bootstrap';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Container } from "react-bootstrap";
import { connect } from 'react-redux';
import { signInSSO, assignActiveMenu, validateCompany, validateSSOState } from '../../store/actions/authActions';
import { getUserEvaluationSessions } from '../../store/actions/entityActions';
import { styles } from '../layout/styles';
import Colors, { CompanyData, companyLogo } from '../../config/Colors';
import Spinner from '../layout/Spinner';
import { RetentasType, MenuType } from '../../config/Constant';

function LoginSSO(props) {
    // const { code, state } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    const { auth, companyData, profile, authError, ssoStateData } = props;

    const usernameRef = useRef()
    const passwordRef = useRef()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [showErrorMsg, setShowErrorMsg] = useState(true)
    const [ssoValidated, setssoValidated] = useState(false)
    const [completeQueryStr, setCompleteQueryStr] = useState('')
    const [uesid, setuesid] = useState(-1);
    const navigate = useNavigate()
    
    useEffect(() => {
        if ((auth?.Validate) && (auth?.UserID > 0)) {
            props.getUserEvaluationSessions(auth?.UserID)
            if ((completeQueryStr) && (completeQueryStr != '')) {
                // console.log(completeQueryStr);
                const params = new URLSearchParams(completeQueryStr);
                const uesid = params.get('uesid'); 
                if ((uesid) && (uesid > 0)) {
                    props.assignActiveMenu(MenuType.Questionnaire);
                    navigate('/questionnaires', {state: {processleveltypeid:RetentasType.Surveys, uesid:uesid}});
                } else {
                    NavigateBasedOnGroups();                    
                }                
            } else {
                NavigateBasedOnGroups();
            }
        }
    }, [auth]);

    useEffect(() => {
        if (ssoValidated) {
            setError(authError?.message);
        }
    }, [authError]);

    useEffect(() => {
        if ((code)) {
            validateSSOData();
        }
    }, []);

    useEffect(() => {
        if ((ssoValidated) && (ssoStateData) && (ssoStateData.CompanyID > 0)) {
            validateSSOCompany();
        }
    }, [ssoValidated]);

    function NavigateBasedOnGroups() {
        if ((profile?.IsCSA === 'True') || (profile?.IsAdministrator === 'True') || (profile?.IsManager === 'True')) {
            props.assignActiveMenu(MenuType.Home);
            navigate('/');
        } else {
            props.assignActiveMenu(MenuType.Questionnaire);
            navigate('/questionnaires', {state: {processleveltypeid:RetentasType.Surveys}});
        }    
    }

    async function validateSSOData() {
        try {
            setLoading(true);
            await props.validateSSOState(state);
            setssoValidated(true);
            // const cid = ssoStateData.CompanyID;
            // console.log(ssoStateData);
            // setCompleteQueryStr(ssoStateData.CompleteQueryString);
            // setuesid(ssoStateData.UserEvaluationSessionID);
            // await props.validateCompany(cid, ssoStateData.CompleteQueryString);
            // await handleSSO(code, cid);
            setLoading(false);
    
        } catch (err){
            console.log('Failed to validate SSO State. Error - ' + err);
            setError('Failed to validate SSO State!');
        }

        setLoading(false);
    }

    async function validateSSOCompany() {
        try {
            setLoading(true);
            // console.log(ssoStateData);
            const cid = ssoStateData.CompanyID;
            setCompleteQueryStr(ssoStateData.CompleteQueryString);
            setuesid(ssoStateData.UserEvaluationSessionID);
            await props.validateCompany(cid, ssoStateData.CompleteQueryString);
            await handleSSO(code, cid);
            setLoading(false);
    
        } catch (err){
            console.log('Failed to validate SSO State. Error - ' + err);
            setError('Failed to validate SSO State!');
        }

        setLoading(false);
    }

    async function handleSSO(pcode, cid) {
        setShowErrorMsg(false);
        try {
            setError('');
            setLoading(true);
            const creds = {
                companyID:cid,
                ssocode:pcode,
            }
            const loginResp = await props.signInSSO(creds);
            setLoading(false);
    
        } catch (err){
            console.log('Failed to validate SSO. Error - ' + err);
            setError('Failed to validate SSO!');
        }

        setShowErrorMsg(true);
        setLoading(false);
    }

    function displayLoginError() {
        if (showErrorMsg) {
            if (error) {
                return (
                    <Alert variant="danger" style={{textAlign:'center'}}>{error}</Alert>
                );    
            } else if ((authError) && ((typeof authError === 'string') )) {
                return (
                    <Alert variant="danger" style={{textAlign:'center'}}>{authError}</Alert>
                );    
            } else 
            return (
                <></>
            );    
        } else {
            return (
                <></>
            );    
        }
    }

    function clearMsg() {
        setShowErrorMsg(false);
    }

  return (
    <>
            <div style={{position:'absolute', width:'520px', height:'220px', top:'22%', left:'56%'}}>
                <Card style={{width:'100%', backgroundColor:'transparent'}}>
                    <CardHeader> 
                        <CardTitle>
                            <span>
                                <h1 style={styles.Header}><Image style={{width:'240px', height:'100px'}} src={companyData?.CompanyLogo}/></h1>
                            </span>
                            {displayLoginError()}
                        </CardTitle>
                    </CardHeader>
                    {(loading) ? (            
                            <Card.Body>
                                <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                    <Spinner/>
                                </div>
                            </Card.Body>
                        ) 
                        : 
                        (!companyData) ? (
                            <Card.Body>
                                <div style={styles.Label}>
                                    Please contact your administrator for login information.
                                </div>
                            </Card.Body>
                            ) 
                        :
                        (                                          
                            <CardBody >
                            </CardBody>
                        )}
                </Card>
            </div>
    </>
  )
}

const mapsStateToProps = (state) => {
    return {
        authError: state?.auth?.authError,
        companyData: state?.auth?.companyData,
        auth: state?.auth?.user,
        profile: state?.auth?.profile,
        ssoStateData: state?.auth?.ssoStateData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signInSSO: (creds) => dispatch(signInSSO(creds)),
        validateCompany: (id, completeQueryStr) => dispatch(validateCompany(id, completeQueryStr)),
        validateSSOState: (pState) => dispatch(validateSSOState(pState)),
        assignActiveMenu: (menuId) => dispatch(assignActiveMenu(menuId)),
        getUserEvaluationSessions: (id) => dispatch(getUserEvaluationSessions(id)),
    }
} 

export default connect(mapsStateToProps, mapDispatchToProps)(LoginSSO)

