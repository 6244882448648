import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image  } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { getReminders, getUserAttributesLookups, insertReminder, deleteReminder, updateReminder, 
        insertReminderEmail, deleteReminderEmail, updateReminderEmail, 
        assignLookupSelection } from '../../store/actions/entityActions';
import Spinner from '../layout/Spinner';
import { useLocation } from 'react-router-dom';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { getPermissionFlags } from '../layout/Utils';

const ReminderList = (props) => {
    const { auth, companyData, profile, reminders, userattributelookups, loadingMenu, fromLookupParam } = props;    
    const [loading, setLoading] = useState(false);
    const [nodeName, setnodeName] = useState('');
    const [sliderValue, setSliderValue] = useState([]);
    const [addNewReminder, setAddNewReminder] = useState(false);
    const [editReminder, setEditReminder] = useState(false);
    const [reminderID, setReminderID] = useState(-1);
    const [reminderName, setReminderName] = useState('');
    const [EditedReminderID, setEditedReminderID] = useState(-1);
    const [EditedReminderEmailID, setEditedReminderEmailID] = useState(-1);
    const [reminderDescription, setReminderDescription] = useState('');

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const reminder = reminders?.reminders?.Reminders;
    const reminderemails = reminders?.reminders?.ReminderEmails;

    const emailToTypeData = userattributelookups?.EmailToTypes;

    const location  = useLocation();
    let fromLookup = location?.state?.fromLookup;
    if ((fromLookup === null) || (fromLookup === undefined)) {
        fromLookup = fromLookupParam;
    }
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location.state.permissiontypeid));

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    async function previousPage() {
        setLoading(true);
        const previousPageNumber = reminders?.reminders?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            ReminderName:nodeName,
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        console.log(oParams);
        await props.getReminders(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = reminders?.reminders?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            ReminderName:nodeName,
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };            
        console.log(oParams);
        await props.getReminders(oParams);
        setLoading(false);
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }
    
    async function searchReminders() {
        setLoading(true);
        const oParams = {
            ReminderName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getReminders(oParams);        
        setLoading(false);
    }
    
    const addInitialSliderArray = (evaluation) => {
        return reminder?.map((oRec) => ({
            ReminderID:oRec.ReminderID, 
            EditedReminderID:-1,
            takeAction:false,
            showDetails:false,
            disableNewReminder:false,
            newReminderClicked:false,
            editReminderClicked:false,
            disableNewReminderEmail:false,
            newReminderEmailClicked:false,
            editReminderEmailClicked:false,
            EditedReminderEmailID:-1,
            BeforeDueDateEmail:false,
            StartDaysDueDate:-1,
            EndDaysDueDate:-1,
            EmailToTypeID:-1,
            NumberOfEmails:-1,
            LearningObjectProcessStatusTypeID:-1,
            OtherEmails:null,
            EmailSubject:null,                        
            EmailBody:null,                        
            editorState:EditorState.createEmpty(),            
        }))
    }
              
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(reminder);
        setSliderValue(initialScoreValues);
    }, [reminder])
    
    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    useEffect(() => {
        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.ReminderID === reminderID) {
                row.editorState = editorState;
            } 
        });
    }, [editorState])  
    
    function handleEditorChange(oEditorState) {
        setEditorState(oEditorState);
    }

    function getHTMLFromEditor() {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()));
    }

    function setEditorHTML(isEmpty, value) {
        if (isEmpty) {
            setEditorState(EditorState.createEmpty());
        } else {
            console.log(value);
            const contentBlock = htmlToDraft(value);
            console.log(contentBlock);
            if (contentBlock) {
              const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
              const oEditorState = EditorState.createWithContent(contentState);
              console.log(oEditorState);
              setEditorState(oEditorState);
            }
        }
    }

    async function getEditorState(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.ReminderID === rowIndex) {
                console.log('Inside getEditorState - ', rowIndex);
                return row.editorState;
            } 
        });
        console.log('Outside getEditorState - ', rowIndex);
        return null;
    }

    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            (addNewReminder) ? setAddNewReminder(false) : setAddNewReminder(true);
            setReminderID(-1);
            setReminderName('');
            setReminderDescription('');
        } else {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.ReminderID === rowIndex) {
                    if (tab === 2) {
                        setReminderID(rowIndex);
                        (row.newReminderEmailClicked) ? row.newReminderEmailClicked = false : row.newReminderEmailClicked = true;                    
                    } 
                }
            });
            setSliderValue(updatedSliderValues);
            setEditorHTML(true, null);
        }

        setLoading(false);
    }

    async function handleCancelChildernClick(tab, currentEval) {
        if (tab === 2) {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.ReminderID === currentEval.ReminderID) {
                    if (tab === 2) {
                        row.editReminderEmailClicked = false;                    
                        row.disableNewReminderEmail = false;
                        row.newReminderEmailClicked = false;
                    } 
                }
            });
            setSliderValue(updatedSliderValues);    
        } else if (tab === 1) {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.ReminderID === currentEval?.ReminderID) {
                    if (tab === 2) {
                        row.disableNewReminder = false;                    
                        row.newReminderClicked = false;
                        row.editReminderClicked = false;
                    } 
                }
            });
            setSliderValue(updatedSliderValues);    
            setReminderID(-1);
            setEditedReminderID(-1);
            setEditedReminderEmailID(-1);
            setReminderName('');
            setReminderDescription('');
            setEditorHTML(true, null)
            setAddNewReminder(false);
            setEditReminder(false);
        } 
    }
    
    async function handleSaveChildernClick(tab, currentRem) {
        setLoading(true);
        if (tab === 1) {
            const oIParams  = {
                ReminderID : -1,
                ReminderName : reminderName,
                ReminderDescription : reminderDescription,
            };        
            console.log(oIParams);
            await props.insertReminder(oIParams);    
        } if (tab === 2) {
            const remRequestList = sliderValue.filter((oRow) => oRow.ReminderID === currentRem.ReminderID);

            const oIParams  = {
                ReminderID : remRequestList[0].ReminderID,
                ReminderEmailID : EditedReminderEmailID,
                BeforeDueDateEmail:remRequestList[0].BeforeDueDateEmail,
                StartDaysDueDate:remRequestList[0].StartDaysDueDate,
                EndDaysDueDate:remRequestList[0].EndDaysDueDate,
                EmailToTypeID:remRequestList[0].EmailToTypeID,
                NumberOfEmails:remRequestList[0].NumberOfEmails,
                LearningObjectProcessStatusTypeID:remRequestList[0].LearningObjectProcessStatusTypeID,
                OtherEmails:remRequestList[0].OtherEmails,    
                EmailSubject: remRequestList[0].EmailSubject,
                EmailBody : getHTMLFromEditor(),
            };        
            console.log(oIParams);
            await props.insertReminderEmail(oIParams);    
        } 

        const oParams = {
            ReminderName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getReminders(oParams);        
        setReminderID(-1);
        setEditedReminderEmailID(-1);
        setReminderName('');
        setReminderDescription('');
        setEditorHTML(true, null)
        setAddNewReminder(false);
        setEditReminder(false);
        setLoading(false);
    }

    async function handleEditedSaveChildernClick(tab, currentRem) {
        setLoading(true);
        if (tab === 1) {
            const oUParams  = {
                ReminderID : EditedReminderID,
                ReminderName : reminderName,
                ReminderDescription : reminderDescription,
            };        
            console.log(oUParams);
            if (EditedReminderID > 0) {
                await props.updateReminder(oUParams);    
            } else {
                await props.insertReminder(oUParams);    
            }
            setReminderID(-1);
            setEditedReminderID(-1);
            setReminderName('');
            setReminderDescription('');
            setAddNewReminder(false);
            setEditReminder(false);    
        } else if (tab === 2) {
            const remRequestList = sliderValue.filter((oRow) => oRow.ReminderID === currentRem.ReminderID);

            const oUParams  = {
                ReminderID : remRequestList[0].ReminderID,
                ReminderEmailID : EditedReminderEmailID,
                BeforeDueDateEmail:remRequestList[0].BeforeDueDateEmail,
                StartDaysDueDate:remRequestList[0].StartDaysDueDate,
                EndDaysDueDate:remRequestList[0].EndDaysDueDate,
                EmailToTypeID:remRequestList[0].EmailToTypeID,
                NumberOfEmails:remRequestList[0].NumberOfEmails,
                LearningObjectProcessStatusTypeID:remRequestList[0].LearningObjectProcessStatusTypeID,
                OtherEmails:remRequestList[0].OtherEmails,    
                EmailSubject: remRequestList[0].EmailSubject,
                EmailBody : getHTMLFromEditor(),
            };        
            console.log(oUParams);
            if (EditedReminderEmailID > 0) {
                await props.updateReminderEmail(oUParams);    
            } else {
                await props.insertReminderEmail(oUParams);    
            }
            setReminderID(-1);
            setEditedReminderID(-1);
            setEditedReminderEmailID(-1);
            setEditorHTML(true, null)
        } 
   
        const oParams = {
            ReminderName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getReminders(oParams);        
        setLoading(false);
    }

    async function handleUpdateChildernClick(tab, id, id1) {
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            const oEdited = reminder?.filter((oRec) => (oRec.ReminderID === id));
            if ((oEdited) && (oEdited.length > 0)) {
                setReminderID(id);
                setEditedReminderID(id);
                setReminderName(oEdited[0]?.ReminderName);
                setReminderDescription((oEdited[0]?.ReminderDescription) ? oEdited[0]?.ReminderDescription : '');
            }

            setEditReminder(true);
        } else if (tab === 2) {
            setEditedReminderEmailID(id1);
            const evalEditedReminderEmails = reminderemails?.filter((oRec) => (oRec.ReminderEmailID === id1));

            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.ReminderID === id) {
                    if (tab === 2) {
                        row.editReminderEmailClicked = true;                    
                        row.disableNewReminderEmail = true;
                        row.newReminderEmailClicked = false;
                        row.EditedReminderEmailID = id1;
                        row.ReminderEmailID = id1;
                        row.BeforeDueDateEmail = evalEditedReminderEmails[0]?.BeforeDueDateEmail;
                        row.StartDaysDueDate = evalEditedReminderEmails[0]?.StartDaysDueDate;
                        row.EndDaysDueDate = evalEditedReminderEmails[0]?.EndDaysDueDate;
                        row.NumberOfEmails = evalEditedReminderEmails[0]?.NumberOfEmails;
                        row.LearningObjectProcessStatusTypeID = evalEditedReminderEmails[0]?.LearningObjectProcessStatusTypeID;
                        row.EmailToTypeID = evalEditedReminderEmails[0]?.EmailToTypeID;
                        row.EmailSubject = evalEditedReminderEmails[0]?.EmailSubject;
                        row.EmailBody = evalEditedReminderEmails[0]?.Email;
                        setEditorHTML(false, evalEditedReminderEmails[0]?.Email)
                    } 
                }
            });
            setSliderValue(updatedSliderValues);    
        }  
    }

    async function handleDeleteChildernClick(tab, id, id1) {
        setLoading(true);

        if (tab === 1) {
            const oParams  = {
                ReminderID: id,
            };        
            console.log(oParams);
            await props.deleteReminder(oParams);    
        } else if (tab === 2) {
            const oParams  = {
                ReminderID: id,
                ReminderEmailID: id1,
            };        
            console.log(oParams);
            await props.deleteReminderEmail(oParams);    
        } 
        
        const oParams = {
            ReminderName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };            
        await props.getReminders(oParams);            

        setLoading(false);
    }

    async function handleAddChildernValues(tab, rowIndex, value) {
        setLoading(true);

        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.ReminderID === rowIndex) {
                if (tab === 1) {
                    row.BeforeDueDateEmail = (value === '1') ? true : false;
                } else if (tab === 2) {
                    row.StartDaysDueDate = value;                    
                } else if (tab === 3) {
                    row.EndDaysDueDate = value;
                } else if (tab === 4) {
                    row.NumberOfEmails = value;                    
                } else if (tab === 5) {
                    row.LearningObjectProcessStatusTypeID = value;                    
                } else if (tab === 6) {
                    row.EmailToTypeID = value;        
                } else if (tab === 7) {
                    row.OtherEmails = value;                    
                } else if (tab === 8) {
                    row.EmailSubject = value;                    
                } 
            }        
        });
        setSliderValue(updatedSliderValues);

        setLoading(false);
    }

    function checkMainReminder(tab, id, id1) {
        if (!fromLookup) {            
            if (permissionFlags.CanModify) {
                if (tab === 2) {
                    return (
                        <div>
                            <a onClick={() => handleUpdateChildernClick(tab, id, id1)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Reminder Email'>edit</span>
                            </a>
                            <a onClick={() => handleDeleteChildernClick(tab, id, id1)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Reminder Email'>delete</span>
                            </a>
                        </div>
                    );                
                } else {            
                    return (
                        <div>
                            <a onClick={() => handleUpdateChildernClick(tab, id, id1)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Reminder'>edit</span>
                            </a>
                            <a onClick={() => handleDeleteChildernClick(tab, id, id1)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Reminder'>delete</span>
                            </a>
                        </div>
                    );      
                }      
            } else {
                return (<></>)
            }
        } else {
            return (<></>)
        }
    }

    function displayNewReminder() {
        if ((!fromLookup) && ((addNewReminder) || (editReminder))) {
            return (
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                    <Card.Title>
                        {(addNewReminder) ? 'New Reminder' : 'Edit Reminder'}                        
                    </Card.Title>
                </Card.Header>                   
                <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="remindername" style={styles.LabelDataEntryNoPadding}>Name</label>
                                    <input id="eemindername" type="text" style={styles.TextDataEntryNoPaddingWBG} defaultValue={reminderName} onChange={(e) => (setReminderName(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="description" style={styles.LabelDataEntryNoPadding}>Description</label>
                                    <input id="description" type="text" style={styles.TextDataEntryNoPaddingWBG} defaultValue={reminderDescription} onChange={(e) => (setReminderDescription(e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'right', width:'5%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <Button onClick={() => handleEditedSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Reminder'>Save</Button>
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'5%', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <Button onClick={() => handleCancelChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey}>Cancel</Button>
                                </div>
                            </td>
                        </tr>  
                    </tbody>
                </table>                
                </Card.Body>
                </Card>                   
            );
        }
    }

    function displayAddMainReminder() {
        if (!fromLookup) {
            if (permissionFlags.CanCreate) {
                if (addNewReminder) {
                    return (
                        <a onClick={() => handleAddChildernClick(1, null)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Reminder'>shadow_minus</span>
                        </a>
                    );   
                } else {
                    return (
                        <a onClick={() => handleAddChildernClick(1, null)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Reminder'>note_add</span>
                        </a>
                    );    
                }
            } else {
                return (<></>);
            }
        } else {
            return (<></>);
        }
    }

    function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Reminders</h3></td>
                <td style={{textAlign:'right', width:'20%', backgroundColor:companyData?.PagesBGColor}}>
                    <div className='input-field'>
                        <input id="searchNode" type="text" style={styles.TextDataEntryNoPadding80} onChange={setSearchNodeName} placeholder='Search Reminder'/>
                        <a onClick={() => searchReminders()}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                        </a>
                        {displayAddMainReminder()}
                    </div>                                            
                </td>
            </tr>
            </tbody>
        </Table>                            
        );
    }

    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;        
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (row.ReminderID === rowIndex)) {
                bReturn = row.showDetails;
            }
        })
        return bReturn;
    }
 
    function handleSwitchDetailChange(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);
        updatedSliderValues.map((row, index) => {
            if (row.ReminderID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSliderValue(updatedSliderValues);
    }

    function displayAddButtons(tabValue, currentRem) {
        const remRequestList = sliderValue.filter((oRow) => oRow.ReminderID === currentRem.ReminderID);

        if (permissionFlags.CanCreate) {       
            if (tabValue === 2 ) {
                if (!remRequestList[0].disableNewReminderEmail) {
                    if (remRequestList[0].newReminderEmailClicked) {
                        return (
                            <a onClick={() => handleAddChildernClick(2, currentRem.ReminderID)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide New Email'>shadow_minus</span>
                            </a>
                        );
                    } else {
                        return (
                            <a onClick={() => handleAddChildernClick(2, currentRem.ReminderID)}>
                                <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add New Email'>note_add</span>
                            </a>
                        );
                    }
                }
            } 
        } else {
            return (<></>);
        }
    }

    function displayNewEmail(currentRem) {
        const remRequestList = sliderValue.filter((oRow) => oRow.ReminderID === currentRem.ReminderID);
        const evalEditedEmails = reminderemails?.filter((oRow) => (oRow.ReminderEmailID === remRequestList[0]?.EditedReminderEmailID));

        if ((remRequestList[0].newReminderEmailClicked) || (remRequestList[0].editReminderEmailClicked)) {
            return (
                <table class="table caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>                          
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="beforeduedate" style={styles.LabelDataEntryNoPadding}>Before Due Date</label>
                                    <select id="beforeduedate" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={(evalEditedEmails[0]?.BeforeDueDateEmail) ? '1' : '0'} onChange={(e) => (handleAddChildernValues(1, currentRem.ReminderID, e.target.value))}>
                                            <option value='1'>Yes</option>        
                                            <option value='0'>No</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="startdays" style={styles.LabelDataEntryNoPadding}>Start Days</label>
                                    <input id="startdays" type="number" min='0' defaultValue={evalEditedEmails[0]?.StartDaysDueDate} style={styles.TextDataEntryNoPaddingWBG} onChange={(e) => (handleAddChildernValues(2, currentRem.ReminderID, e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="enddays" style={styles.LabelDataEntryNoPadding}>End Days</label>
                                    <input id="enddays" type="number" min='0' defaultValue={evalEditedEmails[0]?.EndDaysDueDate} style={styles.TextDataEntryNoPaddingWBG} onChange={(e) => (handleAddChildernValues(3, currentRem.ReminderID, e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="numberofemails" style={styles.LabelDataEntryNoPadding}># of Emails</label>
                                    <input id="numberofemails" type="number" min='1' max='100' step='1' defaultValue={evalEditedEmails[0]?.NumberOfEmails} style={styles.TextDataEntryNoPaddingWBG} onChange={(e) => (handleAddChildernValues(4, currentRem.ReminderID, e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="status" style={styles.LabelDataEntryNoPadding}>Status</label>
                                    <select id="status" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={evalEditedEmails[0]?.LearningObjectProcessStatusTypeID} onChange={(e) => (handleAddChildernValues(5, currentRem.ReminderID, e.target?.selectedOptions[0]?.value))}>
                                            <option value='1'>Pending</option>        
                                            <option value='2'>Assigned</option>        
                                            <option value='5'>Cancel</option>        
                                            <option value='4'>Complete</option>        
                                    </select>                                     
                                </div>
                            </td>
                            <td style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label style={styles.LabelDataEntryNoPadding}>Email To</label>
                                    <select id="emailto" class="browser-default" style={styles.TextDataEntryNoPaddingWBG} defaultValue={evalEditedEmails[0]?.EmailToTypeID} onChange={(e) => (handleAddChildernValues(6, currentRem.ReminderID, e.target?.selectedOptions[0]?.value))}>
                                        {emailToTypeData?.map((emailto, supIndex) => (
                                            <option value={emailto.EmailToTypeID}>{emailto.EmailToTypeName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                            <td colSpan='2' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="otheremails" style={styles.LabelDataEntryNoPadding}>Other Emails</label>
                                    <input id="otheremails" type="text" style={styles.TextDataEntryNoPaddingWBG} defaultValue={evalEditedEmails[0]?.OtherEmails} onChange={(e) => (handleAddChildernValues(7, currentRem.ReminderID, e.target.value))}/>
                                </div>                    
                            </td>                            
                        </tr>
                        <tr>
                            <td colSpan='4' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="emailsubject" style={styles.LabelDataEntryNoPadding}>Subject</label>
                                    <input id="emailsubject" type="email" style={styles.TextDataEntryNoPaddingWBG} defaultValue={evalEditedEmails[0]?.EmailSubject} onChange={(e) => (handleAddChildernValues(8, currentRem.ReminderID, e.target.value))}/>
                                </div>                    
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='4' style={{textAlign:'left', backgroundColor:companyData?.PagesBGColor}}>
                                <div>
                                    <label htmlFor="bresourcekey" style={styles.LabelDataEntryNoPadding}>Body</label>
                                    <div style={{height:'300px', overflowY: 'auto'}}>
                                        <Editor
                                            id={`editor${'detail' + evalEditedEmails[0]?.ReminderEmailID}`} 
                                            // editorState={getEditorState(evalEditedEmails[0]?.ReminderID)}
                                            editorState={editorState}
                                            onEditorStateChange={setEditorState}
                                            wrapperClassName="wrapper-class"
                                            editorClassName="editor-class"
                                            toolbarClassName="toolbar-class"
                                            readOnly={false}
                                            mention={{
                                                separator: ' ',
                                                trigger: '@',
                                                suggestions: [
                                                    { text: 'Employee Address', value: '~Address~' },
                                                    { text: 'All Employee Assigned', value: '~AssignedUserEvaluationsNoDueDate~' },
                                                    { text: 'Assignment Due Date', value: '~Assignment_Due_Date~' },
                                                    { text: 'Authorizer Email', value: '~Auth_EMail~' },
                                                    { text: 'Authorizer First Name', value: '~Auth_Fname~' },
                                                    { text: 'Authorizer Last Name', value: '~Auth_Lname~' },
                                                    { text: 'Authorizer Phone Number', value: '~Auth_Phone~' },
                                                    { text: 'Cancellation User Email', value: '~Cancel_EMail~' },
                                                    { text: 'Cancellation User Full Name', value: '~Cancel_FullName~' },
                                                    { text: 'Due Date', value: '~Due_Date~' },
                                                    { text: 'Employee ID', value: '~EmployeeID~' },
                                                    { text: 'Evaluation Launch Link/Text', value: '~SingleEvaluationLaunchLink~' },
                                                    { text: 'Evaluator First and Last Name', value: '~Evaluator_FirstLast_Name~' },
                                                    { text: 'Evaluator Full Name', value: '~Evaluator_Full_Name~' },
                                                    { text: 'First Name', value: '~First_Name~' },
                                                    { text: 'Last Name', value: '~Last_Name~' },
                                                    { text: 'Login Page', value: '~GLMLoginPage~' },
                                                    { text: 'Manager EvaluationLink/Text', value: '~ManagerEvaluationLaunchLink~' },
                                                    { text: 'Title', value: '~Title~' },
                                                    { text: 'UserName', value: '~User_UserName~' }
                                                    ],
                                                }}                                            
                                        />
                                    </div>
                                </div>                    
                            </td>
                        </tr>
                        <tr>                       
                            <td colSpan='4' style={{textAlign:'right', backgroundColor:companyData?.PagesBGColor}}>
                                <div class="row d-flex justify-content-center align-items-center">
                                    <div class="col-auto">
                                        <Button onClick={() => handleEditedSaveChildernClick(2, currentRem)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Reminder Email'>Save</Button>
                                    </div>
                                    <div class="col-auto">
                                        <Button onClick={() => handleCancelChildernClick(2, currentRem)} disabled={loading} style={styles.CDPillButtonSMGrey}>Cancel</Button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>                                   
            );
        }
    }

    function getReminderEmails(remEmails) {
        if (remEmails.length === 0) {
            return (
                <></>
            );
        } else {
            return remEmails.map((reminder, emailIndex) => (
                <tr> 
                    <td style={styles.tableText}>{emailIndex + 1}</td> 
                    <td style={styles.tableText}>{reminder?.ReminderEmailID}</td> 
                    <td style={styles.tableTextLeft}>{reminder?.EmailSubject}</td> 
                    <td style={styles.tableText}>{(reminder?.BeforeDueDateEmail && (reminder?.BeforeDueDateEmail === true)) ? 'True' : 'False'}</td> 
                    <td style={styles.tableText}>{reminder?.StartDaysDueDate}</td> 
                    <td style={styles.tableText}>{reminder?.EndDaysDueDate}</td> 
                    <td style={styles.tableText}>{reminder?.NumberOfEmails}</td> 
                    <td style={styles.tableText}>{checkMainReminder(2, reminder?.ReminderID, reminder?.ReminderEmailID)}</td> 
                </tr> 
            ));        
        }
    }

    function getDetailSwitchTR(currentRem) {
        const remRequestList = sliderValue?.filter((oRec) => oRec.ReminderID === currentRem.ReminderID);
        const remEmails = reminderemails?.filter((oRec) => (oRec.ReminderID === currentRem.ReminderID));
        if (remRequestList[0]?.showDetails) {
            return (
                <tr id={`tr${'detail' + currentRem.ReminderID}`}>
                    <td colSpan="4"> 
                        <table class="table table-sm caption-top">  
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th style={styles.tableTextLeft}>Emails</th>
                                    <th style={styles.tableTextRight}>{displayAddButtons(2, currentRem)}</th>
                                </tr>
                            </thead>                        
                            <tbody> 
                                <tr>
                                    <td colSpan="6" style={{backgroundColor:companyData?.PagesBGColor}}> 
                                    {displayNewEmail(currentRem)}
                                    {(remEmails.length === 0) ? <></> :
                                        <table class="table table-sm caption-top">  
                                            <thead>
                                                <tr>
                                                    <th style={styles.tableText}>#</th>
                                                    <th style={styles.tableText}>ID</th>
                                                    <th style={styles.tableTextLeft}>Subject</th>
                                                    <th style={styles.tableText}>Before Due Date</th>
                                                    <th style={styles.tableTextMain}>Start Days</th>
                                                    <th style={styles.tableText}>End Days</th>
                                                    <th style={styles.tableText}>Emails</th>
                                                    <th style={styles.tableText}>Action</th>
                                                </tr>
                                            </thead>                        
                                            <tbody> 
                                                {getReminderEmails(remEmails)}  
                                            </tbody> 
                                        </table> 
                                        }
                                    </td> 
                                </tr>             
                            </tbody>
                        </table>    
                    </td> 
            </tr>             
            );
        }
    }


    return (
        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          

                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {displayNewReminder()}                                               
                            {((!reminder) || (reminder.length === 0)) ? (             
                                <div style={styles.tableText}>
                                    No Reminders.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableTextMain}>Name</th>
                                                <th style={styles.tableText}>Description</th>
                                                <th style={styles.tableText}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reminder && reminder.map((oRec, index) => {
                                                return (
                                                    <>
                                                    <tr>
                                                        <td style={styles.tableText}>{oRec?.ReminderID}</td>
                                                        <td style={styles.tableTextSubHeader}>{oRec?.ReminderName}
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input" type="checkbox" role="switch" 
                                                                    checked={getDetailSwitchValue(oRec?.ReminderID)} id={`switch${'odetail' + oRec?.ReminderID}`} 
                                                                    style={(getDetailSwitchValue(oRec?.ReminderID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                                                    onChange={(e) => {handleSwitchDetailChange(oRec?.ReminderID)}}>
                                                                </input>
                                                                <label class="form-check-label" htmlFor={`switch${'detail' + oRec?.ReminderID}`}>Details</label>
                                                            </div>
                                                        </td>
                                                        <td style={styles.tableTextLeft}>{oRec?.ReminderDescription}</td>
                                                        <td style={styles.tableText10}>{checkMainReminder(1, oRec?.ReminderID, -1)}</td>
                                                    </tr>
                                                    {getDetailSwitchTR(oRec)}
                                                    </>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{reminders?.reminders?.RecordFound.toLocaleString()}   Reminders&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(reminders?.reminders?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(reminders?.reminders?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {reminders?.reminders?.PagingMetaData?.PageNumber}  of  {reminders?.reminders?.PagingMetaData?.TotalPage.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                </Card>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state?.auth?.profile,
      reminders: state.entity?.reminders, 
      userattributelookups: state.entity.userattributelookups,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getReminders: (oParams) => dispatch(getReminders(oParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        insertReminder: (oParams) => dispatch(insertReminder(oParams)),
        deleteReminder: (oParams) => dispatch(deleteReminder(oParams)),
        updateReminder: (oParams) => dispatch(updateReminder(oParams)),
        insertReminderEmail: (oParams) => dispatch(insertReminderEmail(oParams)),
        deleteReminderEmail: (oParams) => dispatch(deleteReminderEmail(oParams)),
        updateReminderEmail: (oParams) => dispatch(updateReminderEmail(oParams)),
        assignLookupSelection: (id, value) => dispatch(assignLookupSelection(id, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReminderList)