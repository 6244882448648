import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table, Image, DropdownButton, Dropdown, ButtonGroup, DropdownMenu } from 'react-bootstrap';
import { styles } from '../layout/styles';
import { connect } from 'react-redux';
import { getSkills, getUserAttributesLookups, insertSkill, deleteSkill, updateSkill, assignLookupSelection } from '../../store/actions/entityActions';
import Spinner from '../layout/Spinner';
import { useLocation } from 'react-router-dom';
import { accentColor } from '../../config/Colors';
import "../App.css";
import { getPermissionFlags } from '../layout/Utils';

const CompetencyList = (props) => {
    const { auth, companyData, profile, skills, userattributelookups, loadingMenu, fromLookupParam } = props;    
    const [loading, setLoading] = useState(false);
    const [nodeName, setnodeName] = useState('');
    const [nodeType, setnodeType] = useState('1');
    const [nodeTypeDescription, setnodeTypeDescription] = useState('Search Competency');
    const [sliderValue, setSliderValue] = useState([]);
    const [addNewCompetency, setAddNewCompetency] = useState(false);
    const [editCompetency, setEditCompetency] = useState(false);
    const [skillID, setSkillID] = useState(-1);
    const [skillName, setSkillName] = useState('');
    const [skillTypeID, setSkillTypeID] = useState(-1);
    const [skillSpecialityID, setSkillSpecialityID] = useState(-1);
    const [vendorID, setVendorID] = useState(-1);
    const [vendorSkillID, setVendorSkillID] = useState('');
    const [skillExternalUrl, setSkillExternalUrl] = useState('');
    const [selectedValue, setSelectedValue] = useState('');

    const skillList = skills?.skills?.Skills;
    const skillTypeData = userattributelookups?.SkillTypes;
    const skillSpecialityData = userattributelookups?.SkillSpecialities;
    const vendorData = userattributelookups?.Vendors;

    const location  = useLocation();
    let fromLookup = location?.state?.fromLookup;
    if ((fromLookup === null) || (fromLookup === undefined)) {
        fromLookup = fromLookupParam;
    }
    const [permissionFlags, setPermissionFlags] = useState(getPermissionFlags(location.state.permissiontypeid));

    function setSearchNodeName(e) {
        setnodeName(e.target.value);
    }

    // function changeSearchValue(evtKey, e) {
    //     console.log(evtKey);
    //     console.log(e);
    //     setnodeType(evtKey);
    //     if (evtKey === '1') {
    //         setnodeTypeDescription('Search Competency ');
    //     } else if (evtKey === '2') {
    //         setnodeTypeDescription('Search Competency Type');
    //     } else if (evtKey === '3') {
    //         setnodeTypeDescription('Search Competency Speciality');
    //     }
    // }

    function changeSearchValue(e) {
        console.log(e);
        if (e.target.innerHTML === 'Name') {
            setnodeTypeDescription('Search Competency');
            setnodeType('1');
        } else if (e.target.innerHTML === 'Type') {
            setnodeTypeDescription('Search Competency Type');
            setnodeType('2');
        } else if (e.target.innerHTML === 'Speciality') {
            setnodeTypeDescription('Search Competency Speciality');
            setnodeType('3');
        }
    }

    async function searchPillars() {
        setLoading(true);
        let oParams = {
            SkillName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        }; 
        if (nodeType === '2') {
            oParams = {
                SkillTypeName:nodeName,
                RecordSize:'10',
                PageNumber:'1',
            };     
        } else if (nodeType === '3') {
            oParams = {
                SkillSpecialityName:nodeName,
                RecordSize:'10',
                PageNumber:'1',
            };     
        }            
        console.log(oParams);
        await props.getSkills(oParams);
        setLoading(false);
    }
    
    async function previousPage() {
        setLoading(true);
        const previousPageNumber = skills?.skills?.PagingMetaData?.PageNumber - 1;
        const oParams = {
            SkillName:nodeName,
            RecordSize:'10',
            PageNumber:previousPageNumber,
        };            
        console.log(oParams);
        await props.getSkills(oParams);
        setLoading(false);
    }
    
    async function nextPage() {
        setLoading(true);
        const nextPageNumber = skills?.skills?.PagingMetaData?.PageNumber + 1;
        const oParams = {
            SkillName:nodeName,
            RecordSize:'10',
            PageNumber:nextPageNumber,
        };            
        console.log(oParams);
        await props.getSkills(oParams);
        setLoading(false);
    }
    
    const addInitialSliderArray = (competency) => {
        return skillList?.map((evalRec) => ({
                            SkillID:evalRec.SkillID, 
                            SkillName:evalRec.SkillName,
                            SkillTypeID:evalRec.SkillTypeID,
                            SkillSpecialityID:evalRec.SkillSpecialityID,
                            SkillTypeName:evalRec.SkillTypeName,
                            SkillSpecialityName:evalRec.SkillSpecialityName,
                            takeAction:false,
                            showDetails:false,
                            disableNewSkill:false,
                            newSkillClicked:false,
                            editSkillClicked:false,
        }))
    }
        
    useEffect(() => {
        let initialSliderValue = [];
        const initialScoreValues = addInitialSliderArray(skillList);
        setSliderValue(initialScoreValues);
    }, [skills])
    
    useEffect(() => {
        setLoading(loadingMenu);
    }, [loadingMenu])  
    
    async function handleAddChildernClick(tab, rowIndex) {
        setLoading(true);
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            (addNewCompetency) ? setAddNewCompetency(false) : setAddNewCompetency(true);
            setSkillID(-1);
            setSkillName('');
            setSkillTypeID(-1);
            setSkillSpecialityID(-1);
            setVendorID(-1);
            setVendorSkillID('');
            setSkillExternalUrl('');
        } 

        setLoading(false);
    }

    async function handleCancelChildernClick(tab, currentEval) {
        if (tab === 1) {
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.SkillID === currentEval?.SkillID) {
                    if (tab === 2) {
                        row.disableNewSkill = false;                    
                        row.newSkillClicked = false;
                        row.editSkillClicked = false;
                    } 
                }
            });
            setSliderValue(updatedSliderValues);    
            setSkillID(-1);
            setSkillName('');
            setSkillTypeID(-1);
            setSkillSpecialityID(-1);
            setVendorID(-1);
            setVendorSkillID('');
            setSkillExternalUrl('');
            setEditCompetency(false);
            setAddNewCompetency(false);
        } 
    }
    
    async function handleSaveChildernClick(tab, currentEval) {
        setLoading(true);
        if (tab === 1) {
            const oParams = {
                SkillName: skillName,
                SkillTypeID:skillTypeID,
                SkillSpecialityID:skillSpecialityID,
                VendorID:vendorID,
                VendorSkillID:vendorSkillID,
                ExternalUrl:skillExternalUrl,
            };        
            await props.insertSkill(oParams);    
        } 

        const evalParams1  = {
            SkillName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };        
        await props.getSkills(evalParams1);        
        setSkillID(-1);
        setSkillName('');
        setSkillTypeID(-1);
        setSkillSpecialityID(-1);
        setVendorID(-1);
        setVendorSkillID('');
        setSkillExternalUrl('');
        setAddNewCompetency(false);
        setEditCompetency(false);
        setLoading(false);
    }

    async function handleEditedSaveChildernClick(tab, currentEval) {
        setLoading(true);
        if (tab === 1) {
            const oParams  = {
                SkillID: skillID,
                SkillName: skillName,
                SkillTypeID:skillTypeID,
                SkillSpecialityID:skillSpecialityID,
                VendorID:vendorID,
                VendorSkillID:vendorSkillID,
                ExternalUrl:skillExternalUrl,
            };        
            await props.updateSkill(oParams);    

            const evalParams1  = {
                SkillName:nodeName,
                RecordSize:'10',
                PageNumber:'1',
            };        
            await props.getSkills(evalParams1);        
            setSkillID(-1);
            setSkillName('');
            setSkillTypeID(-1);
            setSkillSpecialityID(-1);
            setVendorID(-1);
            setVendorSkillID('');
            setSkillExternalUrl('');
            setEditCompetency(false);    
        } 

        setLoading(false);
    }

    async function handleUpdateChildernClick(tab, id, id1) {
        if (!userattributelookups) {
            await props.getUserAttributesLookups(auth?.UserID);
        }

        if (tab === 1) {
            const evalEdited = skillList?.filter((evalRec) => (evalRec.SkillID === id));
            const updatedSliderValues = sliderValue.slice(0);
            updatedSliderValues.map((row, index) => {
                if (row.SkillID === id) {
                        row.editCompetencyClicked = true;                    
                        row.disableNewCompetency = true;
                        row.newCompetencyClicked = false;
                        setSkillID(id);
                        setSkillName(evalEdited[0]?.SkillName);
                        setSkillTypeID(evalEdited[0]?.SkillTypeID);
                        setSkillSpecialityID(evalEdited[0]?.SkillSpecialityID);
                        setVendorID(evalEdited[0]?.VendorID);
                        setVendorSkillID(evalEdited[0]?.VendorSkillID);
                        setSkillExternalUrl(evalEdited[0]?.ExternalUrl);
                }
            });
            setEditCompetency(true);
            setSliderValue(updatedSliderValues);    
        } 
    }

    async function handleDeleteChildernClick(tab, id, id1) {
        setLoading(true);

        if (tab === 1) {
            const oParams  = {
                SkillID: id,
            };        
            console.log(oParams);
            await props.deleteSkill(oParams);    
        } 
        
        const oParams1  = {
            SkillName:nodeName,
            RecordSize:'10',
            PageNumber:'1',
        };        
        await props.getSkills(oParams1);            

        setLoading(false);
    }

    async function handleAddChildernValues(tab, rowIndex, value) {
        setLoading(true);

        // const updatedSliderValues = sliderValue.slice(0);
        // updatedSliderValues.map((row, index) => {
        //     if (row.SkillID === rowIndex) {
        //         if (tab === 1) {
        //             row.EvaluationQuestionGroupID = evalGroupData[value].EvaluationQuestionGroupID;
        //         } else if (tab === 2) {
        //             row.DisplayOrder = value;                    
        //         } else if (tab === 3) {
        //             row.Weight = value;
        //         } else if (tab === 4) {
        //             row.Optional = (value === '1') ? true : false;                    
        //         } else if (tab === 5) {
        //             row.EvaluationQuestion = value;                    
        //         } else if (tab === 6) {
        //             row.EvaluationRatingTypeID = value;                    
        //         } 
        //     }        
        // });
        // setSliderValue(updatedSliderValues);

        setLoading(false);
    }

    function checkMainPillar(tab, id, id1) {
        if (!fromLookup) {
            if (permissionFlags.CanModify) {
                return (
                    <div>
                        <a onClick={() => handleUpdateChildernClick(tab, id, id1)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Edit Competency'>edit</span>
                        </a>
                        <a onClick={() => handleDeleteChildernClick(tab, id, id1)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Delete Competency'>delete</span>
                        </a>
                    </div>
                );
            } else {
                return (<></>);
            }
        } else {
            return (
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" 
                        checked={getDetailSwitchValue(id, id1)} id={`switch${'detail' + id1}`} 
                        style={(getDetailSwitchValue(id, id1)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                        onChange={(e) => {handleSwitchDetailChange(id, id1)}}>
                    </input>
                    <label class="form-check-label" for={`switch${'detail' + id1}`}>Select</label>
                </div>
            );
        } 
    }

    function displayNewPillar() {
        if ((!fromLookup) && (addNewCompetency)) {
            return (
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td colSpan='2' style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="skillName" style={styles.LabelDataEntryNoPadding}>Name</label>
                                    <input id="skillName" type="text" style={styles.TextDataEntryNoPadding} onChange={(e) => (setSkillName(e.target.value))}/>
                                </div>                    
                            </td>
                            <td colSpan='2' style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="vendorlist" style={styles.LabelDataEntryNoPadding}>Vendor</label>
                                    <select id="vendorlist" class="browser-default" style={styles.TextDataEntryNoPaddingHeight100} onChange={(e) => (setVendorID(e.target.value))}>
                                        {vendorData && vendorData.map((vendor, supIndex) => (
                                            <option value={vendor.VendorID}>{vendor.VendorName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' style={{textAlign:'left', width:'25%'}}>
                                <div>
                                    <label htmlFor="reqSpeciality" style={styles.LabelDataEntryNoPadding}>Speciality</label>
                                    <select id="reqSpeciality" class="browser-default" style={styles.TextDataEntryNoPaddingHeight100} onChange={(e) => (setSkillSpecialityID(e.target.value))}>
                                        {skillSpecialityData && skillSpecialityData.map((skilltype, supIndex) => (
                                            <option value={skilltype.SkillSpecialityID}>{skilltype.SkillSpecialityName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                            <td colSpan='2' style={{textAlign:'left', width:'25%'}}>
                                <div>
                                    <label htmlFor="reqRatings" style={styles.LabelDataEntryNoPadding}>Type</label>
                                    <select id="reqRatings" class="browser-default" style={styles.TextDataEntryNoPaddingHeight100} onChange={(e) => (setSkillTypeID(e.target.value))}>
                                        {skillTypeData && skillTypeData.map((skilltype, supIndex) => (
                                            <option value={skilltype.SkillTypeID}>{skilltype.SkillTypeName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                        </tr>              
                        <tr>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="vendorskillid" style={styles.LabelDataEntryNoPadding}>Vendor Skill ID</label>
                                    <input id="vendorskillid" type="text" style={styles.TextDataEntryNoPadding} onChange={(e) => (setVendorSkillID(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="externalurl" style={styles.LabelDataEntryNoPadding}>External URL</label>
                                    <input id="externalurl" type="text" style={styles.TextDataEntryNoPadding} onChange={(e) => (setSkillExternalUrl(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'right', width:'5%'}}>
                                <div>
                                    <Button onClick={() => handleCancelChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey}>Cancel</Button>
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'5%'}}>
                                <div>
                                    <Button onClick={() => handleSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Skill'>Save</Button>
                                </div>
                            </td>
                        </tr>  
                    </tbody>
                </table>                                   
            );
        } else if ((!fromLookup) && (editCompetency)) {
            return (
                <table class="table table-sm caption-top text-left">  
                    <thead>
                    </thead>                        
                    <tbody> 
                        <tr>
                            <td colSpan='2' style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="skillName" style={styles.LabelDataEntryNoPadding}>Name</label>
                                    <input id="skillName" type="text" style={styles.TextDataEntryNoPadding} defaultValue={skillName} onChange={(e) => (setSkillName(e.target.value))}/>
                                </div>                    
                            </td>
                            <td colSpan='2' style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="vendorlist" style={styles.LabelDataEntryNoPadding}>Vendor</label>
                                    <select id="vendorlist" class="browser-default" style={styles.TextDataEntryNoPaddingHeight100} defaultValue={vendorID} onChange={(e) => (setVendorID(e.target.value))}>
                                        {vendorData && vendorData.map((vendor, supIndex) => (
                                            <option value={vendor.VendorID}>{vendor.VendorName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' style={{textAlign:'left', width:'25%'}}>
                                <div>
                                    <label htmlFor="reqSpeciality" style={styles.LabelDataEntryNoPadding}>Speciality</label>
                                    <select id="reqSpeciality" class="browser-default" style={styles.TextDataEntryNoPaddingHeight100} defaultValue={skillSpecialityID} onChange={(e) => (setSkillSpecialityID(e.target.value))}>
                                        {skillSpecialityData && skillSpecialityData.map((skilltype, supIndex) => (
                                            <option value={skilltype.SkillSpecialityID}>{skilltype.SkillSpecialityName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                            <td colSpan='2' style={{textAlign:'left', width:'25%'}}>
                                <div>
                                    <label htmlFor="reqRatings" style={styles.LabelDataEntryNoPadding}>Type</label>
                                    <select id="reqRatings" class="browser-default" style={styles.TextDataEntryNoPaddingHeight100} defaultValue={skillTypeID} onChange={(e) => (setSkillTypeID(e.target.value))}>
                                        {skillTypeData && skillTypeData.map((skilltype, supIndex) => (
                                            <option value={skilltype.SkillTypeID}>{skilltype.SkillTypeName}</option>        
                                        ))}
                                    </select>                                     
                                </div>
                            </td>
                        </tr>              
                        <tr>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="vendorskillid" style={styles.LabelDataEntryNoPadding}>Vendor Skill ID</label>
                                    <input id="vendorskillid" type="text" style={styles.TextDataEntryNoPadding} defaultValue={vendorSkillID} onChange={(e) => (setVendorSkillID(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'left'}}>
                                <div>
                                    <label htmlFor="externalurl" style={styles.LabelDataEntryNoPadding}>External URL</label>
                                    <input id="externalurl" type="text" style={styles.TextDataEntryNoPadding} defaultValue={skillExternalUrl} onChange={(e) => (setSkillExternalUrl(e.target.value))}/>
                                </div>                    
                            </td>
                            <td style={{textAlign:'right', width:'5%'}}>
                                <div>
                                    <Button onClick={() => handleCancelChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey}>Cancel</Button>
                                </div>
                            </td>
                            <td style={{textAlign:'left', width:'5%'}}>
                                <div>
                                    <Button onClick={() => handleEditedSaveChildernClick(1, null)} disabled={loading} style={styles.CDPillButtonSMGrey} title='Save Skill'>Save</Button>
                                </div>
                            </td>
                        </tr>  
                    </tbody>
                </table>                                   
            );
        }
    }

    function getDetailSwitchValue(rowIndex) {
        let bReturn = false;        
        sliderValue && sliderValue.map((row, index) => {
            if ((row) && (row.SkillID === rowIndex)) {
                bReturn = row.showDetails;
            }
        })
        return bReturn;
    }
 
    function handleSwitchDetailChange(rowIndex) {
        const updatedSliderValues = sliderValue.slice(0);//[...sliderValue];
        updatedSliderValues.map((row, index) => {
            if (row.SkillID === rowIndex) {
                if (row.showDetails) {
                    row.showDetails = false;
                } else {
                    row.showDetails = true;
                }
            }
        });
        setSliderValue(updatedSliderValues);
        getSelectedValue();
    }

    function getSelectedCount() {
        let iSelected = 0;
        sliderValue?.map((row, index) => {
            if (row.showDetails) {
                iSelected++;
            } 
        });
        return iSelected;
    }

    async function getSelectedValue() {
        let currentSelectedValue = '';
        sliderValue.map((row, index) => {
            if (row.showDetails) {
                if (currentSelectedValue !== '') {
                    currentSelectedValue += '&';
                }
                currentSelectedValue += row.SkillID + '=' + row.SkillName + '|' + row.SkillSpecialityID + '|' + row.SkillSpecialityName + '|' + row.SkillTypeID + '|' + row.SkillTypeName;
            } 
        });
        setSelectedValue(currentSelectedValue);
        await props.assignLookupSelection(15, currentSelectedValue);
        return currentSelectedValue;
    }

    // function getcurrentRecordIndex(){
    //     currentRecIndex++;        
    //     return currentRecIndex;
    // }

    function displayAddMainGoal() {
        if (!fromLookup) {
            if (permissionFlags.CanCreate) {
                if (addNewCompetency) {
                    return (
                        <a onClick={() => handleAddChildernClick(1, null)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Hide Competency'>shadow_minus</span>
                        </a>
                    );   
                } else {
                    return (
                        <a onClick={() => handleAddChildernClick(1, null)}>
                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Add Competency'>note_add</span>
                        </a>
                    );    
                }
            } else {
                return (<></>);
            }
        } else {
            return (<></>);
        }
    }

    function displayMyTeamHeader() {
        return (
            <Table style={{backgroundColor:companyData?.PagesBGColor}}>
            <tbody>
            <tr>
                <td style={{textAlign:'left', width:'60%', backgroundColor:companyData?.PagesBGColor}}><h3 style={styles.SubHeader}>Competencies</h3></td>
                <td style={{textAlign:'right', width:'40%', backgroundColor:companyData?.PagesBGColor}}>
                    <Table>
                        <tbody>
                            <tr>
                                <td style={{textAlign:'right', width:'5%', backgroundColor:companyData?.PagesBGColor}}>
                                    <Dropdown>
                                        <Dropdown.Toggle className='dropdown-search-transparent' id="dropdown-custom-components">
                                            <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>expand_circle_down</span>
                                        </Dropdown.Toggle>                                
                                        <DropdownMenu>
                                            {(nodeType === '1') ?
                                                <Dropdown.Item eventKey="1" className='dropdown-search-item-selected' onClick={changeSearchValue}>Name</Dropdown.Item>
                                                :
                                                <Dropdown.Item eventKey="1" className='dropdown-search-item-unselected' onClick={changeSearchValue}>Name</Dropdown.Item>
                                            }                                        
                                            {(nodeType === '2') ?
                                                <Dropdown.Item eventKey="2" className='dropdown-search-item-selected' onClick={changeSearchValue}>Type</Dropdown.Item>
                                                :
                                                <Dropdown.Item eventKey="2" className='dropdown-search-item-unselected' onClick={changeSearchValue}>Type</Dropdown.Item>
                                            }                                        
                                            {(nodeType === '3') ?
                                                <Dropdown.Item eventKey="3" className='dropdown-search-item-selected' onClick={changeSearchValue}>Speciality</Dropdown.Item>
                                                :
                                                <Dropdown.Item eventKey="3" className='dropdown-search-item-unselected' onClick={changeSearchValue}>Speciality</Dropdown.Item>
                                            }             
                                        </DropdownMenu>                           
                                    </Dropdown>
                                </td>
                                <td style={{textAlign:'left', width:'85%', backgroundColor:companyData?.PagesBGColor}}>
                                    <input id="searchSkillNode" type="text" style={styles.TextDataEntryNoPadding100} onChange={setSearchNodeName} placeholder={nodeTypeDescription}/>
                                </td>
                                <td style={{textAlign:'left', width:'5%', backgroundColor:companyData?.PagesBGColor}}>
                                    <a onClick={() => searchPillars()}>
                                        <span style={styles.ActionIconGrey} class="material-symbols-outlined" title='Search'>quick_reference_all</span>
                                    </a>
                                </td>
                                <td style={{textAlign:'left', width:'5%', backgroundColor:companyData?.PagesBGColor}}>
                                    {displayAddMainGoal()}                                        
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </td>
            </tr>
            </tbody>
        </Table>                            
        );
    }

    return (
        <div style={styles.MainInnerDiv}>
            <div style={styles.DashboardContainer}>
                <Card style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                        <Card.Header style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}> 
                            <Card.Title>
                                <div>
                                    {displayMyTeamHeader()}
                                </div>
                            </Card.Title>
                        </Card.Header>                   
                        {(loading) ? (            
                            <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                <Spinner/>
                            </div>
                        ) 
                        : 
                        (                                          

                        <Card.Body style={{ width:'100%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                            {displayNewPillar()}                                               
                            {((!skillList) || (skillList.length === 0)) ? (             
                                <div style={styles.tableText}>
                                    No Competencies.
                                </div>)
                                :
                                (
                                    <Table responsive="sm" striped bordered hover variant="light">
                                        <thead>
                                            <tr>
                                                <th style={styles.tableText}>#</th>
                                                <th style={styles.tableTextMain}>Name</th>
                                                <th style={styles.tableText}>Speciality</th>
                                                <th style={styles.tableText}>Type</th>
                                                <th style={styles.tableText}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {skillList && skillList.map((evalRec, index) => {
                                                return (
                                                    <>
                                                    <tr>
                                                        <td style={styles.tableText}>{evalRec?.SkillID}</td>
                                                        <td style={styles.tableTextSubHeader}>
                                                            {evalRec?.SkillName}
                                                            {/* <div class="form-check form-switch">
                                                                <input class="form-check-input" type="checkbox" role="switch" 
                                                                    checked={getDetailSwitchValue(evalRec?.SkillID)} id={`switch${'evaldetail' + evalRec?.SkillID}`} 
                                                                    style={(getDetailSwitchValue(evalRec?.SkillID)) ? styles.CDSwitch : styles.CDSwitchUnSelected}
                                                                    onChange={(e) => {handleSwitchDetailChange(evalRec?.SkillID)}}>
                                                                </input>
                                                                <label class="form-check-label" for={`switch${'detail' + evalRec?.UserID}`}>Details</label>
                                                            </div> */}
                                                        </td>
                                                        <td style={styles.tableText}>{evalRec?.SkillSpecialityName}</td>
                                                        <td style={styles.tableText}>{evalRec?.SkillTypeName}</td>
                                                        <td style={styles.tableText}>{checkMainPillar(1, evalRec?.SkillID, -1)}</td>
                                                    </tr>
                                                    </>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>                                                            
                                )
                            }
                        </Card.Body>
                        )}
                        <Card.Footer className="text-right" style={{alignItems:'end'}}>
                            <Table variant="light">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'left'}}>
                                            <h5 style={styles.tableTextLeft}>{skills?.skills?.RecordFound.toLocaleString()}   Competencies&nbsp;&nbsp;({getSelectedCount()} Selected)</h5>
                                        </td>
                                        <td style={{ width:'40%', backgroundColor:companyData?.PagesBGColor, justifyContent:'center'}}>
                                            {(loading) ? <></> : 
                                                <div style={{display:'flex', justifyContent:'center'}}>
                                                <h5 style={styles.tableText}>
                                                {(skills?.skills?.PagingMetaData?.PreviousPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => previousPage()}>
                                                            <span title='Previous Page'>Previous</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                {(skills?.skills?.PagingMetaData?.NextPage) ? (
                                                        <a style={{cursor:'pointer'}} onClick={() => nextPage()}>
                                                            <span  title='Next Page'>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
                                                        </a>
                                                ) 
                                                : 
                                                <></> }
                                                </h5>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width:'30%', backgroundColor:companyData?.PagesBGColor, justifyContent:'right'}}>
                                            <h5 style={styles.tableTextRight}> Page   {skills?.skills?.PagingMetaData?.PageNumber}  of  {skills?.skills?.PagingMetaData?.TotalPage.toLocaleString()}</h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Footer>
                    </Card>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth.user,
      companyData: state?.auth?.companyData,
      profile: state.auth.profile,
      skills: state?.entity?.skills,
      userattributelookups: state.entity.userattributelookups,
      loadingMenu: state.auth.loadingMenu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSkills: (oParams) => dispatch(getSkills(oParams)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        insertSkill: (oParams) => dispatch(insertSkill(oParams)),
        deleteSkill: (oParams) => dispatch(deleteSkill(oParams)),
        updateSkill: (oParams) => dispatch(updateSkill(oParams)),
        assignLookupSelection: (id, value) => dispatch(assignLookupSelection(id, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompetencyList)