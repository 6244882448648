import React, { useContext, useEffect, useRef, useState } from 'react'
import { Form, Button, Card, CardBody, CardHeader, CardTitle, Image, Alert, CardFooter} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { Container } from "react-bootstrap"
import { assignActiveMenu, validateCompany } from '../../store/actions/authActions'
import { insertUser, getUserAttributesLookups } from '../../store/actions/entityActions';
import { connect } from 'react-redux'
import { styles } from '../layout/styles'
import ReCAPTCHA from 'react-google-recaptcha';
import moment from 'moment'
import Spinner from '../layout/Spinner';

function Signup(props) {
    const { auth, companyData, profile, entityError, entitySuccess, userattributelookups } = props;

    const firstnameRef = useRef();
    const lastnameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const [error, setError] = useState('');
    const [showErrorMsg, setShowErrorMsg] = useState(true);
    const [loading, setLoading] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [disableSignUp, setDisableSignUp] = useState(true);
    const [schoolID, setSchoolID] = useState(-1);
    const [divisionID, setDivisionID] = useState(companyData?.GuestDivision);
    const [employmentTypeID, setEmploymentTypeID] = useState(companyData?.GuestEmploymentType);
    const [jobPositionID, setJobPositionID] = useState(companyData?.GuestJobPosition);
    const navigate = useNavigate();

    const schoolData = userattributelookups?.Schools;
    const guestDivisionData = userattributelookups?.GuestDivisions;

    useEffect(() => {
        props.getUserAttributesLookups(-1);
    }, [])  

    useEffect(() => {
        if (loading === true) {
            setDisableSignUp(true);
        } else if (recaptchaValue === null) {
            setDisableSignUp(true);
        } else {
            setDisableSignUp(false);
        }
    }, [loading])  

    useEffect(() => {
        if (recaptchaValue === null) {
            setDisableSignUp(true);
        } else if (loading === true) {
            setDisableSignUp(true);
        } else {
            setDisableSignUp(false);
        }
    }, [recaptchaValue])  

    function checkSignUpDisable() {
        console.log(recaptchaValue);
        if ((loading) || (!recaptchaValue)) {
            return true;
        } else {
            return false;
        }
    }

    async function handleSubmit(e) {
        e.preventDefault()

        const regPassword = new RegExp("^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$");
        const isPasswordString = regPassword.test(passwordRef.current.value);

        if ((companyData?.CheckForPasswordComplexity) && (!isPasswordString)) {
            return setError('Your password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character to ensure its strength.')
        }

        if (passwordRef.current.value !== passwordConfirmRef.current.value){
            return setError('Password do not match!')
        }
        if (parseInt(schoolID) === -1){
            return setError('Please select School!')
        }
        if (parseInt(divisionID) === -1){
            return setError('Please select Guest Facility!')
        }

        try {
            setError('');
            setLoading(true);
            const now = moment();
            // Get the number of milliseconds since the Unix epoch
            const milliseconds = now.valueOf();          
            // Calculate ticks (10,000 ticks per millisecond)
            const ticks = milliseconds * 10000;            
            //await signup(emailRef.current.value, passwordRef.current.value)
            const creds = [{
                LastName:lastnameRef.current.value,
                FirstName:firstnameRef.current.value,
                EmployeeID:'GUEST' + ticks.toString(),
                DateHiredyyyymmdd: moment().toDate(),
                JobPosition:jobPositionID,
                Department:divisionID,
                StatusAOrI:'A',
                Email:emailRef.current.value, 
                ActiveDirectoryID:emailRef.current.value,
                PasswordBlankField:passwordRef.current.value,
                EmploymentTypeName:employmentTypeID,
                School:schoolID,
                Company:companyData?.CompanyID,
                CustomUserFields:'',
            }];
            await props.insertUser(creds);
            console.log(entitySuccess);
            if (entityError) {
                console.log(entityError);
                setError(entityError.message)
            }
            else if (entitySuccess) {
                console.log(entitySuccess?.returnParams[0]?.DataSyncMessageOut);
                const sMsg = entitySuccess?.returnParams[0]?.DataSyncMessageOut;
                if (entitySuccess?.returnParams[0]?.UserID > 0) {
                    sMsg += ' Please login using your credentials.';
                }
                setError(sMsg);
            }
        } catch {
            setError('Failed to create an account')
        }
        setLoading(false)
    }

    function displayLoginError() {
        if (showErrorMsg) {
            if (error) {
                return (
                    <Alert variant="danger" style={{textAlign:'center'}}>{error}</Alert>
                );    
            } else if ((entityError) && ((typeof entityError === 'string') )) {
                return (
                    <Alert variant="danger" style={{textAlign:'center'}}>{entityError}</Alert>
                );    
            } else 
            return (
                <></>
            );    
        } else {
            return (
                <></>
            );    
        }
    }

    function displayLogin() {
        const slink = "/login/" + companyData?.CompanyID;
        return (
            <div style={styles.Label}>
                Already have an account? <Link style={styles.Link} to={slink}>Login</Link>
            </div>
        );    
    }

  return (
    <>
        <div style={{position:'absolute', width:'40%', height:'220px', top:'12%', left:'56%'}}>
            <Form onSubmit={handleSubmit}>
                <Card style={{width:'100%', backgroundColor:'transparent'}}> 
                    <CardHeader> 
                        <CardTitle>
                            <span>
                                <h1 style={styles.Header}><Image style={{width:'240px', height:'100px'}} src={companyData?.CompanyLogo}/></h1>
                            </span>
                        </CardTitle>
                        <Card.Subtitle>
                            <span>
                                <h4 style={styles.Header}>Registration</h4>
                            </span>
                            {displayLoginError()}
                        </Card.Subtitle>
                    </CardHeader>
                    <CardBody>
                        {/* {error && <Alert variant="danger">{error}</Alert>} */}
                        {(loading) ? (            
                            <Card.Body>
                                <div style={{display:'flex', width:'100%', height:'100%', textAlign:'center'}}>
                                    <Spinner/>
                                </div>
                            </Card.Body>
                        ) 
                        : 
                        (                            
                            <>
                                <Form.Group id='firstname'>
                                    <Form.Label style={styles.LabelNoPadding}>First Name</Form.Label>
                                    <Form.Control type='text' ref={firstnameRef} required></Form.Control>
                                </Form.Group>
                                <Form.Group id='lastname'>
                                    <Form.Label style={styles.LabelNoPadding}>Last Name</Form.Label>
                                    <Form.Control type='text' ref={lastnameRef} required></Form.Control>
                                </Form.Group>
                                <Form.Group id='email'>
                                    <Form.Label style={styles.LabelNoPadding}>Email</Form.Label>
                                    <Form.Control type='email' ref={emailRef} required></Form.Control>
                                </Form.Group>
                                <Form.Group id='password'>
                                    <Form.Label style={styles.LabelNoPadding}>Password</Form.Label>
                                    <Form.Control type='password' ref={passwordRef} required></Form.Control>
                                </Form.Group>
                                <Form.Group id='password-confirm'>
                                    <Form.Label style={styles.LabelNoPadding}>Confirm Password</Form.Label>
                                    <Form.Control type='password' ref={passwordConfirmRef} required></Form.Control>
                                </Form.Group>
                                <Form.Group id='schoolg'>
                                    <Form.Label style={styles.LabelNoPadding}>School</Form.Label>
                                    <Form.Select id="school" required class="browser-default" style={{backgroundColor:'transparent'}} onChange={(e) => (setSchoolID(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        {schoolData?.map((school, oIndex) => (
                                            <option value={school.SchoolID}>{school.SchoolName}</option>        
                                        ))}
                                    </Form.Select> 
                                </Form.Group>
                                <Form.Group id='divisiong'>
                                    <Form.Label style={styles.LabelNoPadding}>Guest Facility</Form.Label>
                                    <Form.Select id="division" required class="browser-default" style={{backgroundColor:'transparent'}} onChange={(e) => (setDivisionID(e.target.value))}>
                                        <option value="-1">Select One</option>
                                        {guestDivisionData?.map((division, oIndex) => (
                                            <option value={division.DivisionID}>{division.DivisionName}</option>        
                                        ))}
                                    </Form.Select> 
                                </Form.Group>
                                <Form.Group id='recaptcha'>
                                    <div className="w-100 text-center mt-3">
                                        <ReCAPTCHA style={{backgroundColor:'transparent'}} sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY} onChange={(value) => setRecaptchaValue(value)}/>
                                    </div>
                                </Form.Group>
                            </>              
                        )}
                    </CardBody>
                    <CardFooter>
                        <div className="w-100 text-center mt-3">
                            <Button disabled={disableSignUp} style={(disableSignUp) ? styles.CDButtonDisabled : styles.CDButton} type="submit">
                                Sign Up
                            </Button>
                        </div>
                        {displayLogin()}
                    </CardFooter>
                </Card>
            </Form>
        </div>
    </>
  )
}

const mapsStateToProps = (state) => {
    return {
        companyData: state?.auth?.companyData,
        auth: state?.auth?.user,
        profile: state?.auth?.profile,
        userattributelookups: state.entity.userattributelookups,
        entityError: state?.entity?.entityError,
        entitySuccess: state?.entity?.entitySuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // signUp: (newUser) => dispatch(signUp(newUser)),
        validateCompany: (id, completeQueryStr) => dispatch(validateCompany(id, completeQueryStr)),
        getUserAttributesLookups: (id) => dispatch(getUserAttributesLookups(id)),
        insertUser: (oParam) => dispatch(insertUser(oParam)),
    }
}

export default connect(mapsStateToProps, mapDispatchToProps)(Signup)


