import axios from "axios";
import { tokendata, tokenExpiresOn} from '../config/Constant';
import { useDispatch, useSelector } from "react-redux";
import { store, persistor } from '../store/store' 
import * as actionTypes from '../store/actions/actionTypes';
import Moment from 'moment';

const RetentasAPI = axios.create();

let rtapitokendata = null;
let rtapitokenexpirydate = null

function objToQueryString(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
        keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
    }
    return keyValuePairs.join('&');
}

async function generateRTToken(companyData) {
    // var date = moment().format('YYYY-MM-DD hh:mm:ss a');
    // let compID = '';
    // if ((!cid) || (cid === '-1')) {
    //     compID = '';
    // } else {
    //     compID = cid;
    // }

    const apiKey = companyData?.WebApiClientID;
    const apiSecret = companyData?.WebApiClientSecret;
    const apiGrant = process.env.REACT_APP_RETENTASAPI_GRANTTYPE;

    // const queryParameter = objToQueryString({
    //     client_id: process.env.REACT_APP_RETENTASAPI_KEY,
    //     client_secret: process.env.REACT_APP_RETENTASAPI_SECRET,
    //     grant_type: process.env.REACT_APP_RETENTASAPI_GRANTTYPE
    // });
    const queryParameter = objToQueryString({
        client_id: apiKey,
        client_secret: apiSecret,
        grant_type: apiGrant
    });
    // console.log(queryParameter);
    const url = process.env.REACT_APP_RETENTASAPI_TOKENURL;
    // console.log("RTAPI Token URL - " + url);
    try {
        const res = await axios.post(url, queryParameter);
        // console.log('token', res.data.access_token);
        // console.log('token data', res.data);
        rtapitokendata = res;
        const currentUTCDate = Moment(Moment.utc().valueOf());
        // console.log('CurrentUTCDate', currentUTCDate);				
        var tokenExpiryDate = currentUTCDate.add(res.data.expires_in - 5, "second").utc().format(); 
        // console.log('token expires in', res.data.expires_in.toString());				
        // console.log('token expires on', tokenExpiryDate);				
        rtapitokenexpirydate = tokenExpiryDate;
    } catch (error) {
        console.log('Error getting RTToken - ' + error);
    }

};


RetentasAPI.interceptors.request.use(async req => {
    const currState = store.getState();
    const currtokenState = currState?.auth?.rtapitoken?.rtapitokendata;
    const companyData = currState?.auth?.companyData;

    let authTokens = null;
    let accessToken = null;
    let refreshToken = null;
    let isTokenExpired = false;

    if (currtokenState?.data.access_token) {
        // do nothing
        // console.log('Old token');
    } else {
        // console.log('Fresh token');
        await generateRTToken(companyData);
        store.dispatch( {type: actionTypes.APITOKEN_SUCCESS, rtapitokendata } );
    }

    const newState = store.getState();
    const newtokenState = newState?.auth?.rtapitoken?.rtapitokendata;

    authTokens = newtokenState?.data;
    accessToken = authTokens.access_token;
    refreshToken = authTokens.refresh_token;
    // console.log("Current Token data - " + authTokens);
    // console.log("Current Parse Token data - " + accessToken);

    if(authTokens){
        req.headers.ContentType = 'application/json';   
        req.headers.Authorization = `Bearer ${authTokens.access_token}`
        // console.log("Request Header - " + req.headers.Authorization);
        // console.log("Request Header - " + req.headers.ContentType);
    }

    if (authTokens[".expires"]) {
        var currentDate = Moment().toDate(); //To get the Current Date
        // console.log(currentDate);
        // console.log(authTokens[".expires"]);
        var elapseSeconds = Moment(currentDate).diff(authTokens[".expires"], 'seconds');
        // console.log(elapseSeconds);
        if (elapseSeconds > 0) {
            isTokenExpired = true;
        }    
        // console.log("Is Token Expired - " + isTokenExpired);
    }

    if(!isTokenExpired) return req

    const cid = newState?.auth?.companyID;
    const apiKey = companyData?.WebApiClientID;
    const apiSecret = companyData?.WebApiClientSecret;
    const apiGrant = process.env.REACT_APP_RETENTASAPI_GRANTTYPE;

    // const queryParameter = objToQueryString({
    //     client_id: process.env.REACT_APP_RETENTASAPI_KEY,
    //     client_secret: process.env.REACT_APP_RETENTASAPI_SECRET,
    //     grant_type: process.env.REACT_APP_RETENTASAPI_GRANTTYPE
    // });
    const queryParameter = objToQueryString({
        client_id: apiKey,
        client_secret: apiSecret,
        grant_type: apiGrant
    });
    // console.log('Refresh Token Params -', queryParameter);

    // const queryParameter = objToQueryString({
    //     client_id: process.env.REACT_APP_RETENTASAPI_KEY,
    //     client_secret: process.env.REACT_APP_RETENTASAPI_SECRET,
    //     grant_type: process.env.REACT_APP_RETENTASAPI_GRANTTYPE
    // });
    const baseURL = process.env.REACT_APP_RETENTASAPI_TOKENURL;
    // console.log('Refresh Token URL -', baseURL);

    const response = await axios.post(baseURL, queryParameter);
    // console.log('Refresh Token Response -', response);

    store.dispatch( {type: actionTypes.APITOKEN_SUCCESS, response } );

    req.headers.Authorization = `Bearer ${response.data.access_token}`
    // console.log("Acquired New Token - " + response.data.access_token);
    return req
})

export default RetentasAPI;
