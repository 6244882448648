import { PermissionType } from "../../config/Constant";

export const getPermissionFlags = (iPermissionType) => {
    // Determine flags based on iPermissionType
    const CanCreate = iPermissionType === PermissionType.OnlyCreateNoModify || iPermissionType === PermissionType.FullControl;
    const CanModify = iPermissionType === PermissionType.OnlyModifyNoCreate || iPermissionType === PermissionType.FullControl;
    const CanDelete = iPermissionType === PermissionType.FullControl;

    return {
        CanCreate,
        CanModify,
        CanDelete,
    };
}